const LoadScriptMixin = {
  created() {
    if (
      this.$options.scriptSrc &&
      this.$options.scriptSrc.trim() &&
      this.$options.scriptSrc.trim().length > 0
    ) {
      this.loadScript().then(this.onScriptLoaded);
    }
  },
  beforeUnmount() {
    this.unloadScript();
  },
  methods: {
    loadScript() {
      return new Promise((resolve, reject) => {
        let shouldAppend = false;
        let el = document.querySelector(
          `script[src="${this.$options.scriptSrc}"]`
        );
        if (!el) {
          el = document.createElement('script');
          el.type = 'text/javascript';
          el.async = true;
          el.src = this.$options.scriptSrc;
          shouldAppend = true;
        } else if (el.hasAttribute('data-loaded')) {
          resolve(el);
          return;
        }

        el.addEventListener('error', reject);
        el.addEventListener('abort', reject);
        el.addEventListener('load', () => {
          el.setAttribute('data-loaded', true);
          resolve(el);
        });

        if (shouldAppend) document.head.appendChild(el);
      });
    },
    unloadScript() {
      return new Promise((resolve, reject) => {
        const el = document.querySelector(
          `script[src="${this.$options.scriptSrc}"]`
        );

        if (!el) {
          reject();
          return;
        }

        document.head.removeChild(el);

        resolve();
      });
    },
    onScriptLoaded() {},
  },
};

export default LoadScriptMixin;
