<template>
  <loading-spinner v-if="!blueprintList" />
  <div v-else-if="blueprintList.length === 0">
    <a-card style="margin: 20px auto; width: 500px">
      No Collections created yet.
    </a-card>
  </div>
  <a-layout-content v-else style="padding: 0 20px">
    <a-layout style="padding: 24px 0; background: #fff">
      <a-layout-sider width="200" style="background: #fff">
        <a-menu
          @click="onMenuItemClick"
          mode="inline"
          style="height: 100%"
          :selected-keys="[blueprintId]"
          :default-selected-keys="MenuDefaultSelectedKeys"
        >
          <a-menu-item v-for="blueprint in blueprintList" :key="blueprint._id">
            {{ blueprint.name }}
          </a-menu-item>
        </a-menu>
      </a-layout-sider>
      <a-layout-content :style="{ padding: '0 24px', minHeight: '280px' }">
        <template v-if="!blueprintId">
          <a-card style="width: 500px">
            Select a Collection to see it's entries
          </a-card>
        </template>
        <template v-else>
          <InstanceList :blueprintId="blueprintId" />
        </template>
      </a-layout-content>
    </a-layout>
  </a-layout-content>
</template>

<script>
import { blueprintApi } from '@dataSystem/api';

import { InstanceList } from '@dataSystem/components/InstanceList';

export default {
  components: {
    InstanceList,
  },
  props: ['blueprintId'],
  data() {
    return {
      blueprintList: null,
    };
  },
  created() {
    this.fetchBlueprintList();
  },
  computed: {
    MenuDefaultSelectedKeys() {
      if (this.blueprintId) {
        return [this.blueprintId];
      }
      return [];
    },
  },
  methods: {
    async fetchBlueprintList() {
      this.blueprintList = await blueprintApi.getAll();
      if (!this.blueprintId && this.blueprintList.length > 0) {
        this.$router.push({
          name: 'SubtenantBlueprintList',
          params: { blueprintId: this.blueprintList[0]._id },
        });
      }
    },
    onMenuItemClick({ key }) {
      this.$router.push({
        name: 'SubtenantBlueprintList',
        params: { blueprintId: key },
      });
    },
  },
};
</script>

<style></style>
