<template>
  <div>
    <div
      v-if="loading"
      style="
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      "
    >
      <a-spin />
    </div>
    <div v-else>
      <div class="saas_sections">
        <div class="sidebar">
          <div class="actions__timeline">
            <a-timeline>
              <a-timeline-item :color="'green'" class="pointer">
                <template #dot>
                  <LoginOutlined />
                </template>
                <p @click="openSection(0, 'DataProviderInput')">
                  <span :class="dataProviderSectionId === 0 ? 'strong' : ''">
                    INPUT
                  </span>
                </p>
              </a-timeline-item>
              <a-timeline-item :color="'green'" class="pointer">
                <template #dot>
                  <ApartmentOutlined />
                </template>
                <p @click="openSection(1, 'DataProviderSources')">
                  <span :class="dataProviderSectionId === 1 ? 'strong' : ''">
                    SOURCES
                  </span>
                </p>

                <template v-if="selectedDataProvider.sources">
                  <div
                    class="line"
                    v-for="source in selectedDataProvider.sources"
                    :key="source._id"
                  >
                    <span
                      @click="openSource(source.new ? 'new' : source._id)"
                      :style="
                        sourceId === source._id ||
                        (sourceId === 'new' && source.new)
                          ? 'color:#000'
                          : ''
                      "
                    >
                      {{ source.name ? source.name : 'New source' }}
                    </span>
                  </div>
                </template>
              </a-timeline-item>
              <a-timeline-item :color="'green'" class="pointer">
                <template #dot>
                  <LogoutOutlined />
                </template>
                <p @click="openSection(2, 'DataProviderJoins')">
                  <span :class="dataProviderSectionId === 2 ? 'strong' : ''">
                    OUTPUT
                  </span>
                </p>
              </a-timeline-item>
              <a-timeline-item :color="'green'" class="pointer">
                <template #dot>
                  <DatabaseOutlined />
                </template>
                <p @click="openSection(4, 'DataProviderData')">
                  <span :class="dataProviderSectionId === 4 ? 'strong' : ''">
                    DATA
                  </span>
                </p>
              </a-timeline-item>
              <a-timeline-item :color="'green'" class="pointer">
                <template #dot>
                  <ContainerOutlined />
                </template>
                <p @click="openSection(3, 'DataProviderLogs')">
                  <span :class="dataProviderSectionId === 3 ? 'strong' : ''">
                    LOGS
                  </span>
                </p>
              </a-timeline-item>
              <a-timeline-item
                :color="'green'"
                class="pointer"
                v-if="selectedDataProvider.type === 'manual'"
              >
                <template #dot>
                  <ColumnWidthOutlined />
                </template>
                <p @click="openSection(5, 'DataProviderEmbed')">
                  <span :class="dataProviderSectionId === 5 ? 'strong' : ''">
                    Embed code
                  </span>
                </p>
              </a-timeline-item>
            </a-timeline>
          </div>
        </div>

        <div class="content-view">
          <div class="content-body">
            <h2 style="margin: 0">{{ selectedDataProvider.name }}</h2>
            <h3>{{ dataProviderSectionName }}</h3>
            <DataProviderInputBuilder
              v-if="dataProviderSectionId === 0"
              :selectedDataProvider="selectedDataProvider"
              :blueprints="blueprints"
            />
            <DataProviderSourcesBuilder
              v-else-if="dataProviderSectionId === 1"
              :dataProviderId="dataProviderId"
              :selectedDataProvider="selectedDataProvider"
              :sourceId="sourceId"
            />
            <DataProviderJoinsBuilder
              v-else-if="dataProviderSectionId === 2"
              :dataProviderId="dataProviderId"
              :selectedDataProvider="selectedDataProvider"
              :sourceId="sourceId"
            />
            <DataProviderLogs
              v-else-if="dataProviderSectionId === 3"
              :dataProviderId="dataProviderId"
              :selectedDataProvider="selectedDataProvider"
              :sourceId="sourceId"
            />
            <DataProviderBuilderData
              v-else-if="dataProviderSectionId === 4"
              :dataProviderId="dataProviderId"
              :selectedDataProvider="selectedDataProvider"
              :sourceId="sourceId"
            />
            <DataProviderBuilderEmbed
              v-else-if="dataProviderSectionId === 5"
              :selectedDataProvider="selectedDataProvider"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  DataProviderActions,
  DataProviderGetters,
} from '@dataProvider/shared/dataProvider.store';
import blueprintApi from '@dataSystem/api/blueprint.api';
import {
  ApartmentOutlined,
  ColumnWidthOutlined,
  ContainerOutlined,
  DatabaseOutlined,
  LoginOutlined,
  LogoutOutlined,
} from '@ant-design/icons-vue';

import DataProviderSourcesBuilder from '@/apps/dataProvider/views/dataProviderBuilder/components/sources/sourcesBuilder.vue';
import DataProviderInputBuilder from '@/apps/dataProvider/views/dataProviderBuilder/components/input/inputBuilder.vue';
import DataProviderJoinsBuilder from '@/apps/dataProvider/views/dataProviderBuilder/components/joins/joinsBuilder.vue';
import DataProviderLogs from '@/apps/dataProvider/views/dataProviderBuilder/components/logs/logsBuilder.vue';
import DataProviderBuilderData from '@/apps/dataProvider/views/dataProviderBuilder/components/data/dataBuilder.vue';
import DataProviderBuilderEmbed from '@/apps/dataProvider/views/dataProviderBuilder/components/embed/embedBuilder.vue';

export default {
  name: 'DataProviderBuilder',
  data() {
    return {
      loading: true,

      dataProviderSectionName: this.$route.meta.sectionName,
      dataProviderSectionId: this.$route.meta.sectionId,

      dataProviderId: this.$route.params.dataProviderId
        ? this.$route.params.dataProviderId
        : null,
      sourceId: this.$route.params.sourceId
        ? this.$route.params.sourceId
        : null,

      blueprints: [],
    };
  },
  watch: {
    $route(to) {
      this.dataProviderSectionName = to.meta.sectionName;
      this.dataProviderSectionId = to.meta.sectionId;
      this.dataProviderId = to.params?.dataProviderId
        ? to.params.dataProviderId
        : null;
      this.sourceId = to.params.sourceId ? to.params.sourceId : null;
    },
  },
  async mounted() {
    await DataProviderActions.fetchOneDataProvider(this.dataProviderId);
    this.blueprints = await blueprintApi.getAll();
    this.loading = false;
  },
  computed: {
    selectedDataProvider: DataProviderGetters.getDataProvider,
  },

  methods: {
    openSection(sectionId, name) {
      if (this.dataProviderSectionId !== sectionId || this.sourceId) {
        this.$router.push({
          name,
          params: { dataProviderId: this.dataProviderId },
        });
      }
    },
    openSource(sourceId) {
      if (this.sourceId !== sourceId) {
        this.$router.push({
          name: 'DataProviderSource',
          params: { dataProviderId: this.dataProviderId, sourceId },
        });
      }
    },
  },
  components: {
    DataProviderBuilderEmbed,
    DataProviderBuilderData,
    DataProviderLogs,
    DataProviderJoinsBuilder,
    DataProviderInputBuilder,
    DataProviderSourcesBuilder,
    ApartmentOutlined,
    ColumnWidthOutlined,
    ContainerOutlined,
    DatabaseOutlined,
    LoginOutlined,
    LogoutOutlined,
  },
};
</script>
<style scoped>
.actions__timeline .line {
  position: relative;
}
.actions__timeline .line::before {
  content: '';
  position: absolute;
  height: 2px;
  width: 8px;
  display: block;
  background-color: #e8e8e8;
  left: -13px;
  top: 50%;
  transform: translateY(-50%);
}
.router-link-active {
  font-weight: bold;
}
.strong {
  font-weight: bold;
}
</style>
