<template>
  <div>
    <a-row type="flex">
      <a-col flex="250px">
        <RoleSelect
          :roles="roles"
          @select="onRoleSelect"
          @create="onRoleCreate"
          @remove="onRoleRemove"
        />
      </a-col>
      <a-col flex="auto">
        <div v-if="selectedRole" style="margin: 0 12px">
          <h2 style="margin-top: 46px">{{ selectedRole.name }} Permissions</h2>
          <a-divider style="margin-top: 5px" />
          <PermissionByBlueprint
            :blueprintById="blueprintById"
            :permissionByBlueprintId="selectedRole.permissionByBlueprintId"
            @update="onPermissionUpdate"
          />
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { roleApplicationApi } from '@roleManagement/api';
import { blueprintApi } from '@dataSystem/api';
import RoleSelect from './components/RoleSelect.vue';

import PermissionByBlueprint from './components/PermissionByBlueprint.vue';

export default {
  data() {
    return {
      roles: [],
      blueprints: [],
      selectedRole: null,
    };
  },
  created() {
    this.fetchRoles();
    this.fetchBlueprints();
  },
  components: {
    RoleSelect,
    PermissionByBlueprint,
  },
  computed: {
    blueprintById() {
      return Object.fromEntries(
        this.blueprints.map(blueprint => [blueprint._id, blueprint])
      );
    },
  },
  methods: {
    setUpdatedRole(updatedRole) {
      const roleIndex = this.roles.findIndex(
        role => role._id === updatedRole._id
      );
      this.roles[roleIndex] = updatedRole;
      if (this.selectedRole._id === updatedRole._id) {
        this.selectedRole = updatedRole;
      }
    },
    onRoleSelect(selectedRoleId) {
      this.selectedRole = this.roles.find(role => role._id === selectedRoleId);
    },
    async fetchBlueprints() {
      this.blueprints = await blueprintApi.getAll();
    },
    async fetchRoles() {
      this.roles = await roleApplicationApi.getAllForApplication();
    },
    async onRoleCreate(newRoleName) {
      const newRole = await roleApplicationApi.postOne({
        roleInput: {
          name: newRoleName,
        },
      });
      this.roles.push(newRole);
    },
    async onRoleRemove(roleIdToRemove) {
      await roleApplicationApi.deleteOne({ roleId: roleIdToRemove });

      if (this.selectedRole._id === roleIdToRemove) {
        this.selectedRole = null;
      }
      this.roles = this.roles.filter(role => role._id !== roleIdToRemove);
    },
    async onPermissionUpdate({ blueprintId, permissionInput }) {
      const updatedRole = await roleApplicationApi.patchPermission({
        roleId: this.selectedRole._id,
        blueprintId,
        permissionInput,
      });
      this.setUpdatedRole(updatedRole);
    },
  },
};
</script>

<style></style>
