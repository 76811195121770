<template>
  <div>
    <a-drawer
      placement="right"
      :closable="false"
      :width="300"
      :open="!!openedWorkflowId"
      @close="cancelChanges"
    >
      <template #title>
        <a-input
          placeholder="Workflow name"
          v-model:value="localSelectedWorkflow.name"
        />
      </template>

      <div style="padding-top: 10px">
        Active
        <a-switch
          checked-children="YES"
          v-model:checked="localSelectedWorkflow.activ"
          un-checked-children="NO"
        ></a-switch>
      </div>
      <br />
      <a-button
        :loading="loadingSaveChanges"
        type="primary"
        @click="saveChanges"
      >
        <FileDoneOutlined /> Save
      </a-button>
      <div
        :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
      >
        <a-button class="mr-2" @click="cancelChanges"> Cancel </a-button>
        <a-popconfirm
          placement="topLeft"
          title="Are you sure delete this event?"
          ok-text="Yes"
          cancel-text="No"
          @confirm="removeAction"
        >
          <a-button type="primary" danger>Delete</a-button>
        </a-popconfirm>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import router from '@/router';
import { FileDoneOutlined } from '@ant-design/icons-vue';
import { WorkflowActions } from '@workflow/shared/workflow.store';

export default {
  props: [
    'workflowGroupId',
    'openedWorkflowId',
    'workflows',
    'selectedWorkflow',
  ],
  emits: ['settings-close'],
  components: { FileDoneOutlined },
  data() {
    return {
      loadingSaveChanges: false,
      localSelectedWorkflow: { ...this.selectedWorkflow },
    };
  },
  methods: {
    async saveChanges() {
      this.loadingSaveChanges = true;
      await WorkflowActions.editSelectedWorkflow(this.selectedWorkflow, {
        ...this.localSelectedWorkflow,
      });
      this.$emit('settings-close');
      this.loadingSaveChanges = false;
    },
    cancelChanges() {
      this.localSelectedWorkflow = { ...this.selectedWorkflow };
      this.$emit('settings-close');
    },
    removeAction() {
      WorkflowActions.removeOneWorkflow(this.openedWorkflowId);

      router.push({
        name: 'WorkflowBuilder',
        params: { workflowGroupId: this.workflowGroupId },
      });

      this.$emit('settings-close');
    },
  },
};
</script>
