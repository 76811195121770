import axios from 'axios';

const getAll = async (options = {}) => {
  const params = options ? { ...options } : null;

  try {
    const response = await axios.get('/tenant', { params });
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response.data.error.message,
    };
  }
};

const postOne = async tenantInput => {
  try {
    const response = await axios.post(`/tenant`, tenantInput);
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response.data.error.message,
    };
  }
};

const putOne = async (tenanatId, tenantInput) => {
  try {
    const response = await axios.put(`/tenant/${tenanatId}`, tenantInput);
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response.data.error.message,
    };
  }
};

const deleteOne = async tenanatId => {
  try {
    await axios.delete(`/tenant/${tenanatId}`);
    return true;
  } catch (error) {
    return {
      success: false,
      message: error.response.data.error.message,
    };
  }
};

const postAdmin = async (tenanatId, adminInput) => {
  try {
    const response = await axios.post(`/tenant/${tenanatId}/admin`, adminInput);
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response.data.error.message,
    };
  }
};

const putAdminAccess = async (tenanatId, userId, adminInput) => {
  try {
    const response = await axios.put(
      `/tenant/${tenanatId}/admin/${userId}/expiration`,
      adminInput
    );
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response.data.error.message,
    };
  }
};

const deleteAdmin = async (tenanatId, userId) => {
  try {
    const response = await axios.delete(`/tenant/${tenanatId}/admin/${userId}`);
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response.data.error.message,
    };
  }
};

export default {
  getAll,
  postOne,
  putOne,
  deleteOne,

  postAdmin,
  putAdminAccess,
  deleteAdmin,
};
