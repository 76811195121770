<template>
  <div>
    <div
      v-if="!tenant"
      style="
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      "
    >
      <a-spin />
    </div>
    <div v-else>
      <h2>{{ tenant.name }}</h2>

      <a-col v-if="isUserTenantAdmin" :span="12">
        <a-button
          type="primary"
          @click="createEditAplication('TASBlueprintForm', 'Create Aplication')"
        >
          <PlusOutlined /> Create Application
        </a-button>
      </a-col>
      <a-col :span="12" style="text-align: right"> </a-col>
      <br />
      <br />
      <h3>
        <BuildOutlined />
        Applications list
        <a-badge
          :count="tenant.applications ? tenant.applications.length : 0"
          :number-style="{
            backgroundColor: '#fff',
            color: '#999',
            boxShadow: '0 0 0 1px #d9d9d9 inset',
          }"
        />
      </h3>
      <a-alert
        v-if="!tenant.applications || !tenant.applications.length"
        banner
        message="No application added"
      />

      <template v-if="tenant.applications">
        <div
          class="saas_card mb-10 hoverable"
          v-for="application in tenant.applications"
          :key="application._id"
          data-cy="cardApplication"
        >
          <div class="row-contents">
            <router-link
              :to="{
                name: 'TenantApplication',
                params: {
                  tenantSlug: this.tenant.slug,
                  appSlug: application.slug,
                },
              }"
              custom
              v-slot="{ navigate }"
            >
              <div class="group-text-container" @click="navigate">
                <a data-cy="linkApplicationSelect">
                  <div class="group-text">
                    <span class="title">{{ application.name }}</span>
                    <span class="muted-text" v-if="application.subtenants">
                      {{
                        application.subtenants.length == 1
                          ? 'one subtenant'
                          : application.subtenants.length + ' subtenants'
                      }}
                    </span>
                  </div>
                </a>
              </div>
            </router-link>

            <div class="metadata">
              <div v-if="isUserTenantAdmin" class="controls">
                <a-button
                  type="primary"
                  class="mr-10"
                  @click="goToBlueprints(tenant.slug, application.slug)"
                >
                  <ClusterOutlined /> Business Objects
                </a-button>
                <a-tooltip placement="top">
                  <template #title>
                    <span>Edit</span>
                  </template>
                  <a-button
                    @click="
                      createEditAplication(
                        'TASBlueprintForm',
                        'Edit Application',
                        true,
                        application
                      )
                    "
                    class="mr-10"
                  >
                    <EditOutlined />
                  </a-button>
                </a-tooltip>

                <a-popconfirm
                  placement="topLeft"
                  :title="
                    'Are you sure you want to delete `' +
                    application.name +
                    '`?'
                  "
                  ok-text="Yes"
                  cancel-text="No"
                  @confirm="removeApplication(application._id)"
                >
                  <a-button><DeleteOutlined /></a-button>
                </a-popconfirm>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {
  BuildOutlined,
  ClusterOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from '@ant-design/icons-vue';
import { TenantsActions } from '@tenants/shared/tenants.store';

import { UserGetters } from '@userManagement/user.store';
import router from '@/router';

export default {
  props: ['tenant', 'tenantId', 'openModal'],
  emits: ['openModal'],
  components: {
    BuildOutlined,
    ClusterOutlined,
    DeleteOutlined,
    EditOutlined,
    PlusOutlined,
  },
  data() {
    return {
      loading: false,
    };
  },

  computed: {
    user: UserGetters.getUser,
    isUserTenantAdmin() {
      if (this.user.isSuperAdmin) {
        return true;
      }
      if (this.tenant.adminUserIdList.indexOf(this.user._id) !== -1) {
        return true;
      }
      return false;
    },
  },
  methods: {
    createEditAplication(componentTarget, componentName, edit, editData) {
      this.$emit('openModal', {
        componentTarget,
        componentName,
        edit,
        editData,
        type: 'application',
      });
    },
    goToBlueprints(tenantSlug, appSlug) {
      router.push({
        name: 'BlueprintList',
        params: { tenantSlug, appSlug },
      });
    },
    removeApplication(applicationId) {
      TenantsActions.deleteOneApplication(this.tenantId, applicationId);
    },
  },
};
</script>
<style scoped></style>
