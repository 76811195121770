<template>
  <div>
    {{ localEvent.copyData }}
    <a-select
      allowClear
      show-search
      @change="selectedBlueprint"
      style="width: 250px"
      :disabled="copyFromBlueprintLoading"
      :loading="copyFromBlueprintLoading"
      placeholder="Select Business Objects"
      v-if="selectedBlueprintId"
      :filter-option="filterOption"
    >
      <template v-for="blueprint in blueprints" :key="blueprint._id">
        <a-select-option
          :value="blueprint._id"
          v-if="blueprint._id !== selectedBlueprintId"
        >
          {{ blueprint.name }}
        </a-select-option>
      </template>
    </a-select>

    <a-divider />

    <template
      v-for="copyBlueprint in copyFromBlueprint"
      :key="copyBlueprint.blueprint._id"
    >
      <a-card
        size="small"
        :title="capitalize(copyBlueprint.blueprint.name)"
        style="margin-bottom: 10px"
      >
        <template #extra>
          <a @click="removeOne(copyBlueprint.blueprint._id)">Delete</a>
        </template>
        <div
          class="update-fields"
          v-for="field in Object.values(copyBlueprint.fieldsById)"
          :key="field._id"
          style="margin-bottom: 10px"
        >
          <div class="cell input-name">
            <strong>{{ capitalize(field.label) }}</strong>
            <div class="muted">{{ field.structure.type }}</div>
          </div>

          <div class="cell modify-filed" style="text-align: center">
            <a-radio-group
              :defaultValue="
                selectBindType(copyBlueprint.blueprint._id, field._id)
              "
              @change="
                changeBindType(copyBlueprint.blueprint._id, field._id, $event)
              "
              button-style="solid"
            >
              <a-radio-button value="from-field"
                >Insert value from</a-radio-button
              >
              <a-radio-button value="static-value">Static value</a-radio-button>
            </a-radio-group>
          </div>

          <template
            v-if="
              selectBindType(copyBlueprint.blueprint._id, field._id) ===
              'static-value'
            "
          >
            <a-input
              :default-value="
                staticValue(copyBlueprint.blueprint._id, field._id)
              "
              @change="
                changeStaticValue(
                  copyBlueprint.blueprint._id,
                  field._id,
                  $event
                )
              "
              style="margin-top: 5px"
              placeholder="Value"
            />
          </template>

          <template v-else>
            <a-button-group
              class="mt-1"
              v-if="
                showRefFieldPath(copyBlueprint.blueprint._id, field._id) !== ''
              "
            >
              <a-button style="cursor: default" type="primary">
                {{ showRefFieldPath(copyBlueprint.blueprint._id, field._id) }}
              </a-button>
              <a-button
                @click="editBindField(copyBlueprint.blueprint._id, field._id)"
                ><EditOutlined />
              </a-button>
            </a-button-group>
            <div
              class="mt-1"
              v-if="
                (bindField[copyBlueprint.blueprint._id] ?? '') === field._id ||
                (showRefFieldPath(copyBlueprint.blueprint._id, field._id) ===
                  '' &&
                  (bindField[copyBlueprint.blueprint._id] ?? '') !== field._id)
              "
            >
              <ReferenceFields
                v-if="blueprint"
                :key="referenceFieldsKey"
                :blueprints="blueprints"
                :defaultSettings="{
                  optionChangedData: 'CHANGE DATA (history)',
                }"
                :selectedBlueprint="{
                  ...(blueprint ?? {}),
                  fields: [...Object.values(fields)],
                }"
                :toFieldFromOtherBlueprint="false"
                :justReferenceFields="false"
                :justSingleReferenceFields="true"
                :mainBlueprint="null"
                :cardView="false"
                :otherBlueprint="false"
                @toField="
                  (value, index) =>
                    selectField(
                      copyBlueprint.blueprint._id,
                      field._id,
                      value,
                      index
                    )
                "
              />
            </div>
          </template>
        </div>
      </a-card>
    </template>
  </div>
</template>

<script>
import { blueprintApi } from '@dataSystem/api';
import ReferenceFields from '@/apps/templateManagement/views/Builder/components/ReferenceFields.vue';
import _ from 'lodash';
import { WorkflowActions } from '@workflow/shared/workflow.store';
import { EditOutlined } from '@ant-design/icons-vue';

export default {
  name: 'workflowBuilderCopyDataIndex',
  components: {
    // FieldWidget,
    ReferenceFields,
    EditOutlined,
  },
  props: ['workflows', 'selectedWorkflow', 'event', 'eventList'],
  async mounted() {
    await Promise.all(
      this.localEvent.copyData.map(async data => {
        await this.selectedBlueprint(data.blueprintId);
      })
    );
    await this.fetchBlueprint();
    await this.fetchAllBlueprint();
    this.findMainBlueprintReferenceFields();
  },
  data() {
    return {
      fields: [],
      blueprint: null,
      selectedBlueprintIdValue: null,
      copyFromBlueprintLoading: false,
      copyFromBlueprint: [],
      blueprints: [],
      mainBlueprintReferenceFields: {},
      bindField: {},
      referenceFieldsKey: 1,
      localEvent: { ...this.event },
    };
  },

  computed: {
    selectedBlueprintId() {
      return this.selectedWorkflow.blueprint;
    },
  },

  watch: {
    async localEvent(data) {
      await WorkflowActions.editOneEvent(
        this.selectedWorkflow._id,
        this.event._id,
        { ...data }
      );
    },
    selectedBlueprintId() {
      this.fetchBlueprint();
    },
  },

  methods: {
    capitalize(value) {
      if (!value) return '';
      return (
        value.toString().charAt(0).toUpperCase() + value.toString().slice(1)
      );
    },
    editBindField(blueprintId, toFieldId) {
      if (this.bindField[blueprintId] === toFieldId) {
        this.bindField = { ...this.bindField, [blueprintId]: null };
      } else {
        this.bindField = { ...this.bindField, [blueprintId]: toFieldId };
      }
    },
    showRefFieldPath(blueprintId, toFieldId) {
      const data = (
        this.localEvent.copyData.find(item => item.blueprintId === blueprintId)
          ?.fields ?? []
      ).find(item => item.toFieldId === toFieldId)?.fromFieldId;
      // if (!(data??[]).length) {
      //   return '-'
      // }
      return (data ?? [])
        .map(
          item =>
            `${item.fieldName.startsWith('_') ? _.startCase(item.fieldName.replace('_', '')).toUpperCase() : item.fieldName} (${item.fieldName === '_changedData' ? 'history' : item.type})`
        )
        .join(' > ');
    },
    findMainBlueprintReferenceFields() {
      Object.values(this.fields).forEach(field => {
        if (
          (field?.structure?.elementStructure?.type ??
            field?.structure?.type) === 'reference'
        ) {
          const refBlueprintId =
            field?.structure?.elementStructure?.ruleset?.blueprintId ??
            field.structure.ruleset.blueprintId;
          const reBlueprint = this.blueprints.find(
            item => item._id.toString() === refBlueprintId.toString()
          );

          this.mainBlueprintReferenceFields = {
            ...this.mainBlueprintReferenceFields,
            [field._id]: reBlueprint?.fields ?? [],
          };
        }
      });
    },
    selectBindType(blueprintId, toFieldId) {
      const data = this.localEvent.copyData.find(
        item => item.blueprintId === blueprintId
      );
      if (data) {
        const field = data.fields.find(item => item.toFieldId === toFieldId);
        return field?.toType ?? 'from-field';
      }
      return 'from-field';
    },
    staticValue(blueprintId, toFieldId) {
      const data = this.localEvent.copyData.find(
        item => item.blueprintId === blueprintId
      );
      if (data) {
        const field = data.fields.find(item => item.toFieldId === toFieldId);
        return field?.toValue ?? '';
      }
      return '';
    },

    changeStaticValue(blueprintId, toFieldId, e) {
      const toType = { target: { value: 'static-value' } };
      const { value } = e.target;
      this.changeBindType(blueprintId, toFieldId, toType, value);
    },
    changeBindType(blueprintId, toFieldId, e, toValue = null) {
      const toType = e.target.value;

      const { copyData } = this.localEvent;
      const findCopyData = this.localEvent.copyData.find(
        item => item.blueprintId === blueprintId
      );
      if (!findCopyData) {
        copyData.push({
          blueprintId,
          fields: [
            {
              toFieldId,
              toType,
              toValue: toValue ?? '',
              fromFieldId: [],
            },
          ],
        });
      } else {
        const field = findCopyData.fields.find(
          item => item.toFieldId === toFieldId
        );
        if (field) {
          // update
          findCopyData.fields = findCopyData.fields.map(item => {
            if (item.toFieldId === toFieldId) {
              return {
                toFieldId,
                toType,
                toValue: toValue ?? '',
                fromFieldId: [],
              };
            }
            return item;
          });
        } else {
          // new
          findCopyData.fields.push({
            toFieldId,
            toType,
            toValue: toValue ?? '',
            fromFieldId: [],
          });
        }
      }
    },

    async selectedBlueprint(blueprintId) {
      this.copyFromBlueprintLoading = true;
      const data = await blueprintApi.getOne(blueprintId);

      if (
        !this.copyFromBlueprint.some(
          item => item.blueprint._id === data?.blueprint?._id
        )
      ) {
        this.copyFromBlueprint.push(data);
      }
      this.copyFromBlueprintLoading = false;
    },
    selectField(blueprintId, toFieldId, value, index) {
      this.bindField = { ...this.bindField, [blueprintId]: toFieldId };

      const { copyData } = this.localEvent;
      const findCopyData = this.localEvent.copyData.find(
        item => item.blueprintId === blueprintId
      );
      if (!findCopyData) {
        copyData.push({
          blueprintId,
          fields: [
            {
              toFieldId,
              toType: 'from-field',
              toValue: null,
              fromFieldId: [{ ...value, index }],
            },
          ],
        });
      } else {
        const field = findCopyData.fields.find(
          item => item.toFieldId === toFieldId
        );
        if (field) {
          // update
          const referenceFields = field.fromFieldId.filter(
            item => item.index < index
          );
          if (value?.fieldId) {
            referenceFields.push({ ...value, index });
          }
          field.fromFieldId = referenceFields.sort((a, b) => a.index - b.index);
        } else {
          // new
          findCopyData.fields.push({
            toFieldId,
            toType: 'from-field',
            toValue: null,
            fromFieldId: [{ ...value, index }],
          });
        }
      }
    },

    removeOne(blueprintId) {
      const el = this;
      this.$confirm({
        title: 'Confirm',
        content: 'Sure you want to delete?',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'Cancel',
        async onOk() {
          el.copyFromBlueprint = el.copyFromBlueprint.filter(
            item => item.blueprint._id !== blueprintId
          );
          el.localEvent.copyData = el.localEvent.copyData.filter(
            item => item.blueprintId !== blueprintId
          );
        },
      });
    },

    async fetchAllBlueprint() {
      this.blueprints = await blueprintApi.getAllWithFields();
    },

    async fetchBlueprint() {
      if (!this.selectedBlueprintId) {
        return;
      }
      const { blueprint, fieldsById } = await blueprintApi.getOne(
        this.selectedBlueprintId
      );
      this.blueprint = blueprint;
      this.fields = fieldsById;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
};
</script>
<style scoped>
.update-fields {
  display: table;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px 10px;
  margin-bottom: 10px;
  width: 100%;
}
.update-fields:last-child {
  margin-bottom: 0px;
}
.update-fields .cell {
  display: table-cell;
  vertical-align: middle;
}
.input-name {
  width: 30%;
  border-right: 1px solid #ddd;
  height: 30px;
}
.modify-filed {
  width: 30%;
  padding-left: 10px;
}
.input-field {
  width: 40%;
  border-left: 1px solid #ddd;
  padding-left: 10px;
}
.mutted {
  font-size: 12px;
}
</style>
