<template>
  <loading-spinner v-if="loading" />

  <div v-else>
    <div class="saas_card padd-10">
      <p style="font-weight: bold">XML from template</p>
      <span>Choose the template from which the file will be attached:</span
      ><br />
      <a-select
        v-model:value="templateId"
        allowClear
        showSearch
        :filter-option="filterOption"
        :options="allTemplates"
        style="width: 100%"
        class="mb-3"
      ></a-select>

      <div class="mb-3">
        Add example XML content:<br />
        <a-textarea v-model:value="exampleData"></a-textarea>
      </div>

      <div style="text-align: right">
        <a-button @click="attachFromXML" type="primary"
          >Attach XML from template</a-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import templateManagementApi from '@/apps/templateManagement/templateManagement.api';
import { notification } from 'ant-design-vue';

export default {
  props: ['selectedDataProvider'],
  emits: ['fileFromTemplateId'],
  data() {
    return {
      loading: true,
      templateId: undefined,
      exampleData: '',
      templates: [],
    };
  },
  computed: {
    allTemplates() {
      const options = [];
      if (this.templates.length) {
        this.templates.forEach(template => {
          if (
            template.blueprintId ===
            this.selectedDataProvider.dynamicData.blueprintId
          ) {
            options.push({
              value: template._id,
              label:
                template.name +
                (template.description && template.description !== ''
                  ? ` (${template.description})`
                  : ''),
            });
          }
        });
      }
      return options;
    },
  },
  async mounted() {
    this.templates = await templateManagementApi.getAllTemplates();
    this.loading = false;
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    attachFromXML() {
      if (this.templateId && this.exampleData.length) {
        const template = this.templates.find(t => t._id === this.templateId);
        const templateName =
          template.name +
          (template.description && template.description !== ''
            ? ` (${template.description})`
            : '');
        this.$emit('fileFromTemplateId', {
          xmlTemplateId: this.templateId,
          xmlTemplateName: templateName,
          xmlExampleData: this.exampleData,
        });
      } else {
        notification.warning({
          message: 'Error',
          description:
            'Select the base template for the XML file and insert XML test content data.',
        });
      }
    },
  },
};
</script>
