<template>
  <div>
    <template v-if="isGroup">
      <template v-if="field?.groupOneChoice ?? false">
        <a-checkbox
          v-if="oneChoice"
          @change="onCheckboxChangeGroup(indexValue, oneChoice.value, $event)"
          :key="oneChoice.value"
          :disabled="
            readOnly ||
            (field.isReadOnlyAdd && addNew) ||
            (field.isReadOnlyEdit && !addNew)
          "
          :checked="inputValue && oneChoice.value === inputValue[indexValue]"
        >
          {{ oneChoice.label || oneChoice.value }}
        </a-checkbox>
      </template>
      <template v-else>
        <a-checkbox
          v-for="(choice, index) in field.structure.elementStructure.choices"
          @change="onCheckboxChangeGroup(index, choice.value, $event)"
          :key="choice.value"
          :disabled="
            readOnly ||
            (field.isReadOnlyAdd && addNew) ||
            (field.isReadOnlyEdit && !addNew)
          "
          :checked="inputValue && choice.value === inputValue[indexValue]"
        >
          {{ choice.label || choice.value }}
        </a-checkbox>
      </template>
    </template>

    <template v-else>
      <div class="checkboxListWrapper" v-if="!viewingMode">
        <a-checkbox
          v-for="choice in field.structure.elementStructure.choices"
          @change="onCheckboxChange(choice.value, $event)"
          :key="choice.value"
          :disabled="
            readOnly ||
            (field.isReadOnlyAdd && addNew) ||
            (field.isReadOnlyEdit && !addNew)
          "
          :checked="
            inputValue && !!inputValue.find(val => val === choice.value)
          "
        >
          {{ choice.label || choice.value }}
        </a-checkbox>
      </div>

      <div v-else>
        {{ valueToString }}
      </div>
    </template>
  </div>
</template>

<script>
import InputMixin from './InputMixin';

export default {
  mixins: [InputMixin],
  props: ['indexValue'],
  computed: {
    oneChoice() {
      return this.field.structure.elementStructure.choices[this.indexValue];
    },
    valueToString() {
      if (this.rawValue && Array.isArray(this.rawValue)) {
        return this.rawValue.join(', ');
      }
      return '';
    },
  },
  methods: {
    getDefaultValue() {
      return [];
    },
    onCheckboxChangeGroup(index, choiceValue, e) {
      if (e.target.checked) {
        this.inputValue.splice(this.indexValue, 1);
        this.inputValue.splice(this.indexValue, 0, choiceValue);
      } else {
        this.inputValue.splice(this.indexValue, 1, null);
      }
    },
    onCheckboxChange(choiceValue, e) {
      if (e.target.checked) {
        if (this.inputValue.indexOf(choiceValue) !== -1) {
          return;
        }
        this.inputValue = [...this.inputValue, choiceValue].sort();
      } else {
        if (this.inputValue.indexOf(choiceValue) === -1) {
          return;
        }
        this.inputValue = this.inputValue.filter(val => val !== choiceValue);
      }
    },
  },
};
</script>

<style></style>
