<template>
  <div>
    <div style="display: flex">
      <a-switch
        v-model:checked="
          localTemplateViewSettings.listFieldsCustomHeader.enabled
        "
        checked-children="Custom header enabled"
        un-checked-children="Custom header disabled"
      ></a-switch>

      <a-switch
        v-model:checked="
          localTemplateViewSettings.listFieldsCustomHeader.showFirstRow
        "
        style="margin-left: 40px"
        checked-children="First row static text enabled"
        un-checked-children="First row static text disabled"
      ></a-switch>
    </div>

    <hr />

    <a-row>
      <a-col :span="16" style="padding-right: 30px">
        <a-button
          class="mb-3"
          type="primary"
          size="small"
          @click="addHeaderColumn"
          v-if="localTemplateViewSettings?.listFieldsCustomHeader"
          >Add header column</a-button
        >

        <div
          class="customHeaderConfig"
          v-if="localTemplateViewSettings?.listFieldsCustomHeader"
        >
          <div
            class="mb-3"
            v-for="(headerColumn, index) in localTemplateViewSettings
              .listFieldsCustomHeader.headerColumns"
            :key="index"
          >
            <tableColumnChildRecursive
              :headerColumn="headerColumn"
              :selectedFields="selectedFields"
              :showFirstRow="
                localTemplateViewSettings.listFieldsCustomHeader.showFirstRow
              "
              :firstDepth="true"
              :arrIndex="index"
              @removeColumn="removeHeaderColumn"
              @updateHeaderColumn="updateHeaderColumn(headerColumn, index)"
            />
          </div>
        </div>
      </a-col>

      <a-col :span="8">
        <h5>Field-uri disponibile:</h5>
        <div>
          <a-tooltip
            v-for="selectedField in selectedFields"
            :key="selectedField.id"
            :title="selectedField.label"
            placement="top"
            :mouseLeaveDelay="0"
          >
            <a-badge
              class="mr-2 availableField"
              :count="selectedField.label"
              draggable="true"
              @dragstart="startDrag($event, selectedField.id)"
            />
          </a-tooltip>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import tableColumnChildRecursive from '@/apps/templateManagement/views/Builder/components/elementFieldsComponents/customTableHeaderComponents/tableColumnChildRecursive.vue';

export default {
  name: 'CustomTableHeader',
  props: ['templateViewSettings'],
  emits: ['updateTemplateObjectView'],
  components: {
    tableColumnChildRecursive,
  },
  data() {
    return {
      localTemplateViewSettings: this.templateViewSettings,
    };
  },
  created() {
    this.localTemplateViewSettings = this.templateViewSettings;
    if (
      this.localTemplateViewSettings &&
      !this.localTemplateViewSettings?.listFieldsCustomHeader
    ) {
      this.localTemplateViewSettings.listFieldsCustomHeader = {
        enabled: false,
        headerColumns: [],
        showFirstRow: false,
      };
    }
  },
  computed: {
    selectedFields() {
      return this.localTemplateViewSettings.listFields.filter(f => f.show);
    },
  },
  methods: {
    updateHeaderColumn(headerColumn, index) {
      this.localTemplateViewSettings.listFieldsCustomHeader.headerColumns[
        index
      ] = headerColumn;
    },
    startDrag(event, selectedFieldId) {
      event.dataTransfer.setData('dragAction', 'attachFieldHeaderCell');
      event.dataTransfer.setData('selectedFieldId', selectedFieldId);
    },
    addHeaderColumn() {
      this.localTemplateViewSettings.listFieldsCustomHeader.headerColumns.push({
        displayText: '',
        attachedField: null,
        headerColumns: [],
        firstRowData: null,
      });
    },
    removeHeaderColumn(index) {
      this.localTemplateViewSettings.listFieldsCustomHeader.headerColumns.splice(
        index,
        1
      );
    },
  },
  watch: {
    localTemplateViewSettings: {
      // update stored template.
      deep: true,
      handler(value) {
        this.$emit('updateTemplateObjectView', value);
      },
    },
  },
};
</script>

<style>
.availableField {
  cursor: grab;
  max-width: 100px;

  * {
    text-overflow: ellipsis;
  }

  .ant-badge-count {
    background-color: #fff !important;
    color: #999 !important;
    box-shadow: 0 0 0 1px #d9d9d9 inset !important;

    &:hover {
      box-shadow:
        0 0 0 1px #d9d9d9 inset,
        0 0 0 1px #b2b2b2 !important;
    }
  }
}
.customHeaderConfig {
  max-height: 500px;
  overflow-y: auto;
}
</style>
