<template>
  <div>
    <div
      v-if="loading"
      style="
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      "
    >
      <a-spin />
    </div>
    <div v-else>
      <div class="saas_sections">
        <div class="sidebar">
          <div style="padding: 20px">
            <div style="padding: 10px 0">
              <a-button-group>
                <a-button type="primary">Provider</a-button>
                <a-button disabled>Blueprint</a-button>
                <a-button disabled>File</a-button>
              </a-button-group>
            </div>

            <a-select
              style="width: 100%"
              @change="selectProvider"
              :value="
                selectedFieldMapper.providerId == null
                  ? undefined
                  : selectedFieldMapper.providerId
              "
              placeholder="Select provider output"
            >
              <a-select-option
                v-for="provider in providerList"
                :key="provider._id"
              >
                {{ provider.name }}
              </a-select-option>
            </a-select>

            <div
              v-if="selectedFieldMapper.providerId"
              style="padding-top: 20px"
            >
              <h4>Provider output fields</h4>
              <a-alert
                v-if="!outputProviderFields.length"
                :show-icon="false"
                message="No fields"
                banner
              />

              <transition-group name="list" tag="div">
                <drag
                  v-for="dataProvider in outputProviderFields"
                  :key="dataProvider._id"
                  :data="dataProvider"
                  @cut="remove(n)"
                  :go-back="true"
                  :disabled="
                    isFieldSelected(dataProvider.originalId).length
                      ? true
                      : false
                  "
                  :class="
                    isFieldSelected(dataProvider.originalId).length
                      ? 'disabled'
                      : ''
                  "
                >
                  <div class="saas_card hoverable_drag mb-10 from-sourse">
                    <div class="row-contents">
                      <div class="group-text-container">
                        <div class="group-text">
                          <span class="title">
                            <MoreOutlined />
                            <MoreOutlined style="margin-left: -11px" />
                            {{ dataProvider.label }}
                          </span>
                        </div>
                      </div>
                      <div class="metadata">
                        <div class="controls">
                          {{ isFieldSelected(dataProvider.originalId).length }}
                        </div>
                      </div>
                    </div>
                  </div>
                </drag>
              </transition-group>

              <div
                class="mt-3"
                v-if="
                  dataProv.dynamicData?.active || dataProv.type === 'manual'
                "
              >
                <h4>
                  Provider core fields:
                  <a-tooltip
                    :mouseLeaveDelay="0"
                    style="
                      font-size: 0.7em;
                      position: relative;
                      top: -4px;
                      color: #0059ff;
                    "
                  >
                    <template #title>
                      Available fields:<br /><br />
                      -
                      <span v-if="dataProv.dynamicData?.active"
                        ><strong>instance ID</strong>: only available if the
                        dataflow had dynamic data in any of it's requests;
                        represents the id of the instance that had dynamic data
                        added to the dataflow request(s);</span
                      ><br />
                      -
                      <span v-if="dataProv.dynamicData?.active"
                        ><strong>blueprint ID</strong>: only available if the
                        dataflow had dynamic data in any of it's requests;
                        represents the id of the instance's blueprint that had
                        dynamic data added to the dataflow request(s);</span
                      ><br />
                      -
                      <span v-if="dataProv.type === 'manual'"
                        ><strong>user ID</strong>: only available if the
                        dataflow's type is manual; represents the id of the user
                        that initiated the dataflow;</span
                      ><br />
                      -
                      <span v-if="dataProv.type === 'manual'"
                        ><strong>subtenant ID</strong>: only available if the
                        dataflow's type is manual; represents the id of the
                        subtenant from which the action originated;</span
                      >
                    </template>
                    <InfoCircleOutlined />
                  </a-tooltip>
                </h4>

                <transition-group name="list2" tag="div">
                  <drag
                    v-for="dataProvider in providerSpecialFields"
                    :key="dataProvider._id"
                    :data="dataProvider"
                    :disabled="
                      !!isFieldSelectedSpecial(dataProvider._id).length
                    "
                    :class="
                      isFieldSelectedSpecial(dataProvider._id).length
                        ? 'disabled'
                        : ''
                    "
                  >
                    <div
                      class="saas_card hoverable_drag mb-10 from-sourse"
                      v-if="
                        (['_instanceId', '_blueprintId'].includes(
                          dataProvider._id
                        ) &&
                          dataProv.type === 'manual') ||
                        ['_userId', '_subtenantId'].includes(dataProvider._id)
                      "
                    >
                      <div class="row-contents">
                        <div class="group-text-container">
                          <div class="group-text">
                            <span class="title">
                              <MoreOutlined />
                              <MoreOutlined style="margin-left: -11px" />
                              {{ dataProvider.label }}
                            </span>
                          </div>
                        </div>
                        <div class="metadata">
                          <div class="controls">
                            {{
                              isFieldSelectedSpecial(dataProvider._id).length
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </drag>
                </transition-group>
              </div>
            </div>
          </div>
        </div>

        <div class="content-view">
          <div class="content-body">
            <div class="dataRow">
              <div class="cell">
                <div style="padding: 10px 0">
                  <a-button-group>
                    <a-button
                      :type="
                        destinationType == 'blueprint' ? 'primary' : 'default'
                      "
                      @click="destinationType = 'blueprint'"
                    >
                      Blueprint
                    </a-button>
                    <a-button
                      :type="
                        destinationType == 'endpoint' ? 'primary' : 'default'
                      "
                      @click="destinationType = 'endpoint'"
                      disabled
                    >
                      Endpoit
                    </a-button>
                    <a-button
                      :type="destinationType == 'file' ? 'primary' : 'default'"
                      @click="destinationType = 'file'"
                      disabled
                    >
                      File
                    </a-button>
                  </a-button-group>
                </div>

                <BlueprintDestination
                  :fieldMapperId="fieldMapperId"
                  :destinationType="destinationType"
                  :outputProviderFields="outputProviderFields"
                  :specialOutputProviderFields="providerSpecialFields"
                  :destinations="selectedFieldMapper"
                  v-if="destinationType === 'blueprint'"
                />
                <EndpointDestination
                  :destinationType="destinationType"
                  :outputProviderFields="outputProviderFields"
                  :destinations="selectedFieldMapper"
                  @pushFieldMapper="pushFieldMapper"
                  @pushFieldMapperMappedFields="pushFieldMapperMappedFields"
                  v-if="destinationType === 'endpoint'"
                />
                <FileDestination
                  :destinationType="destinationType"
                  :outputProviderFields="outputProviderFields"
                  :destinations="selectedFieldMapper"
                  v-if="destinationType === 'file'"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { InfoCircleOutlined, MoreOutlined } from '@ant-design/icons-vue';
import {
  DataProviderActions,
  DataProviderMutations,
  DataProviderGetters,
} from '@dataProvider/shared/dataProvider.store';
import {
  FieldMapperActions,
  FieldMapperGetters,
} from '@fieldMapper/shared/fieldMapper.store';
import { Drag } from 'vue-easy-dnd';
import BlueprintDestination from './components/BlueprintDestination.vue';
import EndpointDestination from './components/EndpointDestination.vue';
import FileDestination from './components/FileDestination.vue';

const specialFields = [
  {
    _id: '_instanceId',
    originalId: '_instanceId',
    label: 'Instance ID',
  },
  {
    _id: '_blueprintId',
    originalId: '_blueprintId',
    label: 'Blueprint ID',
  },
  {
    _id: '_userId',
    originalId: '_userId',
    label: 'User ID',
  },
  {
    _id: '_subtenantId',
    originalId: '_subtenantId',
    label: 'Subtenant ID',
  },
];

export default {
  data() {
    return {
      loading: true,
      fieldMapperId: this.$route.params.fieldMapperId
        ? this.$route.params.fieldMapperId
        : null,

      destinationType: 'blueprint',
      providerId: null,

      providerSpecialFields: specialFields,
    };
  },
  watch: {
    $route(to) {
      this.fieldMapperId = to.params.fieldMapperId
        ? to.params.fieldMapperId
        : null;
    },
    'selectedFieldMapper.providerId': function (providerId) {
      DataProviderMutations.SET_JOINS_OUTPUT_FIELDS([]);
      if (providerId) {
        this.setProviderToMapper(providerId);
      }
    },
  },
  async mounted() {
    if (!this.fieldMapperList.length) {
      await FieldMapperActions.fetchFieldMapperList();
      this.loading = false;
    } else {
      this.loading = false;
    }

    DataProviderMutations.SET_JOINS_OUTPUT_FIELDS([]);
    await DataProviderActions.fetchDataProviderList();
    if (this.selectedFieldMapper.providerId) {
      this.setProviderToMapper(this.selectedFieldMapper.providerId);
    }
  },

  computed: {
    fieldMapperList: FieldMapperGetters.getFieldMapperList,

    selectedFieldMapper() {
      if (this.fieldMapperList && this.fieldMapperList.length) {
        const findFieldMapper = this.fieldMapperList.find(
          f => f._id === this.fieldMapperId
        );
        if (findFieldMapper) {
          return {
            ...findFieldMapper,
            fieldMapper: findFieldMapper.fieldMapper,
          };
        }
        return [];
      }
      return [];
    },

    dataProv: DataProviderGetters.getDataProvider,
    providerList: DataProviderGetters.getDataProviderList,
    outputProviderFields: DataProviderGetters.getJoinOutputFields,
  },
  methods: {
    pushFieldMapper(data) {
      this.fieldMapperList.push(data);
    },
    pushFieldMapperMappedFields(data) {
      this.fieldMapperList[0].mappedFields.push(data);
    },
    async setProviderToMapper(providerId) {
      await DataProviderActions.fetchOneDataProvider(providerId);
      DataProviderActions.calculateJoinsFields();
    },
    async selectProvider(key) {
      await FieldMapperActions.editOneFieldMapper(this.fieldMapperId, {
        providerId: key,
      });
    },
    isFieldSelected(dataProviderFieldId) {
      if (this.selectedFieldMapper.fieldMapper.length) {
        return this.selectedFieldMapper.fieldMapper.filter(d => {
          return d.boundFields.some(
            el => el.dataProviderFieldId === dataProviderFieldId
          );
        });
      }
      return [];
    },
    isFieldSelectedSpecial(dataProviderSpecialFieldId) {
      if (this.selectedFieldMapper.fieldMapper.length) {
        return this.selectedFieldMapper.fieldMapper.filter(d => {
          return d.boundSpecialFields.some(
            el => el.dataProviderFieldId === dataProviderSpecialFieldId
          );
        });
      }
      return [];
    },
  },
  components: {
    Drag,
    BlueprintDestination,
    FileDestination,
    EndpointDestination,
    InfoCircleOutlined,
    MoreOutlined,
  },
};
</script>
<style scoped>
.dataRow {
  position: relative;
  display: table;
  width: 100%;
}
.dataRow .cell {
  width: 50%;
  display: table-cell;
}
.dataRow .cell.pl-15 {
  padding-left: 15px;
}
.dataRow .cell.l-line {
  border-left: 1px solid #ddd;
}
.center {
  text-align: center;
}

.dnd-drag.disabled .saas_card {
  opacity: 0.5;
  background-color: #ddd;
}
</style>
