import { reactive } from 'vue';
import workflowApi from '@/apps/workflow/workflow.api';

import { message } from 'ant-design-vue';

const initialState = {
  workflowsLoading: true,
  saveWorkflowsLoading: false,

  selectedWorkflow: null,

  workflows: [],

  timer: 0,

  autoSave: true,
  autoSaveDelay: 10 * 1000, // seconds
  autoSaveBtn: false,
  disableSaveBtn: false,
};

const state = reactive({ ...initialState });

const Getters = {
  getWorkflows: () => {
    return state.workflows;
  },

  getSelectedWorkflow: workflowId => {
    return (state.workflows?.workflows ?? []).find(
      workflow => workflow._id.toString() === workflowId.toString()
    );
  },

  getWorkflowsLoading: () => {
    return state.workflowsLoading;
  },
  getSaveWorkflowsLoading: () => {
    return state.workflowsLoading;
  },
  getAutoSave: () => {
    return state.autoSave;
  },
  getAutoSaveButton: () => {
    return state.autoSaveBtn;
  },
  getDisableSaveButton: () => {
    return state.disableSaveBtn;
  },
};

const Mutations = {
  SET_AUTO_SAVE: value => {
    state.autoSave = value;
  },
  SET_AUTO_SAVE_BUTTON: value => {
    state.autoSaveBtn = value;
  },
};

const Actions = {
  fetchWorkflows: async workflowGroupId => {
    state.workflowsLoading = true;
    const response = await workflowApi.getOneWorkflowById(workflowGroupId);
    state.workflowsLoading = false;

    state.workflows = response;

    if (response.success) {
      message.error(response.message);
    } else {
      message.success('Loaded...', 1);
    }
  },

  editSelectedWorkflow: async (selectedWorkflow, data) => {
    state.workflows.workflows = (state.workflows?.workflows ?? []).map(
      workflow => {
        if (workflow._id === selectedWorkflow._id) {
          return data;
        }
        return workflow;
      }
    );
    state.disableSaveBtn = true;
    await workflowApi.patchWorkflows(state.workflows);
  },

  saveOnLeave: async () => {
    if (state.autoSaveBtn) {
      await workflowApi.patchWorkflows(state.workflows);
      clearTimeout(state.timer);
      state.autoSaveBtn = false;
      state.disableSaveButton = false;
    }
  },

  editOneEvent: async (workflowId, eventId, data) => {
    state.workflows.workflows = (state.workflows?.workflows ?? []).map(
      workflow => {
        if (workflow._id.toString() === workflowId.toString()) {
          return (data?.events ?? []).map(event => {
            if (event._id.toString() === eventId.toString()) {
              return data;
            }
            return event;
          });
        }
        return workflow;
      }
    );
  },

  removeOneWorkflow: async workflowId => {
    state.workflows.workflows = (state.workflows?.workflows ?? []).filter(
      workflow => workflow._id.toString() !== workflowId.toString()
    );
  },

  removeOneEvent: async (workflowId, eventId) => {
    /* eslint-disable */
    state.workflows.workflows = (state.workflows?.workflows ?? []).map(
      workflow => ({
        ...workflow,
        events:
          workflow._id.toString() === workflowId.toString()
            ? workflow.events.filter(event => event._id.toString() !== eventId.toString())
            : workflow.events,
      })
    );
    /* eslint-enable */
  },

  addOneWorkflow: async data => {
    state.workflows = await workflowApi.postOneWorkflow(data);
  },

  addOneEvent: async (workflowId, data) => {
    const response = await workflowApi.postOneWorkflowEvent(data);
    state.workflows.workflows = response.workflows;
  },

  saveWorkflows: async data => {
    state.saveWorkflowsLoading = true;
    clearTimeout(state.timer);
    if (state.autoSave) {
      state.timer = setTimeout(async function () {
        await workflowApi.patchWorkflows(data);

        state.autoSaveBtn = false;
        message.success('Workflow was auto saved!', 1);

        state.disableSaveButton = false;
      }, state.autoSaveDelay);
    }

    state.saveWorkflowsLoading = false;
    state.autosave = true;
  },

  changeWorkflowBlueprint: async (workflowId, blueprintId) => {
    state.workflows.workflows = (state.workflows?.workflows ?? []).map(
      workflow => {
        if (workflow._id.toString() === workflowId.toString()) {
          workflow.blueprint = blueprintId;
        }
        return workflow;
      }
    );
  },
};

export const WorkflowGetters = Getters;
export const WorkflowMutations = Mutations;
export const WorkflowActions = Actions;
