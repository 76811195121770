const InputMixin = {
  props: [
    'value',
    'field',
    'readOnly',
    'structureRuleset',
    'structureOptions',
    'widgetRuleset',
    'widgetOptions',
    'inputRuleset',
    'inputOptions',
    'inputSize',
    'inputPlaceholder',
    'autoFocus',
    'openDropdown',
    'currentFieldValues',
    'fields',
    'waitForValidity',
    'addNew',
    'resetFieldValueOnHide',
    'viewingMode',
    'rawValue',
  ],
  emits: ['update', 'blur', 'update:modelValue'],
  data() {
    return {
      inputValue: null,
    };
  },
  computed: {
    isGroup() {
      return this.field?.group && this.field.group !== '';
    },
    firstFieldInGroup() {
      const group = this.field?.group;
      if (!group || group === '') {
        return null;
      }
      const findFields = (this.fields ?? []).filter(item => {
        return item.group && item.group !== '' && item.group === group;
      });
      return findFields.length ? findFields[0] : null;
    },
  },
  mounted() {
    this.handlePropValueChange();
  },
  beforeUnmount() {
    if (this.resetFieldValueOnHide) {
      this.$emit('update:modelValue', null);
      // this.$emit('update', null);
    }
  },
  watch: {
    value() {
      this.handlePropValueChange();
    },
    inputValue: {
      handler() {
        this.handleInputValueWatcher();
      },
      deep: true,
    },
  },
  methods: {
    handleInputValueWatcher() {
      if (this.isInputValueValid()) {
        const transformedInputValue = this.transformInputValue(this.inputValue);
        this.emitInput(transformedInputValue);
      }
    },
    handlePropValueChange() {
      if (this.isPropValueValid()) {
        this.inputValue = this.transformPropValue(this.value);
      } else {
        this.inputValue = this.getDefaultValue();
      }
    },
    isPropValueValid() {
      return !!this.value;
    },
    isInputValueValid() {
      return true;
    },
    getDefaultValue() {
      return null;
    },
    emitInput(value) {
      if (value) {
        this.$emit('update', value);
        this.$emit('update:modelValue', value);
      } else {
        this.$emit('update', this.inputValue);
        this.$emit('update:modelValue', this.inputValue);
      }
    },
    emitBlur() {
      if (this.isInputValueValid()) {
        const transformedInputValue = this.transformInputValue(this.inputValue);
        this.$emit('blur', {
          target: {
            value: transformedInputValue,
          },
        });
      }
    },
    transformPropValue(propValue) {
      return propValue;
    },
    transformInputValue(inputValue) {
      return inputValue;
    },
  },
};

export default InputMixin;
