<template>
  <div class="AuthLogin_Container">
    <a-card>
      <router-link
        class="ant-btn ant-btn-primary"
        style="display: inline-block; margin-bottom: 25px"
        :to="{ name: 'AuthLogin' }"
      >
        <ArrowLeftOutlined /> Login
      </router-link>
      <h1>Reset Password</h1>
      <p>Enter your e-mail address:</p>
      <a-input v-model:value="input.email" type="email" />
      <a-button
        style="margin-top: 25px"
        type="primary"
        @click="onClickSendCode"
      >
        <MailOutlined /> Send mail confirmation
      </a-button>
    </a-card>
  </div>
</template>

<script>
import { ArrowLeftOutlined, MailOutlined } from '@ant-design/icons-vue';
import { credentialApi } from '@userManagement/api';
import { message } from 'ant-design-vue';

export default {
  props: ['email'],
  components: {
    ArrowLeftOutlined,
    MailOutlined,
  },
  data() {
    return {
      input: {
        email: null,
      },
    };
  },
  created() {
    if (this.email) {
      this.input.email = this.email;
    }
  },
  methods: {
    async onClickSendCode() {
      if (!this.input.email || this.input.email.trim().length === 0) {
        return;
      }
      message.success('A mail has been sent with recovery information.');
      await credentialApi.postPasswordReset(this.input.email);
    },
  },
};
</script>

<style lang="scss" scoped>
.AuthLogin {
  &_Container {
    margin-top: -51px;
    padding-top: 51px;
    background-color: #f5f5f5;
    height: 100vh;
  }
  &_Label {
    display: block;
    margin-top: 20px;
  }
  &_LoginButton {
    margin-top: 25px;
    width: 150px;
  }
}
</style>
