import axios from 'axios';

import { SlugGetters } from '@/slug.store';

const { getAppUrl } = SlugGetters;

const postOne = async (providerId, sourceId, fieldId, data) => {
  try {
    const response = await axios.post(
      `${getAppUrl()}/providers/${providerId}/sources/${sourceId}/fields/${fieldId}/filters`,
      data
    );
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

const putOne = async (providerId, sourceId, fieldId, filterId, data) => {
  try {
    const response = await axios.put(
      `${getAppUrl()}/providers/${providerId}/sources/${sourceId}/fields/${fieldId}/filters/${filterId}`,
      data
    );
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

const putMeny = async (providerId, sourceId, fieldId, data) => {
  try {
    const response = await axios.put(
      `${getAppUrl()}/providers/${providerId}/sources/${sourceId}/fields/${fieldId}/filters`,
      data
    );
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

const deleteOne = async (providerId, sourceId, fieldId, filterId) => {
  try {
    await axios.delete(
      `${getAppUrl()}/providers/${providerId}/sources/${sourceId}/fields/${fieldId}/filters/${filterId}`
    );
    return true;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

export default {
  deleteOne,
  postOne,
  putOne,
  putMeny,
};
