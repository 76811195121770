<template>
  <a-drawer
    title="Create"
    :open="visible"
    @close="close"
    placement="bottom"
    height="80vh"
    :bodyStyle="{
      backgroundColor: '#f5f5f5',
      minHeight: '100%',
      paddingBottom: '100px',
    }"
  >
    <a-card class="InstanceCreator">
      <loading-spinner v-if="isLoading" />
      <template v-if="visible === true && blueprintId && !isLoading">
        <FormRenderer
          :blueprintId="blueprintId"
          :instanceId="instanceId"
          :userRolesWhichCanCreate="userRolesWhichCanCreate"
          :addNew="true"
          @submit="onFormSubmit"
        >
          <div
            v-if="userRolesWhichCanCreate.length > 1"
            style="margin-left: 20px"
          >
            <label style="margin-bottom: 5px; display: block">Owner Role</label>
            <a-select
              v-model:value="selectedOwnerRoleId"
              style="display: block; width: 200px; margin-bottom: 20px"
            >
              <a-select-option
                v-for="role in userRolesWhichCanCreate"
                :key="role._id"
                :value="role._id"
              >
                {{ role.name }}
              </a-select-option>
            </a-select>
          </div>
        </FormRenderer>
      </template>
    </a-card>
  </a-drawer>
</template>

<script>
import { instanceApi } from '@dataSystem/api';

import { roleSubtenantApi } from '@roleManagement/api';
import { message } from 'ant-design-vue';
import { FormRenderer } from '@/apps/dataSystem/components/FormRenderer';

export default {
  props: ['visible', 'blueprintId', 'instanceId'],
  emits: ['close'],
  data() {
    return {
      isLoading: false,
      selectedOwnerRoleId: null,
      userRoles: [],
      instance: null,
    };
  },
  components: {
    FormRenderer,
  },
  created() {
    this.fetchUserRoles();
  },
  computed: {
    userRolesWhichCanCreate() {
      return this.userRoles.filter(role => {
        const permission = role.permissionByBlueprintId[this.blueprintId];
        if (!permission) {
          return false;
        }
        return permission.createAuthority.mode === 'yes';
      });
    },
  },
  watch: {
    userRolesWhichCanCreate() {
      if (this.userRolesWhichCanCreate?.length > 0) {
        this.selectedOwnerRoleId = this.userRolesWhichCanCreate[0]._id;
      }
    },
  },
  methods: {
    async fetchInstance() {
      if (this.instanceId) {
        this.instance = await instanceApi.getOne(
          this.blueprintId,
          this.instanceId
        );
        this.selectedOwnerRoleId = this.instance._ownerRoleId;
      }
    },
    async fetchUserRoles() {
      this.userRoles = await roleSubtenantApi.getAllForUser();
    },
    async onFormSubmit(instanceData) {
      if (!this.selectedOwnerRoleId) {
        message.warning('You must select an Owner Role.');
        return;
      }
      this.isLoading = true;
      if (this.instanceId) {
        await instanceApi.patchOne(
          this.blueprintId,
          this.instanceId,
          this.selectedOwnerRoleId,
          instanceData
        );
      } else {
        await instanceApi.postOne(
          this.blueprintId,
          this.selectedOwnerRoleId,
          instanceData
        );
      }
      this.isLoading = false;
      this.close();
    },
    onClickCancel() {
      this.close();
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.InstanceCreator {
  width: 30vw;
  margin: 0 auto;
  @media (max-width: 2000px) {
    width: 40vw;
  }

  @media (max-width: 1500px) {
    width: 70vw;
  }

  @media (max-width: 1000px) {
    width: 90vw;
  }
}
</style>
