import { reactive } from 'vue';

import _ from 'lodash';

import { blueprintApi, fieldApi, instanceApi } from '@dataSystem/api';

const debouncedFieldUpdateByFieldId = {};

const initialState = {
  builder: {
    lastSaveTime: null,
    widgetTypeToCreate: null,
  },
  blueprint: null,
  fieldsById: {},
  inputsByFieldId: {},
  fieldIdToIsFieldUpdated: {},
  instancesByBlueprintIdAndSubtenant: {},
};

const state = reactive({ ...initialState });

const Mutations = {
  SET_WIDGET_TYPE_TO_CREATE: widgetType => {
    state.builder.widgetTypeToCreate = widgetType;
  },
  INIT_BUILDER: (blueprint, fieldsById) => {
    state.blueprint = blueprint;
    state.fieldsById = fieldsById;

    state.inputsByFieldId = _.cloneDeep(fieldsById);

    state.fieldIdToIsFieldUpdated = Object.fromEntries(
      Object.keys(fieldsById).map(fieldId => [fieldId, false])
    );
  },
  SET_BUILDER_LAST_SAVE_NOW() {
    state.builder.lastSaveTime = new Date();
  },
  UPDATE_BLUEPRINT: updatedBlueprint => {
    state.blueprint = updatedBlueprint;
  },
  INSERT_FIELD: field => {
    state.fieldsById[field._id] = field;
    state.inputsByFieldId[field._id] = _.cloneDeep(field);

    state.blueprint.idListOfFields.push(field._id);
  },
  UPDATE_FIELD: updatedField => {
    state.fieldsById[updatedField._id] = updatedField;
  },
  REMOVE_FIELD: fieldIdToRemove => {
    state.blueprint.idListOfFields = state.blueprint.idListOfFields.filter(
      fieldId => fieldId !== fieldIdToRemove
    );

    delete state.fieldsById[fieldIdToRemove];
    delete state.inputsByFieldId[fieldIdToRemove];
  },
  UPDATE_FIELD_INPUT: (fieldId, fieldObjectPath, updatedInput) => {
    if (
      fieldId === null ||
      fieldId === undefined ||
      typeof fieldId !== 'string' ||
      fieldId.trim() === ''
    ) {
      throw Error(
        '[FormBuilderMutations.UPDATE_FIELD_INPUT]: Must specify a fieldId.'
      );
    }

    let inputBeforeUpdate = _.cloneDeep(state.inputsByFieldId[fieldId]);

    if (
      fieldObjectPath !== undefined &&
      fieldObjectPath !== null &&
      typeof fieldObjectPath === 'string'
    ) {
      const objectAtPath = _.get(
        state.inputsByFieldId[fieldId],
        fieldObjectPath
      );
      _.set(inputBeforeUpdate, fieldObjectPath, {
        ...objectAtPath,
        ...updatedInput,
      });
    } else {
      inputBeforeUpdate = {
        ...state.inputsByFieldId[fieldId],
        ...updatedInput,
      };
    }

    state.inputsByFieldId[fieldId] = inputBeforeUpdate;
    state.fieldIdToIsFieldUpdated[fieldId] = true;
  },
};

const Actions = {
  updateFieldInput(fieldId, fieldObjectPath, updatedInput) {
    Mutations.UPDATE_FIELD_INPUT(fieldId, fieldObjectPath, updatedInput);
    this.updateFieldWithDebounce(fieldId);
  },

  async setFieldInstances(fieldId, subtenantSlug, blueprintId) {
    let field = state.inputsByFieldId[fieldId];
    if (!field) {
      const fieldsById = await blueprintApi.getOne(blueprintId);
      field = fieldsById[fieldId];
      state.inputsByFieldId[fieldId] = field;
    }
    let bpId = null;
    if (field.structure.type === 'reference') {
      bpId = field.structure.ruleset.blueprintId;
    }
    if (field.structure?.elementStructure?.type === 'reference') {
      bpId = field.structure?.elementStructure?.ruleset.blueprintId;
    }
    if (bpId) {
      const { instanceList } = await instanceApi.getAllForSubtenant(
        bpId,
        subtenantSlug
      );
      if (
        typeof state.instancesByBlueprintIdAndSubtenant[fieldId] !== 'object'
      ) {
        state.instancesByBlueprintIdAndSubtenant[fieldId] = {};
      }
      state.instancesByBlueprintIdAndSubtenant[fieldId][subtenantSlug] =
        instanceList;
    }
  },
  async initBuilder(blueprintId) {
    const { blueprint, fieldsById } = await blueprintApi.getOne(blueprintId);
    Mutations.INIT_BUILDER(blueprint, fieldsById);
  },
  async updateBlueprint(blueprintInput) {
    const updatedBlueprint = await blueprintApi.patchOne(
      state.blueprint._id,
      blueprintInput
    );
    Mutations.UPDATE_BLUEPRINT(updatedBlueprint);
    Mutations.SET_BUILDER_LAST_SAVE_NOW();
  },
  async createField(widgetType, structureRuleset) {
    const field = await fieldApi.postOne({
      blueprintId: state.blueprint._id,
      widgetType,
      structureRuleset,
    });
    Mutations.INSERT_FIELD(field);
    Mutations.SET_BUILDER_LAST_SAVE_NOW();
  },
  async updateField(fieldId) {
    if (!state.fieldIdToIsFieldUpdated[fieldId]) {
      return;
    }
    state.fieldIdToIsFieldUpdated[fieldId] = false;
    if (fieldId in debouncedFieldUpdateByFieldId) {
      debouncedFieldUpdateByFieldId[fieldId].cancel();
    }
    const updatedField = await fieldApi.patchOne(
      fieldId,
      state.inputsByFieldId[fieldId]
    );
    Mutations.UPDATE_FIELD(updatedField);
    Mutations.SET_BUILDER_LAST_SAVE_NOW();
  },
  async updateFieldWithDebounce(fieldId) {
    if (!debouncedFieldUpdateByFieldId[fieldId]) {
      debouncedFieldUpdateByFieldId[fieldId] = _.debounce(
        Actions.updateField,
        1000
      );
    }
    debouncedFieldUpdateByFieldId[fieldId](fieldId);
  },
  async deleteField(fieldId) {
    await fieldApi.deleteOne(fieldId);
    Mutations.REMOVE_FIELD(fieldId);
    Mutations.SET_BUILDER_LAST_SAVE_NOW();
  },
};

const Getters = {
  getBuilder: () => state.builder,
  getWidgetTypeToCreate: () => state.builder.widgetTypeToCreate,
  getBlueprint: () => state.blueprint,
  getBlueprintId: () => state.blueprint._id,
  getFields: () => {
    if (!state.blueprint || !state.blueprint.idListOfFields) {
      return [];
    }
    return state.blueprint.idListOfFields.map(fieldId => {
      return {
        ...state.fieldsById[fieldId],
      };
    });
  },
  getFieldInstances: async (fieldId, subtenantSlug, blueprintId) => {
    if (
      !state.instancesByBlueprintIdAndSubtenant[fieldId] ||
      !state.instancesByBlueprintIdAndSubtenant[fieldId][subtenantSlug]
    ) {
      await Actions.setFieldInstances(fieldId, subtenantSlug, blueprintId);
    }

    return state.instancesByBlueprintIdAndSubtenant[fieldId][subtenantSlug];
  },
  getDisplayFieldId: () => state.blueprint.displayFieldId,
  getFieldInput: fieldId => {
    return state.inputsByFieldId[fieldId];
  },
  getFieldsBefore: () => {
    const fieldsBefore = [];
    for (let i = 0; i < state.blueprint.idListOfFields.length; i += 1) {
      const currentFieldId = state.blueprint.idListOfFields[i];
      const currentField = state.inputsByFieldId[currentFieldId];
      /* if (currentFieldId === fieldId) {
        return fieldsBefore;
      } */
      fieldsBefore.push({
        label: currentField?.label,
        structure: {
          type:
            currentField?.structure?.elementStructure?.type ??
            currentField?.structure?.type,
          ruleset: currentField?.structure?.ruleset,
        },
        array: currentField?.structure?.type === 'array',
        _id: currentField?._id,
        successionIndex: currentField?.successionIndex,
      });
    }
    return fieldsBefore;
  },
  isFieldUpdated: fieldId => {
    return state.fieldIdToIsFieldUpdated[fieldId];
  },
};

export const FormBuilderMutations = Mutations;
export const FormBuilderGetters = Getters;
export const FormBuilderActions = Actions;
