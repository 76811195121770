import TemplateManagementList from '@/apps/templateManagement/views/List/List.vue';
import TemplateManagementBuilder from '@/apps/templateManagement/views/Builder/Builder.vue';

const templateManagementRoutes = [
  {
    path: '/list',
    name: 'TemplateManagementList',
    component: TemplateManagementList,
    meta: {
      menuTitle: 'Template management',
    },
  },
  {
    path: `/builder/:templateId?`,
    name: 'TemplateManagementBuilder',
    component: TemplateManagementBuilder,
    meta: {
      menuTitle: 'Template management',
    },
  },
];

export default templateManagementRoutes;
