<template>
  <a-modal
    v-model:open="modalView"
    :destroyOnClose="true"
    :title="`${editingColumn ? 'Editeaza' : 'Adauga'} coloana template`"
    @ok="handleConfirm"
    @cancel="closeModal"
    :width="600"
  >
    <a-tabs default-active-key="1">
      <a-tab-pane key="1" tab="Settings">
        <a-row v-show="!template.object && !template.columnGroups.length">
          <a-col :span="24" style="padding: 3px 5px">
            <a-checkbox v-model:checked="spacingColumn">
              Coloana pentru spatiere
            </a-checkbox>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12" style="padding: 3px 5px" v-show="!spacingColumn">
            <a-input v-model:value="columnName" placeholder="Nume coloana" />
          </a-col>

          <a-col :span="12" style="padding: 3px 5px" v-show="spacingColumn">
            <a-input v-model:value="columnHeight" placeholder="Inaltime (px)" />
          </a-col>

          <a-col :span="12" style="padding: 3px 5px">
            <a-input
              v-model:value="columnWidth"
              placeholder="Latime coloana (%)"
            />
          </a-col>
        </a-row>

        <a-row v-show="!spacingColumn">
          <a-col :span="12" style="padding: 3px 5px">
            <a-input
              v-model:value="columnCssClasses"
              placeholder="Clase css (ex.: back-red textbold)"
            />
          </a-col>

          <a-col :span="12" style="padding: 3px 5px">
            <a-input
              v-model:value="columnInlineStyle"
              placeholder="Inline style (ex.: padding:10px;margin:5px)"
            />
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="24" style="padding: 3px 5px">
            <a-checkbox v-model:checked="tabsView"> Tabs view </a-checkbox>

            <a-radio-group
              name="radioGroup"
              :default-value="''"
              v-model:value="tabViewType"
              v-show="tabsView"
              style="margin-left: 20px"
            >
              <a-radio :value="'card'"> card </a-radio>
              <a-radio :value="''"> none </a-radio>
            </a-radio-group>
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="12" style="padding: 3px 5px">
            <a-checkbox v-model:checked="defaultBackground">
              Default background
            </a-checkbox>
          </a-col>
        </a-row>
      </a-tab-pane>

      <a-tab-pane key="2" tab="Visibility" v-if="editingColumn">
        <div class="mb-3">
          <a-button @click="addVisibilityCondition"
            ><PlusOutlined /> Add condition</a-button
          >
        </div>
        <a-card
          v-for="(vCon, i) in template.visibilityConditions"
          :key="i"
          class="mb-2"
        >
          <a-button
            style="position: absolute; top: 5px; right: 5px"
            @click="removeVisibilyCondition(vCon)"
            ><MinusOutlined
          /></a-button>

          <a-row>
            <a-col :span="12" style="padding: 3px 5px" class="mb-2">
              <strong>{{ templateAssociatedBlueprint.name }}</strong>
              fields:<br />
              <a-select
                v-model:value="vCon.fromFieldId"
                :options="associatedBlueprintFieldsOptions"
                style="width: 100%"
              ></a-select>
            </a-col>

            <a-col :span="12" style="padding: 3px 5px" class="mb-2">
              Comparation value:<br />
              <a-input
                v-model:value="vCon.againstFieldValue"
                style="width: 100%"
              ></a-input>
            </a-col>

            <a-col :span="12" style="padding: 3px 5px" class="mb-2">
              Operation:<br />
              <a-select v-model:value="vCon.operation" style="width: 100%">
                <a-select-option value="and"> AND </a-select-option>
                <a-select-option value="or"> OR </a-select-option>
              </a-select>
            </a-col>

            <a-col :span="12" style="padding: 3px 5px" class="mb-2">
              Comparation:<br />
              <a-select v-model:value="vCon.comparation" style="width: 100%">
                <a-select-option value="EQUAL"> EQUAL </a-select-option>
                <a-select-option value="NOT_EQUAL"> NOT_EQUAL </a-select-option>
                <a-select-option value="GREATER"> GREATER </a-select-option>
                <a-select-option value="GREATER_OR_EQUAL">
                  GREATER_OR_EQUAL
                </a-select-option>
                <a-select-option value="LOWER"> LOWER </a-select-option>
                <a-select-option value="LOWER_OR_EQUAL">
                  LOWER_OR_EQUAL
                </a-select-option>
                <a-select-option value="LIKE"> LIKE </a-select-option>
                <a-select-option value="IS_NULL"> IS_NULL </a-select-option>
              </a-select>
            </a-col>
          </a-row>
        </a-card>
      </a-tab-pane>
    </a-tabs>
  </a-modal>
</template>

<script>
import _ from 'lodash';
import { generateUUID } from '@/utilities';
import { TemplatesActions } from '@/apps/templateManagement/shared/templateManagement.store';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons-vue';

const defaultColumn = {
  uid: null,
  depth: null,
  spacingColumn: false,
  width: 100, // procent
  height: 0, // px
  cssClasses: [],
  inlineStyle: [],
  name: 'Default column',
  object: null,
  tabsView: false,
  tabViewType: 'card',
  defaultBackground: true,
  columnGroups: [],
  visibilityConditions: [],
  renderOnlyForXML: false,
};

export default {
  name: 'TemplateRecursionAddEditColumn',
  props: ['template', 'blueprints'],
  emits: ['update:modalAddColumnVisible'],
  components: {
    MinusOutlined,
    PlusOutlined,
  },
  data() {
    return {
      modalView: false,
      editingColumn: false,

      spacingColumn: false,
      columnName: null,
      columnWidth: null,
      columnHeight: null,
      columnCssClasses: null,
      columnInlineStyle: null,
      tabsView: false,
      tabViewType: '',
      defaultBackground: true,
      defaultColumn,
    };
  },
  computed: {
    templateAssociatedBlueprint() {
      return this.blueprints.find(
        bp => bp.id === this.$route.params.blueprintId
      );
    },

    associatedBlueprintFieldsOptions() {
      const options = [];

      if (Array.isArray(this.templateAssociatedBlueprint.fields)) {
        this.templateAssociatedBlueprint.fields.forEach(field => {
          options.push({
            value: field.id,
            label: field.label,
          });
        });
      }

      return options;
    },
  },
  methods: {
    closeModal() {
      this.modalView = false;
      this.editingColumn = false;
      this.resetThisColumnFields();
    },

    showModal(editMode = false) {
      if (editMode) {
        this.editingColumn = true;
        this.editColumnTemplate();
      }
      this.modalView = true;
    },

    editColumnTemplate() {
      this.columnName = this.template.name;
      this.columnWidth = this.template.width;
      this.columnCssClasses = this.template.cssClasses.join(' ');
      this.tabsView = this.template.tabsView;
      this.tabViewType = this.template.tabViewType;
      this.defaultBackground = this.template.defaultBackground;

      let styleString = '';
      Object.entries(this.template.inlineStyle).forEach(([k, v]) => {
        if (typeof v !== 'function') {
          styleString += `${k}: ${v}; `;
        }
      });
      this.columnInlineStyle = styleString;
    },

    removeVisibilyCondition(item) {
      const localTemplate = this.template;
      localTemplate.visibilityConditions =
        this.localTemplate.visibilityConditions.filter(cond => cond !== item);
      TemplatesActions.setTemplate(localTemplate);
    },

    addVisibilityCondition() {
      const localTemplate = this.template;
      localTemplate.visibilityConditions.push({
        fromFieldId: null,
        operation: null,
        comparation: null,
        againstFieldValue: '',
      });
      TemplatesActions.setTemplate(localTemplate);
    },

    handleConfirm() {
      if (this.editingColumn) {
        this.saveChangesToColumn();
      } else {
        this.addNewColumnToTemplate();
      }

      this.closeModal();
    },

    addNewColumnToTemplate() {
      const newColumn = _.cloneDeep(this.defaultColumn);

      newColumn.uid = generateUUID();
      newColumn.spacingColumn = this.spacingColumn;
      newColumn.depth = this.template.depth + 1;
      newColumn.name = this.columnName;
      newColumn.width = this.columnWidth;
      newColumn.height = this.columnHeight;
      newColumn.tabsView = this.tabsView;
      newColumn.tabViewType = this.tabViewType;
      newColumn.defaultBackground = this.defaultBackground;
      newColumn.cssClasses = this.columnCssClasses?.length
        ? this.columnCssClasses.split(' ')
        : [];

      if (this.columnInlineStyle?.length) {
        const inlineStyle = this.columnInlineStyle.split(';');
        const styleObj = {};
        inlineStyle.forEach(style => {
          const styleArr = style.split(':');
          if (styleArr.length) {
            if (styleArr[0].trim().length && styleArr[1].trim().length) {
              styleObj[styleArr[0].trim()] = styleArr[1].trim();
            }
          }
        });
        newColumn.inlineStyle = styleObj;
      }
      const localTemplate = this.template;
      localTemplate.columnGroups.push(newColumn);
      TemplatesActions.setTemplate(localTemplate);
      this.resetThisColumnFields();
    },

    saveChangesToColumn() {
      const localTemplate = this.template;
      localTemplate.spacingColumn = this.spacingColumn;
      localTemplate.name = this.columnName;
      localTemplate.width = this.columnWidth;
      localTemplate.tabsView = this.tabsView;
      localTemplate.tabViewType = this.tabViewType;
      localTemplate.defaultBackground = this.defaultBackground;
      localTemplate.cssClasses = this.columnCssClasses.split(' ');

      if (this.columnInlineStyle?.length) {
        const inlineStyle = this.columnInlineStyle.split(';');
        const styleObj = {};
        inlineStyle.forEach(style => {
          const styleArr = style.split(':');
          if (styleArr.length) {
            if (styleArr[0].trim().length && styleArr[1].trim().length) {
              styleObj[styleArr[0].trim()] = styleArr[1].trim();
            }
          }
        });
        localTemplate.inlineStyle = styleObj;
      }
      TemplatesActions.setTemplate(localTemplate);
      this.resetThisColumnFields();
    },

    resetThisColumnFields() {
      this.spacingColumn = false;
      this.columnName = null;
      this.columnWidth = null;
      this.columnCssClasses = null;
      this.columnInlineStyle = null;
      this.tabsView = false;
      this.tabViewType = '';
      this.defaultBackground = true;
    },
  },
};
</script>
