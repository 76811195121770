import _ from 'lodash';

import { instanceApi } from '@dataSystem/api';
import { validateAcceptance } from './acceptanceValidation';

function isValueEmpty(value) {
  if (value === undefined || value === null) {
    return true;
  }
  if (typeof value === 'string') {
    if (value.trim().length === 0) {
      return true;
    }
  }
  if (_.isObject(value)) {
    return _.isEmpty(value);
  }
  return false;
}

async function computeReferenceFieldAcceptance({ field, fieldValue }) {
  const validity = {
    isEmptyButRequired: false,
    passedValidation: true,
    validationFieldArrayIndex: -1,
    acceptance: {
      isPassed: true,
      failureMessageList: null,
    },
  };

  if (field.isRequired) {
    if (!Array.isArray(fieldValue)) {
      if (isValueEmpty(fieldValue)) {
        validity.isEmptyButRequired = true;
        return validity;
      }
    } else {
      for (let i = 0; i < fieldValue.length; i += 1) {
        if (isValueEmpty(fieldValue[i])) {
          validity.isEmptyButRequired = true;
          return validity;
        }
      }
    }
  }

  if (!isValueEmpty(fieldValue)) {
    const { isAcceptancePassed, failureMessageList } = validateAcceptance(
      fieldValue,
      field
    );
    validity.acceptance.isPassed = isAcceptancePassed;
    validity.acceptance.failureMessageList = failureMessageList;
  }
  return validity;
}

export class ValidationHelper {
  constructor() {
    // constructor logic if needed
    this.searchTimeout = null;
    this.needCheck = {};
    this.validationLastValue = null;
    this.validationLastField = null;
    this.fieldIdToValidity = {};
    this.ValidatorHandler = {
      EQUAL: (inputValue, expectedValue) => inputValue === expectedValue,
      NOT_EQUAL: (inputValue, expectedValue) => inputValue !== expectedValue,
      REGEX: (inputValue, expectedValue) => {
        const pattern = new RegExp(`${expectedValue}`);
        return pattern.test(inputValue);
      },
      IS_FOUND: async (
        inputValue,
        expectedValue,
        { validator, fieldValue, field }
      ) => {
        return this.databaseValidation({ validator, fieldValue, field });
      },
      NOT_FOUND: async (
        inputValue,
        expectedValue,
        { validator, fieldValue, field }
      ) => {
        return this.databaseValidation({ validator, fieldValue, field });
      },
      FIND_FIRST: async (
        inputValue,
        expectedValue,
        { validator, fieldValue, field }
      ) => {
        return this.databaseValidation({ validator, fieldValue, field });
      },
      FIND_LAST: async (
        inputValue,
        expectedValue,
        { validator, fieldValue, field }
      ) => {
        return this.databaseValidation({ validator, fieldValue, field });
      },
    };
  }

  keyWait(time) {
    return new Promise(resolve => {
      this.searchTimeout = setTimeout(resolve, time);
    });
  }

  async databaseValidation({ validator, fieldValue }) {
    if (
      ['IS_FOUND', 'NOT_FOUND', 'FIND_FIRST', 'FIND_LAST'].includes(
        validator.comparator
      )
    ) {
      if (fieldValue) {
        clearTimeout(this.searchTimeout);
        if (fieldValue.toString().length >= 3) {
          await this.keyWait(700);
          const filterConditions = [];
          const toReferenceFieldIds = [
            {
              blueprintId: validator.blueprintId,
              fieldId: validator.fieldId,
              type: validator.fieldType,
            },
          ];

          filterConditions.push({
            operation: 'and',
            comparation: 'EQUAL',
            firstLast: validator.comparator,
            firstLastValue: validator.expectedValue,
            caseSensitive: validator.caseSensitive,
            includeInCreateInstance: false,
            toFieldFromOtherBlueprint: false,
            toReferenceFieldIds,
            value: fieldValue,
          });

          if (
            ['FIND_FIRST', 'FIND_LAST'].includes(validator.comparator) &&
            validator.expectedValue < fieldValue.toString().length &&
            !/^-?\d+$/.test(fieldValue.toString())
          ) {
            // regex true if integer
            return false;
          }
          const { instanceList } = await instanceApi.getAll(
            validator.blueprintId,
            {},
            filterConditions
          );
          if (
            ['IS_FOUND', 'FIND_FIRST', 'FIND_LAST'].includes(
              validator.comparator
            )
          ) {
            return instanceList.length > 0;
          }
          if (validator.comparator === 'NOT_FOUND') {
            return instanceList.length <= 0;
          }
        } else {
          return false;
        }
      } else {
        return true;
      }
    }
    return true;
  }

  static computeIsValidationPassed({ fieldIdToValidity }) {
    return Object.values(fieldIdToValidity).every(
      validity =>
        (validity.isEmptyButRequired === false &&
          validity.passedValidation === true &&
          validity.acceptance.isPassed) ||
        validity.visibility === false
    );
  }

  async validateReferencedFields({
    field,
    fieldValue,
    validation,
    currentFieldValues,
  }) {
    const { validators } = validation;
    let returnReachLast = null;
    if (fieldValue != null) {
      let conditionIdx = 0;
      const resultsOfValidators = [];
      await validators.reduce((promise, validator) => {
        return promise.then(async () => {
          conditionIdx += 1;
          const handler = this.ValidatorHandler[validator.comparator];
          let isPassingValidator = true;
          if (validator.option !== 'value') {
            let searchValue = currentFieldValues[validator.fieldId];
            if (validator.option !== 'value') {
              if (validator.blueprintId != null) {
                if (validator.references.length > 0) {
                  await validator.references.reduce((promise2, ref) => {
                    return promise2.then(async () => {
                      const filterConditions = [];
                      const toReferenceFieldIds = [
                        {
                          blueprintId: ref.blueprintId,
                          fieldId: '_id',
                          type: ref.fieldType,
                        },
                      ];

                      filterConditions.push({
                        operation: 'and',
                        comparation: 'EQUAL',
                        includeInCreateInstance: true,
                        toFieldFromOtherBlueprint: true,
                        toReferenceFieldIds,
                        value: searchValue,
                      });

                      // Fetch the instances sequentially
                      const { instanceList } = await instanceApi.getAll(
                        ref.blueprintId,
                        {},
                        filterConditions
                      );

                      if (instanceList.length > 0) {
                        searchValue = instanceList[0][ref.fieldId];
                      } else {
                        searchValue = null;
                      }
                    });
                  }, Promise.resolve());
                } else {
                  const filterConditions = [];
                  const toReferenceFieldIds = [
                    {
                      blueprintId: validator.blueprintId,
                      fieldId: '_id',
                      type: 'reference',
                    },
                  ];
                  filterConditions.push({
                    operation: 'and',
                    comparation: validator.comparator,
                    includeInCreateInstance: true,
                    toFieldFromOtherBlueprint: true,
                    toReferenceFieldIds,
                    value: fieldValue,
                  });
                  const { instanceList } = await instanceApi.getAll(
                    validator.blueprintId,
                    {},
                    filterConditions
                  );

                  if (instanceList.length > 0) {
                    searchValue = instanceList[0][validator.fieldId];
                  } else {
                    searchValue = null;
                  }
                }
              }
            }

            if (!isValueEmpty(searchValue)) {
              if (typeof searchValue === 'string') {
                if (validator?.caseSensitive ? validator.caseSensitive : true) {
                  if (validator.expectedValue.split(',').length > 1) {
                    isPassingValidator = await handler(
                      searchValue,
                      validator?.expectedValue
                        ? validator.expectedValue
                            .split(',')
                            .find(str => str === searchValue)
                        : '',
                      { validator, fieldValue: searchValue, field }
                    );
                  } else {
                    isPassingValidator = await handler(
                      searchValue,
                      validator.expectedValue,
                      { validator, fieldValue: searchValue, field }
                    );
                  }
                } else {
                  isPassingValidator = await handler(
                    searchValue.toLocaleLowerCase(),
                    validator?.expectedValue
                      ? validator.expectedValue
                          .split(',')
                          .find(
                            str =>
                              str.toLocaleLowerCase() ===
                              searchValue.toLocaleLowerCase()
                          )
                      : '',
                    {
                      validator,
                      fieldValue: searchValue.toLocaleLowerCase(),
                      field,
                    }
                  );
                }
              } else {
                isPassingValidator = await handler(
                  searchValue,
                  parseInt(
                    validator?.expectedValue ? validator.expectedValue : '',
                    10
                  ),
                  { validator, fieldValue: searchValue, field }
                );
              }
            } else {
              isPassingValidator = true;
            }
          } else if (validator.alwaysActive) {
            if (validator?.caseSensitive ? validator.caseSensitive : true) {
              isPassingValidator = await handler(
                fieldValue.toString(),
                validator?.expectedValue
                  ? validator.expectedValue.toString()
                  : '',
                { validator, fieldValue, field }
              );
            } else {
              isPassingValidator = await handler(
                fieldValue.toString().toLowerCase(),
                validator?.expectedValue
                  ? validator.expectedValue.toString().toLowerCase()
                  : '',
                {
                  validator,
                  fieldValue: fieldValue.toString().toLowerCase(),
                  field,
                }
              );
            }
          } else {
            if (
              validator.conditionedBy.comparator === 'EQUAL' &&
              validator.conditionedBy.expectedValue.toString() ===
                (currentFieldValues[validator.conditionedBy.fieldId]
                  ? currentFieldValues[
                      validator.conditionedBy.fieldId
                    ].toString()
                  : null)
            ) {
              if (validator?.caseSensitive ? validator.caseSensitive : true) {
                isPassingValidator = await handler(
                  fieldValue.toString(),
                  validator?.expectedValue
                    ? validator.expectedValue.toString()
                    : '',
                  { validator, fieldValue, field }
                );
              } else {
                isPassingValidator = await handler(
                  fieldValue.toString().toLowerCase(),
                  validator?.expectedValue
                    ? validator.expectedValue.toString().toLowerCase()
                    : '',
                  {
                    validator,
                    fieldValue: fieldValue.toString().toLowerCase(),
                    field,
                  }
                );
              }
            }
            if (
              validator.conditionedBy.comparator === 'NOT_EQUAL' &&
              validator.conditionedBy.expectedValue.toString() !==
                (currentFieldValues[validator.conditionedBy.fieldId]
                  ? currentFieldValues[
                      validator.conditionedBy.fieldId
                    ].toString()
                  : null)
            ) {
              if (validator?.caseSensitive ? validator.caseSensitive : true) {
                isPassingValidator = await handler(
                  fieldValue.toString(),
                  validator?.expectedValue
                    ? validator.expectedValue.toString()
                    : '',
                  { validator, fieldValue, field }
                );
              } else {
                isPassingValidator = await handler(
                  fieldValue.toString().toLowerCase(),
                  validator?.expectedValue
                    ? validator.expectedValue.toString().toLowerCase()
                    : '',
                  {
                    validator,
                    fieldValue: fieldValue.toString().toLowerCase(),
                    field,
                  }
                );
              }
            }
          }
          if (isPassingValidator) {
            resultsOfValidators.push(true);
          } else {
            resultsOfValidators.push(false);
            if (
              validators.length !== conditionIdx &&
              validation.mode === 'reach'
            ) {
              returnReachLast = true;
            }
          }
        });
      }, Promise.resolve());
      if (returnReachLast) {
        return returnReachLast;
      }
      let isPassed = null;

      if (validation.mode === 'all' || validation.mode === 'reach') {
        isPassed = resultsOfValidators.every(result => result === true);
      }
      if (validation.mode === 'any') {
        isPassed = resultsOfValidators.some(result => result === true);
      }
      return isPassed;
    }
    return true;
  }

  async computeReferenceFieldValidity({
    field,
    fieldValue,
    currentFieldValues,
  }) {
    const validity = {
      isEmptyButRequired: false,
      passedValidation: true,
      validationFieldArrayIndex: -1,
      acceptance: {
        isPassed: true,
        failureMessageList: null,
      },
    };
    const { validation } = field.logic;
    if (field.isRequired) {
      if (!Array.isArray(fieldValue)) {
        if (isValueEmpty(fieldValue)) {
          validity.isEmptyButRequired = true;
          return validity;
        }
      } else {
        for (let i = 0; i < fieldValue.length; i += 1) {
          if (isValueEmpty(fieldValue[i])) {
            validity.isEmptyButRequired = true;
            // return validity;
          }
        }
      }
    }
    if (field.isRequired && isValueEmpty(fieldValue)) {
      validity.isEmptyButRequired = true;
      return validity;
    }
    if (!Array.isArray(fieldValue)) {
      if (!isValueEmpty(fieldValue)) {
        validity.passedValidation = await this.validateReferencedFields({
          field,
          fieldValue,
          validation,
          currentFieldValues,
        });
        return validity;
      }
    } else {
      const validationResults = await Promise.all(
        fieldValue.map(async (value, index) => {
          if (!isValueEmpty(value)) {
            const passedValidation = await this.validateReferencedFields({
              field,
              fieldValue: value,
              validation,
              currentFieldValues,
              arrayIndex: index,
            });
            return { passedValidation, index };
          }
          return { passedValidation: true, index };
        })
      );

      const failedValidation = validationResults
        .filter(result => !result.passedValidation)
        .map(result => result.index);
      if (failedValidation?.length) {
        validity.passedValidation = false;
        validity.validationFieldArrayIndex = failedValidation;
        return validity;
      }
    }
    return validity;
  }

  async fieldsToBeValidated({ fieldsById, fieldIdToValue }) {
    const mainFields = [];
    const secondaryFields = {};
    const calculateFieldsId = [];
    const fieldIdToValidity = {};
    const fieldPromises = [];

    const fieldIds = Object.keys(fieldsById);
    fieldIds.forEach(fieldId => {
      const field = fieldsById[fieldId];
      const { validation, calculation } = field.logic;
      const fieldValue = fieldIdToValue[field._id.toString()];

      // Compute acceptance if required or if validation is enabled but not required
      if (
        field.isRequired ||
        (field.acceptance?.validators?.length > 0 && !validation.isEnabled)
      ) {
        fieldPromises.push(
          computeReferenceFieldAcceptance({
            field,
            fieldValue,
            fieldIdToValue,
          }).then(validity => {
            fieldIdToValidity[field._id.toString()] = validity;
          })
        );
      }

      // Collect fields to be calculated
      if (calculation.isEnabled) {
        calculateFieldsId.push(field._id.toString());
      }

      // Process validation logic
      if (validation.isEnabled) {
        mainFields.push(field._id.toString());
        secondaryFields[field._id.toString()] = [];
        validation.validators.forEach(validator => {
          const validatorFieldId = validator.fieldId?.toString();
          const conditionedByFieldId =
            validator.conditionedBy.fieldId?.toString();

          if (validatorFieldId && field._id.toString() !== validatorFieldId) {
            secondaryFields[field._id.toString()].push(validatorFieldId);
          }

          if (
            conditionedByFieldId &&
            conditionedByFieldId !== field._id.toString() &&
            conditionedByFieldId !== validatorFieldId
          ) {
            secondaryFields[field._id.toString()].push(conditionedByFieldId);
          }
        });

        secondaryFields[field._id.toString()] = secondaryFields[
          field._id.toString()
        ].filter(value => fieldIds.includes(value));
      }
    });
    // Filter out main fields that are not in fieldsById
    const validMainFields = mainFields.filter(value =>
      fieldIds.includes(value)
    );

    // Compute validity for the main fields
    fieldPromises.push(
      ...validMainFields.map(fieldId =>
        this.computeReferenceFieldValidity({
          field: fieldsById[fieldId],
          fieldValue: fieldIdToValue[fieldId],
          currentFieldValues: fieldIdToValue,
        }).then(validity => {
          fieldIdToValidity[fieldId] = validity;
        })
      )
    );

    // Wait for all promises to resolve
    await Promise.all(fieldPromises);

    const toBeChecked = {
      main: validMainFields,
      second: secondaryFields,
      fieldsCalculate: calculateFieldsId,
    };
    return { toBeChecked, fieldIdToValidity };
  }

  isPassingValidation({ fieldValue, validation, currentFieldValues }) {
    // Extract validators from validation
    const { validators } = validation;

    // Process each validator and determine if the field passes validation
    const resultsOfValidators = validators.map(validator => {
      const handler = this.ValidatorHandler[validator.comparator];
      let isPassingValidator = true;

      if (validator.alwaysActive) {
        isPassingValidator = handler(fieldValue, validator.expectedValue);
      } else {
        const conditionedValue =
          currentFieldValues[validator.conditionedBy.fieldId];

        if (
          (validator.conditionedBy.comparator === 'EQUAL' &&
            conditionedValue === validator.conditionedBy.expectedValue) ||
          (validator.conditionedBy.comparator === 'NOT_EQUAL' &&
            conditionedValue !== validator.conditionedBy.expectedValue)
        ) {
          isPassingValidator = handler(fieldValue, validator.expectedValue);
        } else {
          isPassingValidator = true; // If the condition is not met, the validator is considered passing
        }
      }

      return isPassingValidator;
    });

    // Determine the final validation result based on the mode
    const { mode } = validation;
    let isPassed;

    if (mode === 'all') {
      isPassed = resultsOfValidators.every(result => result === true);
    } else if (mode === 'any') {
      isPassed = resultsOfValidators.some(result => result === true);
    } else {
      // If the mode is neither 'all' nor 'any', return false or handle as needed
      isPassed = false;
    }

    return isPassed;
  }

  computeFieldValidity({ field, fieldValue, currentFieldValues }) {
    const validity = {
      isEmptyButRequired: false,
      passedValidation: true,
      validationFieldArrayIndex: -1,
      acceptance: {
        isPassed: true,
        failureMessageList: null,
      },
    };

    const { validation } = field.logic;

    if (field.isRequired && isValueEmpty(fieldValue)) {
      validity.isEmptyButRequired = true;
      return validity;
    }

    if (!isValueEmpty(fieldValue)) {
      if (validation.isEnabled) {
        validity.passedValidation = this.isPassingValidation({
          fieldValue,
          validation,
          currentFieldValues,
        });
      }
      const { isAcceptancePassed, failureMessageList } =
        this.validateAcceptance(fieldValue, field);
      validity.acceptance.isPassed = isAcceptancePassed;
      validity.acceptance.failureMessageList = failureMessageList;
    }

    return validity;
  }

  computeFieldIdToValidity({ fieldsById, fieldIdToValue }) {
    const newFieldIdToValidity = {};

    // Iterate over entries of fieldsById
    Object.entries(fieldsById).forEach(([fieldId, field]) => {
      newFieldIdToValidity[fieldId] = this.computeFieldValidity({
        field,
        fieldValue: fieldIdToValue[fieldId],
        currentFieldValues: fieldIdToValue,
      });
    });

    return newFieldIdToValidity;
  }

  async initReferenced({ fieldsById, fieldIdToValue }) {
    const { toBeChecked, fieldIdToValidity } = await this.fieldsToBeValidated({
      fieldsById,
      fieldIdToValue,
    });
    this.needCheck = toBeChecked;
    // const fieldIdToValidity = await  computeReferenced({ fieldsById, fieldIdToValue, oldFieldIdToValue :{}, fieldIdToValidity : null, isFormLogicInitDone: true });
    return { fieldIdToValidity };
  }

  init({ fieldsById, fieldIdToValue }) {
    const fieldIdToValidity = this.computeFieldIdToValidity({
      fieldsById,
      fieldIdToValue,
    });
    this.needCheck = this.fieldsToBeValidated({ fieldsById });
    return { fieldIdToValidity };
  }

  /**
   * Fill validation arrays  "needCheck" of objects and make first validation for all fields in same loop
   * @param fieldsById
   * @param fieldIdToValue
   * @returns {Promise<{toBeChecked: {fieldIsRequired: *[], fieldsCalculate: *[], main: *[], fieldsAcceptance: *[], second: {}}, fieldIdToValidity: {}}>}
   */

  async computeReferenced({
    fieldsById,
    fieldIdToValue,
    fieldIdToValidity,
    oldFieldIdToValue,
    isValidationInitDone = false,
    modifiedFieldId,
  }) {
    const newFieldIdToValidity = { ...fieldIdToValidity };

    // Collect promises for parallel execution
    const promises = [];
    if (
      fieldsById[modifiedFieldId]?.isRequired ||
      fieldsById[modifiedFieldId]?.acceptance?.validators?.length > 0
    ) {
      // check if required
      promises.push(
        computeReferenceFieldAcceptance({
          field: fieldsById[modifiedFieldId],
          fieldValue: fieldIdToValue[modifiedFieldId],
          currentFieldValues: fieldIdToValue,
        })
          .then(result => {
            newFieldIdToValidity[modifiedFieldId] = result;
          })
          .catch(error => {
            console.error('Error computing field acceptance:', error);
          })
      );
    }
    if (
      fieldIdToValue[modifiedFieldId] !== oldFieldIdToValue[modifiedFieldId] ||
      this.validationLastValue !== fieldIdToValue[modifiedFieldId] ||
      (this.validationLastField !== modifiedFieldId && modifiedFieldId) ||
      isValidationInitDone
    ) {
      this.validationLastValue = fieldIdToValue[modifiedFieldId];
      this.validationLastField = modifiedFieldId;
      if (
        this.needCheck.main.includes(modifiedFieldId) ||
        isValidationInitDone
      ) {
        promises.push(
          this.computeReferenceFieldValidity({
            field: fieldsById[modifiedFieldId],
            fieldValue: fieldIdToValue[modifiedFieldId],
            currentFieldValues: fieldIdToValue,
          })
            .then(result => {
              newFieldIdToValidity[modifiedFieldId] = result;
            })
            .catch(error => {
              console.error('Error computing field validity:', error);
            })
        );
      } else {
        const secondaryPromises = Object.keys(this.needCheck.second).map(
          async secondFieldId => {
            if (
              this.needCheck.second[secondFieldId].includes(modifiedFieldId) ||
              isValidationInitDone
            ) {
              try {
                const result = await this.computeReferenceFieldValidity({
                  field: fieldsById[secondFieldId],
                  fieldValue: fieldIdToValue[secondFieldId],
                  currentFieldValues: fieldIdToValue,
                });
                newFieldIdToValidity[secondFieldId] = result;
                return true; // Indicate that a valid secondary field was found
              } catch (error) {
                console.error(
                  'Error computing secondary field validity:',
                  error
                );
                return false;
              }
            }
            return false;
          }
        );

        const secondaryResults = await Promise.all(secondaryPromises);
        if (secondaryResults.some(result => result)) {
          return newFieldIdToValidity; // If any secondary field was processed, skip further checks
        }
      }
    }

    // Process fieldsCalculate in parallel
    const calculatePromises = this.needCheck.fieldsCalculate.map(
      calculateFieldId => {
        if (fieldsById[calculateFieldId]) {
          return computeReferenceFieldAcceptance({
            field: fieldsById[calculateFieldId],
            fieldValue: fieldIdToValue[calculateFieldId],
            currentFieldValues: fieldIdToValue,
          })
            .then(result => {
              newFieldIdToValidity[calculateFieldId] = result;
            })
            .catch(error => {
              console.error(
                'Error computing calculate field acceptance:',
                error
              );
            });
        }
        return Promise.resolve(); // Ensure to return a resolved promise if the field doesn't exist
      }
    );

    // Wait for all promises to complete
    try {
      await Promise.all([...promises, ...calculatePromises]);
    } catch (error) {
      console.error('Error in computing field references:', error);
      throw error; // Re-throw if necessary
    }
    return newFieldIdToValidity;
  }
}
