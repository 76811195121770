<template>
  <div>
    <UserListManager
      v-if="user.isSuperAdmin"
      v-bind="UserListManagerProps"
      rank="tenant-admin"
      @close="closeUserListManager"
    />
    <h2>Tenants</h2>
    <div v-if="user.isSuperAdmin" style="margin-bottom: 25px">
      <a-button
        type="primary"
        @click="
          createEditTenant('TASBlueprintForm', 'Create Tenant', false, [])
        "
      >
        <PlusOutlined /> Create tenant
      </a-button>
    </div>
    <h3>
      <BuildOutlined />
      Tenants list
      <a-badge
        :count="tenants.length"
        :number-style="{
          backgroundColor: '#fff',
          color: '#999',
          boxShadow: '0 0 0 1px #d9d9d9 inset',
        }"
      />
    </h3>
    <div v-if="!tenants.length">
      <a-alert
        v-if="user.isSuperAdmin"
        data-cy="superAdminAlertTenantListEmpty"
        banner
        message="No tenant created"
      />
      <a-alert
        v-else
        banner
        data-cy="userAlertTenantListEmpty"
        message="You do not have permission to access any tenant."
      />
    </div>

    <template v-if="tenants.length">
      <div
        class="saas_card mb-10 hoverable"
        v-for="tenant in tenants"
        :key="tenant._id"
        data-cy="cardTenant"
      >
        <div class="row-contents">
          <div class="group-text-container" @click="selectTenant(tenant.slug)">
            <a data-cy="linkTenantSelect">
              <div class="group-text">
                <span class="title">
                  {{ tenant.name }}
                </span>
                <span v-if="tenant.applications" class="muted-text">
                  {{
                    tenant.applications.length == 1
                      ? 'one application'
                      : tenant.applications.length + ' applications'
                  }}
                </span>
              </div>
            </a>
          </div>
          <div class="metadata">
            <div v-if="user.isSuperAdmin" class="controls">
              <a-button
                @click="() => openUserListManager(tenant._id)"
                class="mr-10"
              >
                <TeamOutlined /> Admins
              </a-button>

              <a-tooltip placement="top">
                <template #title>
                  <span>Edit</span>
                </template>
                <a-button
                  @click="
                    createEditTenant(
                      'TASBlueprintForm',
                      'Edit Tenant',
                      true,
                      tenant
                    )
                  "
                  class="mr-10"
                  data-cy="btnTenantEdit"
                  ><EditOutlined />
                </a-button>
              </a-tooltip>

              <a-popconfirm
                placement="topLeft"
                :title="
                  'Are you sure you want to delete `' + tenant.name + '`?'
                "
                ok-text="Yes"
                cancel-text="No"
                @confirm="removeTenant(tenant._id)"
              >
                <a-button data-cy="btnTenantDelete">
                  <DeleteOutlined />
                </a-button>
              </a-popconfirm>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {
  BuildOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  TeamOutlined,
} from '@ant-design/icons-vue';
import { TenantsActions } from '@tenants/shared/tenants.store';

import UserListManager from '@userManagement/components/UserListManager/UserListManager.vue';

import { UserGetters } from '@userManagement/user.store';
import { toRaw } from 'vue';

export default {
  props: ['tenants'],
  emits: ['openModal'],
  data() {
    return {
      loading: false,

      UserListManagerProps: {
        visible: false,
        tenantId: null,
      },
    };
  },

  computed: {
    user: UserGetters.getUser,
  },

  components: {
    UserListManager,
    BuildOutlined,
    DeleteOutlined,
    EditOutlined,
    PlusOutlined,
    TeamOutlined,
  },

  methods: {
    selectTenant(tenantSlug) {
      this.$router.push({ name: 'Tenant', params: { tenantSlug } });
    },
    createEditTenant(componentTarget, componentName, edit, editData) {
      this.$emit('openModal', {
        componentTarget,
        componentName,
        edit,
        editData: toRaw(editData),
        type: 'tenant',
      });
    },
    removeTenant(tenantId) {
      TenantsActions.deleteOneTenant(tenantId);
    },
    openUserListManager(tenantId) {
      this.UserListManagerProps = {
        visible: true,
        tenantId,
      };
    },
    closeUserListManager() {
      this.UserListManagerProps = {
        visible: false,
        tenantId: null,
      };
    },
  },
};
</script>
<style scoped></style>
