import { reactive } from 'vue';

import { handleNested } from '@/core/utils/array-manipulation';

import { fieldMapperApi } from '../api';

const initialState = {
  fieldMapperList: [],
  dataflowFields: [],
};

const state = reactive({ ...initialState });

const Getters = {
  getFieldMapperList: () => {
    return state.fieldMapperList;
  },
};

const Mutations = {
  SET_FIELDMAPPER_LIST: fieldMapper => {
    state.fieldMapperList = fieldMapper;
  },

  INSERT_FIELDMAPPER: fieldMapper => {
    state.fieldMapperList.push(fieldMapper);
  },
  EDIT_FIELDMAPPER: (fieldMapperId, updatedFieldMapper) => {
    const statefieldMapperList = handleNested(
      state.fieldMapperList,
      { _id: fieldMapperId },
      { update: updatedFieldMapper }
    );
    state.fieldMapperList = statefieldMapperList;
  },
  DELETE_FIELDMAPPER: fieldMapperId => {
    const statefieldMapperList = handleNested(state.fieldMapperList, {
      _id: fieldMapperId,
    });
    state.fieldMapperList = statefieldMapperList;
  },
};

const Actions = {
  fetchFieldMapperList: async () => {
    const fieldMapperList = await fieldMapperApi.getAll();
    Mutations.SET_FIELDMAPPER_LIST(fieldMapperList);
  },

  createOneFieldMapper: async fieldMapper => {
    const newfieldMapperList = await fieldMapperApi.postOne(fieldMapper);
    Mutations.INSERT_FIELDMAPPER(newfieldMapperList);
  },
  editOneFieldMapper: async (fieldMapperId, fieldMapperForm) => {
    const updatedFieldMapper = await fieldMapperApi.putOne(
      fieldMapperId,
      fieldMapperForm
    );
    Mutations.EDIT_FIELDMAPPER(fieldMapperId, updatedFieldMapper);
  },
  deleteOneFieldMapper: async fieldMapperId => {
    await fieldMapperApi.deleteOne(fieldMapperId);
    Mutations.DELETE_FIELDMAPPER(fieldMapperId);
  },

  createOneMapper: async (fieldMapperId, fieldMapperForm) => {
    const updatedFieldMapper = await fieldMapperApi.postOneMapper(
      fieldMapperId,
      fieldMapperForm
    );
    Mutations.EDIT_FIELDMAPPER(fieldMapperId, updatedFieldMapper);
  },
  deleteOneMapper: async (fieldMapperId, mapperId) => {
    const updatedFieldMapper = await fieldMapperApi.deleteOneMapper(
      fieldMapperId,
      mapperId
    );
    Mutations.EDIT_FIELDMAPPER(fieldMapperId, updatedFieldMapper);
  },
  createOneBind: async (fieldMapperId, fieldMapperForm) => {
    const updatedFieldMapper = await fieldMapperApi.postOneBind(
      fieldMapperId,
      fieldMapperForm
    );
    Mutations.EDIT_FIELDMAPPER(fieldMapperId, updatedFieldMapper);
  },
  deleteOneBind: async (fieldMapperId, bindId, providerFieldId) => {
    const updatedFieldMapper = await fieldMapperApi.deleteOneBind(
      fieldMapperId,
      bindId,
      providerFieldId
    );
    Mutations.EDIT_FIELDMAPPER(fieldMapperId, updatedFieldMapper);
  },
};

export const FieldMapperGetters = Getters;
export const FieldMapperMutations = Mutations;
export const FieldMapperActions = Actions;
