<template>
  <a-popconfirm
    :title="title"
    ok-text="Yes"
    cancel-text="No"
    @cancel="emitCancel"
    @openChange="onVisibleChange"
    @confirm="confirmDelete"
  >
    <DeleteOutlined v-if="iconOnly" @click="emitClick" :style="style" />
    <a-button @click="emitClick" v-else :size="size" :style="buttonStyle">
      <DeleteOutlined />
    </a-button>
    <!-- <a-icon
      @click="emitClick"
      v-if="iconOnly"
      style="cursor: pointer"
      v-bind="iconProps"
    />
    <a-button @click="emitClick" v-else :size="size" :style="buttonStyle">
      <a-icon v-bind="iconProps" />
    </a-button>
    -->
  </a-popconfirm>
</template>

<script>
import { DeleteOutlined } from '@ant-design/icons-vue';

export default {
  props: [
    'entityName',
    'size',
    'buttonStyle',
    'icon',
    'color',
    'iconOnly',
    'style',
  ],
  emits: ['click', 'cancel', 'confirm-delete', 'confirm'],
  components: { DeleteOutlined },
  computed: {
    title() {
      return `Are you sure you want to delete this ${this.entityName}?`;
    },
    iconProps() {
      const iconProps = {
        type: this.icon ? this.icon : 'delete',
      };
      if (this.color === 'red') {
        return {
          ...iconProps,
          theme: 'twoTone',
          'two-tone-color': '#cf1322',
        };
      }
      return iconProps;
    },
  },
  methods: {
    emitClick() {
      this.$emit('click');
    },
    emitCancel() {
      this.$emit('cancel');
    },
    onVisibleChange(isVisible) {
      if (!isVisible) {
        this.emitCancel();
      }
    },
    confirmDelete() {
      this.$emit('confirm-delete');
      this.$emit('confirm');
    },
  },
};
</script>

<style></style>
