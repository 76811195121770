import { SlugGetters } from '@/slug.store';

const isEqualSortedArraysByValues = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const arr1CopySorted = arr1.slice().sort();
  const arr2CopySorted = arr2.slice().sort();
  for (let i = 0; i < arr1CopySorted.length; i += 1) {
    if (arr1CopySorted[i] !== arr2CopySorted[i]) {
      return false;
    }
  }

  return true;
};

const ConditionComparatorHandler = {
  EQUAL: (fieldValue, expectedValue) => {
    if (Array.isArray(fieldValue)) {
      const expectedArr = expectedValue.split('|').sort();
      return isEqualSortedArraysByValues(fieldValue, expectedArr);
    }

    return fieldValue === expectedValue;
  },
  NOT_EQUAL: (fieldValue, expectedValue) => {
    if (Array.isArray(fieldValue)) {
      const expectedArr = expectedValue.split('|').sort();
      return (
        fieldValue !== null &&
        fieldValue !== undefined &&
        !isEqualSortedArraysByValues(fieldValue, expectedArr)
      );
    }

    return (
      fieldValue !== null &&
      fieldValue !== undefined &&
      fieldValue !== expectedValue
    );
  },
  VISIBLE: (fieldValue, expectedValue, conditionedFieldVisibility) =>
    conditionedFieldVisibility === true,
  NOT_VISIBLE: (fieldValue, expectedValue, conditionedFieldVisibility) =>
    conditionedFieldVisibility === false,
  VALID: (
    fieldValue,
    expectedValue,
    conditionedFieldVisibility,
    fieldValidity
  ) =>
    fieldValidity?.passedValidation === true &&
    fieldValue !== null &&
    fieldValue !== undefined,
  NOT_VALID: (
    fieldValue,
    expectedValue,
    conditionedFieldVisibility,
    fieldValidity
  ) =>
    fieldValidity?.passedValidation === false ||
    fieldValue === null ||
    fieldValue === undefined,
  CONTAINS: (fieldValue, expectedValue) => {
    if (Array.isArray(fieldValue)) {
      const expectedArr = expectedValue.split('|').sort();
      return expectedArr.every(val => {
        return fieldValue.includes(val);
      });
    }

    return false;
  },
};

const initialData = {
  fieldIdToDependantFieldIdList: {},
  fieldIdToConditionalDependencies: [],
  fieldIdToIsVisible: {},
};

const computeFieldVisibility = ({
  fieldId,
  fieldsById,
  fieldIdToValue,
  fieldsVisibility,
  fieldIdToValidity,
}) => {
  const field = fieldsById[fieldId];
  const { conditional } = field.logic;

  if (!conditional.isEnabled) {
    return true;
  }

  const resultsOfConditions = [];
  conditional.conditions.forEach(condition => {
    const ConditionfieldValue = fieldIdToValue[condition.fieldId];
    const isPassingCondition = ConditionComparatorHandler[condition.comparator];
    const fieldValidity =
      typeof fieldIdToValidity !== 'undefined'
        ? fieldIdToValidity[condition.fieldId]
        : null;
    const conditionedFieldVisibility =
      fieldsVisibility &&
      ['VISIBLE', 'NOT_VISIBLE'].includes(condition.comparator.toString())
        ? fieldsVisibility[condition.fieldId]
        : null;

    if (
      isPassingCondition(
        ConditionfieldValue,
        condition.expectedValue,
        conditionedFieldVisibility,
        fieldValidity
      )
    ) {
      resultsOfConditions.push(true);
    } else {
      resultsOfConditions.push(false);
    }
  });

  let isVisible = null;

  if (conditional.mode === 'all') {
    isVisible = resultsOfConditions.every(result => result === true);
  }

  if (conditional.mode === 'any') {
    isVisible = resultsOfConditions.some(result => result === true);
  }

  if (conditional.action === 'hide') {
    isVisible = !isVisible;
  }

  return isVisible;
};

const init = ({ fieldsById, fieldIdToValue }) => {
  const fieldIdToIsVisible = {};
  const fieldIdToConditionalDependencies = {};

  // Initialize the conditional logic dependencies between fields
  Object.values(fieldsById).forEach(field => {
    const { conditional } = field.logic;
    if (conditional.isEnabled) {
      fieldIdToIsVisible[field._id] = computeFieldVisibility({
        fieldId: field._id,
        fieldsById,
        fieldIdToValue,
      });

      conditional.conditions.forEach(condition => {
        if (
          !condition.fieldId ||
          !condition.comparator ||
          (condition?.subtenantSlug?.length &&
            condition.subtenantSlug !== SlugGetters.getSubtenantSlug())
        ) {
          return;
        }

        if (!fieldIdToConditionalDependencies[condition.fieldId]) {
          fieldIdToConditionalDependencies[condition.fieldId] = [field._id];
        } else if (
          !fieldIdToConditionalDependencies[condition.fieldId].includes(
            field._id
          )
        ) {
          fieldIdToConditionalDependencies[condition.fieldId].push(field._id);
        }
      });
    } else {
      fieldIdToIsVisible[field._id] = true;
    }
  });

  return {
    fieldIdToIsVisible,
    fieldIdToConditionalDependencies,
  };
};

const computeFieldIdToIsVisible = ({
  fieldsById,
  fieldIdToValue,
  fieldIdToConditionalDependencies,
  fieldIdToIsVisible,
  fieldIdToValidity,
}) => {
  const newFieldIdToIsVisible = { ...fieldIdToIsVisible };

  Object.keys(fieldIdToValue).forEach(fieldId => {
    const idListOfDependantFields = fieldIdToConditionalDependencies[fieldId];
    if (idListOfDependantFields && idListOfDependantFields.length > 0) {
      idListOfDependantFields.forEach(dependantFieldId => {
        const oldEvaluation = newFieldIdToIsVisible[dependantFieldId];
        newFieldIdToIsVisible[dependantFieldId] = computeFieldVisibility({
          fieldId: dependantFieldId,
          fieldsById,
          fieldIdToValue,
          fieldsVisibility: newFieldIdToIsVisible,
          fieldIdToValidity,
        });
        if (oldEvaluation !== newFieldIdToIsVisible[dependantFieldId]) {
          // reevaluation of modified field visibility
          const nestedDependantFields =
            fieldIdToConditionalDependencies[fieldId];
          if (nestedDependantFields && nestedDependantFields.length > 0) {
            nestedDependantFields.forEach(nestedDependantFieldId => {
              newFieldIdToIsVisible[nestedDependantFieldId] =
                computeFieldVisibility({
                  fieldId: nestedDependantFieldId,
                  fieldsById,
                  fieldIdToValue,
                  fieldsVisibility: newFieldIdToIsVisible,
                  fieldIdToValidity,
                });
            });
          }
        }
      });
    }
  });

  return newFieldIdToIsVisible;
};

export default {
  initialData,
  init,
  computeFieldIdToIsVisible,
};
