import axios from 'axios';

import { SlugGetters } from '@/slug.store';

const { getAppUrl } = SlugGetters;

const putOne = async (providerId, sourceId, fieldId, data) => {
  try {
    const response = await axios.put(
      `${getAppUrl()}/providers/${providerId}/sources/${sourceId}/fields/${fieldId}`,
      data
    );
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

export default {
  putOne,
};
