<template>
  <div style="margin-bottom: 10px">
    <label>Choices</label>
    <div
      v-for="(choice, index) in input.choices"
      :key="index"
      style="margin-left: 15px; margin-top: 10px"
    >
      <span>{{ index + 1 }}.</span>
      <a-input
        v-model:value="choice.value"
        style="display: inline-block; margin-left: 10px; width: 345px"
      />
      <a-button
        @click="addChoice(index)"
        size="small"
        style="margin: 0 10px; font-weight: 500"
      >
        +
      </a-button>
      <a-button
        @click="removeChoice(index)"
        v-if="input.choices.length > 1"
        size="small"
        style="font-weight: 500"
      >
        -
      </a-button>
    </div>
  </div>
</template>

<script>
import FieldInputOptionsMixin from '../FieldInputOptionsMixin';

const CHOICE_REGEX = /Choice [0-9]+/;

export default {
  mixins: [FieldInputOptionsMixin],
  fieldInputOptions: {
    path: 'structure.elementStructure',
    keys: ['choices'],
  },
  methods: {
    addChoice(index) {
      let highestChoiceNumber = 1;
      for (let i = 0; i < this.input?.choices?.length; i += 1) {
        const choice = this.input?.choices[i];
        if (CHOICE_REGEX.test(choice.value)) {
          const choiceNumber = parseInt(choice.value.split(' ')[1], 10);
          if (choiceNumber > highestChoiceNumber) {
            highestChoiceNumber = choiceNumber;
          }
        }
      }
      this.input.choices.splice(index + 1, 0, {
        label: null,
        value: `Choice ${highestChoiceNumber + 1}`,
      });
    },
    removeChoice(index) {
      this.input.choices.splice(index, 1);
    },
  },
};
</script>

<style></style>
