<template>
  <div>
    <a-modal
      v-model:open="modalView"
      :width="1200"
      title="Configurare fields element"
      @cancel="close"
      @ok="close"
    >
      <a-tabs
        :default-active-key="
          localTemplate.object.view.type === 'list' ? '1' : '2'
        "
      >
        <a-tab-pane
          v-if="localTemplate.object.view.type === 'list'"
          key="1"
          tab="Fields lista"
        >
          <div class="card mb-2">
            <div class="card-body p-2">
              <span
                class="card-title mb-0 pointer"
                @click="addNewFieldFromReference = !addNewFieldFromReference"
              >
                <b>Add new field from reference</b>
                <span v-if="!addNewFieldFromReference">
                  <PlusOutlined
                    style="float: right; top: 3px; position: relative"
                  />
                </span>
                <span v-else>
                  <MinusOutlined
                    style="float: right; top: 3px; position: relative"
                  />
                </span>
              </span>
              <div v-if="addNewFieldFromReference" class="pt-3">
                <ReferenceFields
                  :key="referenceFieldsKey"
                  :autoFieldConditions="true"
                  :blueprints="blueprints"
                  :cardView="false"
                  :justReferenceFields="true"
                  :mainBlueprint="mainBlueprint"
                  :otherBlueprint="true"
                  :selectedBlueprint="selectedBlueprint"
                  :toFieldFromOtherBlueprint="false"
                  @toField="selectField"
                />
                <a-button
                  v-if="addNewFieldFromReferenceButton"
                  class="mt-2"
                  type="primary"
                  @click="addReferenceField"
                  >Add field</a-button
                >
              </div>
            </div>
          </div>

          <div class="mb-3">
            <a-button @click="addConcatField">Add concat field</a-button>
          </div>

          <div class="mb-3">
            <a-checkbox
              :checked="allListFieldsChecked"
              :indeterminate="someListFieldsChecked"
              style="margin-left: 34px"
              @change="checkUncheckAllListFields"
            >
              Check / uncheck all
            </a-checkbox>
          </div>

          <div v-if="localTemplate?.object?.view?.listFields?.length">
            <draggable
              v-model="localTemplate.object.view.listFields"
              group="templates"
              handle=".listFieldsHandle"
              item-key="id"
              @end="drag = false"
              @start="drag = true"
            >
              <template #item="{ element }">
                <div class="mb-3">
                  <div :style="!element.show ? 'opacity: .5' : ''" class="card">
                    <div class="card-body p-2">
                      <a-tooltip title="Delete">
                        <a-popconfirm
                          cancel-text="No"
                          ok-text="Yes"
                          placement="leftTop"
                          @confirm="deleteFieldConfirm(element)"
                        >
                          <template #title>
                            Are you sure you want to delete this field?
                          </template>
                          <a-button
                            v-if="element.deletable"
                            size="small"
                            style="
                              position: absolute;
                              top: 12px;
                              right: 5px;
                              z-index: 10;
                            "
                            type="primary"
                            danger
                            ><DeleteOutlined
                          /></a-button>
                        </a-popconfirm>
                      </a-tooltip>
                      <div class="row">
                        <div class="col-md-6">
                          <a-tooltip title="Click and drag">
                            <a-button
                              class="listFieldsHandle"
                              size="small"
                              style="
                                position: absolute;
                                left: 12px;
                                top: 5px;
                                color: grey;
                              "
                              type="link"
                              ><MenuOutlined
                            /></a-button>
                          </a-tooltip>
                          <a-checkbox
                            v-model:checked="element.show"
                            style="position: absolute; left: 40px; top: 5px"
                          ></a-checkbox>

                          <div
                            v-if="getFieldExtractList(element).length > 0"
                            style="margin-left: 50px; width: 60%"
                          >
                            <a-tooltip :title="element?.pathDetails">
                              <a-input
                                v-model:value="element.label"
                                :addon-before="
                                  element?.typeDetails ??
                                  getFieldType(element.id)
                                "
                              />
                              <a-select
                                v-model:value="element.getFromInstance"
                                :placeholder="'Extract'"
                                style="width: 29%; position: absolute"
                                value="''"
                              >
                                <a-select-option
                                  v-for="item in getFieldExtractList(element)"
                                  :key="item.value"
                                  :value="item.value"
                                >
                                  {{ item.label }} ({{ item.type }})
                                  <template v-if="item.label === 'Gender'"
                                    >[male/female]</template
                                  >
                                </a-select-option>
                              </a-select>
                            </a-tooltip>
                            <!-- <pre>{{ field}}</pre> -->
                            <template v-if="element.getFromInstance">
                              <template v-if="element.id[0] === '_'">
                                <a-select
                                  v-if="
                                    blueprints
                                      .find(f => f._id === element.blueprintId)
                                      .fields.find(
                                        f =>
                                          f._id ===
                                          element.refIdPath.split('.')[1]
                                      )
                                      .widget.extract.find(
                                        i => i.value === element.getFromInstance
                                      ).calculate ?? null === 'fromDate'
                                  "
                                  v-model:value="
                                    element.calculateWithMainBlueprintField
                                  "
                                  :placeholder="'Calculate using date field'"
                                  style="width: 100%; margin-top: 10px"
                                >
                                  <a-select-option :key="null" :value="null">
                                    {{ 'Current date' }}
                                  </a-select-option>
                                  <a-select-option
                                    v-for="it in selectedBlueprint.fields.filter(
                                      f => f.structure.type === 'date'
                                    )"
                                    :key="it._id"
                                    :value="it._id"
                                  >
                                    {{ it.label }} ({{ it.structure.type }})
                                  </a-select-option>
                                </a-select>
                              </template>
                              <template v-else>
                                <a-select
                                  v-if="
                                    selectedBlueprint.fields
                                      .find(f => f._id === element.id)
                                      .widget.extract.find(
                                        i => i.value === element.getFromInstance
                                      ).calculate ?? null === 'fromDate'
                                  "
                                  v-model:value="
                                    element.calculateWithMainBlueprintField
                                  "
                                  :placeholder="'Calculate using date field'"
                                  style="width: 100%; margin-top: 10px"
                                >
                                  <a-select-option :key="null" :value="null">
                                    {{ 'Current date' }}
                                  </a-select-option>
                                  <a-select-option
                                    v-for="it in selectedBlueprint.fields.filter(
                                      f => f.structure.type === 'date'
                                    )"
                                    :key="it._id"
                                    :value="it._id"
                                  >
                                    {{ it.label }} ({{ it.structure.type }})
                                  </a-select-option>
                                </a-select>
                              </template>
                            </template>
                          </div>
                          <div v-else style="margin-left: 50px">
                            <a-tooltip :title="element?.pathDetails">
                              <a-input
                                v-model:value="element.label"
                                :addon-before="
                                  element?.typeDetails ??
                                  getFieldType(element.id)
                                "
                              />
                            </a-tooltip>
                          </div>
                        </div>

                        <div class="col-md-6" style="padding-right: 43px">
                          <div style="padding-top: 5px">
                            <a-button
                              v-if="element.concat"
                              :type="
                                (element?.concatData?.html ?? null)
                                  ? 'primary'
                                  : 'default'
                              "
                              class="mr-3"
                              size="small"
                              @click="showConcatModal(element)"
                              >Concat</a-button
                            >

                            <template
                              v-if="
                                !element?.delete &&
                                element?.id !== 'owner.fullName'
                              "
                            >
                              <template
                                v-if="
                                  selectedBlueprint.fields.find(
                                    item => item._id === element.id
                                  )?.structure?.type !== 'file'
                                "
                              >
                                <a-checkbox v-model:checked="element.order"
                                  >Orderable</a-checkbox
                                >
                                <a-checkbox
                                  v-model:checked="element.searchable"
                                  style="margin-left: 5px"
                                  >Searchable</a-checkbox
                                >
                                <template
                                  v-if="
                                    (element.searchable &&
                                      selectedBlueprint.fields.find(
                                        item => item._id === element.id
                                      )?.structure?.type === 'date') ||
                                    element?.id === '_created_at'
                                  "
                                >
                                  <a-checkbox
                                    v-model:checked="element.dateRange"
                                    style="margin-left: 5px"
                                    >Range</a-checkbox
                                  >
                                </template>

                                <a-checkbox
                                  v-if="element.blueprintId"
                                  v-model:checked="
                                    element.linkToReferenceInstance
                                  "
                                  style="margin-left: 5px"
                                  >Link</a-checkbox
                                >
                                <a-checkbox
                                  v-if="element?.id !== '_created_at'"
                                  v-model:checked="element.filter"
                                  style="margin-left: 5px"
                                  >Filterable</a-checkbox
                                >
                                <a-button
                                  v-if="element.filter"
                                  size="small"
                                  @click="showFiltersModal(element)"
                                  >Config filters</a-button
                                >
                                <a-button
                                  v-if="element?.id !== '_created_at'"
                                  :type="'default'"
                                  class="ml-3"
                                  size="small"
                                  @click="showFieldFormattingModal(element)"
                                  >Formatting</a-button
                                >
                              </template>
                            </template>
                            <template v-else>
                              <span v-if="element?.delete"
                                >Edit / Delete actions column</span
                              >
                              <span v-if="element?.id === 'owner.fullName'"
                                >Instance owner column</span
                              >
                              <span v-if="element?.id === '_created_at'"
                                >Added at column</span
                              >
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </draggable>
          </div>
        </a-tab-pane>

        <a-tab-pane
          v-if="localTemplate.object.view.type === 'list'"
          key="3"
          tab="Header tabel custom"
        >
          <CustomTableHeader
            :templateViewSettings="localTemplate.object.view"
            @updateTemplateObjectView="updateTemplateObjectView"
          />
        </a-tab-pane>

        <a-tab-pane key="2" tab="Fields detalii">
          <div class="mb-3 ml-3">
            <a-checkbox
              :checked="allItemFieldsChecked"
              :indeterminate="someItemFieldsChecked"
              @change="checkUncheckAllItemFields"
            >
              Check / uncheck all
            </a-checkbox>
          </div>

          <a-row>
            <draggable
              v-model="localTemplate.object.view.itemFields"
              item-key="id"
              style="width: 100%"
              :animation="150"
              :force-fallback="true"
              :scroll-sensitivity="100"
              :scroll-speed="40"
              drag-class="dragging-item"
              ghost-class="destination-item"
              group="templates"
              handle=".itemFieldsHandle"
              @end="drag = false"
              @start="drag = true"
            >
              <template #item="{ element: field }">
                <a-col
                  :span="24"
                  class="canBeDragged"
                  style="padding: 0 5px; margin-bottom: 10px"
                >
                  <a-card>
                    <a-tooltip title="Click and drag"
                      ><a-button
                        class="itemFieldsHandle"
                        size="small"
                        style="position: absolute; top: 5px; right: 5px"
                        ><DragOutlined /></a-button
                    ></a-tooltip>
                    <div>
                      Current blueprint label:
                      <strong>{{ field.currentBpLabel }}</strong>
                    </div>
                    <a-input
                      v-model:value="field.label"
                      style="width: calc(100% - 50px)"
                    />
                    <a-checkbox
                      v-model:checked="field.show"
                      style="margin-left: 5px"
                    ></a-checkbox>
                    <div style="margin-top: 7px">
                      <a-checkbox
                        v-if="field.blueprintId"
                        v-model:checked="field.linkToReferenceInstance"
                        >linkToReferenceInstance</a-checkbox
                      >
                    </div>
                    <div style="margin-top: 7px">
                      <a-checkbox v-model:checked="field.displayOnNewRow"
                        >Display field on a new row</a-checkbox
                      >
                    </div>
                  </a-card>
                </a-col>
              </template>
            </draggable>
          </a-row>
        </a-tab-pane>
        <a-tab-pane key="4" tab="Fields Autocomplete">
          <AutocompleteFields
            v-model="localTemplate.object.view.listFields"
            :blueprints="blueprints"
            :listFields="localTemplate.object.view.listFields"
            :selectedBlueprint="selectedBlueprint"
            :template="localTemplate"
          ></AutocompleteFields>
        </a-tab-pane>
      </a-tabs>

      <template #footer>
        <a-button key="submit" type="primary" @click="close"> Ok </a-button>
      </template>
    </a-modal>

    <a-modal
      v-if="selectedField"
      ref="concatFieldModal"
      v-model:open="modalConcatField"
      title="Configurare concat fields"
      @ok="hideConcatModal"
    >
      <template v-if="selectedField?.concatData">
        <a-input
          v-model:value="selectedField.concatData.html"
          placeholder="Concat data"
        />
        <span
          class="small"
          v-html="'ex.: {{=name}} / {{=surname}} on {{=date}}'"
        ></span>

        <template v-if="selectedField.concatData.enableCalculation">
          <div
            class="small"
            v-html="
              'ex.: {{=expression_1}} / {{=(expression_2 / 0.09)}} on {{=date}}'
            "
          ></div>
        </template>

        <div class="mt-3">
          <div class="card">
            <div class="card-body">
              <a-checkbox
                v-model:checked="selectedField.concatData.enableCalculation"
              >
                Enable Calculation
                <help-icon-popover
                  content="This field will be administrative."
                  title="Sensitive Field"
                />
              </a-checkbox>

              <div v-if="selectedField.concatData.enableCalculation">
                <div class="mb-2 mt-2">
                  Fields available:
                  <a-tag
                    v-for="tag of localTemplate.object.view.listFields
                      .filter(
                        item =>
                          item.type === 'number' ||
                          (item?.formatting?.count ?? false) === true
                      )
                      .map(item => slugify(item.label))"
                    :key="tag"
                  >
                    {{ tag }}
                  </a-tag>
                </div>
                <a-button
                  @click="
                    selectedField.concatData.expressions.push({
                      expression: '',
                      name:
                        'expression_' +
                        (selectedField.concatData.expressions.length + 1),
                    })
                  "
                  >Add expression</a-button
                >

                <div
                  v-for="(expression, index) of selectedField.concatData
                    .expressions"
                  :key="index"
                  class="card mt-3"
                >
                  <div class="card-body" style="position: relative">
                    <a-button
                      size="small"
                      style="position: absolute; top: -12px; right: -12px"
                      @click="deleteConcatExpressionItemFieldId(index)"
                      ><MinusOutlined
                    /></a-button>
                    <div class="row">
                      <div class="col-12 mb-3">
                        <a-input
                          v-model:value="expression.name"
                          placeholder="Expression name"
                        />
                      </div>
                      <div class="col-12">
                        <a-input
                          v-model:value="expression.expression"
                          placeholder="Expression"
                        />
                        <span
                          class="small"
                          v-html="'ex.: (field_1 + field_2 + 2) / 100'"
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <a-button
          @click="
            selectedField.concatData.fields.push({
              id: undefined,
              tokenName: '',
            })
          "
          >Add field</a-button
        >

        <div
          v-for="field of selectedField.concatData.fields"
          :key="field.id"
          class="card mt-3"
        >
          <div class="card-body" style="position: relative">
            <a-button
              size="small"
              style="position: absolute; top: -12px; right: -12px"
              @click="deleteConcatItemFieldId(field.id)"
              ><MinusOutlined
            /></a-button>
            <div class="row">
              <div class="col-6">
                <a-select
                  v-model:value="field.id"
                  :default-value="field.id"
                  :filter-option="filterOption"
                  placeholder="Field"
                  show-search
                  style="width: 100%"
                  @change="fieldId => selectConcatItemFieldId(fieldId, field)"
                >
                  <template
                    v-for="(listField, index) in localTemplate.object.view
                      .listFields"
                    :key="index"
                  >
                    <a-select-option
                      v-if="selectedField.id !== listField.id"
                      :value="listField.id"
                      >{{ listField.label }}</a-select-option
                    >
                  </template>
                </a-select>
              </div>
              <div class="col-6">
                <a-input
                  v-model:value="field.tokenName"
                  placeholder="Token name"
                />
              </div>
              <div v-if="getFieldExtractList(field).length > 0" class="col-6">
                <a-select
                  v-model:value="field.getFromInstance"
                  :placeholder="'Extract'"
                  style="width: 100%; margin-top: 10px"
                  value="''"
                >
                  <a-select-option
                    v-for="item in getFieldExtractList(field)"
                    :key="item.value"
                    :value="item.value"
                  >
                    {{ item.label }} ({{ item.type }})
                  </a-select-option>
                </a-select>
                <template v-if="field.getFromInstance">
                  <a-select
                    v-if="
                      getFieldExtractList(field).find(
                        i => i.value === field.getFromInstance
                      ).calculate ?? null === 'fromDate'
                    "
                    v-model:value="field.calculateWithMainBlueprintField"
                    :placeholder="'Calculate using date field'"
                    style="width: 100%; margin-top: 10px"
                  >
                    <a-select-option :key="null" :value="null">
                      {{ 'Current date' }}
                    </a-select-option>
                    <a-select-option
                      v-for="it in selectedBlueprint.fields.filter(
                        f => f.structure.type === 'date'
                      )"
                      :key="it._id"
                      :value="it._id"
                    >
                      {{ it.label }} ({{ it.structure.type }})
                    </a-select-option>
                  </a-select>
                </template>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template #footer>
        <a-button key="submit" type="primary" @click="hideConcatModal">
          Ok
        </a-button>
      </template>
    </a-modal>

    <a-modal
      v-model:open="modalConfigFieldFilters"
      title="Configurare filtre field"
      @ok="hideFiltersModal"
    >
      <a-row
        v-for="(filtervalue, index) in this.selectedField?.filterValues"
        :key="index"
        style="margin-bottom: 10px"
      >
        <a-col :span="11" style="padding: 3px 5px">
          <a-input v-model:value="filtervalue.text"></a-input>
        </a-col>

        <a-col :span="11" style="padding: 3px 5px">
          <a-input v-model:value="filtervalue.value"></a-input>
        </a-col>

        <a-col :span="2" style="padding: 3px 5px">
          <a-button
            size="small"
            style="margin-top: 4px"
            @click="removeFieldFilter(filtervalue)"
            ><MinusOutlined
          /></a-button>
        </a-col>
      </a-row>

      <div style="padding: 3px 5px">
        <a-button size="small" @click="addFieldFilter"
          ><PlusOutlined
        /></a-button>
      </div>

      <template #footer>
        <a-button key="submit" type="primary" @click="hideFiltersModal">
          Ok
        </a-button>
      </template>
    </a-modal>

    <a-modal
      v-model:open="modalConfigFieldFormatting"
      :title="`Configurare formatting: ${selectedFieldFormatting?.label}`"
      @ok="hideFieldFormattingModal"
    >
      <a-row style="margin-bottom: 10px">
        <a-col :span="12" style="padding: 3px 5px">
          <a-checkbox
            v-if="selectedFieldFormatting?.formatting"
            v-model:checked="selectedFieldFormatting.formatting.toUppercase"
            >UpperCase</a-checkbox
          >
        </a-col>
        <a-col :span="12" style="padding: 3px 5px">
          <a-checkbox
            v-if="selectedFieldFormatting?.fromReference"
            v-model:checked="selectedFieldFormatting.formatting.count"
            >Length values</a-checkbox
          >
        </a-col>
        <a-col :span="12" style="padding: 3px 5px">
          <a-checkbox
            v-if="selectedFieldFormatting?.fromReference"
            v-model:checked="selectedFieldFormatting.formatting.distinct"
            >Distinct values</a-checkbox
          >
        </a-col>
        <a-col :span="12" style="padding: 3px 5px">
          <a-checkbox
            v-if="
              selectedFieldFormatting?.fromReference &&
              selectedFieldFormatting?.type === 'number'
            "
            v-model:checked="selectedFieldFormatting.formatting.sum"
            >Sum values</a-checkbox
          >
        </a-col>
        <a-col :span="24" style="padding: 3px 5px">
          Table column width:
          <a-tooltip
            :mouseLeaveDelay="0"
            title="Enter a number (pixels) for a fixed width (ex.: 200)"
          >
            <a-input
              v-if="selectedFieldFormatting?.formatting"
              v-model:value="selectedFieldFormatting.formatting.columnWidth"
              placeholder="Column width"
            />
          </a-tooltip>
        </a-col>
      </a-row>

      <template #footer>
        <a-button key="submit" type="primary" @click="hideFieldFormattingModal">
          Ok
        </a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import draggable from 'vuedraggable';
import ReferenceFields from '@/apps/templateManagement/views/Builder/components/ReferenceFields.vue';
import CustomTableHeader from '@/apps/templateManagement/views/Builder/components/elementFieldsComponents/customTableHeader.vue';
import { slugify } from '@/core/utils/string-manipulation';
import { objectId } from '@/core/utils/array-manipulation';

import { message } from 'ant-design-vue';

import AutocompleteFields from '@/apps/templateManagement/views/Builder/components/autocompleteFields/AutocompleteFields.vue';
import { TemplatesActions } from '@/apps/templateManagement/shared/templateManagement.store';
import {
  DeleteOutlined,
  DragOutlined,
  MenuOutlined,
  MinusOutlined,
  PlusOutlined,
} from '@ant-design/icons-vue';

export default {
  name: 'ElementField',
  props: [
    'mainBlueprintId',
    'selectedBlueprint',
    'template',
    'templates',
    'blueprints',
    'modalConfigFieldsVisible',
  ],
  emits: ['update:modalConfigFieldsVisible'],
  data() {
    return {
      modalView: this.modalConfigFieldsVisible,
      modalConfigFieldFilters: false,
      modalConcatField: false,
      selectedField: null,

      selectedFieldFormatting: null,
      modalConfigFieldFormatting: false,

      referenceFields: [],
      referenceFieldsKey: 1,
      addNewFieldFromReference: false,
      addNewFieldFromReferenceButton: false,
      test: this.template,
      localTemplate: this.template ?? null,
    };
  },
  created() {
    this.localTemplate = this.template ?? null;
  },
  components: {
    AutocompleteFields,
    ReferenceFields,
    draggable,
    CustomTableHeader,
    MinusOutlined,
    PlusOutlined,
    DeleteOutlined,
    DragOutlined,
    MenuOutlined,
  },
  watch: {
    template(value) {
      if (this.localTemplate === null) {
        this.localTemplate = value;
      }
    },
    localTemplate: {
      // update stored template.
      deep: true,
      handler(value) {
        TemplatesActions.setTemplate(value);
      },
    },
  },
  methods: {
    updateTemplateObjectView(viewSettings) {
      this.localTemplate.object.view = viewSettings;
    },
    slugify,
    showFieldFormattingModal(field) {
      this.selectedFieldFormatting = field;
      this.modalConfigFieldFormatting = true;
    },
    hideFieldFormattingModal() {
      this.modalConfigFieldFormatting = false;
      this.selectedFieldFormatting = null;
    },
    addConcatField() {
      const concatFields = this.localTemplate.object.view.listFields.filter(
        item => item.show && item.concat
      ).length;
      this.localTemplate.object.view.listFields.push({
        id: `_concat_${objectId()}`,
        label: `New concat field${concatFields ? ` ${concatFields + 1}` : ''}`,
        show: true,
        order: false,
        searchable: false,
        formatting: {
          toUppercase: false,
          count: false,
          distinct: true,
        },
        filter: false,
        filterValues: [],
        type: 'string',
        typeDetails: 'string',
        deletable: true,
        concat: true,
        concatData: {
          html: '',
          enableCalculation: false,
          expressions: [],
          fields: [],
        },
      });
      message.success('Field added!');
    },
    selectConcatItemFieldId(fieldId, field) {
      const findField = this.localTemplate.object.view.listFields.find(
        item => item.id === fieldId
      );
      field.tokenName = slugify(findField?.label ?? '');
      field.blueprintId = findField?.blueprintId
        ? findField?.blueprintId
        : this.localTemplate.object.blueprintId;
      field.widgetType = findField?.widgetType;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    deleteConcatItemFieldId(fieldId) {
      this.selectedField.concatData.fields =
        this.selectedField.concatData.fields.filter(
          item => item.id !== fieldId
        );
    },
    deleteConcatExpressionItemFieldId(index) {
      this.selectedField.concatData.expressions =
        this.selectedField.concatData.expressions.filter(
          (item, i) => i !== index
        );
    },
    getFieldType(fieldId) {
      const field = this.selectedBlueprint.fields.find(
        item => item._id === fieldId
      );
      if (!field) {
        return '';
      }
      return field.structure.type === 'array'
        ? `${field.structure.type}[${field.structure.elementStructure.type}]`
        : (field.structure?.elementStructure?.type ?? field.structure.type);
    },
    selectField(value, index) {
      const referenceFields = this.referenceFields.filter(
        item => item.index < index
      );
      if (value?.fieldId) {
        referenceFields.push({ ...value, index });
      }
      referenceFields.sort((a, b) => a.index - b.index);

      this.referenceFields = referenceFields;

      this.addNewFieldFromReferenceButton =
        this.referenceFields.length > 1 &&
        !['array', 'reference'].includes(value.type);
    },
    addReferenceField() {
      let blueprintId = null;
      let blueprintName = '';

      let label;
      let type;
      let widgetType;
      let dateFormat;
      let typeDetails;
      const path = [];
      const pathDetails = [];
      let getFromInstance = null;
      const refIdPath = [];
      const oldRefIdPath = [];

      let autoFieldConditionsData = [];
      let fieldConditionsDataAttributes = [];

      if (Array.isArray(this.referenceFields)) {
        this.referenceFields.forEach(item => {
          const blueprint = this.blueprints.find(
            b => b._id === item.blueprintId
          );
          if (blueprint) {
            blueprintId = blueprint._id;
            blueprintName = blueprint.name;
            getFromInstance = item.getFromInstance ?? null;
            autoFieldConditionsData = item?.autoFieldConditionsData ?? [];
            fieldConditionsDataAttributes =
              item?.fieldConditionsDataAttributes ?? [];

            if (item.fieldId.startsWith('other_')) {
              const fieldIdSplit = item.fieldId
                .replace('other_', '')
                .split('-');
              const otherBlueprintId = fieldIdSplit[0];
              const otherFieldId = fieldIdSplit[1];

              const otherBlueprint = this.blueprints.find(
                b => b._id === otherBlueprintId
              );
              const otherField = otherBlueprint.fields.find(
                f => f._id === otherFieldId
              );
              const fieldRefBlueprintId =
                otherField.structure?.elementStructure?.ruleset?.blueprintId ??
                otherField.structure.ruleset.blueprintId;

              widgetType = otherField.widget.type ? otherField.widget.type : '';
              refIdPath.push(
                `_reference_data_other_${otherBlueprint._id}-${fieldRefBlueprintId}-${otherFieldId}`
              );
              oldRefIdPath.push(
                `_reference_data_other_${otherBlueprintId}-${otherFieldId}`
              );

              pathDetails.push(
                `Other blueprint (${otherBlueprint.name}) -${item.type === 'array' ? ' [array]' : ' '}${otherField.label}`
              );
              path.push(
                `other-blueprint_${slugify(otherBlueprint.name)}-${slugify(otherField.label)}`
              );
            } else {
              const field = blueprint.fields.find(f => f._id === item.fieldId);
              if (field) {
                const fieldRefBlueprintId =
                  field.structure?.elementStructure?.ruleset?.blueprintId ??
                  field.structure.ruleset.blueprintId;

                type =
                  field?.structure?.elementStructure?.type ??
                  field?.structure?.type;
                widgetType = field.widget.type ? field.widget.type : '';
                dateFormat =
                  field?.structure?.elementStructure?.options?.format ??
                  field?.structure?.options?.format ??
                  'DD-MM-YYYY';

                typeDetails =
                  field.structure?.type === 'array'
                    ? `${field.structure.type}[${field?.structure?.elementStructure?.type}]`
                    : (field?.structure?.elementStructure?.type ??
                      field?.structure?.type);

                let prefix = '';
                if (item.type === 'reference') {
                  if (item.type === 'array') {
                    prefix = '[array] ';
                  } else {
                    prefix = ' ';
                  }
                }
                pathDetails.push(prefix + field.label);

                path.push(slugify(field.label));

                label = field.label;

                if (item.type === 'reference') {
                  refIdPath.push(
                    `_reference_data_${!item.index ? `${item.blueprintId}-` : ''}${fieldRefBlueprintId}-${field._id}`
                  );
                  oldRefIdPath.push(`_reference_data_${field._id}`);
                } else {
                  refIdPath.push(field._id);
                  oldRefIdPath.push(field._id);
                }
              } else {
                // if created at of owner

                label = item.fieldId === '_createdAt' ? 'Created At' : 'Owner';

                pathDetails.push(
                  item.fieldId === '_createdAt' ? 'Created At' : 'Owner'
                );
                path.push(
                  item.fieldId === '_createdAt' ? 'created At' : 'owner'
                );

                type = item.fieldId === '_createdAt' ? 'date' : 'string';
                typeDetails = item.fieldId === '_createdAt' ? 'date' : 'string';
                widgetType = item.widget.type ? item.widget.type : '';

                dateFormat = type === 'date' ? 'DD-MM-YYYY' : null;

                refIdPath.push(item.fieldId);
                oldRefIdPath.push(item.fieldId);
              }
              //
            }
          }
        });
      }

      const id = `${this.referenceFields[0].fieldId.startsWith('other_') ? '_other_blueprint_' : '_reference_'}${type}_${objectId()}`;

      if (
        this.localTemplate.object.view.listFields.some(item => item.id === id)
      ) {
        message.warning('This field exist in the list!');
      } else {
        this.localTemplate.object.view.listFields.push({
          id,
          label,

          path: path.join('.'),
          pathDetails: pathDetails.join(' > '),
          widgetType,
          type,
          typeDetails,
          fromReference: true,
          getFromInstance: getFromInstance ?? null,

          refIdPath: refIdPath.join('.'),
          oldRefIdPath: refIdPath.join('.'),

          autoFieldConditionsData,
          fieldConditionsDataAttributes,

          show: true,
          dateFormat,

          order: false,
          searchable: false,
          formatting: {
            toUppercase: false,
            count: false,
            distinct: true,
          },
          filter: false,
          filterValues: [],
          linkToReferenceInstance: false,

          deletable: true,
          mainBlueprintId: this.mainBlueprintId, // extract
          // on reference
          blueprintId,
          blueprintName,
        });

        message.success('Field added!');
        this.referenceFieldsKey += 1;
      }
    },
    deleteFieldConfirm(field) {
      this.localTemplate.object.view.listFields =
        this.localTemplate.object.view.listFields.filter(
          item => item.id !== field.id
        );
      message.success('Field deleted!');
    },
    close() {
      this.$emit('update:modalConfigFieldsVisible', false);
    },
    showConcatModal(field) {
      this.selectedField = field;
      this.modalConcatField = true;
    },
    hideConcatModal() {
      this.selectedField = null;
      this.modalConcatField = false;
    },

    showFiltersModal(field) {
      this.selectedField = field;
      this.modalConfigFieldFilters = true;
    },
    hideFiltersModal() {
      this.selectedField = null;
      this.modalConfigFieldFilters = false;
    },
    addFieldFilter() {
      if (
        !this.selectedField.filterValues ||
        !(this.selectedField.filterValues instanceof Array)
      ) {
        this.selectedField.filterValues = [];
      }
      this.selectedField.filterValues.push({
        text: 'Filter title',
        value: 'Filter value',
      });
    },
    removeFieldFilter(filterValue) {
      this.selectedField.filterValues = this.selectedField.filterValues.filter(
        filterVal => filterVal !== filterValue
      );
    },
    checkUncheckAllListFields() {
      if (this.allListFieldsChecked) {
        this.localTemplate.object.view.listFields.forEach(item => {
          item.show = false;
        });
      } else {
        this.localTemplate.object.view.listFields.forEach(item => {
          item.show = true;
        });
      }
    },
    checkUncheckAllItemFields() {
      if (this.allItemFieldsChecked) {
        this.localTemplate.object.view.itemFields.forEach(item => {
          item.show = false;
        });
      } else {
        this.localTemplate.object.view.itemFields.forEach(item => {
          item.show = true;
        });
      }
    },
    getFieldExtractList(field) {
      // const field = this.selectedBlueprint.fields.find(item => item._id === fieldId);
      if (field.id !== undefined && typeof field.id !== 'undefined') {
        if (!field.id.includes('_reference_')) {
          const blueprintField = this.selectedBlueprint.fields.find(
            item => item._id === field.id
          );
          if (
            blueprintField?.widget?.extract
              ? blueprintField.widget.extract.length
              : 0 > 1
          ) {
            return blueprintField.widget.extract;
          }

          return [];
        }

        if (field.refIdPath) {
          const findReference = field.refIdPath?.split('-')[2];
          const refBlueprint = field.refIdPath?.split('-')[1];
          const refId = findReference?.split('.')[1];
          const otherBlueprint = this.blueprints.find(
            b => b._id.toString() === refBlueprint?.replace(/\s/g, '')
          );
          const otherField = otherBlueprint.fields.find(
            f => f._id.toString() === refId.replace(/\s/g, '')
          );
          if (
            otherField?.widget?.extract
              ? otherField.widget.extract.length
              : 0 > 1
          ) {
            return otherField.widget.extract;
          }

          return [];
        }

        const referencedField = this.localTemplate.object.view.listFields.find(
          item => item.id === field.id
        );
        const findReference = referencedField.refIdPath?.split('-')[2];
        const refBlueprint = referencedField.refIdPath?.split('-')[1];
        const refId = findReference?.split('.')[1];
        const otherBlueprint = this.blueprints.find(
          b => b._id.toString() === refBlueprint?.replace(/\s/g, '')
        );
        const otherField = otherBlueprint?.fields.find(
          f => f._id.toString() === refId?.replace(/\s/g, '')
        );
        if (
          otherField?.widget?.extract ? otherField.widget.extract.length : 0 > 1
        ) {
          return otherField.widget.extract;
        }

        return [];

        // return [];
      }
      return [];
    },
  },
  computed: {
    mainBlueprint() {
      return this.blueprints.find(bp => bp._id === this.mainBlueprintId);
    },
    allListFieldsChecked() {
      return !this.localTemplate.object.view.listFields.some(
        item => !item.show
      );
    },
    someListFieldsChecked() {
      return (
        this.localTemplate.object.view.listFields.some(item => item.show) &&
        this.localTemplate.object.view.listFields.some(item => !item.show)
      );
    },
    allItemFieldsChecked() {
      return !this.localTemplate.object.view.itemFields.some(
        item => !item.show
      );
    },
    someItemFieldsChecked() {
      return (
        this.localTemplate.object.view.itemFields.some(item => item.show) &&
        this.localTemplate.object.view.itemFields.some(item => !item.show)
      );
    },
  },
};
</script>

<style>
.canBeDragged {
  transition: none !important;

  &.destination-item {
    cursor: move;
    opacity: 0;
  }
  &.dragging-item {
    opacity: 1 !important;
  }
}
</style>
