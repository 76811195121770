import axios from 'axios';

import { SlugGetters } from '@/slug.store';

const { getSubtenantUrl } = SlugGetters;

const getPDF = async (htmlContent, fileName) => {
  const formData = new FormData();
  const size = 1000000;
  const numChunks = Math.ceil(htmlContent.length / size);
  for (let i = 0, o = 0; i < numChunks; i += 1, o += size) {
    formData.append('htmlContent', htmlContent.substr(o, size));
  }
  const response = await axios.post(
    `${getSubtenantUrl()}/data-system-export/all/pdf`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'blob',
    }
  );

  const href = URL.createObjectURL(response.data);
  // create "a" HTML element with href to file & click
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', `${fileName}.pdf`); // or any other extension
  document.body.appendChild(link);
  link.click();
  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
  return response;
};

const getAllXlsx = async (
  blueprintId,
  params,
  filterConditions,
  dataColumns,
  blueprintName,
  openInstance = null
) => {
  const response = await axios.get(
    `${getSubtenantUrl()}/data-system-export/all/excel`,
    {
      params: {
        blueprintId,
        params,
        filterConditions,
        openInstance,
        dataColumns,
        blueprintName,
      },
      responseType: 'blob',
      headers: {
        'Content-Type': `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`,
      },
    }
  );
  const href = URL.createObjectURL(response.data);
  // create "a" HTML element with href to file & click
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', `${blueprintName}.xlsx`); // or any other extension
  document.body.appendChild(link);
  link.click();
  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
  return response;
};
export default {
  getAllXlsx,
  getPDF,
};
