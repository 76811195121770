<template>
  <div>
    <div
      class="saas_card hoverable mb-10"
      :class="
        sourceId === source._id || (sourceId === 'new' && source.new)
          ? 'selected'
          : ''
      "
      v-for="source in sources"
      :key="source._id"
    >
      <div class="row-contents">
        <div
          class="group-text-container"
          @click="openSource(source.new ? 'new' : source._id)"
          @dblclick="editSourceName(source, true, $event)"
        >
          <div class="group-text">
            <span class="title">
              <div style="position: relative">
                <template
                  v-if="
                    editSourceNameId === source._id ||
                    (sourceId === 'new' && source.new)
                  "
                >
                  <a-input
                    v-model:value="newSourceName"
                    :id="'input_' + source._id"
                    @pressEnter="updateSourceName(source)"
                    placeholder="Source name"
                    v-on:blur="editSourceName(source, false, $event)"
                  />
                  <a-button
                    type="link"
                    :class="'submit'"
                    :loading="preloaderEditName"
                    @click="updateSourceName(source)"
                    style="position: absolute; right: 5px"
                    ><EditOutlined
                  /></a-button>
                </template>
                <template v-else>{{ source.name }}</template>
              </div>
            </span>
            <span class="muted-text" v-if="source.description">
              {{ source.description }}
            </span>
          </div>
        </div>
        <div class="metadata">
          <div class="controls">
            <template v-if="source.new">
              <a-button
                @click="
                  removeSource(dataProviderId, source.new ? 'new' : source._id)
                "
                ><DeleteOutlined
              /></a-button>
            </template>
            <template v-else>
              <a-popconfirm
                placement="topLeft"
                :title="
                  'Are you sure you want to delete `' + source.name + '`?'
                "
                ok-text="Yes"
                cancel-text="No"
                @confirm="
                  removeSource(dataProviderId, source.new ? 'new' : source._id)
                "
              >
                <a-button :loading="preloaderDeleteSource === source._id"
                  ><DeleteOutlined
                /></a-button>
              </a-popconfirm>
            </template>
          </div>
        </div>
      </div>
      <template
        v-if="sourceId === source._id || (sourceId === 'new' && source.new)"
      >
        <ConfigureConnection
          :selectedDataProvider="selectedDataProvider"
          :sourceSection="sourceSection"
          :dataProviderId="dataProviderId"
          :sourceId="sourceId"
          :source="source"
          :error="sourceError"
          :preloaderUpdate="preloaderUpdateSource"
          v-on:ch-section="selectSourceSection(source, $event)"
          v-on:addSource="addSource(source)"
          v-on:editSource="updateSource(source)"
        />
        <TestConnection
          :testPreloader="testPreloader"
          :sourceSection="sourceSection"
          :dataProviderId="dataProviderId"
          :source="source"
          :error="sourceError"
          ref="testConnection"
          v-on:ch-section="selectSourceSection(source, $event)"
        />
        <ConfigureFields
          :sourceSection="sourceSection"
          :dataProviderId="dataProviderId"
          :sourceId="sourceId"
          :source="source"
          @updateSource="updateSource"
          v-on:ch-section="selectSourceSection(source, $event)"
          @saveSourceChanges="updateSourceOptions(source)"
        />
      </template>
    </div>

    <a-button v-if="!newSource" @click="addNewSource()">
      <PlusOutlined /> Add new source
    </a-button>
  </div>
</template>

<script>
import { DataProviderActions } from '@dataProvider/shared/dataProvider.store';

import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from '@ant-design/icons-vue';
import ConfigureConnection from './components/configureConnection.vue';
import TestConnection from './components/testConnection.vue';
import ConfigureFields from './components/configureFields.vue';

export default {
  props: ['selectedDataProvider', 'dataProviderId', 'sourceId', 'source'],
  name: 'DataProviderSourcesBuilder',
  data() {
    return {
      loading: false,
      testPreloader: false,
      editSourceNameId: null,
      preloaderEditName: false,
      preloaderDeleteSource: null,
      preloaderUpdateSource: false,

      newSourceName: null,
      sourceSection: 1,
      sourceError: {},
    };
  },
  computed: {
    sources() {
      return this.selectedDataProvider.sources;
    },
    newSource() {
      return this.sources.some(s => s.new);
    },
  },
  created() {
    if (!this.sources.length) {
      this.addNewSource();
    }
  },

  methods: {
    editSourceName(source, status, e) {
      if (e.relatedTarget) {
        if (e.relatedTarget.classList.contains('submit')) {
          return;
        }
      }

      if (source.new) {
        return;
      }
      if (status) {
        this.editSourceNameId = source._id;
        this.newSourceName = source.name;

        setTimeout(function () {
          const el = document.getElementById(`input_${source._id}`);
          if (el) {
            el.focus();
          }
        }, 400);
      } else {
        this.editSourceNameId = null;
        this.newSourceName = null;
      }
    },
    async updateSourceName(source) {
      this.preloaderEditName = true;
      await DataProviderActions.updateOneSourceSafely(
        this.dataProviderId,
        source._id,
        {
          name: this.newSourceName,
        }
      );
      const updateSource = this.sources.find(s => s._id === source._id);
      updateSource.name = this.newSourceName;
      this.preloaderEditName = false;
      this.editSourceNameId = null;
      this.newSourceName = null;
    },
    addSource(source) {
      this.createSource(source);
      this.sourceSection = 2;
    },
    async updateSource(source) {
      console.log('edit source');

      this.preloaderUpdateSource = true;
      this.sourceError = {};
      const updateConnection = await DataProviderActions.updateOneSource(
        this.dataProviderId,
        source._id,
        source
      );
      if (
        updateConnection.connectionDetail &&
        updateConnection.connectionDetail.success
      ) {
        this.sourceSection = 2;
        await DataProviderActions.deleteAllJoin(this.dataProviderId);
      } else {
        this.sourceError = {
          ...updateConnection.connectionDetail.error,
        };
      }
      this.preloaderUpdateSource = false;
    },
    async updateSourceOptions(source) {
      await DataProviderActions.updateSourceOptions(
        this.dataProviderId,
        source._id,
        source
      );
    },
    async selectSourceSection(source, sectionId) {
      if (sectionId === 2 && sectionId !== this.sourceSection) {
        if (!source.connectionDetail) {
          await this.createSource(source);
        }
      }
      if (sectionId === this.sourceSection) {
        this.sourceSection = null;
      } else {
        this.sourceSection = sectionId;
      }
    },
    openSource(sourceId) {
      if (this.sourceId !== sourceId) {
        this.$router.push({
          name: 'DataProviderSource',
          params: { dataProviderId: this.dataProviderId, sourceId },
        });
      }
    },
    async createSource(source) {
      this.testPreloader = true;
      this.sourceError = {};
      const newSource = await DataProviderActions.createOneSource(
        this.dataProviderId,
        {
          ...source,
          name: this.newSourceName,
        }
      );
      if (newSource.connectionDetail && newSource.connectionDetail.success) {
        this.openSource(newSource._id);
      } else {
        this.sourceError = {
          ...newSource.connectionDetail.error,
        };
      }
      this.testPreloader = false;
    },
    async removeSource(providerId, sourceId) {
      this.preloaderDeleteSource = sourceId;
      await DataProviderActions.deleteOneSource(providerId, sourceId);
      this.preloaderDeleteSource = null;
    },
    addNewSource() {
      this.sources.push({
        new: true,
        description: '',
      });
      if (this.sourceId !== 'new') {
        this.$router.push({
          name: 'DataProviderSource',
          params: { dataProviderId: this.dataProviderId, sourceId: 'new' },
        });
      }
      this.sourceSection = 1;
    },
  },
  components: {
    ConfigureConnection,
    TestConnection,
    ConfigureFields,
    DeleteOutlined,
    EditOutlined,
    PlusOutlined,
  },
};
</script>
<style scoped></style>
