<template>
  <div style="padding: 20px" v-if="!isLoading">
    <router-link :to="routerLinkBack" custom v-slot="{ navigate }">
      <a-page-header
        style="border: 1px solid rgb(235, 237, 240); margin-bottom: 10px"
        title="Business Objects"
        @back="navigate"
      >
        <template #extra>
          <a-input
            placeholder="Search blueprint"
            style="width: 200px"
            v-model:value="blueprintSearch"
            allow-clear
          />
          <a-button
            @click="blueprintCreateModal.isVisible = true"
            type="primary"
          >
            <PlusOutlined /> Create new Business Object
          </a-button>
          <a-radio-group
            v-model:value="bussinesObjectsFilter"
            v-if="
              typeof tenantSlug !== 'undefined' &&
              typeof appSlug !== 'undefined'
            "
          >
            <a-radio-button value="app">App</a-radio-button>
            <a-radio-button value="all">All</a-radio-button>
            <a-radio-button value="not">NotInApp</a-radio-button>
          </a-radio-group>
        </template>
      </a-page-header>
    </router-link>
    <BlueprintCreateModal
      :visible="blueprintCreateModal.isVisible"
      :editedBlueprint="editedBlueprint"
      :tenantsAndApps="tenantsAndApps"
      @created="onBlueprintCreated"
      @cancel="hideBlueprintCreateModal"
    />

    <a-row
      v-if="blueprints && blueprints.length > 0"
      :gutter="16"
      style="margin-top: 20px"
    >
      <a-col
        v-for="blueprint in visibleBlueprints"
        :key="blueprint._id"
        :sm="24"
        :md="12"
        :lg="8"
        :xl="6"
        style="margin-bottom: 10px"
      >
        <BlueprintElement
          :blueprint="blueprint"
          :tenantsAndApps="tenantsAndApps"
          :routerLink="routerLink"
          @edit="onBlueprintEdit"
          @delete="onBlueprintDelete"
        />
      </a-col>
    </a-row>
    <a-row style="margin-top: 20px" v-else>
      <a-col :md="{ span: 12, offset: 6 }" :xs="24">
        <a-card style="text-align: center">
          <h2 style="margin: 0">No business objects created yet.</h2>
        </a-card>
      </a-col>
    </a-row>
  </div>
  <loading-spinner v-else />
</template>

<script>
import { BlueprintCreateModal } from '@dataSystem/components/BlueprintCreateModal';

import { blueprintApi } from '@dataSystem/api';
import { tenantApi } from '@tenants/api';
import { SlugGetters } from '@/slug.store';
import { PlusOutlined } from '@ant-design/icons-vue';
import BlueprintElement from './components/BlueprintElement.vue';

export default {
  data() {
    return {
      isLoading: false,
      blueprints: null,
      blueprintSearch: '',
      tenantsAndApps: null,
      routerLink: 'globalBlueprintFormBuilder',
      bussinesObjectsFilter: 'all',
      currentAppId: null,

      blueprintCreateModal: {
        isVisible: false,
        isEdit: false,
        isEditBpIp: null,
      },
    };
  },
  async mounted() {
    await this.getAllTenants();
    await this.fetchBlueprints();
  },
  components: {
    BlueprintCreateModal,
    BlueprintElement,
    PlusOutlined,
  },
  computed: {
    tenantSlug: SlugGetters.getTenantSlug,
    appSlug: SlugGetters.getAppSlug,
    routerLinkBack() {
      if (this.tenantSlug) {
        return {
          name: 'TenantApplication',
          params: { tenantSlug: this.tenantSlug, appSlug: this.appSlug },
        };
      }
      return {
        name: 'TenantsList',
      };
    },
    editedBlueprint() {
      let bp = null;
      if (this.blueprintCreateModal.isEdit) {
        bp = this.blueprints.find(
          blueprint => blueprint._id === this.blueprintCreateModal.isEditBpIp
        );
      }
      return bp;
    },
    visibleBlueprints() {
      if (this.bussinesObjectsFilter === 'all') {
        return this.blueprints.filter(obj =>
          obj.name.toLowerCase().includes(this.blueprintSearch.toLowerCase())
        );
      }
      if (this.bussinesObjectsFilter === 'app') {
        return this.blueprints
          .filter(
            blueprint =>
              blueprint.applicationIdList.indexOf(this.currentAppId) > -1 ||
              blueprint.isGlobal
          )
          .filter(obj =>
            obj.name.toLowerCase().includes(this.blueprintSearch.toLowerCase())
          );
      }
      if (this.bussinesObjectsFilter === 'not') {
        return this.blueprints
          .filter(
            blueprint =>
              blueprint.applicationIdList.indexOf(this.currentAppId) === -1 ||
              blueprint.isGlobal
          )
          .filter(obj =>
            obj.name.toLowerCase().includes(this.blueprintSearch.toLowerCase())
          );
      }
      return null;
    },
  },
  methods: {
    async getAllTenants() {
      this.tenantsAndApps = await tenantApi.getAll();
      this.tenantsAndApps.forEach(tenant => {
        tenant.disabled = true; // dont allow tenant click
      });
      this.currentAppId = this.getCurrentAppId();
    },
    getCurrentAppId() {
      const tenantKeys = Object.keys(this.tenantsAndApps);
      for (let index = 0; index < tenantKeys?.length; index += 1) {
        const app = this.tenantsAndApps[index].applications.find(
          ap => ap.slug === this.appSlug
        );
        if (app) {
          this.routerLink = 'BlueprintFormBuilder'; // set element to global or app
          return app._id.toString();
        }
      }
      return null;
    },
    async fetchBlueprints() {
      this.isLoading = true;
      this.blueprints = await blueprintApi.getAllGlobal();
      if (typeof this.appSlug !== 'undefined') {
        this.bussinesObjectsFilter = 'app';
      }

      this.isLoading = false;
    },
    onBlueprintCreated() {
      this.hideBlueprintCreateModal();
      this.fetchBlueprints();
    },
    onBlueprintEdit(bpId, showEditModal = false) {
      this.blueprintCreateModal.isEdit = true;
      this.blueprintCreateModal.isEditBpIp = bpId;
      if (showEditModal) {
        this.blueprintCreateModal.isVisible = true;
      }
    },
    async onBlueprintDelete(blueprintId) {
      this.isLoading = true;
      await blueprintApi.deleteOne(blueprintId);
      this.blueprints = this.blueprints.filter(b => b._id !== blueprintId);
      this.isLoading = false;
    },
    hideBlueprintCreateModal() {
      this.blueprintCreateModal.isVisible = false;
      this.blueprintCreateModal.isEdit = false;
      this.blueprintCreateModal.isEditBpIp = null;
    },
  },
};
</script>

<style></style>
