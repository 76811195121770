<template>
  <div>
    <div
      class="mt-1"
      style="background-color: rgba(0, 0, 0, 0.05); border-radius: 4px"
      v-for="fileExport in value"
      :key="fileExport._id"
      :title="fileExport?.description ?? ''"
    >
      <template v-if="mainItem && showOrder">
        <div style="position: absolute; padding: 5px">
          <a-input-number
            size="small"
            v-model:value="fileExport.order"
            style="width: 65px"
            placeholder="Order"
          />
        </div>
      </template>
      <div
        class="card-body"
        :style="
          mainItem && showOrder ? 'padding: 5px 5px 5px 75px' : 'padding: 5px'
        "
      >
        <div style="float: left">
          &lt;{{ fileExport.label
          }}{{
            ((fileExport?.parameters ?? []).length ? ' ' : '') +
            (fileExport?.parameters ?? [])
              .map(
                item =>
                  `${item.key}="${item.valueType === 'field' ? 'Field - ' + (item.fieldName === '_id' ? 'ID' : item.fieldName) + ' (' + item.fieldType + ')' : item.staticValue}"`
              )
              .join(' ')
          }}&gt;
          <template v-if="!fileExport.children.length">
            <template v-if="fileExport?.valueType === 'static'">
              <strong>{{ 'Static - ' + fileExport.staticValue }}</strong>
            </template>
            <template v-else-if="fileExport?.valueType === 'field'">
              <strong>{{
                fileExport.fieldName
                  ? 'Field - ' +
                    (fileExport.fieldName === '_id'
                      ? 'ID'
                      : fileExport.fieldName) +
                    ' (' +
                    fileExport.fieldType +
                    ')'
                  : '-'
              }}</strong>
            </template>
            <template v-else>
              <strong>#count</strong>
            </template>
          </template>
          <template v-if="!fileExport.children.length">
            &lt;/{{ fileExport.label }}&gt;
          </template>
        </div>
        <div style="float: right">
          <a-button size="small" class="mr-1" @click="edit(fileExport)"
            ><EditOutlined
          /></a-button>
          <a-popconfirm
            title="Are you sure to delete?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="remove(fileExport)"
          >
            <a-button size="small" type="primary" danger class="mr-1"
              ><DeleteOutlined
            /></a-button>
          </a-popconfirm>
        </div>
        <div class="clearfix"></div>

        <NestedFileExportingItem
          :show-order="false"
          :main-item="false"
          :depth="depth + 1"
          v-model:value="fileExport.children"
          @edit="edit"
          @remove="remove"
        />

        <template v-if="fileExport.children.length">
          &lt;/{{ fileExport.label }}&gt;
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { DeleteOutlined, EditOutlined } from '@ant-design/icons-vue';
import fileExporting from '../FileExporting.vue';

export default {
  name: 'NestedFileExportingItem',
  computed: {
    fileExporting() {
      return fileExporting;
    },
  },
  props: ['value', 'depth', 'mainItem', 'showOrder'],
  emits: ['edit', 'remove'],
  components: {
    DeleteOutlined,
    EditOutlined,
  },
  data() {
    return {};
  },
  methods: {
    edit(fileExport) {
      this.$emit('edit', fileExport);
    },
    remove(fileExport) {
      this.$emit('remove', fileExport);
    },
  },
};
</script>
<style scoped></style>
