<template>
  <div style="margin-top: 10px">
    <a-checkbox v-model:checked="input.isSensitive">
      Sensitive
      <help-icon-popover
        title="Sensitive Field"
        content="This field will be sensitive."
      />
    </a-checkbox>

    <a-divider
      v-if="
        field.structure.type === 'reference' ||
        field.structure?.elementStructure?.type === 'reference'
      "
    />
    <SortingOptions
      v-if="
        field.structure.type === 'reference' ||
        field.structure?.elementStructure?.type === 'reference'
      "
      :fieldId="fieldId"
    />
    <a-divider />
    <ConditionalLogicOptions :fieldId="fieldId" />
    <a-divider />
    <ValidationOptions :fieldId="fieldId" :blueprint="blueprint" />
    <a-divider />
    <CalculationOptions :fieldId="fieldId" />
    <a-divider v-if="isReferenceField" />
    <FilterOption v-if="isReferenceField" :fieldId="fieldId" />
  </div>
</template>

<script>
import { FormBuilderGetters } from '@dataSystem/views/BlueprintFormBuilder/formBuilder.store';
import FieldInputOptionsMixin from '../FieldInputOptionsMixin';
import CalculationOptions from './CalculationOptions.vue';
import ConditionalLogicOptions from './ConditionalLogicOptions.vue';
import ValidationOptions from './ValidationOptions.vue';
import FilterOption from './FilterOption.vue';
import SortingOptions from './SortingOptions.vue';

export default {
  name: 'AdvancedOptionsIndex',
  props: ['blueprint'],
  mixins: [FieldInputOptionsMixin],
  fieldInputOptions: {
    keys: ['isSensitive'],
  },
  components: {
    CalculationOptions,
    ConditionalLogicOptions,
    ValidationOptions,
    FilterOption,
    SortingOptions,
  },
  computed: {
    fields: FormBuilderGetters.getFields,
    field() {
      return this.fields.find(f => f.id === this.fieldId);
    },
    isReferenceField() {
      return (
        this.field.structure.type === 'reference' ||
        this.field.structure?.elementStructure?.type === 'reference'
      );
    },
  },
};
</script>

<style scoped>
.fieldRule {
  display: block;
  margin: 5px 0 !important;
}
</style>
