<template>
  <loading-spinner v-if="!authority" />
  <a-drawer v-else width="500px" :open="visible" @close="onClose">
    <a-select
      v-model:value="input.requiredConditionsToFulfill"
      default-value="all"
    >
      <a-select-option value="all">All</a-select-option>
      <a-select-option value="any">Any</a-select-option>
    </a-select>
    <span style="margin: 0 10px">of the following match:</span>

    <div
      v-for="(condition, index) in input.conditions"
      :key="index"
      style="margin: 15px 0"
    >
      <span style="margin-right: 15px; font-weight: 500">{{ index + 1 }}.</span>
      <a-select
        placeholder="Select field"
        style="min-width: 200px; margin-right: 15px"
        v-model:value="condition.fieldId"
      >
        <a-select-option
          v-for="field in fields"
          :key="field._id"
          :value="field._id"
        >
          (ID: {{ field.successionIndex }}) {{ field.label }}
        </a-select-option>
      </a-select>
      <a-select
        v-model:value="condition.comparator"
        style="min-width: 150px"
        default-value="equal"
      >
        <a-select-option value="EQUAL">is equal to</a-select-option>
        <a-select-option value="NOT_EQUAL">is not equal to</a-select-option>
        <a-select-option value="VISIBLE">is visible</a-select-option>
        <a-select-option value="NOT_VISIBLE">is not visible</a-select-option>
      </a-select>
      <div>
        <a-input
          v-model:value="condition.expectedValue"
          style="margin-top: 10px; display: inline-block; width: 355px"
        />
        <a-tooltip title="Remove condition">
          <a-button
            @click="onClickRemoveCondition(index)"
            style="margin-left: 5px"
            ><MinusOutlined />
          </a-button>
        </a-tooltip>
      </div>
    </div>
    <a-button @click="onClickAddCondition" style="margin-top: 10px">
      <PlusOutlined /> Add condition
    </a-button>
  </a-drawer>
</template>

<script>
import { MinusOutlined, PlusOutlined } from '@ant-design/icons-vue';
import { blueprintApi } from '@dataSystem/api';

import _ from 'lodash';

export default {
  props: ['visible', 'authority', 'blueprintId'],
  emits: ['update', 'close'],
  components: {
    MinusOutlined,
    PlusOutlined,
  },
  data() {
    return {
      emitUpdateDebounced: null,
      blueprint: null,
      fieldsById: null,
      input: {
        requiredConditionsToFulfill: 'all',
        conditions: [],
      },
    };
  },
  created() {
    if (this.blueprintId) {
      this.fetchBlueprint();
    }
    if (this.authority && this.authority.mode === 'conditional') {
      this.input = JSON.parse(JSON.stringify(this.authority));
    }
  },
  watch: {
    input: {
      deep: true,
      handler() {
        if (!_.isEqual(this.input, this.authority)) {
          this.debounceEmitUpdate();
        }
      },
    },
  },
  computed: {
    fields() {
      if (this.fieldsById) {
        const fields = Object.values(this.fieldsById);
        return fields;
      }
      return [];
    },
  },
  methods: {
    emitUpdate() {
      this.$emit('update', this.input);
    },
    debounceEmitUpdate() {
      if (!this.emitUpdateDebounced) {
        this.emitUpdateDebounced = _.debounce(this.emitUpdate, 500);
      }
      this.emitUpdateDebounced();
    },
    async fetchBlueprint() {
      const { blueprint, fieldsById } = await blueprintApi.getOne(
        this.blueprintId
      );
      this.blueprint = blueprint;
      this.fieldsById = fieldsById;
    },
    onClickAddCondition() {
      this.input.conditions.push({
        fieldId: null,
        comparator: 'EQUAL',
        expectedValue: null,
      });
    },
    onClickRemoveCondition(index) {
      this.input.conditions.splice(index, 1);
    },
    onClose() {
      this.$emit('close');
    },
  },
};
</script>

<style></style>
