<template>
  <a-modal
    v-model:open="modalView"
    title="Insereaza element"
    @ok="
      addNewElement();
      closeModal();
    "
    @cancel="closeModal"
  >
    <h4>Tip element:</h4>
    <a-select v-model:value="selectieTipElement" style="width: 50%">
      <a-select-option value="staticHtml"> Static html </a-select-option>
      <a-select-option value="item"> Item </a-select-option>
      <a-select-option value="list"> List of items </a-select-option>
      <a-select-option value="menu"> Menu </a-select-option>
    </a-select>

    <hr style="margin: 15px 0" />

    <div class="selectieStaticHtml" v-if="selectieTipElement === 'staticHtml'">
      <a-textarea
        v-model:value="elementData.staticHtml"
        placeholder="Continut element"
        allow-clear
      />
    </div>

    <div
      class="selectieItem"
      v-else-if="selectieTipElement === 'item' || selectieTipElement === 'list'"
    >
      <h4>Alege bussiness object-ul:</h4>
      <a-select
        v-model:value="elementData.blueprintId"
        :options="blueprintOptions"
        :filter-option="filterOption"
        showSearch
        style="width: 50%"
      ></a-select>
    </div>

    <div class="selectieMenu" v-if="selectieTipElement === 'menu'">
      <h4>Alege meniul:</h4>
      <a-select
        v-model:value="elementData.menuId"
        :options="menusOptions"
        style="width: 50%"
      ></a-select>
    </div>
  </a-modal>
</template>

<script>
import _ from 'lodash';
import { generateUUID } from '@/utilities';
import { TemplatesActions } from '@/apps/templateManagement/shared/templateManagement.store';

const defaultElement = {
  blueprintId: null,
  _id: null,
  view: {
    type: 'item',
    contentHtml: '',
    contentHtmlAfter: '',
    contentHtmlSettings: {
      idToLabel: false,
      fieldReferenceData: false,
      excludeFieldMultipleReferenceData: false,
    },
    itemsPerPage: 10,
    listFields: [],
    listFieldsCustomHeader: {
      enabled: false,
      headerColumns: [],
    },
    itemFields: [],
    filters: [],
    loadMore: true,
    default: {
      showAutoData: true,
      showTitle: true,
      customTitle: '',
      convertOwnerIdToOwnerDetails: false,
      showAddNewButton: true,
      addNewButtonLabel: '',
      showEditButton: true,
      editButtonLabel: '',
      showDeleteButton: true,
      deleteButtonLabel: '',
      showMassEmail: false,
      massEmailButtonLabel: '',
      updateListOnAnyCRUD: false,
      updateListOnBlueprintIdCRUD: [],
      order: {
        fieldId: '_created_at',
        order: 'descend',
      },
      showDefaultCreateForm: false,
      showDownloadPDF: false,
      showSearchTable: false,
      showDownloadXLSX: false,
      moveTopDownloadXLSX: false,
      simpleListView: false,
      smsFromAddNew: false,
      smsFromList: false,
      saveMessage: '',
      replaceDiacritics: false,
    },
  },
  clickItem: {
    open: true,
    openTarget: 'popup',
    openItemInPopup: 'otherPopup',
    templateId: null,
    passFieldId: null,
  },
};

export default {
  name: 'TemplateRecursion',
  props: ['template', 'blueprints', 'menus', 'modalAddObjectVisible'],
  emits: ['update:modalAddObjectVisible'],
  data() {
    return {
      modalView: false,
      selectieTipElement: null,
      elementData: {
        staticHtml: '',
        menuId: null,
        blueprintId: null,
      },
      defaultElement,
    };
  },
  computed: {
    blueprintOptions() {
      return this.blueprints.map(bp => {
        return {
          value: bp.id,
          label: bp.name,
        };
      });
    },
    menusOptions() {
      return this.menus
        .filter(item => item.active)
        .map(bp => {
          return {
            value: bp._id,
            label: bp.name,
          };
        });
    },
  },
  methods: {
    closeModal() {
      this.modalView = false;
    },
    showModal() {
      this.modalView = true;
    },

    filterOption(input, option) {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },

    addNewElement() {
      const newElement = _.cloneDeep(this.defaultElement);
      newElement._id = generateUUID();
      if (this.selectieTipElement === 'staticHtml') {
        newElement.view.type = 'html';
        newElement.view.contentHtml = this.elementData.staticHtml;
      } else if (this.selectieTipElement === 'item') {
        newElement.view.type = 'item';
        newElement.blueprintId = this.elementData.blueprintId;
      } else if (this.selectieTipElement === 'menu') {
        newElement.view.type = 'menu';
        newElement.view.menuId = this.elementData.menuId;
      } else {
        newElement.view.type = 'list';
        newElement.blueprintId = this.elementData.blueprintId;
      }
      const templateWithNewObject = this.template;
      templateWithNewObject.object = newElement;
      TemplatesActions.setTemplate(templateWithNewObject);
      // this.template.object = newElement;
      this.resetNewElementFields();
    },
    resetNewElementFields() {
      this.elementData.staticHtml = '';
      this.elementData.blueprintId = null;
      this.elementData.menuId = null;
    },
  },
};
</script>
