<template>
  <div
    v-if="
      groupRowFields.length &&
      lastFieldInGroup &&
      lastFieldInGroup &&
      localFieldIdToValue
    "
  >
    <div
      class="group-container"
      v-for="index in indexGroups"
      :key="'group-' + index"
    >
      <div
        v-for="(rowFields, i) in groupRowFields"
        :key="'group-' + index + '-' + i"
        class="field-row-flex"
      >
        <template v-for="groupField in rowFields" :key="groupField._id">
          <div style="margin-bottom: 10px">
            <FieldWidget
              :indexGroups="indexGroups"
              :indexValue="index"
              :field="groupField"
              :fields="groupFields"
              v-model="localFieldIdToValue[groupField._id]"
              :validity="fieldIdToValidity[groupField._id]"
              :userRolesWhichCanCreate="userRolesWhichCanCreate"
              :current-field-values="currentFieldValues"
              :fieldIdToValidationFailedArray="fieldIdToValidationFailedArray"
              @update:modelValue="
                value => updateGroupFieldValue(value, groupField._id)
              "
            />
          </div>
        </template>
      </div>

      <div class="row">
        <div class="col-6">
          <a-button
            v-if="index !== 0"
            style="margin-bottom: 10px"
            @click="removeItemInGroup(index)"
            size="small"
            ><DeleteOutlined />
          </a-button>
        </div>
        <div style="text-align: right" class="col-6">
          <template
            v-if="
              !firstFieldInGroup.groupOneChoice ||
              (firstFieldInGroup.groupOneChoice &&
                (firstFieldInGroup.structure.elementStructure.choices ?? [])
                  .length >
                  index + 1)
            "
          >
            <a-button
              v-if="
                showAddRemoveButton && (indexGroups ?? []).length === index + 1
              "
              style="margin-bottom: 10px"
              type="primary"
              @click="addItemInGroup(index)"
              size="small"
              ><PlusOutlined />
            </a-button>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons-vue';
import FieldWidget from '@/apps/dataSystem/components/FieldWidget/FieldWidget.vue';
import { toRaw } from 'vue';

export default {
  name: 'group-field-widget',
  emits: ['updateModifiedFieldId', 'updateGroupFieldIdToValue'],
  components: {
    FieldWidget,
    DeleteOutlined,
    PlusOutlined,
  },
  data() {
    return {
      indexGroups: [0],
      localFieldIdToValue: {},
    };
  },
  props: [
    'indexValue',
    'field',
    'fields',
    'userRolesWhichCanCreate',
    'fieldIdToValue',
    'fieldIdToValidity',
    'fieldIdToValidationFailedArray',
  ],
  async created() {
    if (
      this.firstFieldInGroup?._id === this.field._id &&
      this.firstFieldInGroup?.groupOneChoice &&
      this.firstFieldInGroup?.groupOneChoiceLoadAllChoices
    ) {
      this.indexGroups = (
        this.firstFieldInGroup.structure.elementStructure.choices ?? []
      ).map((item, i) => i);
    }
    this.groupFields.forEach(field => {
      this.localFieldIdToValue[field._id] = this.fieldIdToValue[field._id];
      if ((this.localFieldIdToValue[field._id] ?? []).length) {
        const groups = this.localFieldIdToValue[field._id].map((item, i) => i);
        if (groups.length > this.indexGroups.length) {
          this.indexGroups = groups;
        }
      }
    });
  },
  /* watch: {
    localFieldIdToValue: {
      deep: true,
      handler(value) {
        this.$emit('updateFieldIdToValue', value);
      },
    },
  }, */
  computed: {
    isGroup() {
      return this.field?.group && this.field.group !== '';
    },
    currentFieldValues() {
      return this.localFieldIdToValue;
    },
    groupFields() {
      return this.fields.filter(
        item =>
          item.group && item.group !== '' && item.group === this.field.group
      );
    },
    showAddRemoveButton() {
      if (!this.isGroup) {
        return true;
      }
      const findFields = (this.groupFields ?? [])
        .filter(item => {
          if (
            item.group &&
            item.group !== '' &&
            item.group === this.field.group
          ) {
            return true;
          }
          return false;
        })
        .reverse();
      const lastField = findFields.length ? findFields[0] : null;
      if (lastField && lastField._id === this.field._id) {
        return true;
      }
      return false;
    },
    groupRowFields() {
      const fieldRows = [];
      let row = [];
      this.groupFields.forEach((field, i) => {
        if (i === 0 || !field?.displayOnNewRow) {
          row.push(field);
        } else {
          fieldRows.push(row);
          row = [];
          row.push(field);
        }
        if (this.groupFields.length - 1 === i) {
          fieldRows.push(row);
        }
      });
      return fieldRows;
    },
    lastFieldInGroup() {
      const group = this.field?.group;
      if (!group || group === '') {
        return null;
      }
      const findFields = (this.fields ?? [])
        .filter(item => {
          return item.group && item.group !== '' && item.group === group;
        })
        .reverse();
      const lastField = findFields.length ? findFields[0] : null;
      if (lastField && lastField._id === this.field._id) {
        return lastField;
      }
      return null;
    },
    firstFieldInGroup() {
      const group = this.field?.group;
      if (!group || group === '') {
        return null;
      }
      const findFields = (this.fields ?? []).filter(item => {
        return item.group && item.group !== '' && item.group === group;
      });
      return findFields.length ? findFields[0] : null;
    },
  },
  methods: {
    async updateGroupFieldValue(value, groupFieldId) {
      const rawValue = toRaw(value);
      this.$emit('updateGroupFieldIdToValue', { rawValue, groupFieldId });
    },
    addItemInGroup(index) {
      this.indexGroups.push(index + 1);
      this.groupFields.forEach(field => {
        if ((this.localFieldIdToValue[field._id] ?? []).length) {
          this.localFieldIdToValue[field._id].push(null);
          this.updateGroupFieldValue(
            this.localFieldIdToValue[field._id],
            field._id
          );
        }
      });
    },
    removeItemInGroup(index) {
      this.groupFields.forEach(field => {
        if ((this.localFieldIdToValue[field._id] ?? []).length) {
          this.localFieldIdToValue[field._id].splice(index, 1);
        }
      });
      this.indexGroups = this.indexGroups
        .filter(item => item !== index)
        .sort()
        .map((item, i) => i);
    },
  },
};
</script>

<style scoped>
.group-container {
  border: 1px solid #eaeaea;
  background-color: rgba(196, 196, 196, 0.1);
  padding: 10px 10px 0;
  margin-bottom: 10px;
  border-radius: 4px;
}
</style>
