<template>
  <a-input
    allowClear
    :placeholder="`Search`"
    style="width: 188px; margin-bottom: 8px"
    v-model:value="searchInput"
  >
  </a-input>
</template>
<script>
import _ from 'lodash';
import {
  NewTemplatesActions,
  NewTemplatesGetters,
} from '@templateDataSystem/shared/newTemplateDataSystem.store';

export default {
  name: 'SearchTable',
  props: ['object', 'blueprint'],
  data() {
    return {
      searchInput: '',
      searchTimout: null,
    };
  },
  watch: {
    searchInput: {
      deep: true,
      async handler(newVal, oldVal) {
        if (this.searchTimout) {
          clearTimeout(this.searchTimout);
        }
        if (newVal !== oldVal) {
          this.fetchInstances(newVal, oldVal);
        }
      },
    },
  },
  methods: {
    fetchInstances(newVal) {
      this.searchTimout = setTimeout(async () => {
        const query = _.cloneDeep(
          NewTemplatesGetters.getLastQuery(this.object._id, this.blueprint._id)
        );
        const queryStripParams = query;
        if (queryStripParams.filtersForAllColumns) {
          if (newVal.length === 0) {
            queryStripParams.filtersForAllColumns.splice(0, 1); // delete filter
          } else {
            queryStripParams.filtersForAllColumns[0] = newVal;
          }
        } else {
          queryStripParams.filtersForAllColumns = [newVal];
        }
        await NewTemplatesActions.fetchInstanceList(
          this.object._id,
          this.object.blueprintId,
          query
        );
      }, 1000);
    },
  },
};
</script>
