<template>
  <div>
    <a-row :gutter="18">
      <a-col class="gutter-row" :span="12">
        <a-select
          :value="
            localEvent.enableDisableDataProvider.dataProvideId == null
              ? undefined
              : localEvent.enableDisableDataProvider.dataProvideId
          "
          placeholder="Select Data Provider"
          @change="selectProvider"
          style="width: 100%"
          allowClear
        >
          <a-select-option
            v-for="provider in dataProviderList"
            :key="provider._id"
            :value="provider._id"
          >
            {{ provider.name }}
          </a-select-option>
        </a-select>
      </a-col>
      <a-col
        class="gutter-row"
        :span="12"
        v-if="localEvent.enableDisableDataProvider.dataProvideId"
      >
        <a-radio-group
          v-model:value="localEvent.enableDisableDataProvider.status"
          button-style="solid"
        >
          <a-radio-button :value="true">Enable</a-radio-button>
          <a-radio-button :value="false">Disable</a-radio-button>
        </a-radio-group>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import {
  DataProviderActions,
  DataProviderGetters,
} from '@dataProvider/shared/dataProvider.store';
import { WorkflowActions } from '@workflow/shared/workflow.store';

export default {
  name: 'workflowBuilderEnableDisableDataProviderIndex',
  props: ['workflows', 'selectedWorkflow', 'event', 'eventList'],

  data() {
    return {
      localEvent: { ...this.event },
    };
  },
  watch: {
    async localEvent(data) {
      await WorkflowActions.editOneEvent(
        this.selectedWorkflow._id,
        this.event._id,
        { ...data }
      );
    },
  },
  async mounted() {
    await DataProviderActions.fetchDataProviderList();
  },
  computed: {
    dataProviderList: DataProviderGetters.getDataProviderList,
  },

  methods: {
    selectProvider(providerId) {
      this.localEvent.enableDisableDataProvider.dataProvideId = providerId;
      this.localEvent.enableDisableDataProvider.status = null;
    },
  },
};
</script>
