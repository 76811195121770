import { reactive } from 'vue';

import { handleNested } from '@/core/utils/array-manipulation';

import { UserGetters } from '@userManagement/user.store';
import { SlugGetters } from '@/slug.store';

import {
  serializeTenant,
  serializeApp,
  serializeSubtenant,
} from '@tenants/serializers/serializer';
import { tenantApi, applicationApi, subtenantApi } from '../api';

const initialState = {
  tenants: [],
};

const state = reactive({ ...initialState });

const Getters = {
  getAppId: () => {
    const tenantSlug = SlugGetters.getTenantSlug();
    const appSlug = SlugGetters.getAppSlug();

    if (tenantSlug && appSlug) {
      const tenant = (Getters.getTenants() ?? []).find(
        t => t.slug === tenantSlug
      );
      if (!tenant) {
        return null;
      }
      const application = tenant.applications.find(a => a.slug === appSlug);
      return application ? application._id : null;
    }
    return null;
  },
  getAllSubtenants() {
    const tenantSlug = SlugGetters.getTenantSlug();
    const appSlug = SlugGetters.getAppSlug();

    const tenant = (state?.tenants ?? []).find(
      item => item.slug === tenantSlug
    );
    const app = (tenant?.applications ?? []).find(
      item => item.slug === appSlug
    );

    return app?.subtenants ?? [];
  },
  getTenantAppSubtenant: () => {
    const tenantSlug = SlugGetters.getTenantSlug();
    const appSlug = SlugGetters.getAppSlug();
    const subtenantSlug = SlugGetters.getSubtenantSlug();

    const tenant = (state?.tenants ?? []).find(
      item => item.slug === tenantSlug
    );
    const app = (tenant?.applications ?? []).find(
      item => item.slug === appSlug
    );
    const subtenant = (app?.subtenants ?? []).find(
      item => item.slug === subtenantSlug
    );

    const copyTenant = { ...tenant };
    const copyApp = { ...app };
    const copySubtenant = { ...subtenant };

    return {
      tenant: serializeTenant(copyTenant),
      app: serializeApp(copyApp),
      subtenant: serializeSubtenant(copySubtenant),
    };
  },
  getTenants: () => {
    return state.tenants;
  },
  getBySlugs: (tenantSlug, appSlug, subtenantSlug) => {
    let tenant = {};
    let app = {};
    let subtenant = {};

    if (tenantSlug) {
      tenant = state.tenants.find(t => t.slug === tenantSlug);
    }

    if (appSlug && tenant) {
      app = tenant.applications.find(a => a.slug === appSlug);
    }

    if (subtenantSlug && app) {
      subtenant = app.subtenants.find(s => s.slug === subtenantSlug);
    }

    return { tenant, app, subtenant };
  },
  getTenantBySlug: tenantSlug => {
    const tenant = state.tenants.find(t => t.slug === tenantSlug);
    if (!tenant) {
      return {};
    }
    return tenant;
  },
  getAppBySlugs: (tenantSlug, appSlug) => {
    const tenant = state.tenants.find(t => t.slug === tenantSlug);
    if (!tenant) {
      return {};
    }
    const app = tenant.applications.find(a => a.slug === appSlug);
    if (!app) {
      return {};
    }
    return app;
  },
  getSubtenantBySlugs: (tenantSlug, appSlug, subtenantSlug) => {
    const tenant = state.tenants.find(t => t.slug === tenantSlug);
    if (!tenant) {
      return {};
    }
    const app = tenant.applications.find(a => a.slug === appSlug);
    if (!app) {
      return {};
    }
    const subtenant = app.subtenants.find(s => s.slug === subtenantSlug);
    if (!subtenant) {
      return {};
    }
    return subtenant;
  },
  isUserTenantAdmin(tenantSlug, userId) {
    if (!UserGetters.getUser()) {
      return false;
    }
    if (UserGetters.getUser().isSuperAdmin) {
      return true;
    }
    if (!tenantSlug || !userId) {
      return false;
    }
    const tenant = state.tenants.find(t => t.slug === tenantSlug);
    if (!tenant) {
      return false;
    }
    if (tenant.adminUserIdList.indexOf(userId) !== -1) {
      return true;
    }
    return false;
  },
  isUserSubtenantManager(tenantSlug, appSlug, subtenantSlug, userId) {
    if (!tenantSlug || !appSlug || !subtenantSlug || !userId) {
      return false;
    }
    if (Getters.isUserTenantAdmin(tenantSlug, userId)) {
      return true;
    }
    const tenant = state.tenants.find(t => t.slug === tenantSlug);
    if (!tenant) {
      return false;
    }
    const app = tenant.applications.find(a => a.slug === appSlug);
    if (!app) {
      return false;
    }
    const subtenant = app.subtenants.find(s => s.slug === subtenantSlug);
    if (!subtenant) {
      return false;
    }
    if (subtenant.managerUserIdList.indexOf(userId) !== -1) {
      return true;
    }
    return false;
  },
};

const Mutations = {
  SET_TENANTS: tenants => {
    state.tenants = tenants;
  },

  // TENANT
  INSERT_TENANT: tenant => {
    state.tenants.push(tenant);
  },
  EDIT_TENANT: (tenantId, updatedTenant) => {
    const stateTenants = handleNested(
      state.tenants,
      { _id: tenantId },
      { update: updatedTenant }
    );
    state.tenants = stateTenants;
  },
  DELETE_TENANT: tenantId => {
    const stateTenants = handleNested(state.tenants, { _id: tenantId });
    state.tenants = stateTenants;
  },

  // APPLICATION
  INSERT_APPLICATION: (tenantId, application) => {
    state.tenants.find(t => t._id === tenantId).applications.push(application);
  },
  EDIT_APPLICATION: (tenantId, applicationId, updatedApplication) => {
    const stateTenants = handleNested(
      state.tenants,
      { _id: tenantId, applications: { _id: applicationId } },
      { update: updatedApplication }
    );
    state.tenants = stateTenants;
  },
  DELETE_APPLICATION: (tenantId, applicationId) => {
    const stateTenants = handleNested(state.tenants, {
      _id: tenantId,
      applications: { _id: applicationId },
    });
    state.tenants = stateTenants;
  },

  // SUBTENANT
  INSERT_SUBTENANT: (tenantId, applicationId, subtenant) => {
    state.tenants
      .find(t => t._id === tenantId)
      .applications.find(a => a._id === applicationId)
      .subtenants.push(subtenant);
  },
  EDIT_SUBTENANT: (tenantId, applicationId, subtenantId, updatedSubtenant) => {
    const stateTenants = handleNested(
      state.tenants,
      {
        _id: tenantId,
        applications: { _id: applicationId, subtenants: { _id: subtenantId } },
      },
      { update: updatedSubtenant }
    );
    state.tenants = stateTenants;
  },
  DELETE_SUBTENANT: (tenantId, applicationId, subtenantId) => {
    const stateTenants = handleNested(state.tenants, {
      _id: tenantId,
      applications: { _id: applicationId, subtenants: { _id: subtenantId } },
    });
    state.tenants = stateTenants;
  },
};

const Actions = {
  fetchTenants: async () => {
    const tenants = await tenantApi.getAll();
    Mutations.SET_TENANTS(tenants);
  },

  createOneTenant: async tenant => {
    const newTenant = await tenantApi.postOne(tenant);
    if (newTenant.success === false) {
      throw Error(newTenant.message);
    }
    Mutations.INSERT_TENANT({ applications: [], ...newTenant });
    return newTenant;
  },
  editOneTenant: async (tenantId, tenantForm) => {
    const updatedTenant = await tenantApi.putOne(tenantId, tenantForm);
    if (updatedTenant.success === false) {
      throw Error(updatedTenant.message);
    }
    Mutations.EDIT_TENANT(tenantId, updatedTenant);
    return updatedTenant;
  },
  deleteOneTenant: async tenantId => {
    await tenantApi.deleteOne(tenantId);
    Mutations.DELETE_TENANT(tenantId);
  },

  // admin
  postOneTenantAdmin: async (tenantId, adminForm) => {
    const updatedTenant = await tenantApi.postAdmin(tenantId, adminForm);
    Mutations.EDIT_TENANT(tenantId, updatedTenant);
    return updatedTenant;
  },
  putOneAdminAccess: async (tenantId, userId, adminForm) => {
    const updatedTenant = await tenantApi.putAdminAccess(
      tenantId,
      userId,
      adminForm
    );
    Mutations.EDIT_TENANT(tenantId, updatedTenant);
    return updatedTenant;
  },
  deleteOneTenantAdmin: async (tenantId, userId) => {
    const updatedTenant = await tenantApi.deleteAdmin(tenantId, userId);
    Mutations.EDIT_TENANT(tenantId, updatedTenant);
    return updatedTenant;
  },

  // manager
  postOneTenantManager: async (
    tenantId,
    applicationId,
    subtenantId,
    managerForm
  ) => {
    const updatedSubtenant = await subtenantApi.postManager(
      tenantId,
      applicationId,
      subtenantId,
      managerForm
    );
    Mutations.EDIT_SUBTENANT(
      tenantId,
      applicationId,
      subtenantId,
      updatedSubtenant
    );
    return updatedSubtenant;
  },
  putOneManagerAccess: async (
    tenantId,
    applicationId,
    subtenantId,
    userId,
    managerForm
  ) => {
    const updatedSubtenant = await subtenantApi.putManagerAccess(
      tenantId,
      applicationId,
      subtenantId,
      userId,
      managerForm
    );
    Mutations.EDIT_SUBTENANT(
      tenantId,
      applicationId,
      subtenantId,
      updatedSubtenant
    );
    return updatedSubtenant;
  },
  deleteOneTenantManager: async (
    tenantId,
    applicationId,
    subtenantId,
    userId
  ) => {
    const updatedSubtenant = await subtenantApi.deleteManager(
      tenantId,
      applicationId,
      subtenantId,
      userId
    );
    Mutations.EDIT_SUBTENANT(
      tenantId,
      applicationId,
      subtenantId,
      updatedSubtenant
    );
    return updatedSubtenant;
  },

  //
  createOneApplication: async (tenantId, application) => {
    const newApplication = await applicationApi.postOne(tenantId, application);
    if (newApplication.success === false) {
      throw Error(newApplication.message);
    }
    Mutations.INSERT_APPLICATION(tenantId, {
      subtenants: [],
      ...newApplication,
    });
    return newApplication;
  },
  editOneApplication: async (tenantId, applicationId, application) => {
    const updatedApplication = await applicationApi.putOne(
      tenantId,
      applicationId,
      application
    );
    if (updatedApplication.success === false) {
      throw Error(updatedApplication.message);
    }
    Mutations.EDIT_APPLICATION(tenantId, applicationId, updatedApplication);
  },
  deleteOneApplication: async (tenantId, applicationId) => {
    await applicationApi.deleteOne(tenantId, applicationId);
    Mutations.DELETE_APPLICATION(tenantId, applicationId);
  },

  //
  createOneSubtenant: async (tenantId, applicationId, subtenant) => {
    const newSubtenant = await subtenantApi.postOne(
      tenantId,
      applicationId,
      subtenant
    );
    if (newSubtenant.success === false) {
      throw Error(newSubtenant.message);
    }
    Mutations.INSERT_SUBTENANT(tenantId, applicationId, newSubtenant);
    return newSubtenant;
  },
  editOneSubtenant: async (tenantId, applicationId, subtenantId, subtenant) => {
    const updatedSubtenant = await subtenantApi.putOne(
      tenantId,
      applicationId,
      subtenantId,
      subtenant
    );
    if (updatedSubtenant.success === false) {
      throw Error(updatedSubtenant.message);
    }
    Mutations.EDIT_SUBTENANT(
      tenantId,
      applicationId,
      subtenantId,
      updatedSubtenant
    );
  },
  deleteOneSubtenant: async (tenantId, applicationId, subtenantId) => {
    await subtenantApi.deleteOne(tenantId, applicationId, subtenantId);
    Mutations.DELETE_SUBTENANT(tenantId, applicationId, subtenantId);
  },
};

export const TenantsGetters = Getters;
export const TenantsMutations = Mutations;
export const TenantsActions = Actions;
