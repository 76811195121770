<template>
  <div>
    <a-tooltip title="Add condition" v-if="input.isEnabled">
      <a-button
        @click="onClickAddCondition"
        style="right: 0; position: absolute"
        ><PlusOutlined
      /></a-button>
    </a-tooltip>
    <a-checkbox style="margin: 15px 0" v-model:checked="input.isEnabled">
      Enable Validation with referenced fields
      <help-icon-popover title="Validation" content="option data" />
    </a-checkbox>

    <div style="padding: 0 27.5px" v-if="input.isEnabled">
      <span>
        The
        <b>value</b>
        of
        <b>this field</b>
        must match
      </span>
      <a-select
        v-model:value="input.mode"
        style="margin: 0 10px; width: 100px"
        default-value="all"
      >
        <a-select-option value="all" title="Validate if all conditions are TRUE"
          >All</a-select-option
        >
        <a-select-option value="any" title="Validate if one condition is TRUE"
          >Any</a-select-option
        >
        <a-select-option
          value="reach"
          title="Validate last condition if can reach it"
          >Reach Last</a-select-option
        >
      </a-select>
      <span>of the following:</span>

      <div style="padding: 0 27.5px">
        <div
          v-for="(validator, index) in input.validators"
          :key="index"
          style="margin: 15px 0"
        >
          <div
            v-if="index !== 0 && input.mode === 'reach'"
            class="ant-input-group-addon"
            style="width: 10%; height: 32px; border: 1px solid #d9d9d9"
          >
            {{ 'THEN' }}
          </div>
          <a-input-group compact style="margin-top: 15px">
            <span
              style="margin-right: 15px; margin-bottom: 25px; font-weight: 500"
              v-if="!validator.conditionings"
            >
              {{ index + 1 }}.
            </span>
            <span
              v-if="input.mode === 'reach'"
              class="ant-input-group-addon"
              style="
                width: 20%;
                height: 32px;
                line-height: 30px;
                margin-bottom: 10px;
              "
            >
              {{ 'IF' }}
            </span>
            <a-select
              placeholder="Select field"
              style="min-width: 200px; margin-right: 15px"
              v-model:value="validator.option"
              @change="onChangeOption($event, validator, index)"
              v-if="input.isEnabled"
            >
              <a-select-option value="value">Value</a-select-option>
              <a-select-option value="another">{{
                blueprint.name
              }}</a-select-option>
              <a-select-option
                v-if="fieldInput.input.type === 'input_reference'"
                value="current"
                >{{ fieldInput.label }}</a-select-option
              >
            </a-select>

            <div
              v-if="validator.option !== 'value' && validator.option != null"
            >
              <a-select
                placeholder="Select field"
                style="min-width: 200px; margin-right: 15px"
                v-model:value="validator.fieldId"
                @change="onChangeReferenceField($event, validator)"
                v-if="input.isEnabled && validator.option === 'another'"
              >
                <a-select-option
                  v-for="field in fieldsBefore"
                  :key="field._id"
                  :value="field._id"
                >
                  (ID: {{ field.successionIndex }}) {{ field.label }} ({{
                    field.structure.type
                  }})
                </a-select-option>
              </a-select>

              <a-select
                placeholder="Select field"
                style="min-width: 200px; margin-right: 15px"
                v-model:value="validator.fieldId"
                @change="onChangeReferenceField($event, validator)"
                v-if="input.isEnabled && validator.option === 'current'"
              >
                <a-select-option
                  v-for="field in fieldsOtherBlueprint"
                  :key="field._id"
                  :value="field._id"
                >
                  (ID: {{ field.successionIndex }}) {{ field.label }} ({{
                    field.structure.type
                  }})
                </a-select-option>
              </a-select>

              <span
                v-for="(reference, index) in validator.references"
                :key="index"
              >
                <a-select
                  placeholder="Select field"
                  style="
                    min-width: 200px;
                    margin-right: 15px;
                    margin-bottom: 15px;
                  "
                  v-model:value="reference.fieldId"
                  @change="onReferences($event, reference, validator)"
                  v-if="reference.blueprintId"
                >
                  <a-select-option
                    v-for="field in load(reference.blueprintId)"
                    :key="field._id"
                    :value="field._id"
                  >
                    (ID: {{ field.successionIndex }}) {{ field.label }} ({{
                      field.structure.type
                    }})
                  </a-select-option>
                </a-select>
              </span>

              <a-select
                v-if="validator.fieldType !== 'reference'"
                v-model:value="validator.comparator"
                style="min-width: 100px; margin-right: 15px"
                default-value="equal"
              >
                <a-select-option value="EQUAL">is equal to</a-select-option>
                <a-select-option value="NOT_EQUAL"
                  >is not equal to</a-select-option
                >
              </a-select>

              <ExpectedValueInput
                v-if="
                  validator.fieldType !== 'reference' &&
                  !['IS_FOUND', 'NOT_FOUND'].includes(validator.comparator)
                "
                :fieldId="validator.fieldId"
                :fieldType="validator.fieldType"
                :condition="validator"
                style="padding-left: 15px; display: inline-block; width: 200px"
                @expectedValue="setCondition($event, validator)"
              />
            </div>
            <div
              style="padding: 0 27.5px; width: 100%"
              v-if="validator.option === 'value'"
            >
              <div>
                <a-select
                  v-model:value="validator.comparator"
                  style="min-width: 150px; margin-right: 15px"
                  default-value="equal"
                  @change="clearValidatorFieldId(validator)"
                >
                  <a-select-option value="EQUAL">is equal </a-select-option>
                  <a-select-option value="NOT_EQUAL"
                    >is not equal
                  </a-select-option>
                  <a-select-option value="REGEX">REGEX</a-select-option>
                  <a-select-option value="IS_FOUND">is found </a-select-option>
                  <a-select-option value="NOT_FOUND"
                    >is not found
                  </a-select-option>
                  <a-select-option value="FIND_FIRST">
                    first x caracters
                  </a-select-option>
                  <a-select-option value="FIND_LAST">
                    last x caracters</a-select-option
                  >
                </a-select>

                <a-select
                  v-if="
                    [
                      'IS_FOUND',
                      'NOT_FOUND',
                      'FIND_FIRST',
                      'FIND_LAST',
                    ].includes(validator.comparator)
                  "
                  placeholder="Select blueprint"
                  style="min-width: 200px; margin-right: 15px"
                  v-model:value="validator.blueprintId"
                  @change="
                    referencedFieldId(validator.blueprintId);
                    clearValidatorFieldId(validator);
                  "
                >
                  <a-select-option
                    v-for="blueprint in blueprints"
                    :key="blueprint._id"
                    :value="blueprint._id"
                  >
                    {{ blueprint.name }}
                  </a-select-option>
                </a-select>

                <a-select
                  v-if="
                    [
                      'IS_FOUND',
                      'NOT_FOUND',
                      'FIND_FIRST',
                      'FIND_LAST',
                    ].includes(validator.comparator) && fieldsLoaded
                  "
                  placeholder="Select field"
                  style="min-width: 200px; margin-right: 15px; margin-top: 10px"
                  v-model:value="validator.fieldId"
                  @change="onChangeReferenceField($event, validator)"
                >
                  <a-select-option
                    v-for="field in load(validator.blueprintId)"
                    :key="field._id"
                    :value="field._id"
                  >
                    <span v-if="field.structure.type !== 'reference'"
                      >(ID: {{ field.successionIndex }}) {{ field.label }}
                    </span>
                  </a-select-option>
                </a-select>

                <div>
                  <a-input
                    v-if="
                      !['IS_FOUND', 'NOT_FOUND'].includes(validator.comparator)
                    "
                    v-model:value="validator.expectedValue"
                    style="
                      margin-top: 10px;
                      display: inline-block;
                      width: 355px;
                    "
                    placeholder="Enter value"
                  />
                </div>
                <div
                  v-if="validator.fieldType === 'string'"
                  style="margin-top: 10px"
                >
                  Case sensitive:
                  <a-checkbox
                    style="margin-left: 10px"
                    v-model:checked="validator.caseSensitive"
                  >
                  </a-checkbox>
                </div>

                <div style="margin-top: 10px">
                  Always active:
                  <a-switch
                    style="position: relative; top: -1px"
                    checked-children="Yes"
                    un-checked-children="No"
                    v-model:checked="validator.alwaysActive"
                  />
                </div>
                <div v-if="!validator.alwaysActive && validator.conditionedBy">
                  <div style="margin: 10px 0">When should it be active?</div>

                  <a-select
                    placeholder="Select field"
                    style="min-width: 200px; margin-right: 15px"
                    v-model:value="validator.conditionedBy.fieldId"
                  >
                    <a-select-option
                      v-for="field in fieldsBefore"
                      :key="field._id"
                      :value="field._id"
                    >
                      (ID: {{ field.successionIndex }}) {{ field.label }}
                    </a-select-option>
                  </a-select>
                  <a-select
                    v-model:value="validator.conditionedBy.comparator"
                    style="min-width: 150px"
                    default-value="equal"
                  >
                    <a-select-option value="EQUAL">is equal </a-select-option>
                    <a-select-option value="NOT_EQUAL"
                      >not equal</a-select-option
                    >
                  </a-select>
                  <ExpectedValueInput
                    style="margin-top: 10px; width: 365px"
                    v-if="
                      validator.conditionedBy.fieldId &&
                      !['IS_FOUND', 'NOT_FOUND'].includes(
                        validator.conditionedBy.comparator
                      )
                    "
                    :condition="validator.conditionedBy"
                    :fieldId="validator.conditionedBy.fieldId"
                    @expectedValue="
                      setCondition($event, validator.conditionedBy)
                    "
                  />
                </div>
                <hr />
              </div>
            </div>
            <a-tooltip title="Remove condition">
              <a-button
                @click="onClickRemoveCondition(index)"
                style="right: 0; position: absolute; top: 50%"
                ><MinusOutlined
              /></a-button>
            </a-tooltip>
          </a-input-group>
        </div>
      </div>
    </div>

    <!-- <pre>{{ input }}</pre> -->
    <!-- <pre>{{ input.conditions }}</pre> -->
    <div style="margin-bottom: 15px" v-if="input.isEnabled">
      <label style="display: block; margin-top: 10px; margin-bottom: 5px">
        Failed validation message
      </label>
      <a-input
        v-model:value="input.failureMessage"
        placeholder="Enter message"
      />
    </div>
  </div>
</template>

<script>
import { blueprintApi } from '@dataSystem/api';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons-vue';
import FieldInputOptionsMixin from '../FieldInputOptionsMixin';

import ExpectedValueInput from './ExpectedValueInput.vue';
import { FormBuilderGetters } from '../../../formBuilder.store';

export default {
  props: ['blueprint'],
  mixins: [FieldInputOptionsMixin],
  fieldInputOptions: {
    path: 'logic.validation',
    keys: [
      'isEnabled',
      'action',
      'mode',
      'conditions',
      'references',
      'failureMessage',
      'validators',
    ],
  },
  data() {
    return {
      otherBlueprintCheckbox: false,
      referencedBlueprint: [],
      referenceFields: [],
      fieldsOtherBlueprint: [],
      fieldsBefore: [],
      referenceConditionsFields: [],
      secondReferenceField: {},
      listOptionReferences: [],
      blueprints: null,
      blueprintFields: null,
      fieldsLoaded: true,
    };
  },
  components: {
    ExpectedValueInput,
    MinusOutlined,
    PlusOutlined,
  },
  async mounted() {
    this.blueprints = await blueprintApi.getAll();
    if (this.fieldInput.input.type === 'input_reference') {
      this.referencedBlueprint = await blueprintApi.getAllWithFields([
        this.fieldInput.structure.ruleset.blueprintId,
      ]);
      this.referenceFields = await this.referencedBlueprint[0].fields;
      [this.field] = this.referenceFields;
      this.fieldsOtherBlueprint = this.referenceFields;
    }
    this.fieldsBefore = FormBuilderGetters.getFieldsBefore(this.fieldId);
    this.input.validators.forEach(async validator => {
      if (validator.references.length) {
        await validator.references.forEach(async ref => {
          await this.referencedFieldId(ref.blueprintId);
        });
      }
    });
  },
  methods: {
    clearValidatorFieldId(validator) {
      if (
        ['IS_FOUND', 'NOT_FOUND', 'FIND_FIRST', 'FIND_LAST'].includes(
          validator.comparator
        )
      ) {
        validator.fieldId = null;
      }
    },
    load(blueprintId) {
      if (!this.secondReferenceField[blueprintId]) {
        this.referencedFieldId(blueprintId);
      }
      return this.secondReferenceField[blueprintId];
    },

    async referencedFieldId(blueprintId) {
      if (blueprintId) {
        this.fieldsLoaded = false;
        const referencedBlueprint = await blueprintApi.getAllWithFields([
          blueprintId,
        ]);
        this.secondReferenceField[blueprintId] =
          await referencedBlueprint[0].fields;
        this.fieldsLoaded = true;
      }
    },
    onReferences(value, reference, validator) {
      this.secondReferenceField[reference.blueprintId].forEach(async ref => {
        if (ref._id === reference.fieldId) {
          if (ref.structure.type === 'reference') {
            if (reference.idx === validator.references.length - 1) {
              await this.referencedFieldId(ref.structure.ruleset.blueprintId);
              validator.references.push({
                blueprintId: ref.structure.ruleset.blueprintId,
                fieldId: null,
                validator: 'EQUAL',
                fieldType: null,
                idx: reference.idx + 1,
              });
            } else {
              validator.references.splice(
                reference.idx + 1,
                validator.references.length - reference.idx + 1
              );
              // condition.expectedValue = null;
              await this.referencedFieldId(ref.structure.ruleset.blueprintId);
              validator.references.push({
                blueprintId: ref.structure.ruleset.blueprintId,
                fieldId: null,
                validator: 'EQUAL',
                fieldType: null,
                idx: reference.idx + 1,
              });
            }
          } else {
            if (reference.idx !== validator.references.length - 1) {
              validator.references.splice(
                reference.idx + 1,
                validator.references.length - reference.idx + 1
              );
            }

            // condition.expectedValue = null;
            validator.fieldType = ref.structure.type;
            reference.fieldType = ref.structure.type;
          }
        }
      });
    },
    onChangeOption(value, validator) {
      if (value === 'value') {
        validator.fieldId = this.fieldId;
      } else {
        validator.fieldId = null;
        validator.references = [];
        validator.expectedValue = null;
      }
    },
    onClickAddValidator() {
      this.input.validators.push({
        comparator: 'EQUAL',
        expectedValue: null,
        message: null,
        alwaysActive: true,
        caseSensitive: true,
        conditionedBy: {
          fieldId: '',
          comparator: '',
          expectedValue: '',
          subtenantSlug: '',
        },
      });
      return this.input.validators.length - 1;
    },
    async onChangeReferenceField(value, validator) {
      let refField = {};
      // this.fieldsBefore = await FormBuilderGetters.getFieldsBefore(validator.fieldId);
      if (
        ['IS_FOUND', 'NOT_FOUND', 'FIND_FIRST', 'FIND_LAST'].includes(
          validator.comparator
        ) &&
        validator.option === 'value'
      ) {
        this.secondReferenceField[validator.blueprintId].forEach(field => {
          if (value === field._id) {
            refField = field;
          }
        });
      } else {
        this.fieldsBefore.forEach(field => {
          if (value === field._id) {
            refField = field;
          }
        });
      }
      this.fieldsOtherBlueprint.forEach(field => {
        if (value === field._id) {
          refField = field;
        }
      });
      if (validator.fieldId === value) {
        validator.fieldType = refField.structure.type;
        if (validator.fieldType !== 'string') {
          validator.caseSensitive = true;
        }
        if (
          ['IS_FOUND', 'NOT_FOUND', 'FIND_FIRST', 'FIND_LAST'].includes(
            validator.comparator
          ) &&
          validator.option === 'value'
        ) {
          if (validator.references.length === 0) {
            await this.referencedFieldId(refField.blueprintId);
            validator.references.push({
              blueprintId: refField.structure.ruleset.blueprintId,
              fieldId: null,
              condition: 'EQUAL',
              idx: 0,
              fieldType: validator.fieldType,
            });
          } else {
            validator.references[0].blueprintId =
              validator.referencedBlueprintId;
            validator.references[0].fieldType = validator.fieldType;
          }
        }
        if (validator.fieldType === 'reference') {
          validator.blueprintId = refField.structure.ruleset.blueprintId;
          if (validator.references.length === 0) {
            await this.referencedFieldId(
              refField.structure.ruleset.blueprintId
            );
            validator.references.push({
              blueprintId: refField.structure.ruleset.blueprintId,
              fieldId: null,
              condition: 'EQUAL',
              idx: 0,
              fieldType: validator.fieldType,
            });
          } else {
            validator.references[0].blueprintId =
              validator.referencedBlueprintId;
            validator.references[0].fieldType = validator.fieldType;
          }
        } else {
          validator.references = [];
          validator.blueprintId = refField.blueprintId;
          validator.expectedValue = null;
        }
      }
    },

    onClickAddCondition() {
      if (!this.input.validators) this.input.validators = [];
      this.input.validators.push({
        validatorIndex: null,
        fieldId: null,
        blueprintId: null,
        comparator: 'EQUAL',
        fieldType: 'string',
        expectedValue: null,
        caseSensitive: true,
        references: [],
        option: null,
        subtenantSlug: null,
        conditionedBy: {
          fieldId: null,
          comparator: null,
          expectedValue: null,
          subtenantSlug: null,
        },
      });
    },
    onClickRemoveCondition(index) {
      this.input.validators.splice(index, 1);
    },
    setCondition(conditionObj, condition) {
      condition.expectedValue = conditionObj.value;
      if (conditionObj.subtenantSlug) {
        condition.subtenantSlug = conditionObj.subtenantSlug;
      }
    },
  },
};
</script>
