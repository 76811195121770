<template>
  <div style="margin-bottom: 15px">
    <div v-if="input.ruleset.usePlaceholder === true">
      <label>Placeholder</label>
      <a-input
        v-model:value="input.options.placeholder"
        placeholder="Enter placeholder value"
        style="display: block"
      />
    </div>
    <div v-if="input.ruleset.useSize === true" style="margin: 10px 0">
      <label>Field Size</label>
      <a-select
        v-model:value="input.options.size"
        default-value="default"
        style="width: 200px; display: block"
      >
        <a-select-option value="small">Small</a-select-option>
        <a-select-option value="default">Default</a-select-option>
        <a-select-option value="large">Large</a-select-option>
      </a-select>
    </div>
    <div v-if="input.type === 'input_radio_group'">
      <label>Radio Circles Direction</label>
      <a-select
        v-model:value="input.options.direction"
        default-value="default"
        style="width: 200px; display: block"
      >
        <a-select-option value="horizontal">Horizontal</a-select-option>
        <a-select-option value="vertical">Vertical</a-select-option>
      </a-select>
    </div>
  </div>
</template>

<script>
import FieldInputOptionsMixin from '../FieldInputOptionsMixin';

export default {
  mixins: [FieldInputOptionsMixin],
  fieldInputOptions: {
    path: 'input',
    keys: ['type', 'options', 'ruleset'],
  },
};
</script>

<style></style>
