<template>
  <div
    class="templateContainer"
    :class="{ coloanaSpatiere: localTemplate.spacingColumn }"
    :style="[
      {
        width: `${localTemplate.width}%`,
        height: localTemplate?.spacingColumn
          ? `${localTemplate.height}px`
          : 'auto',
      },
      localTemplate.inlineStyle,
    ]"
  >
    <div class="controlButtons">
      <a-tooltip
        :mouseLeaveDelay="0"
        title="Genereaza datele acestei coloane doar pentru export fisier XML (in frontend aceasta nu va fi disponibila)"
      >
        <a-switch
          default-checked
          v-model:checked="localTemplate.renderOnlyForXML"
        >
          <template #checkedChildren>
            <CheckOutlined />
          </template>
          <template #unCheckedChildren>
            <CloseOutlined />
          </template>
        </a-switch>
      </a-tooltip>

      <a-tooltip title="Click and drag" v-if="localTemplate.depth > 1"
        ><a-button size="small" class="handle"><DragOutlined /></a-button
      ></a-tooltip>
      <a-tooltip title="Delete column" v-if="localTemplate.depth > 1"
        ><a-button @click="deleteColumn" size="small"
          ><DeleteOutlined /></a-button
      ></a-tooltip>
      <a-tooltip title="Edit column" v-if="!localTemplate?.spacingColumn"
        ><a-button @click="$refs.recAddEditColumn.showModal(true)" size="small"
          ><EditOutlined /></a-button
      ></a-tooltip>
      <a-button
        v-if="
          !localTemplate.columnGroups?.length &&
          !localTemplate?.spacingColumn &&
          !localTemplate?.object
        "
        @click="$refs.recInsEl.showModal()"
        size="small"
        >Insereaza element</a-button
      >
      <a-button
        v-if="!localTemplate?.spacingColumn && !localTemplate?.object"
        @click="$refs.recAddEditColumn.showModal()"
        size="small"
        >Adauga o coloana</a-button
      >
    </div>

    <div
      v-if="!localTemplate?.spacingColumn"
      style="font-size: 0.8em; margin-bottom: 20px"
    >
      Adancime: {{ localTemplate.depth }}<br />
      Name: {{ localTemplate.name }}<br />
      Width: {{ localTemplate.width }}%<br />
      Css classes: {{ localTemplate.cssClasses }}<br />
      Inline style: {{ localTemplate.inlineStyle }}
    </div>

    <div
      class="elementAdaugat"
      v-if="
        localTemplate?.object &&
        selectedBlueprint &&
        ['item', 'list'].includes(localTemplate?.object?.view?.type)
      "
    >
      <ElementConfiguration
        :mainBlueprintId="mainBlueprintId"
        :selected-blueprint="selectedBlueprint"
        :mainTemplate="mainTemplate"
        :template="localTemplate"
        :templates="templates"
        :blueprints="blueprints"
        @deleteElement="deleteElement"
      ></ElementConfiguration>
    </div>
    <div
      class="elementAdaugat"
      v-else-if="['html', 'menu'].includes(localTemplate?.object?.view?.type)"
    >
      <HtmlElementConfiguration
        :mainBlueprintId="mainBlueprintId"
        :template="localTemplate"
        @deleteElement="deleteElement"
      ></HtmlElementConfiguration>
    </div>
    <a-spin v-else-if="localTemplate?.object"
      ><LoadingOutlined spin :style="{ fontSize: '24px' }"
    /></a-spin>

    <div class="childElements" v-if="localTemplate.columnGroups?.length">
      <draggable
        v-model="localTemplate.columnGroups"
        item-key="uid"
        group="templates"
        @start="drag = true"
        @end="drag = false"
        handle=".handle"
        :swapThreshold="0.1"
      >
        <template #item="{ element }">
          <TemplateRecursion
            :mainTemplate="mainTemplate"
            :template="element"
            :templates="templates"
            :blueprints="blueprints"
            :mainBlueprintId="mainBlueprintId"
            @deleteColumn="deleteColumnEvent"
          >
          </TemplateRecursion>
        </template>
      </draggable>
    </div>

    <RecursionAddEditColumn
      ref="recAddEditColumn"
      :blueprints="blueprints"
      :template="localTemplate"
    ></RecursionAddEditColumn>
    <RecursionInsertElement
      ref="recInsEl"
      :blueprints="blueprints"
      :template="localTemplate"
      :menus="menus"
      :modalAddObjectVisible="modalAddObjectVisible"
    ></RecursionInsertElement>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import {
  MenuGetters,
  MenuActions,
} from '@menuBuilder/shared/menuBuilder.store';
import ElementConfiguration from '@/apps/templateManagement/views/Builder/components/ElementConfiguration.vue';
import HtmlElementConfiguration from '@/apps/templateManagement/views/Builder/components/HtmlElementConfiguration.vue';
import { TemplatesActions } from '@/apps/templateManagement/shared/templateManagement.store';
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  DragOutlined,
  EditOutlined,
  LoadingOutlined,
} from '@ant-design/icons-vue';
import RecursionInsertElement from './RecursionInsertElement.vue';
import RecursionAddEditColumn from './RecursionAddEditColumn.vue';

export default {
  name: 'TemplateRecursion',
  props: [
    'mainBlueprintId',
    'mainTemplate',
    'template',
    'templates',
    'blueprints',
  ],
  emits: ['deleteColumn'],
  components: {
    draggable,
    ElementConfiguration,
    HtmlElementConfiguration,
    RecursionInsertElement,
    RecursionAddEditColumn,
    CheckOutlined,
    CloseOutlined,
    LoadingOutlined,
    DeleteOutlined,
    DragOutlined,
    EditOutlined,
  },
  data() {
    return {
      editingColumn: false,

      spacingColumn: false,
      columnName: null,
      columnWidth: null,
      columnHeight: null,
      columnCssClasses: null,
      columnInlineStyle: null,
      tabsView: false,
      tabViewType: '',
      defaultBackground: true,

      modalAddObjectVisible: false,
      localTemplate: this.template ?? null,
    };
  },
  async created() {
    await MenuActions.init();
    this.localTemplate = this.template ?? null;
  },
  watch: {
    template(value) {
      if (this.localTemplate === null) {
        this.localTemplate = value;
      }
    },
    localTemplate: {
      // update stored template.
      deep: true,
      handler(value) {
        TemplatesActions.setTemplate(value);
      },
    },
  },
  computed: {
    menus: MenuGetters.getMenus,

    selectedBlueprint() {
      if (this.localTemplate.object) {
        return this.blueprints.find(
          bp => bp._id === this.localTemplate.object.blueprintId
        );
      }
      return null;
    },
    listOfBpIds() {
      return this.blueprints.map(bp => bp._id);
    },
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    deleteColumn() {
      this.$emit('deleteColumn', this.localTemplate);
    },
    deleteColumnEvent(val) {
      this.localTemplate.columnGroups = this.localTemplate.columnGroups.filter(
        item => item !== val
      );
    },
    deleteElement() {
      this.localTemplate.object = null;
    },
  },
};
</script>

<style scoped>
.templateContainer {
  border: 2px dashed #cbcbcb;
  margin-bottom: 5px;
  padding: 5px;
  position: relative;
}
.templateContainer.coloanaSpatiere {
  background-color: #efefef;
}
.controlButtons {
  display: flex;
  justify-content: right;
  flex-wrap: wrap;
}
.controlButtons button {
  margin-left: 3px;
  margin-bottom: 3px;
}
.childElements > div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.elementAdaugat {
  background-color: #dfffee;
  padding: 15px 5px;
}
</style>
