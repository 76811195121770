<template>
  <div v-if="input.elementStructure.insertValueMode !== 'choices'">
    <label style="font-weight: 500; margin-bottom: 10px; display: block">
      Array Element options
    </label>
    <div style="margin-left: 15px">
      <component
        v-if="elementTypeOptionsComponent"
        :is="elementTypeOptionsComponent"
        :fieldId="fieldInput._id"
        v-model:value="input.elementStructure.options"
      />
    </div>
  </div>
</template>

<script>
import FieldInputOptionsMixin from '../../FieldInputOptionsMixin';

import StringTypeOptions from './StringTypeOptions.vue';
import NumberTypeOptions from './NumberTypeOptions.vue';
import DateTypeOptions from './DateTypeOptions.vue';

const ELEMENT_TYPES = ['boolean', 'string', 'integer', 'decimal', 'date'];

export default {
  mixins: [FieldInputOptionsMixin],
  fieldInputOptions: {
    path: 'structure',
    keys: ['elementStructure'],
  },
  data() {
    return {
      ELEMENT_TYPES,
    };
  },
  computed: {
    elementTypeOptionsComponent() {
      if (this.input.elementStructure.type === 'string') {
        return StringTypeOptions;
      }
      if (this.input.elementStructure.type === 'number') {
        return NumberTypeOptions;
      }
      if (this.input.elementStructure.type === 'date') {
        return DateTypeOptions;
      }
      return null;
    },
  },
};
</script>

<style></style>
