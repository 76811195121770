<template>
  <a-select
    mode="tags"
    style="width: 100%"
    placeholder="Select Users"
    @change="onSelectChange"
  >
    <a-select-option v-for="user in users" :value="user._id" :key="user._id">
      {{ user.email }}
    </a-select-option>
  </a-select>
</template>

<script>
import { userApi } from '@userManagement/api';

export default {
  emits: ['select'],
  data() {
    return {
      users: [],
    };
  },
  created() {
    this.fetchUsers();
  },
  methods: {
    async fetchUsers() {
      this.users = await userApi.getSubtenantUsersBySlugs();
    },
    onSelectChange(selectedUsers) {
      this.$emit('select', selectedUsers);
    },
  },
};
</script>

<style></style>
