<template>
  <div>
    <h3>Blueprint</h3>
    <a-row :gutter="[8, 8]">
      <a-col :span="11">
        <a-tree-select
          @change="selectBlueprint"
          v-model:value="updatedValue.blueprintId"
          show-search
          style="min-width: 100%"
          :tree-data="treeBlueprint"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          placeholder="Select blueprint..."
          allow-clear
        ></a-tree-select>
      </a-col>
      <a-col :span="2" v-if="loadingBlueprintParentInstances">
        <div style="text-align: center; padding-top: 6px"><a-spin /></div>
      </a-col>
      <a-col
        :span="2"
        v-if="
          blueprintParentInstances &&
          blueprintParentInstances.length &&
          !loadingBlueprintParentInstances
        "
      >
        <div style="text-align: center; padding-top: 6px">ON</div>
      </a-col>
      <a-col
        :span="11"
        v-if="blueprintParentInstances && blueprintParentInstances.length"
      >
        <a-select
          :value="
            updatedValue.blueprintParentInstanceId == null
              ? undefined
              : updatedValue.blueprintParentInstanceId
          "
          @change="selectParentInstace"
          :placeholder="blueprintParentInstances[0].parentBlueprintName"
          allowClear
          style="width: 100%"
        >
          <a-select-option
            v-for="parentInstance in blueprintParentInstances"
            :key="parentInstance._id"
            :value="parentInstance._id"
          >
            {{ parentInstance.name }}
          </a-select-option>
        </a-select>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  name: 'BlueprintComponent',
  props: ['type', 'modelValue'],
  emits: ['update:modelValue'],
  data() {
    return {
      loading: false,

      // TODO: refactor this, remove "parentInstances" because they do not exist anymore
      blueprintParentInstances: [],
      loadingBlueprintParentInstances: false,

      updatedValue: {
        blueprintId: null,
        blueprintParentInstanceId: null,
      },
    };
  },
  async mounted() {
    // await SharedBlueprintActions.fetchBlueprintTree({ includeFields: true });
    if (this.modelValue) {
      this.updatedValue = this.modelValue;
    }
  },

  computed: {
    // TODO: refactor this component, remove the need of treeBlueprint since it doesn't exist anymore
    treeBlueprint: () => {
      return null;
    },
  },

  watch: {
    updatedValue: {
      deep: true,
      handler() {
        this.$emit('update:modelValue', {
          blueprintId: this.updatedValue.blueprintId,
          blueprintParentInstanceId:
            this.updatedValue.blueprintParentInstanceId,
        });
      },
    },
  },

  methods: {
    async selectBlueprint(blueprintId) {
      await this.getParentInstancesForBlueprint(blueprintId);
    },

    selectParentInstace(blueprintParentInstanceId) {
      this.updatedValue.blueprintParentInstanceId = blueprintParentInstanceId;
    },

    async getParentInstancesForBlueprint(blueprintId) {
      if (blueprintId) {
        this.loadingBlueprintParentInstances = true;
        // const instances = await blueprintInstanceApi.getParentInstancesForBlueprint(blueprintId);
        // if(instances){
        //     this.loadingBlueprintParentInstances = false;
        //     this.blueprintParentInstances = instances;
        //     return instances;
        // }
        // return [];
      }
    },
  },
  components: {},
};
</script>
