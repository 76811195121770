<template>
  <a-drawer :title="`Api key's Management`" width="900px" :open="visible">
    <div style="margin-bottom: 15px">
      <a-button @click="newApiKey()" type="primary" style="margin-left: 10px">
        New
      </a-button>
    </div>
    <a-table :columns="tableColumns" :data-source="apiKeys">
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'apiKey'">
          <span style="font-size: 12px"> {{ record.apiKey }}</span>
        </template>
        <template v-if="column.key === 'ip'">
          <template v-for="(item, index) in record.ip" v-bind:key="index">
            <div style="font-size: 12px">{{ item }}</div>
          </template>
        </template>
        <template v-if="column.key === 'actions'">
          <div>
            <a-button
              @click="() => revokeAccess(record)"
              size="small"
              v-if="record.active"
              class="mr-2"
              style="width: 60px"
            >
              Revoke
            </a-button>
            <a-button
              @click="() => grantAcces(record)"
              size="small"
              class="mr-2"
              style="width: 60px"
              v-if="!record.active"
            >
              Grant
            </a-button>
            <a-button
              @click="() => setSelectedRow(record)"
              size="small"
              icon="edit"
              class="mr-2"
              style="margin-top: 10px; width: 60px; display: block"
            >
            </a-button>
          </div>
        </template>
      </template>
    </a-table>
    <a-modal
      :footer="false"
      :width="'1200px'"
      :destroyOnClose="true"
      :maskClosable="false"
      v-model:open="showCreateEdit"
      @cancel="showCreateEdit = false"
      :title="rankLabel"
    >
      <CreateEditApiKey
        v-model="selectedRow"
        :subtenantId="subtenantId"
        :subtenantSlug="subtenantSlug"
        @reloadTable="init"
        @cancel="cancel"
      />
    </a-modal>
  </a-drawer>
</template>

<script>
import ApiKey from '@/apps/tenants/api/apiKeyManagement.api';
import CreateEditApiKey from './components/CeateEditApiKey.vue';

const tableColumns = [
  {
    title: 'Api Key',
    key: 'apiKey',
    dataIndex: 'apiKey',
    filterMultiple: false,
    scopedSlots: { customRender: 'apiKey' },
  },
  {
    title: 'Secret password',
    key: 'secret',
    dataIndex: 'secret',
  },
  {
    title: 'IP',
    key: 'ip',
    dataIndex: 'ip',
    scopedSlots: { customRender: 'ip' },
    sorter: (a, b) => (a?.ip?.length ?? '') - (b?.ip?.length ?? ''),
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Actions',
    key: 'actions',
    scopedSlots: { customRender: 'actions' },
    filters: [
      { text: 'Active', value: 1 },
      { text: 'Revoked', value: 0 },
    ],
    filterMultiple: false,
    onFilter: (value, record) => (record.active & 1) === value,
  },
];

export default {
  props: [
    'visible',
    'rank',
    'tenantId',
    'appId',
    'subtenantId',
    'subtenantSlug',
  ],
  components: {
    CreateEditApiKey,
  },
  data() {
    return {
      InvitationManagerProps: {
        visible: false,
      },
      tableColumns,
      apiKeys: [],
      rankLabel: 'Create',
      showCreateEdit: false,
      roleList: [],
      selectedRow: null,
    };
  },
  created() {
    this.init();
  },
  methods: {
    async newApiKey() {
      this.selectedRow = null;
      this.showCreateEdit = true;
    },
    async setSelectedRow(row) {
      row.key = row.apiKey;
      if (row) {
        this.rankLabel = 'Edit';
      } else {
        this.rankLabel = 'Create';
      }
      this.selectedRow = row;
      this.showCreateEdit = true;
    },
    async init() {
      this.fetchKeyList();
    },
    async fetchKeyList() {
      const apikeys = await ApiKey.getAll(this.subtenantId);
      apikeys.forEach(obj => {
        obj.apiKey = obj.key;
        obj.key = obj._id;
        obj.edit = 'edit';
      });
      this.apiKeys = apikeys;
    },
    async revokeAccess(row) {
      row.active = false;
      row.subtenantId = this.subtenantId;
      row.key = row.apiKey;
      await ApiKey.putOne(row);
      this.init();
    },
    cancel() {
      this.showCreateEdit = false;
    },
    async grantAcces(row) {
      row.active = true;
      row.subtenantId = this.subtenantId;
      row.key = row.apiKey;
      await ApiKey.putOne(row);
      this.init();
    },
  },
};
</script>

<style></style>
