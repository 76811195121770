<template>
  <div v-if="!hideUserFeedback" class="UserFeedback">
    <a-modal
      v-if="isModalVisible"
      :open="isModalVisible"
      @cancel="hideModal"
      okText="Send"
      @ok="sendFeedback"
    >
      <div style="margin-top: 25px; margin-bottom: 25px">
        <label style="margin-bottom: 10px; display: block; font-weight: 500">
          Let us know what went wrong or what can be improved
        </label>
        <a-textarea
          v-model:value="input.description"
          placeholder="Enter your message here..."
          :rows="7"
          auto-focus
        ></a-textarea>
      </div>
    </a-modal>
    <a-button size="small" @click="showModal"
      ><span style="font-size: 12px"
        ><MessageOutlined /> Send us feedback</span
      ></a-button
    >
  </div>
</template>

<script>
import { MessageOutlined } from '@ant-design/icons-vue';
import { userApi } from '@userManagement/api';
import { message } from 'ant-design-vue';

export default {
  data() {
    return {
      input: {
        description: '',
      },
      isModalVisible: false,
    };
  },
  components: {
    MessageOutlined,
  },
  computed: {
    hideUserFeedback() {
      return this.$route.query['no-feedback'];
    },
  },
  methods: {
    showModal() {
      this.isModalVisible = true;
    },
    hideModal() {
      this.isModalVisible = false;
    },
    async sendFeedback() {
      if (!this.input.description || this.input.description.trim() === '') {
        message.warning('You must enter a message to send the feedback.');
        return;
      }
      await userApi.postFeedback(this.input.description);
      this.input.description = '';
      message.success('Thank you! Your feedback has been successfully sent.');
      this.hideModal();
    },
  },
};
</script>

<style></style>
