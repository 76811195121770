import axios from 'axios';

import { SlugGetters } from '@/slug.store';

const { getAppUrl, getAppUrlWithGlobal } = SlugGetters;

const getAllForApplication = async () => {
  const response = await axios.get(
    `${getAppUrlWithGlobal()}/role-management/roles`
  );
  return response.data;
};

const postOne = async ({ roleInput }) => {
  const response = await axios.post(
    `${getAppUrl()}/role-management/roles`,
    roleInput
  );
  return response.data;
};

const patchOne = async ({ roleId, roleInput }) => {
  const response = await axios.patch(
    `${getAppUrl()}/role-management/roles/${roleId}`,
    roleInput
  );
  return response.data;
};

const patchPermission = async ({ roleId, blueprintId, permissionInput }) => {
  const response = await axios.patch(
    `${getAppUrl()}/role-management/roles/${roleId}/permission/${blueprintId}`,
    permissionInput
  );
  return response.data;
};

const deleteOne = async ({ roleId }) => {
  const response = await axios.delete(
    `${getAppUrl()}/role-management/roles/${roleId}`
  );
  return response.data;
};

export default {
  getAllForApplication,
  postOne,
  patchOne,
  deleteOne,
  patchPermission,
};
