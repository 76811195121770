<template>
  <loading-spinner v-if="loading" />

  <div v-else>
    <div
      class="saas_card padd-10"
      v-for="authRest in mainReqAuthList"
      :key="authRest._id"
    >
      <p style="font-weight: bold; margin-bottom: 5px">{{ authRest.name }}</p>

      <div v-if="authRest.response && authRest.response?.data">
        <div
          class="saas_card padd-5 mb-1"
          v-for="(value, key) in authRest.response.data"
          :key="key"
        >
          <a-button
            type="primary"
            size="small"
            @click="emitSelectedAuthToken(authRest._id, 'response', key, value)"
            >Use this token</a-button
          ><br />
          <span>{{ key }}: {{ value }}</span>
        </div>
      </div>

      <div v-if="authRest.returnResponse && authRest.returnResponse?.data">
        <div
          class="saas_card padd-5 mb-1"
          v-for="(value, key) in authRest.returnResponse.data"
          :key="key"
        >
          <a-button
            type="primary"
            size="small"
            @click="
              emitSelectedAuthToken(authRest._id, 'returnResponse', key, value)
            "
            >Use this token</a-button
          ><br />
          <span>{{ key }}: {{ value }}</span>
        </div>
      </div>

      <ChainedAuthTokensRecursive
        v-if="childAuthRequest(authRest._id)"
        :authFullList="authFullList"
        :authRest="childAuthRequest(authRest._id)"
        @tokenSelectedRecursive="emitRecursiveSelectedAuthToken"
      />
    </div>
  </div>
</template>

<script>
import DataAuthRestApi from '@/apps/dataAuthRest/api/dataAuthRest.api';
import ChainedAuthTokensRecursive from './chainedAuthTokensRecursive.vue';

export default {
  props: [],
  emits: ['tokenSelected'],
  components: {
    ChainedAuthTokensRecursive,
  },

  data() {
    return {
      loading: true,
      authFullList: [],
    };
  },

  computed: {
    mainReqAuthList() {
      if (this.authFullList.length) {
        return this.authFullList.filter(auth => auth.mainRequest);
      }

      return this.authFullList;
    },
  },

  watch: {},

  async mounted() {
    this.authFullList = await DataAuthRestApi.getAllWithChainedRequests();
    this.loading = false;
  },

  methods: {
    childAuthRequest(authReqId) {
      return this.authFullList.find(
        auth => auth.parentDataAuthRestId === authReqId
      );
    },
    emitSelectedAuthToken(authRestId, responseType, key, value) {
      this.$emit('tokenSelected', { authRestId, responseType, key, value });
    },
    emitRecursiveSelectedAuthToken(payload) {
      this.emitSelectedAuthToken(
        payload.authRestId,
        payload.responseType,
        payload.key,
        payload.value
      );
    },
  },
};
</script>
