import axios from 'axios';

import { SlugGetters } from '@/slug.store';

const { getAppUrl } = SlugGetters;

const postOne = async (providerId, sourceId, fieldId, data) => {
  try {
    const response = await axios.post(
      `${getAppUrl()}/providers/${providerId}/sources/${sourceId}/fields/${fieldId}/transforms`,
      data
    );
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

const putOne = async (providerId, sourceId, fieldId, transformId, data) => {
  try {
    const response = await axios.put(
      `${getAppUrl()}/providers/${providerId}/sources/${sourceId}/fields/${fieldId}/transforms/${transformId}`,
      data
    );
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

const deleteOne = async (providerId, sourceId, fieldId, transformId) => {
  try {
    await axios.delete(
      `${getAppUrl()}/providers/${providerId}/sources/${sourceId}/fields/${fieldId}/transforms/${transformId}`
    );
    return true;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

export default {
  deleteOne,
  postOne,
  putOne,
};
