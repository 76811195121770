<template>
  <div>
    <a-modal
      v-model:open="modalView"
      title="Filters"
      :width="1200"
      @ok="handleAddEditFilter"
      @cancel="close"
    >
      <a-card
        v-for="(filter, index) in template.object?.view?.filters"
        :key="index"
        style="margin-bottom: 10px"
      >
        <a-row>
          <a-col :span="24" style="padding: 3px 5px">
            <div style="margin-bottom: 10px">
              <a-button size="small" @click="removeObjectFilter(filter)"
                ><MinusOutlined /> Remove</a-button
              >
              <a-button
                class="ml-1"
                size="small"
                @click="editObjectFilter(filter)"
                ><EditOutlined /> Edit</a-button
              >
            </div>

            fromValue: {{ filter?.fromValue }}<br />
            fromBlueprintId: {{ filter?.fromBlueprintId }}<br />
            fromFieldId: {{ filter?.fromFieldId }}<br />
            operation: {{ filter?.operation }}<br />
            comparation: {{ filter?.comparation }}<br />
            acceptEmpty: {{ filter?.acceptEmpty }}<br />
            acceptNull: {{ filter?.acceptNull }}<br />
            useManuallyValue: {{ filter?.useManuallyValue }}<br />
            toFieldFromOtherBlueprint: {{ filter?.toFieldFromOtherBlueprint
            }}<br />
          </a-col>
        </a-row>
      </a-card>

      <div style="margin-top: 30px">
        <a-button size="small" @click="modalAddEditObjectFilter = true"
          ><PlusOutlined /> Add filter</a-button
        >
      </div>

      <template #footer>
        <a-button key="submit" type="primary" @click="close"> Ok </a-button>
      </template>
    </a-modal>

    <a-modal
      v-if="modalAddEditObjectFilter"
      v-model:open="modalAddEditObjectFilter"
      :title="`${editedObjectFilter ? 'Edit' : 'Add'} filter`"
      :width="900"
      @ok="handleAddEditFilter"
      @cancel="resetAddEditFields"
    >
      <a-row class="mb-3">
        <a-col :span="24" class="mb-3">
          <div>
            <a-checkbox
              class="m-0 mr-3"
              v-model:checked="includeInCreateInstance"
              >Include in create instance</a-checkbox
            >
          </div>
        </a-col>

        <a-col :span="12" style="padding: 0 5px">
          <h5 class="mb-3">From</h5>

          <div class="mb-3">
            <a-checkbox
              class="m-0"
              v-model:checked="toFieldFromOtherBlueprint"
              style="margin-top: 5px"
              >Is from another Business Object</a-checkbox
            >
          </div>
          <ReferenceFields
            v-if="blueprints"
            v-model="toReferenceFieldIds"
            :defaultSettings="template.object.view.default"
            :blueprints="blueprints"
            :selectedBlueprint="selectedBlueprint"
            :toFieldFromOtherBlueprint="toFieldFromOtherBlueprint"
            @toField="
              value => {
                toField = value;
              }
            "
          />
          <div class="card mt-3">
            <div class="card-body" style="padding: 10px">
              <a-row>
                <a-col :span="8" style="padding: 3px 5px">
                  Operation:<br />
                  <a-select v-model:value="operation" style="width: 100%">
                    <a-select-option value="and">AND</a-select-option>
                    <a-select-option value="or">OR</a-select-option>
                  </a-select>
                </a-col>

                <a-col :span="16" style="padding: 3px 5px">
                  Comparation:<br />
                  <a-select v-model:value="comparation" style="width: 100%">
                    <a-select-option value="EQUAL">EQUAL</a-select-option>
                    <a-select-option value="NOT_EQUAL"
                      >NOT EQUAL</a-select-option
                    >
                    <a-select-option value="GREATER">GREATER</a-select-option>
                    <a-select-option value="GREATER_OR_EQUAL"
                      >GREATER OR EQUAL</a-select-option
                    >
                    <a-select-option value="LOWER">LOWER</a-select-option>
                    <a-select-option value="LOWER_OR_EQUAL"
                      >LOWER OR EQUAL</a-select-option
                    >
                    <a-select-option value="LIKE">LIKE</a-select-option>
                    <a-select-option value="IS_NULL">IS NULL</a-select-option>
                    <a-select-option value="IS_NOT_NULL"
                      >IS NOT NULL</a-select-option
                    >
                  </a-select>
                </a-col>
              </a-row>
            </div>
          </div>
        </a-col>

        <a-col :span="12" style="padding: 0 5px">
          <h5 class="mb-3">To</h5>

          <div class="mb-3">
            <a-checkbox class="m-0 mr-3" v-model:checked="acceptNull"
              >Accept null value</a-checkbox
            >
            <a-checkbox class="m-0" v-model:checked="acceptEmpty"
              >Accept empty value</a-checkbox
            >
          </div>
          <div class="card mb-3">
            <div class="card-body" style="padding: 10px">
              <div>
                <a-checkbox
                  class="m-0"
                  v-model:checked="useManuallyValue"
                  style="margin-top: 5px"
                  >Use manually value</a-checkbox
                >
              </div>

              <div v-if="useManuallyValue" class="mt-3">
                <template v-if="toField && toField.type === 'date'">
                  <a-checkbox
                    class="ml-2"
                    :disabled="!useManuallyValue"
                    v-model:checked="manuallyCurrentDate"
                    >Current date</a-checkbox
                  >
                  <template v-if="!manuallyCurrentDate">
                    <a-date-picker
                      :disabled="!useManuallyValue"
                      style="width: 150px; margin-top: 5px"
                      :readOnly="true"
                      :format="toField?.dateFormat ?? 'DD-MM-YYYY'"
                      v-model:value="fromValue"
                      :valueFormat="toField?.dateFormat ?? 'DD-MM-YYYY'"
                    />
                  </template>
                  <a-input-number
                    :disabled="!useManuallyValue"
                    style="width: 80px"
                    class="ml-2"
                    v-model:value="manuallyDateDays"
                  />
                  days
                </template>
                <template v-else>
                  <a-input
                    v-model:value="fromValue"
                    placeholder="From value"
                    :disabled="!useManuallyValue"
                  ></a-input>
                </template>
              </div>
            </div>
          </div>

          <div class="card" v-if="!useManuallyValue">
            <div class="card-body" style="padding: 10px">
              <div class="mb-2">
                <a-checkbox
                  class="m-0"
                  @change="changeAnotherMainBlueprint"
                  style="margin-top: 5px"
                  >Another blueprint</a-checkbox
                >
              </div>
              <div v-if="anotherMainBlueprint" class="mb-2">
                <div>Blueprint:</div>
                <a-select
                  v-model:value="fromBlueprintId"
                  :options="blueprintOptions"
                  style="width: 100%"
                ></a-select>
              </div>

              <b>{{ mainBlueprint?.name }}</b> fields:<br />
              <div>
                <a-select v-model:value="fromFieldId" style="width: 100%">
                  <a-select-option value="_id">ID - (number)</a-select-option>
                  <a-select-option value="_ownerId"
                    >Owner - (string)</a-select-option
                  >
                  <a-select-option
                    v-for="field of fromFieldIdOptions"
                    :key="field.value"
                    :value="field.value"
                  >
                    {{ field.label }} ({{ field?.type }})
                  </a-select-option>
                  <a-select-option value="_createdAt"
                    >Created At - (date)</a-select-option
                  >
                </a-select>
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import ReferenceFields from '@/apps/templateManagement/views/Builder/components/ReferenceFields.vue';
import { TemplatesActions } from '@/apps/templateManagement/shared/templateManagement.store';
import {
  EditOutlined,
  MinusOutlined,
  PlusOutlined,
} from '@ant-design/icons-vue';
import dayjs from 'dayjs';

export default {
  name: 'ElementFilters',
  props: [
    'mainBlueprintId',
    'blueprints',
    'selectedBlueprint',
    'template',
    'modalConfigObjectFilters',
  ],
  emits: ['update:modalConfigObjectFilters'],
  components: {
    ReferenceFields,
    EditOutlined,
    MinusOutlined,
    PlusOutlined,
  },
  data() {
    return {
      modalView: this.modalConfigObjectFilters,
      anotherMainBlueprintStatus: false,
      modalAddEditObjectFilter: false,
      acceptNull: false,
      acceptEmpty: false,
      useManuallyValue: false,
      fromValue: '',
      fromBlueprintId: null,
      fromFieldId: null,
      againstFieldId: null,
      operation: null,
      comparation: null,
      isFromReference: false,
      referenceFieldId: null,
      referenceBlueprintId: null,
      editedObjectFilter: null,
      manuallyDateDays: 0,
      manuallyCurrentDate: false,
      //
      includeInCreateInstance: true,
      toFieldFromOtherBlueprint: false,
      toReferenceFieldIds: [],
      toField: [],
    };
  },
  methods: {
    close() {
      this.$emit('update:modalConfigObjectFilters', false);
    },
    changeAnotherMainBlueprint(e) {
      const status = e.target.checked;
      if (!status) {
        this.fromBlueprintId = null;
      }
      this.anotherMainBlueprintStatus = status;
    },
    handleAddEditFilter() {
      const localTemplate = this.template;
      if (this.editedObjectFilter) {
        localTemplate.object.view.filters =
          this.template.object.view.filters.map(filter => {
            if (filter === this.editedObjectFilter) {
              filter.acceptNull = this.acceptNull;
              filter.acceptEmpty = this.acceptEmpty;
              filter.useManuallyValue = this.useManuallyValue;
              filter.includeInCreateInstance = this.includeInCreateInstance;

              filter.toFieldFromOtherBlueprint = this.toFieldFromOtherBlueprint;
              filter.toReferenceFieldIds = this.toReferenceFieldIds;

              filter.fromValue = this.fromValue;
              filter.fromFieldId = this.fromFieldId;
              filter.fromBlueprintId = this.fromBlueprintId;

              filter.toFieldFromOtherBlueprint = this.toFieldFromOtherBlueprint;

              filter.operation = this.operation;
              filter.comparation = this.comparation;

              filter.manuallyDateDays = this.manuallyDateDays;
              filter.manuallyCurrentDate = this.manuallyCurrentDate;
            }

            return filter;
          });
      } else {
        localTemplate.object.view.filters.push({
          useManuallyValue: this.useManuallyValue,
          includeInCreateInstance: this.includeInCreateInstance,
          fromBlueprintId: this.fromBlueprintId,
          fromFieldId: this.fromFieldId,
          operation: this.operation,
          acceptNull: this.acceptNull,
          acceptEmpty: this.acceptEmpty,
          comparation: this.comparation,

          toFieldFromOtherBlueprint: this.toFieldFromOtherBlueprint,
          toReferenceFieldIds: this.toReferenceFieldIds,

          fromValue: this.fromValue,
          manuallyDateDays: this.manuallyDateDays,
          manuallyCurrentDate: this.manuallyCurrentDate,
        });
      }

      this.modalAddEditObjectFilter = false;
      TemplatesActions.setTemplate(localTemplate);
      this.resetAddEditFields();
    },
    editObjectFilter(filter) {
      this.editedObjectFilter = filter;

      this.acceptNull = filter?.acceptNull ?? false;
      this.acceptEmpty = filter?.acceptEmpty ?? false;
      this.useManuallyValue = filter?.useManuallyValue ?? false;
      this.includeInCreateInstance = filter?.includeInCreateInstance ?? true;

      this.fromBlueprintId = filter?.fromBlueprintId ?? null;
      this.fromFieldId = filter?.fromFieldId ?? null;

      this.operation = filter?.operation ?? '';
      this.comparation = filter?.comparation ?? '';

      this.toFieldFromOtherBlueprint =
        filter?.toFieldFromOtherBlueprint ?? false;
      this.toReferenceFieldIds = filter?.toReferenceFieldIds ?? [];

      this.fromValue = filter?.fromValue ?? '';
      this.manuallyDateDays = filter?.manuallyDateDays ?? 0;
      this.manuallyCurrentDate = filter?.manuallyCurrentDate ?? false;

      this.modalAddEditObjectFilter = true;
    },
    removeObjectFilter(filter) {
      const localTemplate = this.template;
      localTemplate.object.view.filters =
        this.template.object.view.filters.filter(f => f !== filter);
      TemplatesActions.setTemplate(localTemplate);
    },
    resetAddEditFields() {
      this.acceptNull = false;
      this.acceptEmpty = false;
      this.useManuallyValue = false;
      this.includeInCreateInstance = true;

      this.fromBlueprintId = null;
      this.fromFieldId = null;

      this.operation = null;
      this.comparation = null;

      this.editedObjectFilter = null;

      this.toFieldFromOtherBlueprint = false;
      this.toReferenceFieldIds = [];

      this.fromValue = '';
      this.manuallyDateDays = 0;
      this.manuallyCurrentDate = false;
    },
  },
  computed: {
    dayjs() {
      return dayjs;
    },
    mainBlueprint() {
      if (this.fromBlueprintId) {
        return this.blueprints.find(bp => bp._id === this.fromBlueprintId);
      }
      return this.blueprints.find(bp => bp._id === this.mainBlueprintId);
    },
    blueprintOptions() {
      return this.blueprints.map(bp => {
        return {
          value: bp.id,
          label: bp.name,
        };
      });
    },
    anotherMainBlueprint() {
      return this.fromBlueprintId || this.anotherMainBlueprintStatus;
    },

    fromFieldIdOptions() {
      return this.mainBlueprint.fields.map(field => {
        return {
          value: field.id,
          label: field.label,
          type:
            field.structure?.elementStructure?.type ??
            field?.structure?.type ??
            '-',
        };
      });
    },
  },
};
</script>
