<template>
  <div v-if="localLayout">
    <div class="mt-3 padd-10">
      <div class="row">
        <div class="col-12 col-md-3" v-if="localLayout.leftColumn.length">
          <div
            v-for="menu of localLayout.leftColumn"
            :key="menu._id"
            class="mb-3"
          >
            <RecursiveMenu
              position="l"
              v-if="
                menu.active &&
                menu.subtenantId.includes(tenantData.subtenant._id)
              "
              :depth="0"
              :list="menu.menuBuilder"
            />
          </div>
        </div>
        <div class="col-12" :class="centerColumnSize">
          <div v-for="menu of localLayout.centerColumn" :key="menu._id">
            <template v-if="menu._id === '_content'">
              <TemplateDataSystemBuilder
                :menuItem="menuItem"
                v-if="menuItem && menuItem.templateId && menuItem.active"
              />
            </template>
            <template v-else>
              <RecursiveMenu
                position="c"
                v-if="
                  menu.active &&
                  menu.subtenantId.includes(tenantData.subtenant._id)
                "
                :depth="0"
                :list="menu.menuBuilder"
              />
            </template>
          </div>
        </div>
        <div class="col-12 col-md-3" v-if="localLayout.rightColumn.length">
          <div v-for="menu of localLayout.rightColumn" :key="menu._id">
            <RecursiveMenu
              position="r"
              v-if="
                menu.active &&
                menu.subtenantId.includes(tenantData.subtenant._id)
              "
              :depth="0"
              :list="menu.menuBuilder"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TemplateDataSystemBuilder from '@/apps/templateDataSystem/views/Builder/TemplateDataSystemBuilder.vue';

import {
  MenuLayoutGetters,
  MenuLayoutActions,
} from '@templateDataSystem/shared/menuLayout.store';
import { findRecursive } from '@/core/utils/array-manipulation';

import { TenantsGetters } from '@tenants/shared/tenants.store';
import { roleSubtenantApi } from '@roleManagement/api';
// eslint-disable-next-line import/no-cycle
import router from '@/router';
import RecursiveMenu from './RecursiveMenu.vue';

export default {
  name: 'TemplateLayoutBuilder',
  props: [],
  components: {
    RecursiveMenu,
    TemplateDataSystemBuilder,
  },
  data() {
    return {
      localLayout: null,
      menuItem: null,
      menuItemId: this.$route.params.menuItemId ?? null,
    };
  },
  computed: {
    layout: MenuLayoutGetters.getMenuLayout,
    tenantData: TenantsGetters.getTenantAppSubtenant,

    centerColumnSize() {
      let col = 12;
      if ((this.localLayout?.leftColumn ?? []).length) {
        col -= 3;
      }
      if ((this.localLayout?.rightColumn ?? []).length) {
        col -= 3;
      }
      return `col-md-${col}`;
    },
  },
  async created() {
    await MenuLayoutActions.init();
    await this.getMenuItem();
    await this.fetchUserRoles();
    // this.appTemplates = await templateManagement.getAllTemplates();

    this.localLayout = {
      ...this.layout,
    };
    this.localLayout.centerColumn.splice(
      this.localLayout.centerContentPosition,
      0,
      { name: 'CONTENT', id: '_content', _id: '_content' }
    );

    await this.findStartPage();
  },
  watch: {
    '$route.params.menuItemId': async function (value) {
      this.menuItemId = value;
      await this.getMenuItem();
      await this.findStartPage();
    } /* ,
    userRoles() {
      if (this.userRoles.length) {
        //this.setMenuItemsVisibility();
      }
    } */,
  },

  methods: {
    /* setMenuItemsVisibility() {
      if (this.localLayout.leftColumn.length) {
        this.localLayout.leftColumn.forEach(menu => {
          if (menu?.menuBuilder?.length) {
            menu.menuBuilder.forEach(menuSection => {
              this.recursiveSetMenuItemVisibility(menuSection);
            });
          }
        });
      }
    },
    recursiveSetMenuItemVisibility(menuSection) {
      if (menuSection.templateId) {
        const templateBpId = this.appTemplates.find(t => t._id === menuSection.templateId).blueprintId;
        menuSection.showInMenu = false;
        this.userRoles.forEach(role => {
          if (role.permissionByBlueprintId[templateBpId] && role.permissionByBlueprintId[templateBpId].viewAuthority.mode !== 'none') {
            menuSection.showInMenu = true;
          }
        });
      }

      if (menuSection.children.length) {
        menuSection.children.forEach(ms => this.recursiveSetMenuItemVisibility(ms));
      }
    }, */
    async fetchUserRoles() {
      this.userRoles = await roleSubtenantApi.getAllForUser();
    },
    async findStartPage() {
      if (!this.menuItemId) {
        let redirected = await this.findStartPageColumn(
          this.layout.leftColumn,
          'l'
        );
        if (!redirected) {
          redirected = await this.findStartPageColumn(
            this.layout.centerColumn,
            'c'
          );
          if (!redirected) {
            await this.findStartPageColumn(this.layout.rightColumn, 'r');
          }
        }
      }
    },
    async findStartPageColumn(data, position) {
      await Promise.all(
        data.map(async menu => {
          // Comentat blocul original de cod
          /* if (menu.startMenuId) {
              const menuItem = await findRecursive(menu.menuBuilder, menu.startMenuId);
              if (menuItem.templateId) {
                await this.$router.push({ name: "templateDataSystemBuilder", params: { menuItemId: position + menuItem._id } });
                return true;
              }
            } */

          if (
            Array.isArray(menu.startMenus) &&
            menu.startMenus.length &&
            this.userRoles.length
          ) {
            let menuStartPage = null;

            this.userRoles.some(role => {
              menuStartPage = menu.startMenus.find(sm =>
                sm.userRoles.includes(role._id)
              );
              return !!menuStartPage;
            });

            if (menuStartPage) {
              await router.push({
                name: 'templateDataSystemBuilder',
                params: { menuItemId: position + menuStartPage.startMenuId },
              });
              return true;
            }
          }
          return menu;
        })
      );
      return false;
    },
    async getMenuItem() {
      this.menuItem = null;
      let items = [];
      if (this.menuItemId) {
        const menuItemId = (this.menuItemId ?? '').substring(1);
        const position = (this.menuItemId ?? '').charAt(0);
        if (position === 'l') {
          items = this.layout.leftColumn;
        } else if (position === 'c') {
          items = this.layout.centerColumn;
        } else if (position === 'r') {
          items = this.layout.rightColumn;
        }
        if (!items.length) {
          this.menuItem = null;
        }
        await Promise.all(
          items.map(async menu => {
            this.menuItem = await findRecursive(
              menu?.menuBuilder ?? [],
              menuItemId
            );
          })
        );
      } else {
        this.menuItem = null;
      }
    },
  },
};
</script>
<style scoped>
@media screen and (max-width: 600px) {
  .padd-10 {
    padding: 0;
  }
}
</style>
