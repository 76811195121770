<template>
  <div>
    <a-row :gutter="18">
      <a-col class="gutter-row" :span="12">
        <a-select
          :value="
            localEvent.jump.workflow == null
              ? undefined
              : localEvent.jump.workflow
          "
          @change="selectWorkflow"
          placeholder="Select a workflow to jump"
          :filter-option="filterOption"
          style="width: 100%"
          allowClear
        >
          <a-select-option
            :label="workflow.name"
            :disabled="!workflow.activ"
            v-for="workflow in workflows"
            :key="workflow._id"
            :value="workflow._id"
          >
            <span role="img" :aria-label="workflow.name">
              <CheckCircleOutlined
                :style="workflow.activ ? 'color:#52c41a' : ''"
                v-if="workflow.activ"
              />
              <ExclamationCircleOutlined
                :style="workflow.activ ? 'color:#52c41a' : ''"
                v-else
              />
            </span>
            {{ workflow.name }}
          </a-select-option>
        </a-select>
      </a-col>

      <a-col class="gutter-row" :span="12">
        <a-select
          :value="
            localEvent.jump.event == null ? undefined : localEvent.jump.event
          "
          @change="selectWorkflowEvent"
          v-if="localEvent.jump.workflow"
          show-search
          placeholder="Select an event"
          style="width: 100%"
          :filter-option="filterOption"
          allowClear
        >
          <a-select-option
            v-for="(event, key) in showEvents"
            :key="event._id"
            :value="event._id"
          >
            {{ key + 1 }}. {{ event.name }}
          </a-select-option>
        </a-select>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { WorkflowActions } from '@workflow/shared/workflow.store';
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons-vue';

export default {
  name: 'workflowBuilderJumpIndex',
  props: ['workflows', 'selectedWorkflow', 'event', 'eventList'],
  components: {
    CheckCircleOutlined,
    ExclamationCircleOutlined,
  },
  data() {
    return {
      localEvent: { ...this.event },
    };
  },
  watch: {
    async localEvent(data) {
      await WorkflowActions.editOneEvent(
        this.selectedWorkflow._id,
        this.event._id,
        { ...data }
      );
    },
  },

  computed: {
    showEvents() {
      const workflows = this.workflows.filter(
        event => event._id === this.localEvent.jump.workflow
      );
      return workflows.length ? workflows[0].events : [];
    },
  },

  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    selectWorkflow(key) {
      this.localEvent.jump.workflow = key;
    },
    selectWorkflowEvent(key) {
      this.localEvent.jump.event = key;
    },
  },
};
</script>
