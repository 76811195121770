<template>
  <a-checkbox
    v-if="!viewingMode"
    v-model:checked="inputValue"
    :disabled="
      readOnly ||
      (field.isReadOnlyAdd && addNew) ||
      (field.isReadOnlyEdit && !addNew)
    "
  >
    {{ field.label }}
  </a-checkbox>

  <div v-else>{{ viewingModeValue }}</div>
</template>

<script>
import InputMixin from './InputMixin';

export default {
  mixins: [InputMixin],
  computed: {
    viewingModeValue() {
      if (this.rawValue) {
        return 'Da';
      }
      return 'Nu';
    },
  },
  methods: {
    getDefaultValue() {
      return false;
    },
  },
};
</script>

<style></style>
