<template>
  <div>
    <div style="margin-bottom: 20px">
      <a-button size="small" @click="openTokens = true" type="primary"
        >Tokens to use</a-button
      >
    </div>
    <a-select
      :value="localEvent.notification.roleId"
      @change="selectRole"
      placeholder="Select a group..."
      allowClear
      style="width: 100%"
    >
      <a-select-option v-for="role in roles" :key="role._id" :value="role._id">
        {{ role.name }}
      </a-select-option>
    </a-select>
    <div style="margin-top: 10px" v-if="localEvent.notification.roleId">
      <p>
        <a-textarea
          v-model:value="localEvent.notification.message"
          placeholder="Message..."
        />
      </p>
      <p>
        <a-switch
          v-model:checked="localEvent.notification.continueWorkflowEvent.activ"
        >
          <template #checkedChildren>
            <CheckOutlined />
          </template>
        </a-switch>
        <span @click="onToggle">Accept or Decline</span>
      </p>

      <span v-if="localEvent.notification.continueWorkflowEvent.activ">
        <a-radio-group
          v-model:check="
            localEvent.notification.continueWorkflowEvent.continueBy
          "
        >
          <a-radio default-checked value="either-one">Either one</a-radio>
          <a-radio value="all">All</a-radio>
        </a-radio-group>
      </span>
    </div>
    <a-alert
      style="margin-top: 10px"
      v-else
      :show-icon="false"
      message="No users in this group"
      banner
    />
    <TokensModal
      :visible="openTokens"
      @close="openTokens = false"
      :blueprintId="selectedBlueprintId"
    />
  </div>
</template>

<script>
import { roleApplicationApi } from '@roleManagement/api';
import TokensModal from '@core/components/TokensModal';
import { WorkflowActions } from '@workflow/shared/workflow.store';
import { CheckOutlined } from '@ant-design/icons-vue';

export default {
  name: 'workflowBuilderNotificationIndex',
  props: ['workflows', 'selectedWorkflow', 'event', 'eventList'],
  components: {
    TokensModal,
    CheckOutlined,
  },
  data() {
    return {
      roles: [],
      openTokens: false,
      customize: false,
      localEvent: { ...this.event },
    };
  },
  watch: {
    async localEvent(data) {
      await WorkflowActions.editOneEvent(
        this.selectedWorkflow._id,
        this.event._id,
        { ...data }
      );
    },
  },
  computed: {
    selectedBlueprintId() {
      return this.selectedWorkflow.blueprint;
    },
  },
  created() {
    this.fetchRoles();
  },
  methods: {
    async fetchRoles() {
      this.roles = await roleApplicationApi.getAllForApplication();
    },
    selectRole(key) {
      this.localEvent.notification.roleId = key;
    },
    onToggle() {
      this.localEvent.notification.continueWorkflowEvent.activ =
        !this.localEvent.notification.continueWorkflowEvent.activ;
    },
  },
};
</script>
