import axios from 'axios';

import { SlugGetters } from '@/slug.store';

const { getAppUrl } = SlugGetters;

const postOne = async (dataProviderId, data) => {
  try {
    const response = await axios.post(
      `${getAppUrl()}/providers/${dataProviderId}/sources`,
      data
    );
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

const deleteOne = async (providerId, sourceId) => {
  try {
    await axios.delete(
      `${getAppUrl()}/providers/${providerId}/sources/${sourceId}`
    );
    return true;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

const putOne = async (dataProviderId, sourceId, data) => {
  try {
    const response = await axios.put(
      `${getAppUrl()}/providers/${dataProviderId}/sources/${sourceId}`,
      data
    );
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

const updateSourceOptions = async (dataProviderId, sourceId, data) => {
  try {
    const response = await axios.put(
      `${getAppUrl()}/providers/${dataProviderId}/sources/options/${sourceId}`,
      data
    );
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

const updateSampleObject = async (dataProviderId, sourceId, data) => {
  try {
    const response = await axios.put(
      `${getAppUrl()}/providers/${dataProviderId}/sources/sample-object/${sourceId}`,
      {
        sampleObject: data,
      }
    );
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

const testOne = async (sourceId, providerId) => {
  try {
    const response = await axios.post(
      `${getAppUrl()}/providers/${providerId}/sources/${sourceId}/retest`
    );
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

const putOneSafely = async (dataProviderId, sourceId, data) => {
  try {
    const response = await axios.put(
      `${getAppUrl()}/providers/${dataProviderId}/sources/${sourceId}/safe`,
      data
    );
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

export default {
  deleteOne,
  postOne,
  putOne,
  testOne,
  updateSampleObject,
  updateSourceOptions,
  putOneSafely,
};
