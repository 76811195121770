import { reactive } from 'vue';

const initialState = {
  tenantSlug: null,
  appSlug: null,
  subtenantSlug: null,
  path: null,
  isPublicPage: false,
  templateId: null,
};

const state = reactive({ ...initialState });

const Getters = {
  getTenantSlug: () => state.tenantSlug,
  getAppSlug: () => state.appSlug,
  getSubtenantSlug: () => state.subtenantSlug,
  getTenantUrl: () => {
    if (!state.tenantSlug) {
      return null;
    }

    return `/${state.tenantSlug}`;
  },
  getTemplateId() {
    return state.templateId;
  },
  getAppUrlWithGlobal: (withSubtenant = true) => {
    // console.log(`/${state.tenantSlug}/${state.appSlug}/${state.subtenantSlug}`)
    if (
      state.tenantSlug &&
      state.appSlug &&
      state.subtenantSlug &&
      withSubtenant
    ) {
      return `/${state.tenantSlug}/${state.appSlug}/${state.subtenantSlug}`;
    }

    if (state.tenantSlug && state.appSlug) {
      return `/${state.tenantSlug}/${state.appSlug}`;
    }

    if (!state.tenantSlug && !state.appSlug) {
      if (state.path.includes('tenants')) {
        return `/global/global`;
      }
    }

    if (state.tenantSlug && !state.appSlug) {
      if (state.path.includes('tenants')) {
        return `/${state.tenantSlug}/global`;
      }
    }
    if (state.isPublicPage && state.templateId && state.subtenantSlug) {
      return `/public/template-management/${state.subtenantSlug}/${state.templateId}`;
    }
    return null;
  },

  getAppUrl: () => {
    if (!state.tenantSlug || !state.appSlug) {
      return null;
    }

    return `/${state.tenantSlug}/${state.appSlug}`;
  },

  getSubtenantUrl: () => {
    if (state.isPublicPage && state.templateId && state.subtenantSlug) {
      return `/public/template-management/${state.subtenantSlug}/${state.templateId}`;
    }

    if (!state.tenantSlug || !state.appSlug || !state.subtenantSlug) {
      return null;
    }

    return `/${state.tenantSlug}/${state.appSlug}/${state.subtenantSlug}`;
  },

  getFullUrlForSubtenant: subtenantSlug => {
    if (!state.tenantSlug || !state.appSlug) {
      return null;
    }

    return `/${state.tenantSlug}/${state.appSlug}/${subtenantSlug}`;
  },
};

const Mutations = {
  UPDATE_SLUG_ON_ROUTE_CHANGE: route => {
    const { tenantSlug, appSlug, subtenantSlug, templateId } = route.params;
    state.tenantSlug = tenantSlug;
    state.appSlug = appSlug;
    state.subtenantSlug = subtenantSlug;
    state.path = route.path;
    state.templateId = templateId;
    const { isPublicPage } = route.meta;
    state.isPublicPage = isPublicPage;
  },
};

export const SlugGetters = Getters;
export const SlugMutations = Mutations;
