<template>
  <div :class="'item-menu-' + position" v-if="list.length">
    <template v-for="item in list" :key="item._id">
      <div
        class="item-section"
        v-if="
          item.active &&
          item.showInMenu &&
          item.subtenantId.includes(tenantData.subtenant._id)
        "
      >
        <div class="item" v-if="!item.templateId">
          <span>{{ item.title }}</span>
          <DownOutlined
            class="menu-arrow"
            :class="{ closed: !item.subMenuVisible }"
            v-if="item.children.length"
            @click="item.subMenuVisible = !item.subMenuVisible"
          />
        </div>

        <router-link
          v-else
          :to="{
            name: 'templateDataSystemBuilder',
            params: { menuItemId: position + item._id },
          }"
          custom
          v-slot="{ isActive, href, navigate }"
        >
          <div class="item" :class="isActive ? 'active' : ''">
            <a :href="href" @click="handleClick($event, navigate)">
              {{ item.title }}
            </a>
            <DownOutlined
              class="menu-arrow"
              :class="{ closed: !item.subMenuVisible }"
              v-if="item.children.length"
              @click="item.subMenuVisible = !item.subMenuVisible"
            />
          </div>
        </router-link>

        <RecursiveMenu
          v-if="
            item && item.active && item.children.length && item.subMenuVisible
          "
          :position="position ?? 0"
          class="item-menu-sub"
          :list="item.children ?? []"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { DownOutlined } from '@ant-design/icons-vue';
import { TenantsGetters } from '@tenants/shared/tenants.store';

export default {
  name: 'recursive-menu',
  props: {
    position: {
      type: String,
    },
    depth: {
      type: Number,
      default: 0,
    },
    list: {
      required: false,
      type: Array,
      default: null,
    },
  },
  components: {
    DownOutlined,
  },
  created() {
    this.list.forEach(item => {
      if (typeof item.subMenuVisible === 'undefined') {
        item.subMenuVisible = window.screen.width > 600;
      }
    });
  },
  computed: {
    tenantData: TenantsGetters.getTenantAppSubtenant,
  },
  methods: {
    handleClick(event, navigate) {
      event.preventDefault(); // Prevent default anchor behavior
      navigate(); // Navigate to the route
      this.goToTop(); // Additional custom logic
    },
    goToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
<style lang="scss" scoped>
.item-menu-c {
  display: flex;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.item {
  background-color: #ffffff;
  display: block;
  text-decoration: none;
  font-size: 15px;
  padding: 8px 30px;
  position: relative;
  .menu-arrow {
    position: absolute;
    font-size: 12px;
    right: 10px;
    top: 10px;
    z-index: 1;
    opacity: 1;
    padding: 5px 15px;

    &.closed {
      transform: rotate(180deg);
    }
  }
}
/*.item-section {
  &:hover {
    .item {
      > .menu-arrow {
        opacity: 1;
      }
    }
  }
}*/

.item-menu-l .item {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.item-menu-r .item {
  text-align: right;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}
.item-menu-c .item {
  text-align: center;
}

.item a {
  cursor: pointer;
  display: block;
  color: #000;
  text-decoration: none;
}
.item.active {
  background-color: #e6f7ff;

  a {
    color: #1890ff;
  }
}
.item-menu-l .item.active,
.item-menu-r .item.active {
  border-right: 1px solid #1890ff;
}
.item-menu-c .item.active {
  border-bottom: 1px solid #1890ff;
}
.item-menu-sub {
  margin: 0 0 0 1rem;
}

.ghost {
  opacity: 0.4;
  background: #ffffff;
  border-radius: 4px;
}
</style>
