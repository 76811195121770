<template>
  <div>
    <a-checkbox style="margin: 15px 0" v-model:checked="input.isEnabled">
      Enable Calculation
      <help-icon-popover
        title="Sensitive Field"
        content="This field will be administrative."
      />
    </a-checkbox>

    {{ Object.keys(successionIndexesOfNumberFields) }}

    <div style="padding: 0 27.5px" v-if="input.isEnabled">
      <label style="margin-bottom: 5px; display: block">Expression</label>
      <a-textarea v-model:value="input.expression" />
      <div v-if="isExpressionValid !== null" style="margin-top: 5px">
        <a-tag v-if="isExpressionValid === true" color="green">
          Valid expression.
        </a-tag>
        <a-tag v-if="isExpressionValid === false" color="red">
          Invalid expression.
        </a-tag>
        <a-tag
          v-if="isExpressionValid === false && invalidExpressionMessage"
          color="red"
          style="display: block; margin-top: 5px"
        >
          {{ invalidExpressionMessage }}
        </a-tag>
      </div>
    </div>
  </div>
</template>

<script>
import { create, all } from 'mathjs';
import { slugify } from '@/core/utils/string-manipulation';
import FieldInputOptionsMixin from '../FieldInputOptionsMixin';
import { FormBuilderGetters } from '../../../formBuilder.store';

export default {
  mixins: [FieldInputOptionsMixin],
  fieldInputOptions: {
    path: 'logic.calculation',
    keys: ['isEnabled', 'expression'],
  },
  data() {
    return {
      isExpressionValid: null,
      math: null,
      invalidExpressionMessage: null,
    };
  },
  computed: {
    fieldsBefore() {
      return FormBuilderGetters.getFieldsBefore(this.fieldId);
    },
    successionIndexesOfNumberFields() {
      if (!this.fieldsBefore || !this.fieldsBefore.length) {
        return [];
      }
      const data = {};

      this.fieldsBefore
        .filter(f => f.structure.type === 'number')
        .forEach(item => {
          if (item._id !== this.fieldId) {
            const slugifiedLabel = slugify(item.label);
            data[slugifiedLabel] = 1;
            data[`${slugifiedLabel}_exists`] = 1;
            if (item.array) {
              data[`${slugifiedLabel}_count`] = 1;
            }
          }
        });

      return data;
    },
  },
  mounted() {
    this.math = create(all, {});
    this.math.import({
      ...this.successionIndexesOfNumberFields,
    });
  },
  watch: {
    successionIndexesOfNumberFields(data) {
      Object.entries(data).forEach(([key, value]) => {
        if (this.math[key] === null) {
          this.math.import({ [key]: value });
        }
      });
    },
  },
  methods: {
    validateInput(input) {
      this.invalidExpressionMessage = null;

      if (
        input == null ||
        input.expression == null ||
        input.expression.trim() === ''
      ) {
        this.isExpressionValid = null;
        return {
          isInputValid: true,
          validatedInput: {
            isEnabled: input.isEnabled ? input.isEnabled : false,
            expression: null,
          },
        };
      }

      try {
        this.math.evaluate(this.input.expression);
        this.isExpressionValid = true;
        return { isInputValid: true, validatedInput: input };
      } catch (error) {
        this.isExpressionValid = false;
        if (error.message === 'invalid_field_id') {
          this.invalidExpressionMessage =
            'The specified Field is not of type Number or does not exist.';
        }
        return { isInputValid: false };
      }
    },
  },
};
</script>

<style></style>
