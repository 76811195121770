<template>
  <div>
    <a-tabs v-model:activeKey="activeKey" v-if="blueprintsToUpdate?.length">
      <template v-for="(itemBlueprint, index) of blueprintsToUpdate">
        <template v-if="itemBlueprint">
          <a-tab-pane
            :tab="
              itemBlueprint.name +
              (localEvent.updateFields.fields.find(
                f => f.blueprintId === itemBlueprint._id && f.active === true
              )
                ? '*'
                : '')
            "
            :key="index"
          >
            <div v-if="!fields">
              <br />
              <a-alert message="No fields found." banner />
            </div>
            <div v-else style="padding-top: 10px">
              <template v-for="field in fields" :key="field._id">
                <div class="update-fields">
                  <div class="cell input-name">
                    <div
                      style="
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                      "
                    >
                      <a-tooltip>
                        <template #title>
                          <span>Change field value</span>
                        </template>
                        <a-checkbox
                          :checked="fieldValue(field, 'active')"
                          @change="
                            value => changeFieldValue(value, field, 'active')
                          "
                        />
                      </a-tooltip>
                    </div>

                    <div style="display: inline-block; padding-left: 27px">
                      <strong>{{ field.label }}</strong>
                      <div class="mutted">{{ field.structure.type }}</div>
                    </div>
                  </div>
                  <div class="cell modify-filed">
                    <template
                      v-if="
                        localEvent?.updateFields?.fields[indexOfField(field)] &&
                        fieldValue(field, 'active')
                      "
                    >
                      <a-radio-group
                        v-model:value="
                          localEvent.updateFields.fields[indexOfField(field)]
                            .bindType
                        "
                        @change="
                          value => changeFieldValue(value, field, 'bindType')
                        "
                      >
                        <a-radio-button value="from-instance"
                          >Insert from Instance
                        </a-radio-button>
                        <a-radio-button value="from-field"
                          >Insert from Other
                          <help-icon-popover
                            style="float: right"
                            title="Insert with data from other blueprint"
                            content="Used to get data from other blueprint with no connections between trigger instance and other blueprint fields.
                                                    ( 1. Used to get autoincrement field from other blueprint and update selected field)"
                          />
                        </a-radio-button>
                        <a-radio-button value="static-value"
                          >Static value
                          <help-icon-popover
                            style="float: right"
                            title="Simple value"
                            content="Update field with written value"
                          />
                        </a-radio-button>
                        <a-radio-button
                          v-if="field.structure.type === 'number'"
                          value="auto-increment"
                        >
                          Auto Increment
                          <help-icon-popover
                            style="float: right"
                            title="Autoincrement"
                            content="AutoIncrement field with last number or with list length ( apply only on numbers)"
                          />
                        </a-radio-button>
                      </a-radio-group>
                    </template>

                    <div
                      class="mt-2"
                      v-if="
                        fieldValue(field, 'active') &&
                        fieldValue(field, 'bindType') !== 'auto-increment'
                      "
                    >
                      <template
                        v-if="fieldValue(field, 'bindType') === 'from-instance'"
                      >
                        <ReferenceFieldManger
                          style="width: 100%"
                          :blueprints="blueprints"
                          :field="
                            fieldValue(field, 'fromInstanceFieldId') ?? null
                          "
                          :selectedBlueprint="blueprint"
                          :toFieldFromOtherBlueprint="false"
                          :justReferenceFields="false"
                          :autoFieldConditions="false"
                          :mainBlueprint="blueprint"
                          :mainBlueprintId="blueprint._id.toString()"
                          :cardView="false"
                          :otherBlueprint="false"
                          :forceShowId="true"
                          :justReferencesAndFieldType="
                            field.structure.type === 'reference'
                              ? 'number'
                              : field.structure.type
                          "
                          :keyLimit="1"
                          @updateField="
                            value =>
                              changeFieldValue(
                                value,
                                field,
                                'fromInstanceFieldId'
                              )
                          "
                        />
                      </template>
                      <template
                        v-if="fieldValue(field, 'bindType') === 'from-field'"
                      >
                        <template v-if="showRefFieldPath(field) !== ''">
                          <a-button-group>
                            <a-button style="cursor: default" type="primary">
                              {{
                                blueprints.find(
                                  item =>
                                    item._id ===
                                    fieldValue(field, 'otherBlueprintId')
                                )?.name
                              }}
                              > {{ showRefFieldPath(field) }}
                            </a-button>
                            <a-button
                              @click="editBindField(field)"
                              :class="'edit-btn'"
                            >
                              <template #icon>
                                <EditOutlined />
                              </template>
                            </a-button>
                          </a-button-group>

                          <a-checkbox
                            v-if="fieldValue(field, 'fieldType') === 'number'"
                            class="ml-3"
                            :checked="
                              fieldValue(field, 'autoIncrementRefField')
                            "
                            @change="
                              value =>
                                changeFieldValue(
                                  value,
                                  field,
                                  'autoIncrementRefField'
                                )
                            "
                          >
                            Auto increment
                            <template
                              v-if="fieldValue(field, 'autoIncrementRefField')"
                            >
                              into field</template
                            >
                          </a-checkbox>

                          <div
                            v-if="fieldValue(field, 'autoIncrementRefField')"
                          >
                            <a-select
                              class="mt-2"
                              :defaultValue="
                                fieldValue(
                                  field,
                                  'autoIncrementRefFieldToFieldId'
                                ) ?? undefined
                              "
                              @change="
                                value =>
                                  changeFieldValue(
                                    value,
                                    field,
                                    'autoIncrementRefFieldToFieldId'
                                  )
                              "
                              allowClear
                              show-search
                              placeholder="Select field"
                              style="width: 100%"
                              :filter-option="filterOption"
                              :options="
                                blueprints
                                  .find(
                                    item =>
                                      item._id ===
                                      fieldValue(field, 'otherBlueprintId')
                                  )
                                  ?.fields.map(field => {
                                    return {
                                      value: field._id,
                                      label: field.label,
                                    };
                                  })
                              "
                            >
                            </a-select>
                          </div>
                        </template>

                        <template
                          v-if="
                            bindField === field._id ||
                            localEvent.updateFields.fields[indexOfField(field)]
                              .bindType === 'from-field'
                          "
                        >
                          <hr style="margin: 10px 0" />
                          <a-select
                            class="mt-2"
                            v-model:value="
                              localEvent.updateFields.fields[
                                indexOfField(field)
                              ].otherBlueprintId
                            "
                            @change="
                              value =>
                                changeFieldValue(
                                  value,
                                  field,
                                  'otherBlueprintId'
                                )
                            "
                            allowClear
                            show-search
                            placeholder="Select Business Objects"
                            style="width: 100%"
                            :filter-option="filterOption"
                            :options="
                              blueprints
                                ?.filter(item => item._id !== blueprint._id)
                                ?.map(blue => {
                                  return {
                                    value: blue._id,
                                    label: blue.name,
                                  };
                                })
                            "
                          >
                          </a-select>
                          <ReferenceFields
                            v-model="
                              localEvent.updateFields.fields[
                                indexOfField(field)
                              ].fromFieldId
                            "
                            v-if="
                              blueprints.find(
                                item =>
                                  item._id ===
                                  fieldValue(field, 'otherBlueprintId')
                              )
                            "
                            :key="referenceFieldsKey"
                            :blueprints="blueprints"
                            :selectedBlueprint="
                              blueprints.find(
                                item =>
                                  item._id ===
                                  fieldValue(field, 'otherBlueprintId')
                              )
                            "
                            :toFieldFromOtherBlueprint="false"
                            :justReferenceFields="false"
                            :justSingleReferenceFields="true"
                            :conditionsAcceptFieldValue="true"
                            :autoFieldConditions="false"
                            :mainBlueprint="{
                              ...(blueprint ?? {}),
                              fields: [...Object.values(fields)],
                            }"
                            :cardView="false"
                            :otherBlueprint="false"
                            @toField="
                              (value, index) => selectField(field, value, index)
                            "
                          />
                        </template>
                      </template>

                      <template
                        v-if="fieldValue(field, 'bindType') === 'static-value'"
                      >
                        <template
                          v-if="fieldValue(field, 'fieldType') === 'date'"
                        >
                          <a-checkbox
                            class="m-0"
                            @change="
                              value =>
                                changeFieldValue(
                                  value,
                                  field,
                                  'manuallyCurrentDate'
                                )
                            "
                            :default-checked="
                              fieldValue(field, 'manuallyCurrentDate')
                            "
                            >Current date</a-checkbox
                          >
                          <a-date-picker
                            style="width: 150px"
                            v-if="!fieldValue(field, 'manuallyCurrentDate')"
                            :readOnly="true"
                            :format="fieldValue(field, 'dateFormat')"
                            :default-value="fieldValue(field, 'value')"
                            @change="
                              value => changeFieldValue(value, field, 'value')
                            "
                          />
                          <a-input-number
                            style="width: 80px"
                            class="ml-2"
                            @change="
                              value =>
                                changeFieldValue(
                                  value,
                                  field,
                                  'manuallyDateDays'
                                )
                            "
                            :default-value="
                              fieldValue(field, 'manuallyDateDays')
                            "
                          />
                          days
                        </template>
                        <template
                          v-else-if="
                            ['number', 'reference'].includes(
                              fieldValue(field, 'fieldType')
                            )
                          "
                        >
                          <a-input-number
                            placeholder="Value"
                            style="width: 100%"
                            @change="
                              value => changeFieldValue(value, field, 'value')
                            "
                            :default-value="fieldValue(field, 'value')"
                          />
                        </template>
                        <template
                          v-else-if="
                            fieldValue(field, 'fieldType') === 'boolean'
                          "
                        >
                          <a-select
                            placeholder="Value"
                            style="width: 100%"
                            @change="
                              value => changeFieldValue(value, field, 'value')
                            "
                            :default-value="fieldValue(field, 'value')"
                          >
                            <a-select-option :value="true"
                              >True</a-select-option
                            >
                            <a-select-option :value="false"
                              >False</a-select-option
                            >
                          </a-select>
                        </template>
                        <template v-else>
                          <a-input
                            :default-value="fieldValue(field, 'value')"
                            @change="
                              value => changeFieldValue(value, field, 'value')
                            "
                            placeholder="Value"
                          />
                        </template>
                      </template>
                    </div>

                    <div
                      class="mt-2"
                      v-if="fieldValue(field, 'bindType') === 'auto-increment'"
                    >
                      <a-radio-group
                        v-model:value="
                          localEvent.updateFields.fields[indexOfField(field)]
                            .autoIncrementType
                        "
                        @change="
                          value =>
                            changeFieldValue(value, field, 'autoIncrementType')
                        "
                        button-style="solid"
                      >
                        <a-radio-button value="last-number"
                          >Last number</a-radio-button
                        >
                        <a-radio-button value="list-length"
                          >List length</a-radio-button
                        >
                      </a-radio-group>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </a-tab-pane>
        </template>
      </template>
    </a-tabs>
  </div>
</template>

<script>
import { blueprintApi } from '@dataSystem/api';
import ReferenceFields from '@/apps/templateManagement/views/Builder/components/ReferenceFields.vue';
import _ from 'lodash';
import ReferenceFieldManger from '@/apps/templateManagement/views/Builder/components/ReferenceFieldManger.vue';
import { WorkflowActions } from '@/apps/workflow/shared/workflow.store';
import { EditOutlined } from '@ant-design/icons-vue';

export default {
  name: 'workflowBuilderUpdateFieldsIndex',
  components: {
    EditOutlined,
    ReferenceFieldManger,
    ReferenceFields,
  },
  props: ['workflows', 'selectedWorkflow', 'event', 'eventList', 'eventIndex'],
  mounted() {
    this.fetchBlueprint();
    this.selectedBlueprint = this.selectedBlueprintId;
  },
  data() {
    return {
      activeKey: 0,
      blueprints: [],
      blueprint: null,
      referenceFieldsKey: 1,
      bindField: null,
      selectedBlueprint: null,
      resetKey: 1,
      localEvent: { ...this.event },
    };
  },
  computed: {
    selectedBlueprintId() {
      return this.selectedWorkflow.blueprint;
    },
    blueprintsToUpdate() {
      const arrayOfBlueprintsId = [];
      if (!this.blueprints) {
        return arrayOfBlueprintsId;
      }
      arrayOfBlueprintsId.push(
        this.blueprints.filter(item => item._id === this.selectedBlueprintId)[0]
      );
      this.selectedWorkflow?.events
        .filter((itemEvent, index) => index < this.eventIndex)
        .forEach(event => {
          if (event?.comparisons?.blueprint) {
            arrayOfBlueprintsId.push(
              this.blueprints.find(
                item => item._id === event?.comparisons?.blueprint
              )
            );
          }
        });
      return arrayOfBlueprintsId;
    },
    fields() {
      if (
        this.blueprints?.length &&
        this.blueprintsToUpdate &&
        this.selectedBlueprint
      ) {
        return this.blueprintsToUpdate.find(
          item => this.selectedBlueprint === item._id
        ).fields;
      }
      return [];
    },
  },
  watch: {
    async localEvent(data) {
      await WorkflowActions.editOneEvent(
        this.selectedWorkflow._id,
        this.event._id,
        { ...data }
      );
    },
    selectedBlueprintId() {
      this.fetchBlueprint();
    },
    activeKey() {
      this.selectedBlueprint = this.blueprintsToUpdate[this.activeKey]._id;
    },
  },

  methods: {
    editBindField(field) {
      this.bindField = this.bindField === field._id ? null : field._id;
    },
    selectField(field, value, index) {
      const findField = this.localEvent.updateFields.fields.find(
        f => f.toFieldId === field._id.toString()
      );

      const referenceFields = findField.fromFieldId.filter(
        item => item.index < index
      );
      if (value?.fieldId) {
        referenceFields.push({ ...value, index });
      }
      findField.fromFieldId = referenceFields.sort((a, b) => a.index - b.index);
    },
    showRefFieldPath(field) {
      const findField = this.localEvent.updateFields.fields.find(
        f => f.toFieldId === field._id.toString()
      );
      return (findField?.fromFieldId ?? [])
        .map(
          item =>
            `${
              item.fieldName.startsWith('_')
                ? _.startCase(item.fieldName.replace('_', '')).toUpperCase()
                : item.fieldName
            } (${item.fieldName === '_changedData' ? 'history' : item.type})`
        )
        .join(' > ');
    },
    showRefFieldPathConditions(field) {
      const findField = this.localEvent.updateFields.fields.find(
        f => f.toFieldId === field._id.toString()
      );
      return findField?.fromFieldId ?? [];
    },
    async fetchAllBlueprint() {
      this.blueprints = await blueprintApi.getAllWithFields();
    },

    fieldValue(field, fieldName) {
      const findField = this.localEvent.updateFields.fields.find(
        f => f.toFieldId === field._id.toString()
      );
      if (findField) {
        if (!findField?.bindType) {
          findField.bindType = 'static-value';
        }
      }
      if (fieldName === 'bindType') {
        return findField?.[fieldName] ?? 'static-value';
      }
      return findField?.[fieldName] ?? null;
    },
    indexOfField(field) {
      return this.localEvent.updateFields.fields
        .map(function (x) {
          return x.toFieldId;
        })
        .indexOf(field._id);
    },
    changeFieldValue(event, field, fieldName) {
      const value = event?.target?.value ?? event?.target?.checked ?? event;
      this.resetKey += 1;

      this.bindField = field._id;

      const findField = this.localEvent.updateFields.fields.find(
        f => f.toFieldId === field._id
      );
      if (findField) {
        findField[fieldName] = value;
        findField.blueprintId = this.selectedBlueprint;
        if (fieldName === 'active') {
          findField.value = '';
          if (!value) {
            findField.bindType = null;
          } else {
            findField.bindType = 'static-value';
          }
        }
      } else {
        this.localEvent.updateFields.fields.push({
          active: fieldName === 'active' ? value : true,
          blueprintId: this.selectedBlueprint,
          otherBlueprintId: null,
          fromFieldId: [],
          fromInstanceFieldId: null,

          autoIncrementType: 'last-number',

          autoIncrementRefField: false,
          autoIncrementRefFieldToFieldId: null,

          bindType: fieldName === 'bindType' ? value : 'static-value',

          manuallyCurrentDate: false,
          dateFormat: field?.structure?.options?.format ?? 'DD-MM-YYYY',
          manuallyDateDays: 0,

          toFieldId: field._id,
          fieldType: field.structure.type,
          value: '',
        });
      }
    },

    async fetchBlueprint() {
      if (!this.selectedBlueprintId) {
        return;
      }
      const { blueprint } = await blueprintApi.getOne(this.selectedBlueprintId);
      this.blueprint = blueprint;

      await this.fetchAllBlueprint();
    },

    filterOption(input, option) {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
  },
};
</script>
<style scoped>
.update-fields {
  display: table;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px 10px;
  margin-bottom: 10px;
  width: 100%;
}
.update-fields:last-child {
  margin-bottom: 0px;
}
.update-fields .cell {
  position: relative;
  display: table-cell;
  vertical-align: middle;
}
.input-name {
  width: 30%;
  border-right: 1px solid #ddd;
  height: 30px;
}
.modify-filed {
  width: 70%;
  padding-left: 10px;
}
.input-field {
  width: 40%;
  border-left: 1px solid #ddd;
  padding-left: 10px;
}
.mutted {
  font-size: 12px;
}
</style>
