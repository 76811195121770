import axios from 'axios';

import { SlugGetters } from '@/slug.store';

const {
  getAppUrl,
  // getSubtenantUrl
} = SlugGetters;

const getAllTemplates = async data => {
  try {
    const response = await axios.post(
      `${getAppUrl()}/template-management/list`,
      {
        data,
      }
    );
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response.data.message,
    };
  }
};

const getOneTemplate = async templateId => {
  try {
    const response = await axios.get(
      `${getAppUrl()}/template-management/${templateId}`
    );
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response.data.message,
    };
  }
};

const getDefaultTemplateForBlueprint = async blueprintId => {
  try {
    const response = await axios.get(
      `${getAppUrl()}/template-management/blueprint/${blueprintId}`
    );
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response.data.message,
    };
  }
};

const updateOneTemplate = async (templateId, template) => {
  try {
    const response = await axios.post(
      `${getAppUrl()}/template-management/edit/${templateId}`,
      { data: template }
    );
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response.data.message,
    };
  }
};

const duplicateOneTemplate = async templateId => {
  try {
    const response = await axios.post(
      `${getAppUrl()}/template-management/duplicate/${templateId}`
    );
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response.data.message,
    };
  }
};

const deleteOneTemplate = async templateId => {
  try {
    const response = await axios.delete(
      `${getAppUrl()}/template-management/${templateId}`
    );
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response.data.message,
    };
  }
};

const postOneTemplate = async data => {
  try {
    const response = await axios.post(
      `${getAppUrl()}/template-management/add`,
      { data }
    );
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response.data.message,
    };
  }
};

export default {
  getAllTemplates,
  getOneTemplate,
  getDefaultTemplateForBlueprint,
  updateOneTemplate,
  postOneTemplate,
  duplicateOneTemplate,
  deleteOneTemplate,
};
