<template>
  <div>
    <loading-spinner v-if="isLoading" />
    <div class="FormContainer" v-else>
      <template v-if="!isFormSubmitted">
        <a-card style="width: 50%; margin: 25px auto; padding: 35px 50px">
          <FormRenderer
            v-if="blueprint && fieldsById"
            :propsBlueprint="blueprint"
            :propsFieldsById="fieldsById"
            :addNew="true"
            :instanceId="null"
            :userRolesWhichCanCreate="['public']"
            :showFields="[]"
            @submit="onFormSubmit"
          >
            <div style="margin-left: 20px">
              <vue-recaptcha :sitekey="siteKey" @verify="onReCaptchaVerify" />
            </div>
          </FormRenderer>
        </a-card>
      </template>
      <template v-else>
        <a-card style="width: 50%; margin: 25px auto; padding: 35px 50px">
          <p>The Form has been successfully submitted!</p>
          <a-button @click="reloadPage">Submit again</a-button>
        </a-card>
      </template>
    </div>
  </div>
</template>

<script>
import { FormRenderer } from '@dataSystem/components/FormRenderer';

import { blueprintPublicApi, instancePublicApi } from '@dataSystem/api';

import * as VueRecaptcha from 'vue-recaptcha';

import LoadScriptMixin from '@/core/mixins/LoadScriptMixin';
import { message } from 'ant-design-vue';

export default {
  mixins: [LoadScriptMixin],
  props: ['linkSharingSlug', 'subtenantId'],
  components: {
    FormRenderer,
    VueRecaptcha,
  },
  scriptSrc: `${process.env.VUE_APP_GOOGLE_RECAPTCHA_SCRIPT_SRC}?onload=vueRecaptchaApiLoaded&render=explicit`,
  data() {
    return {
      isLoading: true,
      blueprint: null,
      fieldsById: null,
      reCaptchaToken: null,
      isFormSubmitted: false,
    };
  },
  created() {
    this.fetchBlueprint();
  },
  computed: {
    siteKey: () => {
      return process.env.VUE_APP_GOOGLE_SITE_KEY;
    },
  },
  methods: {
    onReCaptchaVerify(response) {
      this.reCaptchaToken = response;
    },
    async fetchBlueprint() {
      try {
        const { blueprint, fieldsById } =
          await blueprintPublicApi.getBlueprintFromSlug(this.linkSharingSlug);
        this.blueprint = blueprint;
        this.fieldsById = fieldsById;
        this.isLoading = false;
      } catch (error) {
        window.location.href = '/';
      }
    },
    async onFormSubmit(instanceData) {
      this.isLoading = true;
      if (!this.reCaptchaToken) {
        this.isLoading = false;
        return;
      }
      await instancePublicApi.postOne(
        this.blueprint._id,
        null,
        instanceData,
        this.reCaptchaToken,
        this.subtenantId
      );
      this.isLoading = false;
      message.success('Success!');
      this.isFormSubmitted = true;
    },
    reloadPage() {
      window.location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.FormContainer {
  margin-top: -51px;
  padding-top: 51px;
  background-color: #f5f5f5;
  height: 100vh;
}
</style>
