<template>
  <div class="padding-20">
    <div
      v-if="loading"
      style="
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      "
    >
      <a-spin />
    </div>
    <div v-else>
      <div
        class="saas_card hoverable mb-10"
        v-for="workflowGroup in workflowGroups"
        :key="workflowGroup._id"
      >
        <div class="row-contents">
          <div
            class="group-text-container"
            @click="goToWorkflow(workflowGroup)"
          >
            <div class="group-text">
              <span class="title">{{ workflowGroup.name }}</span>
              <span class="muted-text" v-if="workflowGroup.description">
                {{ workflowGroup.description }}
              </span>
            </div>
          </div>
          <div class="metadata">
            <div class="controls">
              <a-tooltip>
                <template #title>Workflows</template>
                <a-tag>
                  <RocketOutlined />
                  {{ workflowGroup.workflows.length }}
                </a-tag>
              </a-tooltip>
              <a-tooltip>
                <a-button
                  style="margin-right: 5px"
                  type="link"
                  @click="openEditSettings(workflowGroup)"
                  ><SettingOutlined />
                </a-button>
              </a-tooltip>
            </div>
          </div>
        </div>
      </div>
      <a-button @click="addNewWorkflow"
        ><PlusOutlined /> Add new workflow</a-button
      >
    </div>

    <a-drawer
      placement="right"
      :closable="false"
      :width="300"
      :open="workflowSettings ? true : false"
      @close="closeEditSettings"
    >
      <template #title>
        <a-input
          v-model:value="editWorkflowGroup.name"
          placeholder="Workflow group name"
        />
      </template>
      <p>
        <a-textarea
          v-model:value="editWorkflowGroup.description"
          placeholder="Description..."
        />
      </p>

      <a-button type="primary" @click="saveWorkflowGroup">
        <FileDoneOutlined /> Save
      </a-button>
      <div
        :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
      >
        <a-button style="marginright: 8px" @click="closeEditSettings">
          Cancel
        </a-button>
        <a-popconfirm
          placement="topLeft"
          title="Are you sure delete this workflow group?"
          ok-text="Yes"
          cancel-text="No"
          @confirm="removeWorkflow(selectedWorkflowGroup)"
        >
          <a-button type="primary" danger>Delete</a-button>
        </a-popconfirm>
      </div>
    </a-drawer>
  </div>
</template>
<script>
import {
  FileDoneOutlined,
  PlusOutlined,
  RocketOutlined,
  SettingOutlined,
} from '@ant-design/icons-vue';
import workflowApi from '@workflow/workflow.api';
// eslint-disable-next-line import/no-cycle
import router from '@/router';

export default {
  components: {
    FileDoneOutlined,
    PlusOutlined,
    RocketOutlined,
    SettingOutlined,
  },
  data() {
    return {
      blueprintId: this.$route.query.blueprintId
        ? this.$route.query.blueprintId
        : null,

      loading: true,
      workflowGroups: {},

      workflowSettings: false,
      editWorkflowGroup: {
        name: '',
        description: '',
      },
      selectedWorkflowGroup: null,
    };
  },
  computed: {},
  created() {
    this.allWorkflowGroups();
  },
  methods: {
    addNewWorkflow() {
      this.$router.push({ name: 'AddWorkflow' });
    },
    async removeWorkflow(workflow) {
      const deleted = await workflowApi.deleteOneWorkflowGroup(workflow._id);
      if (deleted.success) {
        this.workflowGroups = this.workflowGroups.filter(
          w => w._id !== workflow._id
        );
      }
      this.closeEditSettings();
    },
    goToWorkflow(workflow) {
      router.push({
        name: 'WorkflowBuilder',
        params: { workflowGroupId: workflow._id },
      });
    },
    async allWorkflowGroups() {
      try {
        const workflowGroups = await workflowApi.getAllMyList({
          blueprintId: this.blueprintId,
        });
        this.workflowGroups = workflowGroups;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    openEditSettings(workflowGroup) {
      this.workflowSettings = true;
      this.editWorkflowGroup.name = workflowGroup.name;
      this.editWorkflowGroup.description = workflowGroup.description;
      this.selectedWorkflowGroup = workflowGroup;
    },
    closeEditSettings() {
      this.workflowSettings = false;
      this.selectedWorkflowGroup = null;
    },
    async saveWorkflowGroup() {
      this.selectedWorkflowGroup.name = this.editWorkflowGroup.name;
      this.selectedWorkflowGroup.description =
        this.editWorkflowGroup.description;

      await workflowApi.patchWorkflows(this.selectedWorkflowGroup);
      this.closeEditSettings();
    },
  },
};
</script>
<style scoped>
.padding-20 {
  padding: 20px;
}
</style>
