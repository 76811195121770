<template>
  <div style="padding-bottom: 100px">
    <a-card
      style="margin: 20px"
      v-for="feedback in feedbackList"
      :key="feedback._id"
    >
      <label style="font-weight: 500">Description:</label>
      <p>{{ feedback.description }}</p>
      <label style="font-weight: 500">Sent by:</label>
      <span>
        {{ feedback.user.firstName }} {{ feedback.user.lastName }} -
        {{ feedback.user.email }} on {{ formatDate(feedback.createdAt) }}
      </span>
      <br />
      <label style="font-weight: 500">Session URL:</label>
      <a :href="feedback.scope.sessionUrl">
        {{ shortenUrl(feedback.scope.sessionUrl) }}
      </a>
    </a-card>
  </div>
</template>

<script>
import { userApi } from '@userManagement/api';
import moment from 'moment';
// eslint-disable-next-line import/no-cycle
import router from '@/router';

export default {
  data() {
    return {
      feedbackList: [],
    };
  },
  mounted() {
    this.fetchFeedbackList();
  },
  methods: {
    async fetchFeedbackList() {
      try {
        this.feedbackList = await userApi.getFeedback();
      } catch {
        router.push('/');
      }
    },
    formatDate(value) {
      return moment(value).format('DD-MM-YYYY');
    },
    shortenUrl(value) {
      if (value && value.length > 50) {
        return `${value.substring(0, 100 - 3)}...`;
      }
      return value;
    },
  },
};
</script>

<style></style>
