import parsePhoneNumber from 'libphonenumber-js';
import { CNP } from 'romanian-personal-identity-code-validator';
import isValidHttpUrl from './isValidHttpUrl.method';

import FailureMssageByMethod from './FailureMessageByMethod.constant';

const ACCEPTANCE_VALIDATOR_METHODS_MAP = {
  is_email: value => {
    return value.match(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    );
  },
  is_cnp: value => {
    return new CNP(value).isValid();
  },
  is_phone_number: value => {
    const phoneNumber = parsePhoneNumber(
      value
      // field.structure.ruleset.countryCode
    );
    if (phoneNumber) {
      return phoneNumber.isValid();
    }
    return false;
  },
  is_website_url: value => {
    return isValidHttpUrl(value);
  },
  structure_option_minLength: (value, field) => {
    return value.length >= field.structure.options.minLength;
  },
};

export const validateAcceptance = (fieldValue, field) => {
  let isAcceptancePassed = true;
  const failureMessageList = [];
  for (let i = 0; i < field.acceptance?.validators?.length; i += 1) {
    const validator = field.acceptance?.validators[i];
    const validatorMethod = ACCEPTANCE_VALIDATOR_METHODS_MAP[validator.method];
    try {
      if (!validatorMethod(fieldValue, field)) {
        failureMessageList.push(FailureMssageByMethod[validator.method]);
        isAcceptancePassed = false;
      }
    } catch {
      failureMessageList.push(FailureMssageByMethod[validator.method]);
      isAcceptancePassed = false;
    }
  }
  return { isAcceptancePassed, failureMessageList };
};
