<template>
  <div v-if="type === 'audio/mpeg'" style="margin-top: 10px">
    <audio controls ref="audio" :src="src" @play="stopOthers()">
      Your browser does not support the audio tag.
    </audio>
  </div>
</template>
<script>
import {
  DataSystemActions,
  DataSystemGetters,
} from '../../../../shared/dataSystem.store';

export default {
  props: ['src', 'type'],
  data() {
    return {
      audioTypes: ['audio/mpeg'],
    };
  },
  computed: {
    runningAudioPlayerSrc() {
      return DataSystemGetters.getRunningAudioPlayerSrc();
    },
  },
  methods: {
    stopOthers() {
      DataSystemActions.setRunningAudioPlayerSrc(this.src);
    },
    stop() {
      if (this?.$refs?.audio) {
        this.$refs.audio.pause();
      }
    },
  },
  watch: {
    runningAudioPlayerSrc(newValue) {
      if (this.src !== newValue) {
        this.stop();
      }
    },
  },
};
</script>
