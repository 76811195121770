<template>
  <div>
    <div>
      <label class="Register_Label">E-mail address:</label>
      <a-input v-model:value="input.recipientEmail" />
    </div>
    <div>
      <label class="Register_Label">First name:</label>
      <a-input v-model:value="input.firstName" />
    </div>
    <div>
      <label class="Register_Label">Last name:</label>
      <a-input v-model:value="input.lastName" />
    </div>
    <div v-if="roleList.length">
      <label class="Register_Label">Role:</label>
      <a-select
        placeholder="Select app role"
        :disabled="!roleList"
        style="width: 100%"
        @change="setRole($event)"
      >
        <a-select-option
          v-for="roleItem in roleList"
          :value="roleItem._id"
          :key="roleItem._id"
          >{{ roleItem.name }}</a-select-option
        >
      </a-select>
    </div>
    <label class="Register_Label">Password</label>
    <a-input v-model:value="input.password" type="password" />
    <div>
      <label class="Register_Label">Confirm password</label>
      <a-input v-model:value="input.passwordConfirm" type="password" />
    </div>
    <div style="margin-top: 25px; text-align: center">
      <a-button @click="onClickRegister" type="primary" size="large">
        Create
      </a-button>
    </div>
  </div>
</template>

<script>
import { userApi } from '@userManagement/api';
import { message } from 'ant-design-vue';

export default {
  props: [
    'rankLabel',
    'user',
    'tenantId',
    'appId',
    'subtenantId',
    'emailList',
    'roleList',
  ],
  emits: ['reloadTable', 'cancel'],
  data() {
    return {
      input: {
        firstName: null,
        lastName: null,
        password: null,
        passwordConfirm: null,
        recipientEmail: null,
        role: undefined,
      },
    };
  },
  methods: {
    validateEmail(email) {
      return email.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      );
    },
    async onClickRegister() {
      if (
        Object.keys(this.input).some(
          key =>
            key !== 'role' &&
            (!this.input[key] || this.input[key].trim().length === 0)
        )
      ) {
        message.warning('All fields are required!');
        return;
      }
      if (!this.validateEmail(this.input.recipientEmail)) {
        message.warning('Invalid e-mail address!');
        return;
      }
      if (this.emailList.includes(this.input.recipientEmail)) {
        message.warning('E-mail address is already associated to an account !');
        return;
      }
      if (this.input.password.length < 8) {
        message.warning('Password should have at least 8 characters!');
        return;
      }
      if (this.input.password !== this.input.passwordConfirm) {
        message.warning('Passwords do not match!');
        return;
      }
      try {
        await userApi.createUser(
          this.user._id,
          this.rankLabel,
          this.tenantId,
          this.appId,
          this.subtenantId,
          {
            ...this.input,
            email: this.input.recipientEmail,
          }
        ); // has token
        message.success('Registered successfully!');
        this.$emit('reloadTable');
        this.$emit('cancel');
      } catch (error) {
        this.error = true;
        message.error('Something went wrong...');
      }
    },
    setRole(value) {
      this.input.role = value;
    },
  },
};
</script>
<style>
.Register_Label {
  padding-top: 10px;
  margin-bottom: 0;
}
</style>
