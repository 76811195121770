<template>
  <div>
    <div v-if="!templateLoading" id="element-to-convert">
      <RecursiveColumns
        :lastDepth="false"
        v-if="template && !template?.renderOnlyForXML"
        :viewType="viewType"
        :template="template"
        @exportXls="exportXls"
      />
    </div>
    <template v-else>
      <loading-spinner />
    </template>
  </div>
</template>

<script>
import RecursiveColumns from '@templateDataSystem/views/components/RecursiveColumns';

import { SlugGetters } from '@/slug.store';

import {
  NewTemplatesActions,
  NewTemplatesGetters,
  NewTemplatesMutations,
} from '@templateDataSystem/shared/newTemplateDataSystem.store';
import _ from 'lodash';
import { FilterConditions } from '@templateDataSystem/shared/FilterConditions';
import { instanceApi } from '@dataSystem/api';

export default {
  name: 'PublicTemplateDataSystemBuilder',
  components: {
    RecursiveColumns,
    // BlueprintFormLinkSharing
  },
  props: ['menuItem'],
  updated() {},
  computed: {
    downloadPDF: NewTemplatesGetters.getDownloadPDF,
    downloadXLSX: NewTemplatesGetters.getDownloadXLSX,
    tenantUrl: SlugGetters.getTenantUrl,
    appUrl: SlugGetters.getAppUrl,
    subtenantUrl: SlugGetters.getSubtenantUrl,
    templateLoading: NewTemplatesGetters.getTemplateLoading,
    //
    template() {
      return NewTemplatesGetters.getTemplate('main');
    },
  },
  data() {
    return {
      loading: false,

      htmlToPdfOptions: {
        margin: 5,

        filename: 'test-pdf',
      },

      viewType: this.$route.params.viewType ?? null,
      templateId: this.$route.params.templateId ?? null,
      subtenantSlug: this.$route.params.subtenantId ?? null,
      instanceId: this.$route.params.instanceId ?? null,

      isLinkSharingVisible: false,
      // template:null,
    };
  },
  async created() {
    this.loading = true;
    await NewTemplatesActions.init(
      this.templateId,
      this.instanceId,
      true,
      this.subtenantSlug
    );

    this.loading = false;
  },
  watch: {
    downloadPDF(status) {
      if (status) {
        this.generatePDF();
        NewTemplatesMutations.SET_DOWNLOAD_PDF(false);
      }
    },
    '$route.params.viewType': function (value) {
      this.viewType = value;
    },
    '$route.params': async function (data) {
      const instanceId = data.instanceId ?? null;
      const templateId = data.templateId ?? this.menuItem?.templateId ?? null;

      this.instanceId = instanceId;

      await NewTemplatesActions.init(templateId, instanceId);
    },
    async downloadXLSX(xslx) {
      if (
        xslx.status &&
        xslx.templateIdList.length &&
        xslx.blueprintIdList.length
      ) {
        await this.exportXls(
          xslx.templateIdList,
          xslx.blueprintIdList,
          xslx.columnsList,
          xslx.workbooksNames
        );
        NewTemplatesMutations.SET_DOWNLOAD_XLSX(false, [], []);
      }
    },
  },
  methods: {
    /* onProgress(event) {
      console.log(`Processed: ${event} / 100`);
    },
    hasGenerated() {
      alert('PDF generated successfully!');
    }, */

    openLinkSharingDrawer() {
      this.isLinkSharingVisible = true;
    },
    closeLinkSharingDrawer() {
      this.isLinkSharingVisible = false;
    },
    async exportXls(
      templateIdList,
      blueprintIdList,
      columnsList,
      workbooksNames
    ) {
      this.loading = true;
      const query = _.cloneDeep(
        NewTemplatesGetters.getLastQuery(templateIdList[0], blueprintIdList[0])
      );
      query.limit = null;
      const filterConditions = await FilterConditions(query.filterConditions);
      const queryStripParams = { ...query };
      const openInstance = NewTemplatesGetters.getMainInstance() ?? null;
      await instanceApi.getAllXlsx(
        blueprintIdList[0],
        queryStripParams,
        filterConditions,
        columnsList[0],
        workbooksNames[0],
        openInstance
      );
      this.loading = false;
    },
  },
};
</script>

<style scoped></style>
