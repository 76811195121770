<template>
  <div>
    <div class="details-container">
      <div
        class="content section"
        :class="sourceSection === 3 ? 'active' : ''"
        @click="selectSection"
      >
        <div>
          <CaretDownOutlined v-if="sourceSection === 3" />
          <CaretUpOutlined v-else />
          <strong>3. Configure fields and additional options</strong>
          <CheckCircleOutlined
            class="validate-section"
            v-if="source.fields && source.fields.length"
          />
        </div>
      </div>
      <template v-if="sourceSection === 3">
        <div class="details-container">
          <div class="content">
            <a-alert
              v-if="!source.fields"
              message="No connection configured!"
              banner
            />

            <a-button
              class="mb-3"
              type="primary"
              @click="additionalOptionsModal = true"
              >Additional options</a-button
            >

            <a-table
              v-if="source.fields"
              :row-selection="rowSelection"
              :loading="preloaderTable"
              rowKey="_id"
              :columns="tableColumns"
              :data-source="source.fields"
            >
              <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'output'">
                  <div>
                    <a-switch
                      v-model:checked="record.isIncludedInOutput"
                      @change="
                        isIncludedInOutput =>
                          onClickIncludeFieldInOutput(
                            source,
                            record,
                            isIncludedInOutput
                          )
                      "
                      data-cy="switchIncludeInOutput"
                    />
                  </div>
                </template>
                <template v-if="column.key === 'filter'">
                  <div>
                    <a-button
                      @click="onClickManageFilters(source, record)"
                      :disabled="!record.isIncludedInOutput"
                      style="margin-right: 10px"
                      size="small"
                      data-cy="buttonManageFilters"
                    >
                      Manage
                    </a-button>
                    <span data-cy="spanCountFilters">
                      {{ record.filters.filter(f => !f.new).length }} Filters
                    </span>
                  </div>
                </template>
              </template>
            </a-table>
          </div>
        </div>
      </template>
    </div>

    <a-modal
      v-model="additionalOptionsModal"
      title="Additional options"
      :footer="false"
      :width="1000"
    >
      <a-tabs type="card">
        <a-tab-pane :key="1" tab="Instance creation">
          <strong>Instance creation</strong><br /><br />

          <a-radio-group
            v-if="localSource.connectionDetail?.instanceCreation"
            v-model="localSource.connectionDetail.instanceCreation.creationType"
            :defaultValue="'default'"
          >
            <a-radio :value="'default'">
              <a-tooltip
                :mouseLeaveDelay="0"
                title="For JSON response if the base is an array, each element will create an instance. For XML and file download response only one instance will be created"
                >Default</a-tooltip
              >
            </a-radio>
            <a-radio
              :value="'fromXmlKeyArray'"
              v-if="localSource.connectionDetail.responseType === 'xml'"
            >
              <a-tooltip
                :mouseLeaveDelay="0"
                title="If the XML contains at least one list of items, the user will be able to choose it as the base of creating a list of instances"
                >From XML key with an array of values</a-tooltip
              >
            </a-radio>
          </a-radio-group>

          <div
            v-if="
              localSource.connectionDetail?.instanceCreation?.creationType ===
              'fromXmlKeyArray'
            "
            class="mt-3"
          >
            <a-select
              v-if="
                localSource.connectionDetail?.arrayKeysInXmlResponseObject &&
                localSource.connectionDetail.arrayKeysInXmlResponseObject.length
              "
              :value="
                !localSource.connectionDetail.instanceCreation?.choosenKey?._id
                  ? undefined
                  : localSource.connectionDetail.instanceCreation.choosenKey._id
              "
              @change="setXmlArrayForInstanceCreation"
              placeholder="Select a list from the XML response..."
              style="width: 300px"
            >
              <a-select-option
                v-for="arrayKey in localSource.connectionDetail
                  .arrayKeysInXmlResponseObject"
                :key="arrayKey._id"
                :value="arrayKey._id"
              >
                {{ arrayKey.key }}
              </a-select-option>
            </a-select>

            <span v-else
              >No lists were detected in the XML response object.</span
            >
          </div>
        </a-tab-pane>

        <a-tab-pane :key="2" tab="File download">
          <strong>File download response</strong><br />
          TODO: add option to redirect response as file download OR use response
          as a dataflow field that can be used in a Mapper to save the file in a
          File type field.
        </a-tab-pane>
      </a-tabs>

      <div style="text-align: right">
        <a-button type="primary" class="mt-3" @click="saveSourceChanges"
          >Save changes</a-button
        >
      </div>
    </a-modal>

    <ManageField
      :visible="fieldFilterList.visible"
      :dataProviderId="dataProviderId"
      :sourceId="fieldFilterList.sourceId"
      :fieldId="fieldFilterList.fieldId"
      :source="source"
      @close="onCloseFieldFilterList"
    />
  </div>
</template>

<script>
import { DataProviderActions } from '@dataProvider/shared/dataProvider.store';
import {
  CaretDownOutlined,
  CaretUpOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons-vue';
import ManageField from './manageField.vue';

export default {
  props: ['sourceSection', 'dataProviderId', 'sourceId', 'source'],
  emits: ['updateSource', 'ch-section'],
  data() {
    return {
      localSource: null,
      loading: false,
      preloaderTable: false,
      additionalOptionsModal: false,

      tableColumns: [
        {
          title: 'Field Name',
          dataIndex: 'expectedPath',
          key: '',
        },
        {
          title: 'Include in Output',
          dataIndex: 'output',
          key: 'output',
        },
        {
          title: 'Filters',
          key: 'filter',
          dataIndex: 'filter',
        },
      ],
      fieldFilterList: {
        visible: false,
        sourceId: null,
        fieldId: null,
      },
    };
  },
  created() {
    this.localSource = this.source;
  },
  computed: {
    primaryFieldId() {
      if (this.source.primaryFieldId == null) {
        return [];
      }
      return [this.source.primaryFieldId];
    },

    rowSelection() {
      if (!this.source.dataAlwaysNew) {
        return {
          columnTitle: 'Unique',
          type: 'radio',
          selectedRowKeys: this.primaryFieldId,
          onChange: this.onRowKeysChange,
        };
      }
      return null;
    },
  },

  methods: {
    saveSourceChanges() {
      this.$emit('saveSourceChanges', this.localSource);
      this.additionalOptionsModal = false;
    },
    setXmlArrayForInstanceCreation(id) {
      const selectedArrayKey =
        this.source.connectionDetail.arrayKeysInXmlResponseObject.find(
          item => item._id === id
        );
      this.localSource.connectionDetail.instanceCreation.choosenKey.key =
        selectedArrayKey.key;
      this.localSource.connectionDetail.instanceCreation.choosenKey.path =
        selectedArrayKey.path;
      this.localSource.connectionDetail.instanceCreation.choosenKey._id =
        selectedArrayKey._id;
    },
    async onRowKeysChange(fieldId) {
      this.preloaderTable = true;
      await DataProviderActions.updateOneSourceSafely(
        this.dataProviderId,
        this.sourceId,
        {
          primaryFieldId: fieldId[0],
        }
      );

      [this.localSource.primaryFieldId] = fieldId;
      this.$emit('updateSource', this.localSource);
      this.preloaderTable = false;
    },
    selectSection() {
      this.$emit('ch-section', 3);
    },
    onClickManageFilters(source, field) {
      this.fieldFilterList = {
        visible: true,
        sourceId: source._id,
        fieldId: field._id,
      };
    },
    onCloseFieldFilterList() {
      this.fieldFilterList = {
        visible: false,
        dataSourceId: null,
        fieldId: null,
      };
    },
    async onClickIncludeFieldInOutput(source, field, isIncludedInOutput) {
      await DataProviderActions.updateSourceField(
        this.dataProviderId,
        source._id,
        field._id,
        { isIncludedInOutput }
      );
    },
  },
  components: {
    ManageField,
    CaretDownOutlined,
    CaretUpOutlined,
    CheckCircleOutlined,
  },
};
</script>
<style scoped>
.section {
  position: relative;
  cursor: pointer;
}
.section:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
}
.section.active {
  background-color: whitesmoke;
}
.section .validate-section {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  font-size: 18px;
  color: #1aa967;
}
</style>
