<template>
  <div class="padding-20">
    <div
      v-if="loading"
      style="
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      "
    >
      <a-spin />
    </div>
    <div v-else>
      <a-tabs type="card">
        <a-tab-pane key="1" tab="Automatic Dataflows">
          <div
            class="saas_card hoverable"
            v-for="dataProvider in autoDataProviders"
            :key="dataProvider._id + listKey"
            style="margin-bottom: 10px"
            data-cy="cardProvider"
          >
            <div class="row-contents">
              <div
                class="group-text-container"
                @click="goToDataProvider(dataProvider)"
                data-cy="gotoProviderBuilder"
              >
                <div class="group-text">
                  <span class="title" data-cy="spanProviderName">
                    {{ dataProvider.name }}
                  </span>
                  <span class="muted-text" v-if="dataProvider.description">
                    {{ dataProvider.description }}
                  </span>
                </div>
              </div>
              <div class="metadata">
                <div class="controls">
                  <a-tag :color="dataProvider.isEnabled ? 'green' : 'red'">
                    <span v-if="dataProvider.isEnabled">Active</span>
                    <span v-else>Not active</span>
                  </a-tag>
                  <a-tooltip>
                    <a-button
                      style="margin-right: 5px"
                      type="link"
                      @click="openEditSettings(dataProvider)"
                      data-cy="btnProviderEdit"
                      ><SettingOutlined />
                    </a-button>
                  </a-tooltip>
                </div>
              </div>
            </div>
          </div>

          <a-button
            :loading="preloaderAddDataProvider"
            @click="onClickProviderCreateAutomatic"
            data-cy="btnProviderCreate"
          >
            <PlusOutlined />
            <span>Create new <strong>AUTOMATIC</strong> Dataflow</span>
          </a-button>
        </a-tab-pane>

        <a-tab-pane key="2" tab="Manual Dataflows">
          <div
            class="saas_card hoverable"
            v-for="dataProvider in manualDataProviders"
            :key="dataProvider._id + listKey"
            style="margin-bottom: 10px"
            data-cy="cardProvider"
          >
            <div class="row-contents">
              <div
                class="group-text-container"
                @click="goToDataProvider(dataProvider)"
                data-cy="gotoProviderBuilder"
              >
                <div class="group-text">
                  <span class="title" data-cy="spanProviderName">
                    {{ dataProvider.name }}
                  </span>
                  <span class="muted-text" v-if="dataProvider.description">
                    {{ dataProvider.description }}
                  </span>
                </div>
              </div>
              <div class="metadata">
                <div class="controls">
                  <a-tooltip>
                    <a-button
                      style="margin-right: 5px"
                      type="link"
                      @click="openEditSettings(dataProvider)"
                      data-cy="btnProviderEdit"
                      ><SettingOutlined />
                    </a-button>
                  </a-tooltip>
                </div>
              </div>
            </div>
          </div>

          <a-button
            :loading="preloaderAddDataProvider"
            @click="onClickProviderCreateManual"
            data-cy="btnProviderCreate"
          >
            <PlusOutlined />
            <span>Create new <strong>MANUAL</strong> Dataflow</span>
          </a-button>
        </a-tab-pane>
      </a-tabs>

      <a-drawer
        placement="right"
        :closable="false"
        :width="300"
        :open="filedMapperSettings"
        @close="closeEditSettings"
      >
        <p>
          <a-input
            v-model:value="editDataProviderItem.name"
            placeholder="Provider name"
            data-cy="inputProviderName"
          />
        </p>
        <p>
          <a-textarea
            v-model:value="editDataProviderItem.description"
            placeholder="Provider description..."
            data-cy="inputProviderDescription"
          />
        </p>

        <a-button
          type="primary"
          :loading="preloaderSaveDataProvider"
          @click="saveDataProvider"
          data-cy="btnProviderSave"
        >
          <FileDoneOutlined /> Save
        </a-button>
        <template #footer>
          <div
            :style="{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e8e8e8',
              padding: '10px 16px',
              textAlign: 'right',
              left: 0,
              background: '#fff',
              borderRadius: '0 0 4px 4px',
            }"
          >
            <a-button style="marginright: 8px" @click="closeEditSettings">
              Cancel
            </a-button>
            <a-popconfirm
              placement="topLeft"
              title="Are you sure delete this dataflow?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="removeDataProvider(selectedDataProviderItem)"
            >
              <a-button
                type="primary"
                danger
                :loading="preloaderRemoveDataProvider"
                data-cy="btnProviderDelete"
              >
                Delete
              </a-button>
            </a-popconfirm>
          </div>
        </template>
      </a-drawer>
    </div>
  </div>
</template>

<script>
import {
  FileDoneOutlined,
  PlusOutlined,
  SettingOutlined,
} from '@ant-design/icons-vue';
import {
  DataProviderActions,
  DataProviderGetters,
} from '@dataProvider/shared/dataProvider.store';

export default {
  name: 'DataProviderListBuilder',
  data() {
    return {
      listKey: 1,

      loading: false,
      preloaderSaveDataProvider: false,
      preloaderRemoveDataProvider: false,
      preloaderAddDataProvider: false,

      filedMapperSettings: false,
      editDataProviderItem: {
        name: '',
        description: '',
      },
      selectedDataProviderItem: null,
    };
  },

  async mounted() {
    await DataProviderActions.fetchDataProviderList();
  },

  computed: {
    dataProviderList: DataProviderGetters.getDataProviderList,
    autoDataProviders() {
      if (this.dataProviderList && Array.isArray(this.dataProviderList)) {
        return this.dataProviderList.filter(dp => dp.type === 'auto');
      }
      return [];
    },
    manualDataProviders() {
      if (this.dataProviderList && Array.isArray(this.dataProviderList)) {
        return this.dataProviderList.filter(dp => dp.type === 'manual');
      }
      return [];
    },
  },
  methods: {
    async onClickProviderCreateAutomatic() {
      this.preloaderAddDataProvider = true;
      await DataProviderActions.createOneDataProvider(
        {
          name: 'New Automatic Dataflow',
        },
        'auto'
      );
      this.listKey += 1;
      this.preloaderAddDataProvider = false;
    },
    async onClickProviderCreateManual() {
      this.preloaderAddDataProvider = true;
      await DataProviderActions.createOneDataProvider(
        {
          name: 'New Manual Dataflow',
        },
        'manual'
      );
      this.listKey += 1;
      this.preloaderAddDataProvider = false;
    },
    goToDataProvider(dataProvider) {
      this.$router.push({
        name: 'DataProviderInput',
        params: { dataProviderId: dataProvider._id },
      });
    },
    openEditSettings(dataProvider) {
      this.filedMapperSettings = true;
      this.editDataProviderItem.name = dataProvider.name;
      this.editDataProviderItem.description = dataProvider.description;
      this.selectedDataProviderItem = dataProvider;
    },
    async removeDataProvider(dataProvider) {
      this.preloaderRemoveDataProvider = true;

      await DataProviderActions.deleteOneDataProvider(dataProvider._id);

      this.preloaderRemoveDataProvider = false;
      this.closeEditSettings();
    },
    async saveDataProvider() {
      this.preloaderSaveDataProvider = true;

      await DataProviderActions.editOneDataProvider(
        this.selectedDataProviderItem._id,
        {
          name: this.editDataProviderItem.name,
          description: this.editDataProviderItem.description,
        }
      );

      this.selectedDataProviderItem.name = this.editDataProviderItem.name;
      this.selectedDataProviderItem.description =
        this.editDataProviderItem.description;

      this.preloaderSaveDataProvider = false;
      this.closeEditSettings();
    },
    closeEditSettings() {
      this.filedMapperSettings = false;
      this.selectedFiledMapper = null;
    },
  },
  components: { FileDoneOutlined, PlusOutlined, SettingOutlined },
};
</script>
<style scoped>
.padding-20 {
  padding: 20px;
}
</style>
