<template>
  <div v-if="!viewingMode">
    <a-input-group compact>
      <a-select
        class="mobile-input-phone"
        style="width: 150px"
        show-search
        :options="
          countryCodes.map(code => {
            return {
              value: code.code,
              label: code.name,
            };
          })
        "
        :filter-option="
          (input, option) =>
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
        "
        v-model:value="localPhonePrefixCountryCode"
        @change="emitCompletePhone"
      >
      </a-select>

      <a-input
        style="width: 70px"
        v-model:value="localPhonePrefixNumber"
        disabled
      />

      <a-input
        class="inputNumberHideArrows"
        style="width: calc(100% - 220px)"
        v-model:value="localPhoneNumber"
        :readOnly="readOnly"
        :required="field.isRequired"
        :placeholder="inputPlaceholder"
        :disabled="
          (field.isReadOnlyAdd && addNew) || (field.isReadOnlyEdit && !addNew)
        "
        @blur="e => $emit('blur', e)"
        @pressEnter="e => $emit('pressEnter', e)"
        @input="emitCompletePhone"
        :loading="waitForValidity"
      />
    </a-input-group>
  </div>

  <div v-else>
    {{ rawValue }}
  </div>
</template>

<script>
import parsePhoneNumber from 'libphonenumber-js';
import countryCodes from '@/core/constants/countryCodes.constant';
import InputMixin from './InputMixin';

export default {
  mixins: [InputMixin],
  emits: ['pressEnter'],
  data() {
    return {
      countryCodes,
      localPhoneNumber: '',
      localPhonePrefixCountryCode: '',
    };
  },
  mounted() {
    this.setLocalPhoneAndPrefix();
  },
  computed: {
    localPhonePrefixNumber() {
      if (this.localPhonePrefixCountryCode) {
        return this.countryCodes.find(
          cc => cc.code === this.localPhonePrefixCountryCode
        )?.prefix;
      }
      return '';
    },
  },
  watch: {
    localPhoneNumber(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.localPhoneNumber = newValue.replace(/[^0-9]/g, '');
      }
    },
  },
  methods: {
    setLocalPhoneAndPrefix() {
      if (!this.value) {
        this.localPhonePrefixCountryCode =
          this.field.structure.ruleset.countryCode;
      } else {
        const initialValue = this.value.replace(/[^0-9/+]/g, '');
        const phoneNumber = parsePhoneNumber(initialValue);

        if (phoneNumber) {
          if (phoneNumber.getPossibleCountries()?.length) {
            [this.localPhonePrefixCountryCode] =
              phoneNumber.getPossibleCountries();
            this.localPhoneNumber = initialValue.replace(
              this.localPhonePrefixNumber,
              ''
            );
          } else {
            this.localPhonePrefixCountryCode =
              this.field.structure.ruleset.countryCode;
            this.localPhoneNumber = initialValue.replace(
              this.localPhonePrefixNumber,
              ''
            );
          }
        } else {
          const phoneNumberWithDefaultPrefix = parsePhoneNumber(
            initialValue,
            this.field.structure.ruleset.countryCode
          );
          this.localPhonePrefixCountryCode =
            this.field.structure.ruleset.countryCode;
          if (phoneNumberWithDefaultPrefix) {
            this.localPhoneNumber = initialValue.replace(
              this.localPhonePrefixNumber,
              ''
            );
          } else {
            this.localPhoneNumber = initialValue;
          }
        }

        /* else {
          this.localPhonePrefixCountryCode = this.field.structure.ruleset.countryCode;
        } */
      }

      this.emitCompletePhone();
    },
    emitCompletePhone() {
      this.inputValue = `${this.localPhoneNumber.length && this.localPhonePrefixNumber.length ? this.localPhonePrefixNumber : ''}${this.localPhoneNumber.length ? this.localPhoneNumber : ''}`;
    },
  },
};
</script>

<style>
@media screen and (max-width: 600px) {
  .mobile-input-phone {
    width: 100px !important;
  }
  .inputNumberHideArrows {
    width: calc(100% - 170px) !important;
  }
}
</style>
