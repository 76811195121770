<template>
  <div>
    <div
      v-if="loading"
      style="position: absolute; width: 100vw; height: 100vh; top: 0; left: 0"
    >
      <a-spin
        style="
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        "
      />
    </div>
    <div v-else>
      <div class="mt-5 mb-10">
        <a-input
          v-model:value="filterString"
          placeholder="Search template"
          style="width: 300px"
        /><br />
        content: {{ appUrl }}
      </div>
      <a-row style="margin: 0 -10px; display: flex; flex-wrap: wrap">
        <a-col
          v-for="blueprint in visibleBlueprints"
          :key="blueprint.id"
          :span="12"
          style="padding: 0 10px 10px 10px"
        >
          <div
            class="saas_card hoverable mb-10 padd-10"
            style="height: 100%; display: flex; flex-direction: column"
          >
            <h3>{{ blueprint.name }}</h3>
            <div
              class="templatesContainer"
              v-if="blueprint.templates.length"
              style="flex-grow: 1"
            >
              <div
                v-for="template in blueprint.templates"
                :key="template._id"
                style="margin-bottom: 5px"
              >
                {{ template.name }} / {{ template.description }}
                <a-button
                  size="small"
                  type="primary"
                  @click="goToAddNewTemplate(blueprint.id, template._id)"
                  ><EditOutlined /> Edit</a-button
                >

                <a-popconfirm
                  title="Are you sure you want to duplicate this template?"
                  ok-text="Yes"
                  cancel-text="No"
                  @confirm="duplicateTemplate(template._id, blueprint.id)"
                >
                  <a-button
                    size="small"
                    class="ml-2"
                    :loading="templateIdDuplicateLoading === template._id"
                    ><EditOutlined /> Duplicate</a-button
                  >
                </a-popconfirm>

                <a-popconfirm
                  title="Are you sure you want to delete this template?"
                  ok-text="Yes"
                  cancel-text="No"
                  @confirm="deleteTemplate(template, blueprint.id)"
                >
                  <a-button size="small" type="primary" danger class="ml-2"
                    ><DeleteOutlined /> Delete</a-button
                  >
                </a-popconfirm>
              </div>
            </div>

            <div>
              <a-button
                size="small"
                style="float: right"
                @click="goToAddNewTemplate(blueprint.id)"
                ><PlusOutlined /> Adauga template</a-button
              >
              <div class="clearfix"></div>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import blueprintApi from '@dataSystem/api/blueprint.api';
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from '@ant-design/icons-vue';
import { SlugGetters } from '@/slug.store';
import templateManagementApi from '@/apps/templateManagement/templateManagement.api';
import { notification } from 'ant-design-vue';

export default {
  name: 'templateManagementList',
  components: {
    DeleteOutlined,
    EditOutlined,
    PlusOutlined,
  },
  created() {
    this.getAllTemplates();
  },
  updated() {},
  computed: {
    tenantUrl: SlugGetters.getTenantUrl,
    appUrl: SlugGetters.getAppUrl,

    visibleBlueprints() {
      if (this.filterString.length < 3) {
        return this.blueprints;
      }
      return this.blueprints.filter(b => {
        // template in blueprint.templates
        let visible = false;
        b.templates.every(template => {
          if (
            template.name
              .toLowerCase()
              .indexOf(this.filterString.toLowerCase()) !== -1
          ) {
            visible = true;
            return false;
          }
          return true;
        });
        return visible;
      });
    },
  },
  data() {
    return {
      loading: true,
      templateIdDuplicateLoading: null,
      filterString: '',

      blueprints: [],
    };
  },
  methods: {
    goToAddNewTemplate(blueprintId, templateId = null) {
      this.$router.push({
        name: 'TemplateManagementBuilder',
        params: { templateId },
      });
    },

    async duplicateTemplate(templateId, blueprintId) {
      this.templateIdDuplicateLoading = templateId;
      const duplicatedTemplate =
        await templateManagementApi.duplicateOneTemplate(templateId);
      if (duplicatedTemplate) {
        this.blueprints
          .find(bp => bp.id === blueprintId)
          ?.templates?.push(duplicatedTemplate);
      }

      setTimeout(() => {
        this.templateIdDuplicateLoading = null;
      }, 500);
    },

    async deleteTemplate(template, blueprintId) {
      const deleteTemplate = await templateManagementApi.deleteOneTemplate(
        template._id
      );
      if (deleteTemplate.success) {
        notification.success({
          message: 'Template deleted!',
          description: `Template '${template.name}' was successfully deleted!`,
        });
        const bp = this.blueprints.find(bprint => bprint.id === blueprintId);
        if (bp) {
          bp.templates = bp.templates?.filter(tmpl => tmpl !== template);
        }
      }
    },

    async getAllTemplates() {
      try {
        const blueprintsDb = await blueprintApi.getAll();
        const templatesDb = await templateManagementApi.getAllTemplates();

        const blueprints = [];

        if (blueprintsDb.length) {
          blueprintsDb.forEach(bp => {
            const newBp = {
              ...bp,
              templates: templatesDb.filter(
                template => template.blueprintId === bp.id
              ),
            };
            blueprints.push(newBp);
          });
        }

        this.blueprints = blueprints;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
