const initialData = {
  math: null,
  fieldIdToValue: {},
  fieldIdToCalculationDependencies: {},
  fieldIdToLastMathEval: {},
};

const init = ({ fieldsById, instance }) => {
  let fieldIdToValue = {};
  const fieldIdToCalculationDependencies = {};

  if (instance) {
    fieldIdToValue = { ...instance };
  } else {
    fieldIdToValue = Object.fromEntries(
      Object.keys(fieldsById).map(fieldId => [fieldId, null])
    );
  }

  return { fieldIdToValue, fieldIdToCalculationDependencies };
};

const getFields = ({ blueprint, fieldsById }) => {
  if (!blueprint || !blueprint.idListOfFields) {
    return [];
  }
  return blueprint.idListOfFields.map(fieldId => {
    return {
      ...fieldsById[fieldId],
    };
  });
};

const computeFieldValue = ({ math, fieldId, fieldsById }) => {
  const mathEval = math.evaluate(
    fieldsById[fieldId].logic.calculation.expression
  );

  return mathEval;
};

const computeFieldIdToValue = ({
  math,
  fieldIdToValue,
  fieldIdToCalculationDependencies,
  fieldsById,
}) => {
  const newFieldIdToValue = JSON.parse(JSON.stringify(fieldIdToValue));

  Object.keys(fieldIdToValue).forEach(fieldId => {
    if (fieldsById[fieldId]?.structure?.type !== 'file') {
      const idListOfDependantFields = fieldIdToCalculationDependencies[fieldId];
      if (idListOfDependantFields && idListOfDependantFields.length > 0) {
        idListOfDependantFields.forEach(dependantFieldId => {
          newFieldIdToValue[dependantFieldId] = computeFieldValue({
            math,
            fieldId: dependantFieldId,
            fieldsById,
          });
        });
      }
    } else {
      newFieldIdToValue[fieldId] = fieldIdToValue[fieldId];
    }
  });

  return newFieldIdToValue;
};

export default {
  initialData,
  init,
  getFields,
  computeFieldIdToValue,
};
