<template>
  <div :class="cardView ? 'saas_card' : ''">
    <div :class="cardView ? 'padd-10' : ''">
      <div
        class="row mb-3"
        v-for="(query, index) in updatedValue"
        :key="'param_' + index"
      >
        <div class="col-3">
          <a-input v-model:value="query.key" placeholder="Key" />
        </div>

        <div class="col-3">
          <a-select
            v-model:value="query.valueType"
            default-value="static"
            style="width: 100%"
          >
            <a-select-option v-if="allowFieldSelection" :value="'field'">
              Field
            </a-select-option>
            <a-select-option :value="'static'">Static value</a-select-option>
            <a-select-option v-if="objectType === 'list'" :value="'count'"
              >Count</a-select-option
            >
          </a-select>
        </div>

        <div :class="'col-5'">
          <a-select
            v-if="query.valueType === 'field'"
            v-model:value="query.fieldId"
            @change="addFieldNameAndType(query)"
            placeholder="Field"
            :allowClear="true"
            show-search
            :filter-option="filterOption"
            style="width: 100%"
          >
            <a-select-option :value="'_id'">ID (number)</a-select-option>
            <a-select-option
              v-for="field in availableFields"
              :key="field.id"
              :value="field.id"
            >
              {{ field.label }} {{ field.type ? '(' + field.type + ')' : '' }}
            </a-select-option>
          </a-select>
          <a-input
            v-else-if="query.valueType === 'static'"
            v-model:value="query.staticValue"
            placeholder="Value"
          />
        </div>

        <div class="col-1">
          <a-button @click="remove(query)"><DeleteOutlined /></a-button>
        </div>
      </div>
      <a-button size="small" @click="add()"
        ><PlusOutlined />
        {{
          updatedValue.length ? 'Add new parameter' : 'Add parameter'
        }}</a-button
      >
    </div>
  </div>
</template>

<script>
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons-vue';

export default {
  name: 'fileExportingParameters',
  // props: ['modelValue', 'cardView', 'availableFields', 'objectType'],
  props: {
    modelValue: {},
    cardView: {},
    availableFields: {},
    objectType: {},
    allowFieldSelection: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:modelValue'],
  components: {
    DeleteOutlined,
    PlusOutlined,
  },
  data() {
    return {
      loading: false,

      updatedValue: [],
    };
  },

  mounted() {
    if (this.modelValue) {
      this.updatedValue = this.modelValue;
    }
  },

  watch: {
    updatedValue() {
      this.$emit('update:modelValue', this.updatedValue);
    },
    // updatedValue: {
    //   deep: true,
    //   handler() {
    //     let body = this.updatedValue?.body[0]?.key ? this.updatedValue.body : [
    //       { key: undefined, value: undefined, keyValue: undefined }
    //     ];
    //     let headers = this.updatedValue?.headers[0]?.key ? this.updatedValue.headers : [
    //       { key: undefined, value: undefined, keyValue: undefined }
    //     ];
    //     let query = this.updatedValue?.query[0]?.key ? this.updatedValue.query : [
    //       { key: undefined, value: undefined, keyValue: undefined }
    //     ];
    //
    //     this.$emit("input", {
    //       method: this.updatedValue.method,
    //       url: this.updatedValue.url,
    //       openInTab: this.updatedValue.openInTab,
    //       dataType: this.updatedValue.dataType,
    //       dataParseType: this.updatedValue.dataParseType,
    //       body: body,
    //       headers: headers,
    //       query: query,
    //     });
    //   },
    // },
  },

  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    addFieldNameAndType(parameterObj) {
      const field = this.availableFields.find(
        item => item.id === parameterObj.fieldId
      );
      parameterObj.fieldName =
        parameterObj.fieldId === '_id' ? '_id' : (field?.label ?? '');
      parameterObj.fieldType =
        parameterObj.fieldId === '_id' ? 'number' : (field?.type ?? '');
    },
    add() {
      this.updatedValue.push({
        key: undefined,
        value: undefined,
        staticValue: '',
        valueType: 'static',
      });
    },
    remove(row) {
      const key = this.updatedValue.indexOf(row);
      this.updatedValue = this.updatedValue.filter(
        r => this.updatedValue.indexOf(r) !== key
      );
    },
  },
};
</script>
<style scoped>
.padding-20 {
  padding: 20px;
}
.dataRow {
  position: relative;
  display: table;
  width: 100%;
}
.dataRow .cell {
  display: table-cell;
}
.dataRow .w120 {
  width: 120px;
}
.dataRow .w50 {
  width: 50px;
}
.dataRow .cell.pl-15 {
  padding-left: 15px;
}
.dataRow .cell.l-line {
  border-left: 1px solid #ddd;
}

.options-content {
  padding: 15px 10px 10px 10px;
  border: 1px solid #ddd;
  border-radius: 0 4px 4px 4px;
  margin-top: -5px;
  background-color: #f1f4f5;
}
.mb-10 {
  margin-bottom: 10px;
}
.mt-10 {
  margin-top: 10px;
}
.clear::after {
  content: '';
  width: 100%;
  display: block;
  clear: both;
}
</style>
