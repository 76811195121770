<template>
  <div>
    <div style="margin: 10px 0px">
      <label>Minimum characters length</label>
      <a-input-number
        style="display: block; width: 100%"
        placeholder="No minimum set"
        v-model:value="minLength"
      />
    </div>
    <div style="margin: 10px 0px">
      <label>Maximum characters length</label>
      <a-input-number
        style="display: block; width: 100%"
        placeholder="No maximum set"
        v-model:value="input.maxLength"
      />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import FieldInputOptionsMixin from '../../FieldInputOptionsMixin';

export default {
  mixins: [FieldInputOptionsMixin],
  fieldInputOptions: {
    path: 'structure.options',
    keys: ['minLength', 'maxLength'],
  },
  data() {
    return {
      minLength: null,
    };
  },
  mounted() {
    this.minLength = this.input.minLength;
  },
  methods: {
    validateInput(input) {
      if (
        (input.minLength === null || typeof input.minLength === 'number') &&
        (input.maxLength === null || typeof input.maxLength === 'number')
      ) {
        return { isInputValid: true, validatedInput: input };
      }
      return { isInputValid: false };
    },
  },
  watch: {
    minLength(value) {
      this.input.minLength = value;
      if (value && value !== '') {
        if (
          !this.fieldInput.acceptance.validators.find(
            validator => validator.method === 'structure_option_minLength'
          )
        )
          this.fieldInput.acceptance.validators.push({
            method: 'structure_option_minLength',
          });
      } else {
        const updatedField = _.cloneDeep(this.fieldInput);
        updatedField.acceptance.validators =
          this.fieldInput.acceptance.validators.filter(validator => {
            return validator.method !== 'structure_option_minLength';
          });
        this.updateEntireField(updatedField);
      }
    },
  },
};
</script>

<style></style>
