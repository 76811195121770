<template>
  <div class="columns">
    <a-tabs
      v-if="template.tabsView"
      :type="template?.tabViewType ?? ''"
      style="width: 100%"
    >
      <template v-for="(column, i) in columnGroups" :key="i">
        <a-tab-pane
          :key="i"
          :tab="column.name"
          v-if="!column?.renderOnlyForXML"
          :style="column?.inlineStyle ?? ''"
        >
          <div
            :class="
              template?.tabViewType === 'card'
                ? 'box-column-tab-type-card'
                : 'box-column'
            "
            :style="
              'width: calc(' +
              column.width +
              '% - ' +
              (column.width < 100 && i > 0 ? '10' : '0') +
              'px)'
            "
          >
            <RecursiveColumns
              :viewType="viewType"
              :lastDepth="lastDepth"
              :instanceId="instanceId"
              v-if="column?.columnGroups.length"
              :template="column"
            />
            <div
              v-else
              :class="(column?.cssClasses ?? []).join(' ')"
              :style="column?.inlineStyle ?? ''"
            >
              <ElementData
                :viewType="viewType"
                :lastDepth="lastDepth"
                :instanceId="instanceId"
                :object="column.object"
              />
            </div>
          </div>
        </a-tab-pane>
      </template>
    </a-tabs>
    <template v-else>
      <template v-for="(column, i) in columnGroups" :key="i">
        <div
          v-if="!column?.renderOnlyForXML"
          :class="
            (column?.defaultBackground ?? true)
              ? 'mobile-row r-row'
              : 'mobile-row r-row-no-bg'
          "
          :style="
            'width: calc(' +
            column.width +
            '% - ' +
            (column.width < 100 && i > 0 ? '10' : '0') +
            'px)'
          "
        >
          <RecursiveColumns
            :viewType="viewType"
            :lastDepth="lastDepth"
            :instanceId="instanceId"
            v-if="column?.columnGroups.length"
            :template="column"
          />
          <div
            v-else
            :class="(column?.cssClasses ?? []).join(' ')"
            :style="column?.inlineStyle ?? ''"
          >
            <ElementData
              :viewType="viewType"
              :lastDepth="lastDepth"
              :instanceId="instanceId"
              :object="column.object"
            />
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { NewTemplatesGetters } from '@templateDataSystem/shared/newTemplateDataSystem.store';

import {
  asyncFilter,
  filterTypeHandlerMap,
} from '@/core/utils/array-manipulation';
import ElementData from './ElementData.vue';

export default {
  name: 'RecursiveColumns',
  props: ['template', 'viewType', 'lastDepth', 'instanceId'],
  components: {
    ElementData,
  },
  data() {
    return {
      columnGroups: [],
    };
  },
  async created() {
    await this.generateColumnGroups();
  },
  computed: {
    createdEditedDeletedInstance:
      NewTemplatesGetters.getCreatedEditedDeletedInstance,

    instanceByBlueprint() {
      return NewTemplatesGetters.getMainInstance();
    },
  },
  watch: {
    createdEditedDeletedInstance(data) {
      if (!data.status) {
        this.generateColumnGroups();
      }
    },
    instanceByBlueprint() {
      this.generateColumnGroups();
    },
  },
  methods: {
    async generateColumnGroups() {
      this.columnGroups = await asyncFilter(
        this.template.columnGroups,
        async item => {
          return this.visible(item?.visibilityConditions);
        }
      );
    },

    async visible(visibilityConditions) {
      if (visibilityConditions && visibilityConditions.length) {
        const comparisonValue = [];
        await Promise.all(
          visibilityConditions.map(async item => {
            if (
              this.instanceByBlueprint &&
              this.instanceByBlueprint[item.fromFieldId]
            ) {
              const comparison = filterTypeHandlerMap[item.comparation];
              if (comparison instanceof Function) {
                if (comparisonValue.length) {
                  comparisonValue.push(
                    item.operation === 'and' ? ' && ' : ' || '
                  );
                }
                comparisonValue.push(
                  comparison(
                    this.instanceByBlueprint[item.fromFieldId],
                    item.againstFieldValue
                  )
                );
              }
            }
          })
        );
        if (comparisonValue.length) {
          // eslint-disable-next-line no-eval
          return eval(comparisonValue.join(' '));
        }
        return false; // if exist visibilityConditions and instanceByBlueprint not load
      }
      return true; // if not exist visibilityConditions
    },
  },
};
</script>
<style scoped>
.columns {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
}
.columns .r-row {
  border-radius: 4px;
  padding: 10px;
  background-color: rgba(190, 190, 190, 0.1);
}
.columns .r-row-no-bg {
  border-radius: 4px;
}

.box-column-tab-type-card {
  padding: 10px;
  background-color: #fff;
  margin-top: -16px;
  border: 1px solid #e8e8e8;
  border-top: 0;
  border-radius: 0 0 4px 4px;
}

@media screen and (max-width: 600px) {
  .mobile-row {
    width: 100%;
    display: block;
    flex: 100%;
  }
}
</style>
