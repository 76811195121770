<template>
  <!--  <a-input v-if="fieldStructureType === 'string'" v-model:value="inputValue" />
  <a-input-number
    v-else-if="fieldStructureType === 'number'"
    v-model:value="inputValue"
  />
  <a-select
    v-else-if="fieldStructureType === 'boolean'"
    :default-value="inputValue === true ? 'true' : 'false'"
    @change="onBooleanValueSelect"
  >
    <a-select-option value="true">True</a-select-option>
    <a-select-option value="false">False</a-select-option>
  </a-select>
  <a-input
    v-else-if="fieldStructureType === 'not_selected'"
    placeholder="Please select a field"
    :disabled="true"
  />-->
  <div>
    <div v-if="fieldStructureType === 'reference'">
      <a-select
        placeholder="Select subtenant"
        v-model:value="subtenantSlug"
        style="width: 100%; margin-bottom: 5px"
        :options="subtenantOptions"
        @change="
          getReferenceInstanceOptions();
          emitSelection();
        "
      >
      </a-select>
      <a-select
        v-model:value="inputValue"
        :options="referenceOptions"
        style="width: 100%"
        :loading="referenceOptionsLoading"
        @change="emitSelection"
      ></a-select>
    </div>

    <div v-else>
      <a-input v-model:value="inputValue" @input="emitSelection" />
    </div>
  </div>
</template>

<script>
// TODO: Implement Input for Array and Json
// TODO: Implement logic for field.choices
// TODO: Decide if this component is needed or if FieldWidget can be used instead
import { TenantsGetters } from '@tenants/shared/tenants.store';
import blueprintApi from '@dataSystem/api/blueprint.api';
import subtenantApi from '@tenants/api/subtenant.api';
import { FormBuilderGetters } from '../../../formBuilder.store';

export default {
  props: ['fieldId', 'condition', 'fieldType'],
  emits: ['update:modelValue', 'expectedValue'],
  data() {
    return {
      inputValue: null,
      subtenantSlug: undefined,
      subtenantOptions: [],
      selectedFieldType: null,

      referenceOptions: [],
      referenceOptionsLoading: false,
    };
  },
  computed: {
    fieldStructureType() {
      if (!this.fieldId) {
        return 'not_selected';
      }
      if (this.fieldType) {
        return this.fieldType;
      }
      const field = FormBuilderGetters.getFieldInput(this.fieldId);
      return field.structure.type;
    },
    selectedBlueprintId() {
      if (this.fieldStructureType !== 'reference') {
        return 'not_reference';
      }

      const field = FormBuilderGetters.getFieldInput(this.fieldId);
      return field.structure.ruleset.blueprintId;
    },
  },
  async mounted() {
    await this.getAppSubtenants();
    this.selectedFieldType = this.fieldStructureType;

    if (this.condition.expectedValue !== undefined) {
      this.inputValue = this.condition.expectedValue;
    }
    if (
      this.condition.subtenantSlug &&
      this.condition.subtenantSlug !== '' &&
      this.fieldStructureType === 'reference'
    ) {
      this.subtenantSlug = this.condition.subtenantSlug;
      await this.getReferenceInstanceOptions();
    }
  },
  watch: {
    // inputValue: {
    //   deep: true,
    //   handler() {
    //     this.emitInputValue();
    //     /*if (this.inputValue === null) {
    //       this.emitInputValue();
    //     }
    //     if (typeof this.inputValue === this.fieldStructureType) {
    //       this.emitInputValue();
    //     }*/
    //   },
    // },
    /* fieldStructureType: {
      handler() {
        if (this.fieldStructureType === 'reference') {
          //this.getReferenceInstanceOptions();
        }
        else {
          this.referenceOptions = [];
        }
      },
    }, */
    fieldId() {
      if (
        this.fieldStructureType !== this.selectedFieldType ||
        this.fieldStructureType === 'reference'
      ) {
        this.subtenantSlug = '';
        this.inputValue = '';
      }
      this.selectedFieldType = this.fieldStructureType;
      this.emitSelection();
    },
  },
  methods: {
    emitSelection() {
      this.$emit('expectedValue', {
        subtenantSlug: this.subtenantSlug ?? '',
        value: this.inputValue,
      });
    },

    async getAppSubtenants() {
      let subtenants = TenantsGetters.getAllSubtenants(); // pentru blueprint Global sa arate toti subtenatii si sa nu crape
      if (subtenants.length === 0) {
        subtenants = await subtenantApi.getAll();
      }
      this.subtenantOptions = subtenants.map(function (s) {
        return {
          label: s.name,
          value: s.slug,
        };
      });
    },

    async getReferenceInstanceOptions() {
      if (this.condition.blueprintId) {
        this.referenceOptionsLoading = true;
        this.referenceOptions = [];
        console.log(
          this.fieldId,
          this.subtenantSlug,
          this.condition.blueprintId
        );
        const instances = await FormBuilderGetters.getFieldInstances(
          this.fieldId,
          this.subtenantSlug,
          this.condition.blueprintId
        );
        const bp = await blueprintApi.getOne(this.selectedBlueprintId);
        const bpDisplayFieldId = bp.blueprint.displayFieldId;

        this.referenceOptions = instances.map(function (i) {
          return {
            value: i._id,
            label: i[bpDisplayFieldId], // trebuie scos display field pt a afisa testul default
          };
        });

        this.referenceOptionsLoading = false;
      }
    },
    emitInputValue() {
      this.$emit('update:modelValue', this.inputValue);
    },
    onBooleanValueSelect(value) {
      this.inputValue = value === 'true';
    },
  },
};
</script>

<style></style>
