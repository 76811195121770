import axios from 'axios';

import { SlugGetters } from '@/slug.store';

const { getAppUrl, getAppUrlWithGlobal } = SlugGetters;

const getAll = async () => {
  try {
    const response = await axios.get(`${getAppUrl()}/providers/`);
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

const getOne = async dataProviderId => {
  try {
    const response = await axios.get(
      `${getAppUrl()}/providers/${dataProviderId}`
    );
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

const postOne = async (data, type = 'auto') => {
  try {
    const response = await axios.post(`${getAppUrl()}/providers/${type}`, data);
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

const putOne = async (providerId, data) => {
  try {
    const response = await axios.put(
      `${getAppUrl()}/providers/${providerId}`,
      data
    );
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

const getData = async providerId => {
  try {
    const response = await axios.get(
      `${getAppUrl()}/providers/${providerId}/data`
    );
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

const deleteOne = async providerId => {
  try {
    await axios.delete(`${getAppUrl()}/providers/${providerId}`);
    return true;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

const runManualDataflow = async (providerId, data) => {
  try {
    return await axios.post(
      `${getAppUrlWithGlobal()}/providers/run-manually/${providerId}`,
      data,
      { responseType: 'blob' }
    );
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

export default {
  getAll,
  putOne,
  getOne,
  postOne,
  getData,
  deleteOne,
  runManualDataflow,
};
