<template>
  <div>
    <a-timeline>
      <a-timeline-item
        v-for="workflow in workflows"
        :key="workflow._id"
        :color="workflow.activ ? 'green' : ''"
        @click="openWorkflow(workflow)"
        class="pointer"
      >
        <template #dot>
          <CheckCircleOutlined v-if="workflow.activ" />
          <ExclamationCircleOutlined v-else />
        </template>
        <div>
          <span :class="workflowId === workflow._id ? 'strong' : ''">
            {{ workflow.name }}
          </span>
        </div>

        <div
          v-for="(event, key) in workflow.events"
          :key="event._id"
          @click="openEvent(event)"
          style="margin: 0"
        >
          <div
            class="workflows"
            :style="selectedEventId === event._id ? 'color:#000' : ''"
          >
            <span
              :style="selectedEventId === event._id ? 'font-weight: bold' : ''"
            >
              {{ key + 1 }}.
              {{ event.name }}
            </span>
          </div>
        </div>

        <a-button
          type="link"
          @click="openWorkflowSettings(workflow)"
          class="actions__pull-right-top"
          size="small"
          ><SettingOutlined
        /></a-button>
      </a-timeline-item>

      <a-timeline-item>
        <template #dot>
          <LoadingOutlined v-if="loadingWorkflow" />
          <PlusCircleOutlined v-else />
        </template>
        <a @click="createWorkflow()">Add new workflow</a>
      </a-timeline-item>
    </a-timeline>

    <workflow-settings
      v-if="selectedWorkflow"
      :workflowGroupId="workflowGroupId"
      :workflows="workflows"
      :openedWorkflowId="openedWorkflowId"
      :selectedWorkflow="selectedWorkflow"
      @settings-close="closeWorkflowSettings"
    ></workflow-settings>
  </div>
</template>

<script>
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusCircleOutlined,
  SettingOutlined,
} from '@ant-design/icons-vue';
import {
  WorkflowActions,
  WorkflowGetters,
} from '@workflow/shared/workflow.store';
import WorkflowSettings from './WorkflowSettings.vue';

export default {
  props: ['workflowGroupId', 'workflowId', 'selectedEventId', 'workflows'],
  emits: ['open:event', 'open:workflow', 'autosave'],
  computed: {
    selectedWorkflow() {
      return WorkflowGetters.getSelectedWorkflow(this.workflowId);
    },
  },

  data() {
    return {
      openedWorkflowId: null,

      loadingWorkflow: false,
      workflow: {
        _id: this.workflowGroupId,
        name: 'New workflow name',
      },
      eventId: this.$route.params.eventId ? this.$route.params.eventId : 0,
    };
  },

  methods: {
    openEvent(event) {
      const elem = document.getElementById(`event_${event._id}`);
      // const el = this;
      if (elem) {
        setTimeout(function () {
          elem.scrollIntoView({ behavior: 'smooth', block: 'start' });
          // window.scroll(0, el.findPosition(elem));
        }, 200);
      } else {
        setTimeout(function () {
          const elem2 = document.getElementById(`event_${event._id}`);
          if (elem2) {
            elem2.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
          // window.scroll(0, el.findPosition(elem));
        }, 600);
      }
      this.$emit('open:event', event._id);
    },
    openWorkflowSettings(workflow) {
      this.openedWorkflowId = workflow._id;
    },
    closeWorkflowSettings() {
      this.openedWorkflowId = null;
    },

    openWorkflow(event) {
      this.$emit('open:workflow', event);
    },
    async createWorkflow() {
      this.loadingWorkflow = true;

      await WorkflowActions.addOneWorkflow(this.workflow);

      this.loadingWorkflow = false;
      this.$emit('autosave', false);
    },
    findPosition(obj) {
      let currentTop = -80;
      while (obj && obj?.offsetParent) {
        currentTop += obj.offsetTop;
        window.scroll(0, [currentTop]);
      }
      return [currentTop];
    },
  },
  components: {
    WorkflowSettings,
    CheckCircleOutlined,
    ExclamationCircleOutlined,
    LoadingOutlined,
    PlusCircleOutlined,
    SettingOutlined,
  },
};
</script>

<style scoped>
.actions__pull-right-top {
  position: absolute !important;
  top: 0px;
  right: 0px;
  float: right;
}

.workflows {
  position: relative;
}
.workflows::before {
  content: '';
  position: absolute;
  height: 2px;
  width: 8px;
  display: block;
  background-color: #e8e8e8;
  left: -13px;
  top: 50%;
  transform: translateY(-50%);
}
</style>
