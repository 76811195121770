import axios from 'axios';

import { SlugGetters } from '@/slug.store';

const { getAppUrl, getSubtenantUrl } = SlugGetters;

const getAllMyList = async data => {
  try {
    const response = await axios.post(`${getAppUrl()}/workflow/list`, {
      data,
    });
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response.data.message,
    };
  }
};

const getOneWorkflowById = async workflowId => {
  try {
    const response = await axios.get(`${getAppUrl()}/workflow/${workflowId}`);
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response.data.message,
    };
  }
};

const postOneWorkflowGroup = async data => {
  try {
    const response = await axios.post(
      `${getAppUrl()}/workflow/group/add`,
      data
    );
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response.data.message,
    };
  }
};

const postOneWorkflow = async data => {
  try {
    const response = await axios.post(`${getAppUrl()}/workflow/add`, {
      data,
    });
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response.data.message,
    };
  }
};

const postOneWorkflowEvent = async data => {
  try {
    const response = await axios.post(`${getAppUrl()}/workflow/event/add`, {
      data,
    });
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response.data.message,
    };
  }
};

const patchWorkflows = async data => {
  try {
    const response = await axios.patch(`${getAppUrl()}/workflow/`, data);
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response.data.message,
    };
  }
};

const continueWorkflowEvent = async data => {
  try {
    const response = await axios.put(
      `${getSubtenantUrl()}/workflow/continue/event`,
      data
    );
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response.data.message,
    };
  }
};

const getBlueprintByIdCounter = async data => {
  try {
    const response = await axios.post(`${getAppUrl()}/workflow/count`, {
      data,
    });
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response.data.message,
    };
  }
};

const deleteOneWorkflowGroup = async workflowId => {
  try {
    const response = await axios.delete(
      `${getAppUrl()}/workflow/${workflowId}`
    );
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response.data.message,
    };
  }
};

export default {
  getAllMyList,
  getOneWorkflowById,

  postOneWorkflowGroup,
  postOneWorkflow,
  postOneWorkflowEvent,

  patchWorkflows,

  getBlueprintByIdCounter,

  continueWorkflowEvent,
  deleteOneWorkflowGroup,
};
