<template>
  <div>
    <h3>SFTP Connection</h3>
    <div class="dataRow mb-10">
      <div class="cell">
        <a-input addon-before="Host" v-model:value="updatedValue.host" />
      </div>
      <div class="cell w220 pl-15">
        <a-input addon-before="Port" v-model:value="updatedValue.port" />
      </div>
    </div>
    <div class="dataRow mb-10">
      <div class="cell p50">
        <a-input addon-before="User" v-model:value="updatedValue.user" />
      </div>
      <div class="cell p50 pl-15">
        <a-input
          addon-before="Password"
          v-model:value="updatedValue.password"
        />
      </div>
    </div>

    <div class="dataRow mb-10">
      <div class="cell">
        <a-input
          addon-before="Surfix"
          v-model:value="updatedValue.fileSurfix"
          placeholder="/"
        />
      </div>
      <div class="cell w220 pl-15">
        <a-input
          addon-before="Extension"
          v-model:value="updatedValue.fileExtension"
          placeholder="ex.: jpg"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FtpForm',
  props: ['type', 'modelValue'],
  emits: ['update:modelValue'],
  data() {
    return {
      loading: false,

      updatedValue: {
        host: '',
        port: 22,
        user: '',
        password: '',
        fileSurfix: '',
        fileExtension: '',
      },
    };
  },

  watch: {
    updatedValue: {
      deep: true,
      handler() {
        this.$emit('update:modelValue', {
          method: this.updatedValue.method,
          url: this.updatedValue.url,
          body: this.updatedValue.body,
          headers: this.updatedValue.headers,
          query: this.updatedValue.query,
        });
      },
    },
  },

  methods: {
    add() {
      this.updatedValue[this.tab].push({ key: null, value: null });
    },
    remove(row) {
      const key = this.updatedValue[this.tab].indexOf(row);
      this.updatedValue[this.tab] = this.updatedValue[this.tab].filter(
        r => this.updatedValue[this.tab].indexOf(r) !== key
      );
    },
  },
  components: {},
};
</script>
<style scoped>
.dataRow {
  position: relative;
  display: table;
  width: 100%;
}
.dataRow .cell {
  display: table-cell;
}
.dataRow .w220 {
  width: 220px;
}
.dataRow .p50 {
  width: 50%;
}
.dataRow .cell.pl-15 {
  padding-left: 15px;
}
.dataRow .cell.l-line {
  border-left: 1px solid #ddd;
}
.mb-10 {
  margin-bottom: 10px;
}
</style>
