<template>
  <div>
    <div>
      <label class="Register_Label">Key:</label>
      <a-input v-model:value="input.key" :readOnly="true" />
    </div>
    <div class="row">
      <div class="col-5">
        <label class="Register_Label">Secret:</label>
        <a-input v-model:value="input.secret" />
      </div>

      <div class="col-2">
        <a-button
          @click="generatePassword"
          type="primary"
          style="margin-top: 30px"
        >
          Generate
        </a-button>
      </div>
      <div class="col-5">
        <label class="Register_Label">Ip:</label>
        <a-select
          v-model:value="input.ip"
          mode="tags"
          :token-separators="[',']"
          placeholder="No ip address"
          style="width: 100%"
        ></a-select>
      </div>
    </div>
    <div class="row" style="margin-top: 10px">
      <div class="col-12">
        <ApiPermissionByBlueprint
          v-if="blueprintById && input?.permissionByBlueprintId?.length"
          :blueprintById="blueprintById"
          :permissionByBlueprintId="input.permissionByBlueprintId"
          @update="update"
        >
        </ApiPermissionByBlueprint>
      </div>
    </div>
    <div style="margin-top: 25px; text-align: center">
      <a-button @click="onClickRegister" type="primary" size="large">
        Save
      </a-button>
    </div>
  </div>
</template>

<script>
import ApiKey from '@/apps/tenants/api/apiKeyManagement.api';
import { ApiPermissionByBlueprint } from '@roleManagement/views/ApiKeyRoleManager';
import { blueprintApi } from '@/apps/dataSystem/api';

export default {
  props: ['subtenantId', 'subtenantSlug', 'modelValue'],
  emits: ['reloadTable', 'cancel'],
  components: {
    ApiPermissionByBlueprint,
  },
  data() {
    return {
      blueprints: null,
      selectedRow: null,
      input: {
        subtenantId: this.subtenantId,
        subtenantSlug: this.subtenantSlug,
        key: null,
        secret: null,
        ip: [],
        permissionByBlueprintId: [],
      },
    };
  },
  async mounted() {
    this.selectedRow = this.modelValue;
    await this.fetchBlueprints();
    if (!this.selectedRow) {
      const apiKey = await ApiKey.generateApiKeyCredentials(
        this.subtenantId,
        crypto.randomUUID()
      );
      this.input.key = apiKey.key;
      this.generatePassword();
    } else {
      if (!this.selectedRow.permissionByBlueprintId) {
        this.input = this.selectedRow;
      }
      this.input = this.selectedRow;
    }

    if (!this?.input?.permissionByBlueprintId?.length) {
      // check if apiKey has blueprints permissions list
      this.input.permissionByBlueprintId = [];
      this.blueprints.forEach(blueprint => {
        this.input.permissionByBlueprintId.push({
          blueprintId: blueprint._id,
          createAuthorityMode: false,
          viewAuthorityMode: false,
          modifyAuthorityMode: false,
          deleteAuthorityMode: false,
        });
      });
    } else if (
      this.blueprints.length > this.selectedRow.permissionByBlueprintId.length
    ) {
      // check if a blueprint is added
      this.blueprints.forEach(blueprint => {
        const findBlueprint = this.input.permissionByBlueprintId.find(
          b => b.blueprintId.toString() === blueprint._id.toString()
        );
        if (!findBlueprint) {
          this.input.permissionByBlueprintId.push({
            blueprintId: blueprint._id,
            createAuthorityMode: false,
            viewAuthorityMode: false,
            modifyAuthorityMode: false,
            deleteAuthorityMode: false,
          });
        }
      });
    } else if (
      this.blueprints.length < this.selectedRow.permissionByBlueprintId.length
    ) {
      // check if a blueprint is deleted
      this.selectedRow.permissionByBlueprintId.forEach(blueprint => {
        const findBlueprint = this.blueprints.find(
          b => b._id.toString() === blueprint.blueprintId.toString()
        );
        if (!findBlueprint) {
          this.selectedRow.permissionByBlueprintId =
            this.selectedRow.permissionByBlueprintId.filter(function (obj) {
              return (
                obj.blueprintId.toString() !== blueprint.blueprintId.toString()
              );
            });
        }
      });
      this.$emit('update:modelValue', this.selectedRow);
    }
  },
  computed: {
    blueprintById() {
      if (this.blueprints) {
        return Object.fromEntries(
          this.blueprints.map(blueprint => [blueprint._id, blueprint])
        );
      }
      return null;
    },
  },
  methods: {
    async fetchBlueprints() {
      this.blueprints = await blueprintApi.getAll();
    },
    generatePassword() {
      const length = 20;
      const characters =
        '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$';
      this.input.secret = Array.from(
        crypto.getRandomValues(new Uint32Array(length))
      )
        .map(x => characters[x % characters.length])
        .join('');
    },
    async onClickRegister() {
      const inputKeyList = Object.keys(this.input);
      for (let key = 0; key < inputKeyList?.length; key += 1) {
        if (inputKeyList[key] === 'ip') {
          for (
            let i = 0;
            i < (this.input[inputKeyList[key]] ?? [])?.length;
            i += 1
          ) {
            const item = this.input[inputKeyList[key]][i];
            if (
              !/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
                item
              ) &&
              item !== '' &&
              item !== 'localhost'
            ) {
              this.$message.warning('Invalid ip address !');
              return;
            }
          }
        }
        if (typeof this.input[inputKeyList[key]] === 'string') {
          if (
            inputKeyList[key] !== 'apiRolesSerializer' &&
            inputKeyList[key] !== 'ip' &&
            (!this.input[inputKeyList[key]] ||
              this.input[inputKeyList[key]].trim().length === 0)
          ) {
            this.$message.warning('All fields are required!');
            return;
          }
        }
      }
      try {
        this.input.permissionByBlueprintId =
          this.input.permissionByBlueprintId.filter(
            permission =>
              permission.createAuthorityMode ||
              permission.viewAuthorityMode ||
              permission.modifyAuthorityMode ||
              permission.deleteAuthorityMode
          );
        if (this.input?._id) {
          await ApiKey.putOne(this.input); // has token
        } else {
          await ApiKey.postOne(this.input); // has token
        }
        this.$message.success('Registered successfully!');
        this.$emit('reloadTable');
        this.$emit('cancel');
      } catch (error) {
        this.error = true;
        this.$message.error('Something went wrong...');
      }
    },
    update(data) {
      this.input.permissionByBlueprintId = data;
    },
  },
};
</script>
<style>
.Register_Label {
  padding-top: 10px;
  margin-bottom: 0px;
}
</style>
