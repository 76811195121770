<template>
  <div>
    <div class="dataRow">
      <div class="cell p-50 mw300">
        <h4>Sources list</h4>
        <transition-group name="list" tag="div">
          <drag
            v-for="source in sources"
            :key="source._id"
            :data="source"
            :go-back="true"
            :disabled="ifSourceExist(source._id)"
            :class="ifSourceExist(source._id) ? 'disabled' : ''"
          >
            <div
              class="saas_card hoverable_drag mb-10"
              :class="
                selectedDataProvider.primarySourceId === source._id
                  ? 'primary'
                  : ''
              "
            >
              <div class="row-contents">
                <div class="group-text-container">
                  <div class="group-text">
                    <span class="title">
                      <MoreOutlined />
                      <MoreOutlined style="margin-left: -11px" />
                      {{ source.name }}
                    </span>
                  </div>
                </div>
                <div class="metadata">
                  <div class="controls">
                    <GoldOutlined
                      v-if="selectedDataProvider.primarySourceId === source._id"
                    />
                    {{ source.fields.length }} fields
                  </div>
                </div>
              </div>
            </div>
          </drag>
        </transition-group>
      </div>
      <div class="cell p-50 pl-15">
        <h3>Output fields</h3>
        <a-switch
          v-if="selectedDataProvider.type === 'auto'"
          checked-children="ACTIVE"
          un-checked-children="NOT ACTIVE"
          :disabled="!selectedDataProvider.primarySourceId"
          :checked="selectedDataProvider.isEnabled"
          style="position: absolute; right: 0; top: 0"
          :loading="loadingActiveOutput"
          @change="activOutput"
        />
        <div style="clear: both"></div>

        <drop @drop="onDropField">
          <div
            class="saas_card hoverable mb-10"
            :class="output.joined.length ? 'join-card' : ''"
            v-for="output in joinOutputFields"
            :key="output._id"
          >
            <div class="row-contents" @click="editField(output)">
              <div class="group-text-container">
                <div class="group-text">
                  <span class="title">
                    {{ output.label }}
                  </span>
                  <span class="muted-text">
                    {{
                      joinDetails(
                        output.originalSourceId,
                        output.originalId,
                        output.originalLabel !== output.label ? 2 : 1
                      )
                    }}
                  </span>
                  <span v-if="output.joined.length" class="muted-text">
                    <div v-for="join in output.joined" :key="join._id">
                      <ForkOutlined class="rotateIcon" />
                      Join with
                      {{ joinDetails(join.sourceId, join.sourceFieldId, 2) }}
                    </div>
                  </span>
                </div>
              </div>
              <div class="metadata">
                <div class="controls">
                  <FormOutlined />
                </div>
              </div>
            </div>
          </div>
        </drop>
        <div
          v-if="
            (joinSources && joinSources.length) ||
            selectedDataProvider.primarySourceId
          "
        >
          <a-popconfirm
            placement="topLeft"
            title="Are you sure to delete this joins?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="removeJoins"
          >
            <a-button
              :loading="loadingClearAll"
              type="link"
              style="float: right; margin-top: 10px"
            >
              <DeleteOutlined /> Clear all joins
            </a-button>
          </a-popconfirm>
        </div>
      </div>
    </div>

    <a-modal
      v-model:open="editFieldModal.activ"
      v-if="editFieldModal.field"
      :title="'Edit field: ' + editFieldModal.field.label"
      width="720px"
    >
      <div class="dataRow">
        <div class="cell p40">
          <a-input
            addon-before="New field name"
            v-model:value="editFieldModal.outputLabel"
            placeholder="Alias"
          />
        </div>
        <div class="cell p40 pl-15">
          <a-select
            style="width: 100%"
            placeholder="Transform value"
            allowClear
          >
            <a-select-option :value="'uppercase'">Uppercase</a-select-option>
            <a-select-option :value="'lowercase'">Lowercase</a-select-option>
          </a-select>
        </div>
        <div class="cell pl-15">
          <a-checkbox @change="onChangeTrim">Trim value</a-checkbox>
        </div>
      </div>
      <template #footer>
        <a-button key="back" @click="cancelEditFieldModal">Cancel</a-button>
        <a-button
          key="submit"
          :loading="loadingEditField"
          type="primary"
          @click="okEdit"
        >
          EDIT
        </a-button>
      </template>
    </a-modal>

    <a-modal v-model:open="joinModal.activ" title="Join" width="720px">
      <div class="dataRow">
        <div class="cell p70">
          <div class="dataRow">
            <div class="cell p50">
              <h4>Output fields</h4>
              <a-select
                style="width: 100%"
                v-model:value="joinModal.originField"
                placeholder="Select o field"
                allowClear
              >
                <a-select-option
                  v-for="joinOutput in joinOutputFields"
                  :key="joinOutput._id"
                  :value="
                    joinOutput._id +
                    ':' +
                    joinOutput.originalSourceId +
                    ':' +
                    joinOutput.originalId
                  "
                >
                  {{ joinOutput.label }}
                </a-select-option>
              </a-select>
            </div>
            <div
              class="cell p50 pl-15"
              v-if="joinModal.source && joinModal.source.fields"
            >
              <h4>`{{ joinModal.source.name }}` fields</h4>
              <a-select
                style="width: 100%"
                v-model:value="joinModal.destinationField"
                placeholder="Select o field"
                allowClear
              >
                <a-select-option
                  v-for="field in joinModal.source.fields"
                  :key="field._id"
                  :value="field._id"
                >
                  {{ field.outputLabel }}
                </a-select-option>
              </a-select>
            </div>
          </div>
        </div>
        <div class="cell pl-15">
          <h4>New field name</h4>
          <a-input
            addon-before="as"
            v-model:value="joinModal.outputLabel"
            placeholder="Alias"
          />
        </div>
      </div>

      <template #footer>
        <a-button key="back" @click="cancelJoinModal">Cancel</a-button>
        <a-button
          key="submit"
          :loading="loadingJoin"
          type="primary"
          :disabled="!joinModal.originField || !joinModal.destinationField"
          @click="okJoin"
        >
          JOIN
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { Drag, Drop } from 'vue-easy-dnd';

import {
  DataProviderActions,
  DataProviderGetters,
} from '@dataProvider/shared/dataProvider.store';
import {
  DeleteOutlined,
  ForkOutlined,
  FormOutlined,
  GoldOutlined,
  MoreOutlined,
} from '@ant-design/icons-vue';

export default {
  props: ['dataProviderId', 'selectedDataProvider'],
  name: 'DataProviderJoinsBuilder',
  data() {
    return {
      loading: false,
      loadingJoin: false,
      loadingClearAll: false,
      loadingEditField: false,
      loadingActiveOutput: false,

      joinModal: {
        activ: false,
        source: null,
        originField: null,
        destinationField: null,
        outputLabel: null,
      },
      editFieldModal: {
        activ: false,
        field: null,
        outputLabel: null,
      },
    };
  },
  async mounted() {
    DataProviderActions.calculateJoinsFields();
    // await this.selectPrimaryFields();
  },
  computed: {
    joinOutputFields: DataProviderGetters.getJoinOutputFields,

    joinSources() {
      return this.selectedDataProvider.aggregations;
    },
    sources() {
      return this.selectedDataProvider.sources
        .filter(s => !s.new)
        .map(source => {
          return {
            ...source,
            fields: source.fields.filter(field => field.isIncludedInOutput),
          };
        });
    },
  },

  methods: {
    async activOutput(status) {
      this.loadingActiveOutput = true;
      await DataProviderActions.editOneDataProvider(this.dataProviderId, {
        isEnabled: status,
      });
      this.loadingActiveOutput = false;
    },
    joinDetails(sourceId, sourceFieldId, type = 1) {
      const source = this.sources.find(s => s._id === sourceId);
      if (source) {
        const field = source.fields.find(f => f._id === sourceFieldId);
        if (field) {
          if (type === 1) {
            return `\`${source.name}\` field`;
          }
          if (type === 2) {
            return `\`${field.originalLabel}\` field on source \`${
              source.name
            }\``;
          }
        }
      }
      return null;
    },

    ifSourceExist(sourceId) {
      if (this.selectedDataProvider.primarySourceId === sourceId) {
        return true;
      }
      return this.joinSources.some(
        s =>
          (s.origin && s.origin.sourceId === sourceId) ||
          (s.destination && s.destination.sourceId === sourceId)
      );
    },

    async onDropField(e) {
      if (!this.joinSources.length) {
        // set priary source
        await DataProviderActions.editOneDataProvider(this.dataProviderId, {
          primarySourceId: e.data._id,
          isEnabled: 'no-update',
        });

        await DataProviderActions.createOneJoin(this.dataProviderId, {
          first: true,
          origin: {
            sourceId: e.data._id,
          },
        });
      } else {
        this.joinModal = {
          activ: true,
          source: e.data,
          originField: null,
          destinationField: null,
          outputLabel: null,
        };
      }
    },
    /* async selectPrimaryFields() {
      if (this.selectedDataProvider.primarySourceId) {
        if (
          this.sources.filter(
            (s) => s._id === this.selectedDataProvider.primarySourceId
          ).length
        ) {
          await DataProviderActions.createOneJoin(this.dataProviderId, {
            first: true,
            origin: {
              sourceId: this.selectedDataProvider.primarySourceId,
            },
          });
        }
        // we can delete `primarySourceId` if not found
      }
    }, */

    async removeJoins() {
      this.loadingClearAll = true;
      await DataProviderActions.deleteAllJoin(this.dataProviderId);
      // this.selectedDataProvider.primarySourceId = null;
      // this.selectedDataProvider.isEnabled = false;
      this.loadingClearAll = false;
    },
    cancelJoinModal() {
      this.joinModal = {
        activ: false,
        source: null,
        originField: null,
        destinationField: null,
        outputLabel: null,
      };
    },
    async okJoin() {
      const originSourceId = this.joinModal.originField.split(':')[1]; // unim cu `_id` pt ca se intampla sa avem id identice de la `sources` diferite
      const originFieldId = this.joinModal.originField.split(':')[2]; // unim cu `_id` pt ca se intampla sa avem id identice de la `sources` diferite
      this.loadingJoin = true;

      await DataProviderActions.createOneJoin(this.dataProviderId, {
        origin: {
          sourceId: originSourceId,
          sourceFieldId: originFieldId,
        },
        destination: {
          sourceId: this.joinModal.source._id,
          sourceFieldId: this.joinModal.destinationField,
        },
      });

      if (this.joinModal.outputLabel && this.joinModal.outputLabel !== '') {
        const isIncludedInOutput = 'no-update';
        const { outputLabel } = this.joinModal;
        await DataProviderActions.updateSourceField(
          this.dataProviderId,
          originSourceId,
          originFieldId,
          { outputLabel, isIncludedInOutput }
        );
      }

      this.loadingJoin = false;
      this.joinModal = {
        activ: false,
        source: null,
        originField: null,
        destinationField: null,
        outputLabel: null,
      };
    },

    editField(field) {
      this.editFieldModal = {
        activ: true,
        field,
        outputLabel: null,
      };
    },
    cancelEditFieldModal() {
      this.editFieldModal = {
        activ: false,
        field: null,
        outputLabel: null,
      };
    },
    async okEdit() {
      this.loadingEditField = true;
      const isIncludedInOutput = 'no-update';
      const { outputLabel } = this.editFieldModal;
      const sourceId = this.editFieldModal.field.originalSourceId;
      const fieldId = this.editFieldModal.field.originalId;

      await DataProviderActions.updateSourceField(
        this.dataProviderId,
        sourceId,
        fieldId,
        { outputLabel, isIncludedInOutput }
      );

      this.loadingEditField = false;
      this.cancelEditFieldModal();
    },

    onChangeTrim() {},
  },
  components: {
    Drag,
    Drop,
    ForkOutlined,
    FormOutlined,
    GoldOutlined,
    MoreOutlined,
    DeleteOutlined,
  },
};
</script>
<style scoped>
.dataRow {
  position: relative;
  display: table;
  width: 100%;
}
.dataRow .cell {
  display: table-cell;
  position: relative;
}
.dataRow .p50 {
  width: 50%;
}
.dataRow .p40 {
  width: 40%;
}
.dataRow .p70 {
  width: 70%;
}
.dataRow .p30 {
  width: 30%;
}
.dataRow .mw300 {
  width: 300px;
}
.dataRow .cell.pl-15 {
  padding-left: 15px;
}
.dataRow .cell.l-line {
  border-left: 1px solid #ddd;
}
</style>
