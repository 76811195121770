import axios from 'axios';

import { SlugGetters } from '@/slug.store';

const { getAppUrl, getSubtenantUrl } = SlugGetters;

const getOneById = async templateId => {
  try {
    const response = await axios.get(
      `${getAppUrl()}/template-management/${templateId}`
    );
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

const exportTemplate = async (templateId, data) => {
  try {
    const response = await axios.post(
      `${getSubtenantUrl()}/template-management/file-export/${templateId}`,
      { ...data },
      { responseType: 'blob' }
    );
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

export default {
  getOneById,
  exportTemplate,
};
