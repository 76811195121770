import createMathjsInstance from '@/core/utils/create-mathjs-instance';
import _ from 'lodash';
import { instanceApi } from '@dataSystem/api';
import FieldValueHelper from './helpers/fieldValue.helper';
import ConditionalLogicHelper from './helpers/conditionalLogic.helper';
import { ValidationHelper } from './helpers/validation.helper';

const BlueprintTableLogicMixin = {
  data() {
    return {
      focusedCell: {
        instanceId: null,
        fieldId: null,
      },
      lastEditedCell: {
        instanceId: null,
        fieldId: null,
      },
      cellInEdit: {
        instanceId: null,
        fieldId: null,
      },
      formLogicByInstance: {},
      validationHelper: new ValidationHelper(),
    };
  },
  created() {
    this.validationHelper = new ValidationHelper();
    this.init();
    window.addEventListener('keydown', this.onKeyDown);
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.onKeyDown);
  },
  watch: {
    instanceList() {
      this.formLogicByInstance = {};
      this.init();
    },
  },
  methods: {
    onKeyDown(e) {
      if (e.key === 'ArrowLeft') {
        this.moveFocus(0, -1); // left
      }
      if (e.key === 'ArrowUp') {
        this.moveFocus(-1, 0); // up
      }
      if (e.key === 'ArrowRight') {
        this.moveFocus(0, 1); // right
      }
      if (e.key === 'ArrowDown') {
        this.moveFocus(1, 0); // down
      }
      if (e.key === 'Enter') {
        this.editFocusedCell();
      }
      if (e.key === 'Escape') {
        this.clearFocus();
      }
    },
    init() {
      if (!this.instanceList) {
        return;
      }

      const { fieldsById } = this;
      for (let i = 0; i < this.instanceList?.length; i += 1) {
        const instance = this.instanceList[i];
        const instanceLogic = {};

        const { fieldIdToValue, fieldIdToCalculationDependencies } =
          FieldValueHelper.init({
            fieldsById,
            instance,
          });
        instanceLogic.fieldIdToValue = fieldIdToValue;
        instanceLogic.fieldIdToCalculationDependencies =
          fieldIdToCalculationDependencies;

        const { fieldIdToIsVisible, fieldIdToConditionalDependencies } =
          ConditionalLogicHelper.init({ fieldsById, fieldIdToValue });
        instanceLogic.fieldIdToIsVisible = fieldIdToIsVisible;
        instanceLogic.fieldIdToConditionalDependencies =
          fieldIdToConditionalDependencies;

        const { fieldIdToValidity } = this.validationHelper.init({
          fieldsById,
          fieldIdToValue: instanceLogic.fieldIdToValue,
        });
        instanceLogic.fieldIdToValidity = fieldIdToValidity;

        this.formLogicByInstance[instance._id] = instanceLogic;
      }
    },
    editCell(cell) {
      if (!this.canModifyInstanceMap[cell.instanceId]) {
        return;
      }

      const instanceLogic = this.formLogicByInstance[cell.instanceId];
      if (!instanceLogic.fieldIdToIsVisible[cell.fieldId]) {
        return;
      }
      if (this.fieldsById[cell.fieldId].logic.calculation.isEnabled) {
        return;
      }
      this.lastEditedCell = {
        instanceId: cell.instanceId,
        fieldId: cell.fieldId,
      };
      this.cellInEdit = {
        instanceId: cell.instanceId,
        fieldId: cell.fieldId,
      };
    },
    onEditCellComplete(cell, event) {
      if (!this.instanceList) {
        return;
      }
      const { fieldsById } = this;
      const instance = this.instanceList.find(i => i._id === cell.instanceId);

      const instanceLogic = JSON.parse(
        JSON.stringify(this.formLogicByInstance[instance._id])
      );

      instanceLogic.fieldIdToValue[cell.fieldId] = event.target.value;
      const fieldValidity = this.validationHelper.computeFieldValidity({
        field: fieldsById[cell.fieldId],
        fieldValue: event.target.value,
      });

      instanceLogic.fieldIdToValidity[cell.fieldId] = fieldValidity;

      if (fieldValidity.isEmptyButRequired || !fieldValidity.passedValidation) {
        this.formLogicByInstance[instance._id] = instanceLogic;
        event.target.focus();
        return;
      }

      const getFieldValue = fieldSuccessionIndex => {
        const field = Object.values(fieldsById).find(
          f => f.successionIndex === fieldSuccessionIndex
        );
        return instanceLogic.fieldIdToValue
          ? instanceLogic.fieldIdToValue[field._id]
          : 0;
      };

      const math = createMathjsInstance();
      math.import({
        field: getFieldValue,
      });

      const newFieldIdToValue = FieldValueHelper.computeFieldIdToValue({
        math,
        fieldsById,
        fieldIdToValue: instanceLogic.fieldIdToValue,
        fieldIdToCalculationDependencies:
          instanceLogic.fieldIdToCalculationDependencies,
      });

      if (!_.isEqual(instanceLogic.fieldIdToValue, newFieldIdToValue)) {
        instanceLogic.fieldIdToValue = newFieldIdToValue;
      }

      const newFieldIdToIsVisible =
        ConditionalLogicHelper.computeFieldIdToIsVisible({
          fieldsById,
          fieldIdToValue: instanceLogic.fieldIdToValue,
          fieldIdToConditionalDependencies:
            instanceLogic.fieldIdToConditionalDependencies,
          fieldIdToIsVisible: instanceLogic.fieldIdToIsVisible,
        });
      instanceLogic.fieldIdToIsVisible = newFieldIdToIsVisible;

      this.formLogicByInstance[instance._id] = instanceLogic;

      this.updateInstance(instance._id, instanceLogic.fieldIdToValue);

      this.cellInEdit = {
        instanceId: null,
        fieldId: null,
      };
    },
    async updateInstance(instanceId, updatedInstance) {
      const instanceIndex = this.instanceList.findIndex(
        i => i._id === instanceId
      );

      this.instanceList[instanceIndex] = updatedInstance;

      await instanceApi.patchOne(
        this.blueprint._id,
        instanceId,
        this.instanceList[instanceIndex]._ownerRoleId,
        updatedInstance
      );
    },
    isCellInEdit(cell) {
      if (!this.cellInEdit || !cell) {
        return false;
      }
      return (
        this.cellInEdit.instanceId === cell.instanceId &&
        this.cellInEdit.fieldId === cell.fieldId
      );
    },
    isCellFocused(cell) {
      if (!this.focusedCell || !cell) {
        return false;
      }
      return (
        this.focusedCell.instanceId === cell.instanceId &&
        this.focusedCell.fieldId === cell.fieldId
      );
    },
    focusCell({ instanceId, fieldId }) {
      this.focusedCell = {
        instanceId,
        fieldId,
      };
    },
    editFocusedCell() {
      if (this.cellInEdit.instanceId || this.cellInEdit.fieldId) {
        return;
      }
      if (!this.focusedCell.instanceId || !this.focusedCell.fieldId) {
        return;
      }
      if (
        this.lastEditedCell.instanceId === this.focusedCell.instanceId &&
        this.lastEditedCell.fieldId === this.focusedCell.fieldId
      ) {
        this.lastEditedCell = {
          instanceId: null,
          fieldId: null,
        };
        return;
      }

      this.editCell({
        instanceId: this.focusedCell.instanceId,
        fieldId: this.focusedCell.fieldId,
      });
    },
    moveFocus(vertical, horizontal) {
      if (
        this.cellInEdit.instanceId != null ||
        this.cellInEdit.fieldId != null
      ) {
        return;
      }

      if (!this.focusedCell.instanceId || !this.focusedCell.fieldId) {
        const instanceId = this.instanceList[0]?._id;
        const fieldId = this.tableColumns[0]?.key;
        if (instanceId && fieldId) {
          this.focusedCell = {
            instanceId,
            fieldId,
          };
        }
        return;
      }

      const focusedInstanceId = this.focusedCell.instanceId;
      const focusedFieldId = this.focusedCell.fieldId;

      const instanceIndex = this.instanceList.findIndex(
        i => i._id === focusedInstanceId
      );

      let newInstanceIndex = instanceIndex + vertical;
      if (newInstanceIndex < 0) {
        newInstanceIndex = this.instanceList.length - 1;
      } else {
        newInstanceIndex %= this.instanceList.length;
      }
      const newInstanceId = this.instanceList[newInstanceIndex]._id;

      const columnIndex = this.tableColumns.findIndex(
        c => c.key === focusedFieldId
      );
      let newColumnIndex = columnIndex + horizontal;

      if (newColumnIndex < 0) {
        newColumnIndex = this.tableColumns.length - 2;
      } else {
        newColumnIndex %= this.tableColumns.length - 1;
      }

      const newFieldId = this.tableColumns[newColumnIndex].key;

      this.focusedCell = {
        instanceId: newInstanceId,
        fieldId: newFieldId,
      };
    },
    clearFocus() {
      this.focusedCell = {
        instanceId: null,
        fieldId: null,
      };
      this.lastEditedCell = {
        instanceId: null,
        fieldId: null,
      };
      this.cellInEdit = {
        instanceId: null,
        fieldId: null,
      };
    },
  },
};

export default BlueprintTableLogicMixin;
